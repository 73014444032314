import React, {Box, IconButton, Paper} from '@material-ui/core';
import {FC, useCallback, useEffect, useRef} from 'react';

import {ReactComponent as CloseIcon} from 'assets/icon/light/times.svg';
import {ReactComponent as ActionIcon} from 'assets/icon/regular/action.svg';
import {ReactComponent as ChevronRight} from 'assets/icon/regular/chevron-right.svg';
import {ReactComponent as PlusIcon} from 'assets/icon/regular/plus.svg';

import Icon from './Icons';
import Typography from './i18n/Typography';

interface PWANotificationProps {
  os: 'ios' | 'android' | 'other';
  open: boolean;
  onClose(): void;
  onOpen(): void;
}

const PWANotification: FC<PWANotificationProps> = ({os, open, onClose, onOpen}) => {
  const installEvent = useRef<Event>();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      // Prevent the default hint from appearing on mobile
      e.preventDefault();
      installEvent.current = e;
      onOpen();
    });
  }, [onOpen]);

  useEffect(() => {
    (async () => {
      if ('getInstalledRelatedApps' in window.navigator) {
        const relatedApps: any[] = await (navigator as any).getInstalledRelatedApps();
        if (relatedApps.length > 0) {
          onClose();
        }
      }
    })();
  }, [onClose]);

  const install = useCallback(() => {
    if (installEvent.current) {
      (installEvent.current as any).prompt();
      (installEvent.current as any).userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          onClose();
        } else {
          onOpen();
        }
      });
    }
  }, [onClose, onOpen]);

  if (open && os === 'ios') {
    return (
      <Box
        style={{
          transform: 'translateX(-50%)',
        }}
        zIndex={9999}
        borderRadius={10}
        position="absolute"
        bottom={18}
        left="50%"
        bgcolor="rgba(255,255,255,0.9)">
        <Box position="relative" display="flex" flexDirection="column" px={4} py={1}>
          <Typography color="primary" variant="h6" align="center">
            title.pwa
          </Typography>
          <Typography color="textSecondary" align="center">
            title.pwa_sub
          </Typography>
          <Box display="flex" flexWrap="nowrap" mt={2}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                width={64}
                height={64}
                borderRadius={64}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="#D5D5D5">
                <Paper
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ActionIcon />
                </Paper>
              </Box>
              <Typography color="textSecondary" noWrap>
                common.click
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginBottom={3} mx={1}>
              <Icon style={{color: '#BAB9BA'}} svg={ChevronRight} />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                width={64}
                height={64}
                borderRadius={64}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{backgroundColor: '#D5D5D5'}}>
                <Paper
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Box
                    bgcolor="#6B6B6B"
                    borderRadius={4}
                    width={22}
                    height={22}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <Icon svg={PlusIcon} />
                  </Box>
                </Paper>
              </Box>
              <Typography color="textSecondary" noWrap>
                common.add_home
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginBottom={3} mx={1}>
              <Icon style={{color: '#BAB9BA'}} svg={ChevronRight} />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                width={64}
                height={64}
                borderRadius={64}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{backgroundColor: '#D5D5D5'}}>
                <Paper
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <img
                    style={{borderRadius: 8}}
                    width={32}
                    height={32}
                    src="/android-chrome-192.png"
                    alt="logo"
                  />
                </Paper>
              </Box>
              <Typography
                color="textSecondary"
                noWrap
                keys={{name: process.env.REACT_APP_TITLE_SHORT}}>
                common.add
              </Typography>
            </Box>
          </Box>

          <Box position="absolute" right={0} top={0}>
            <IconButton onClick={onClose}>
              <Icon style={{color: '#707070'}} fontSize="large" svg={CloseIcon} />
            </IconButton>
          </Box>

          <Box
            position="absolute"
            width={0}
            height={0}
            bottom={-15}
            left="50%"
            style={{
              borderStyle: 'solid',
              borderWidth: '15px 10px 0 10px',
              borderColor: 'rgba(255,255,255,0.9) transparent transparent transparent',
              transform: 'translateX(-10px)',
            }}
          />
        </Box>
      </Box>
    );
  }
  if (open && os === 'android') {
    return (
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        display="flex"
        bgcolor="#fff"
        px={2}
        py={1}
        onClick={install}>
        <img
          width={45}
          height={45}
          style={{borderRadius: 45}}
          src="/android-chrome-192.png"
          alt="logo"
        />
        <Box display="flex" flexDirection="column" ml={1} flex={1}>
          <Typography color="primary" variant="h6" align="left">
            title.pwa
          </Typography>
          <Typography color="textSecondary" align="left">
            title.pwa_sub
          </Typography>
        </Box>

        <IconButton onClick={onClose} size="small">
          <Icon style={{color: '#707070'}} fontSize="large" svg={CloseIcon} />
        </IconButton>
      </Box>
    );
  }

  return null;
};

export default PWANotification;
