import {AppBar, Backdrop, Box, Button, CircularProgress, Grid, Toolbar} from '@material-ui/core';
import {ErrorCode} from 'api';
import {useThemeStyles} from 'context/Theme';
import Cookies from 'js-cookie';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {loginByThirdParty, register} from 'model/user/UserActions';
import {LoginProvider, RegisterType} from 'model/user/UserTypes';

import {getSearchQuery} from 'utils/string';

import BackButton from 'components/BackButton';
import TermsDialog from 'components/TermsDialog';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

function ThirdPartyAuthPage() {
  const history = useHistory<{token?: string}>();
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [registered, setRegistered] = useState(true);

  const {provider} = useParams<{provider: LoginProvider}>();
  const token =
    provider === 'line'
      ? getSearchQuery(history.location.search, 'code')
      : history.location.state?.token || '';

  const error = getSearchQuery(history.location.search, 'error');

  const [name, setName] = useState('');
  const [invite, setInvite] = useState(
    getSearchQuery(history.location.search, 'invite') ||
      getSearchQuery(Cookies.get('search') ?? '', 'invite'),
  );
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<ErrorCode | undefined>(undefined);
  const [termsOpen, setTermsOpen] = useState(false);

  const goBack = useCallback(() => {
    history.replace('/auth/login');
  }, [history]);

  const handleRegister = useCallback(() => {
    setLoading(true);
    dispatch(
      register({
        name,
        otp: token,
        invite,
        type:
          provider === 'google'
            ? RegisterType.Google
            : provider === 'facebook'
            ? RegisterType.Facebook
            : RegisterType.Line,
      }),
    )
      .then(() => {
        history.push('/recommended');
      })
      .catch((err: ErrorCode) => {
        setErrorCode(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, name, invite, history, provider, token]);

  useEffect(() => {
    if (error) {
      return goBack();
    }

    dispatch(loginByThirdParty({provider, token}))
      .then((registered) => {
        if (!registered) {
          setRegistered(registered);
        }
      })
      .catch(goBack);

    // eslint-disable-next-line
  }, []);

  if (registered) {
    return null;
  }

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <BackButton onClick={goBack} />
        </Toolbar>
      </AppBar>

      <Box component="main" display="flex" flexDirection="column" mx={2} alignItems="center">
        <Box component="header" mb={2} textAlign="center">
          <Typography component="h3" variant="h4">
            title.personal_info
          </Typography>
        </Box>
        <Grid container component="form" direction="column" spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              required
              variant="outlined"
              color="secondary"
              label="input.name_label"
              value={name}
              onChange={(e) => setName(e.target.value)}
              errorCode={errorCode}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              label="input.invite_label"
              value={invite}
              onChange={(e) => setInvite(e.target.value)}
            />
          </Grid>
        </Grid>

        <Box my={1}>
          <Button
            onClick={() => {
              setTermsOpen(true);
            }}>
            <Typography color="primary">title.terms</Typography>
          </Button>
        </Box>

        <Box
          position="relative"
          display="flex"
          alignItems="center"
          className="gradientButtonWrapper"
          width="100%"
          my={2}>
          <Box
            className={['overlay', styles.gradient, 'gradientButton'].join(' ')}
            top={-4}
            bottom={-4}
          />
          <Button
            variant="outlined"
            size="large"
            fullWidth
            disabled={loading || name === ''}
            onClick={handleRegister}>
            <Typography>input.signup</Typography>
          </Button>
        </Box>
      </Box>

      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <TermsDialog
        open={termsOpen}
        onClose={() => {
          setTermsOpen(false);
        }}
      />
    </>
  );
}

export default ThirdPartyAuthPage;
