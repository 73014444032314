import dayjs from 'dayjs';

export function genSearchQuery(search: string, key: string, value: string) {
  const query = new URLSearchParams(search);
  query.set(key, value);
  return `?${query.toString()}`;
}

export function deleteSearchQuery(search: string, key: string) {
  const query = new URLSearchParams(search);
  query.delete(key);
  return `?${query.toString()}`;
}

export function getSearchQuery(search: string, key: string): string {
  const query = new URLSearchParams(search);
  // query.get(query)
  return query.get(key) || '';
}

export function isASCII(str: string): boolean {
  // eslint-disable-next-line
  return /^[\x00-\xFF]*$/.test(str);
}

export function calculateStringWidth(str: string): number {
  let width = 0;
  for (const c of str) {
    if (isASCII(c)) {
      width += 8.27;
    } else {
      width += 13.72;
    }
  }

  return width;
}

export function sliceStringByLines(str: string, window: number): string[] {
  let width = 0;
  const lines: string[] = [];
  let chars: string[] = [];

  for (const c of str) {
    if (isASCII(c)) {
      width += 8.27;
    } else {
      width += 13.72;
    }

    if (width >= window) {
      lines.push(chars.join(''));
      width -= window;
      chars = [c];
    } else {
      chars.push(c);
    }
  }

  if (chars.length > 0) {
    lines.push(chars.join(''));
  }

  return lines;
}

export function relativeTime(time: string): [string, number] {
  if (!time) {
    return ['', 0];
  }

  const beginDate = dayjs();
  const dayDiff = beginDate.diff(time, 'day');

  switch (true) {
    case dayDiff > 14:
      return [beginDate.format('MM-DD'), 0];
    case dayDiff >= 7:
      return ['time.weeks_ago', Math.floor(dayDiff / 7)];
    case dayDiff > 0:
      return ['time.days_ago', dayDiff];
    case beginDate.diff(time, 'hour') > 0:
      return ['time.hours_ago', beginDate.diff(time, 'hour')];
    case beginDate.diff(time, 'minute') > 0:
      return ['time.minutes_ago', beginDate.diff(time, 'minute')];
    default:
      return ['time.recent', 0];
  }
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const charactersLength = characters.length;
export function genId(length: number): string {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
