import {PayloadAction, PureAction} from 'model/helper';

export class UserAction {
  public static readonly USER_LOGIN = 'USER_LOGIN';
  public static readonly USER_THIRD_PARTY_LOGIN = 'USER_THIRD_PARTY_LOGIN';
  public static readonly USER_LOGIN_S = 'USER_LOGIN_S';
  public static readonly USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';

  public static readonly USER_LOGOUT = 'USER_LOGOUT';
  public static readonly USER_WALLET = 'USER_WALLET';
  public static readonly USER_WALLET_S = 'USER_WALLET_S';

  public static readonly USER_SEND_SMS = 'USER_SEND_SMS';
  public static readonly USER_VERIFY_SMS = 'USER_VERIFY_SMS';
  public static readonly USER_REGISTER = 'USER_REGISTER';

  public static readonly USER_FOLLOW = 'USER_FOLLOW';
  public static readonly USER_UNFOLLOW = 'USER_UNFOLLOW';

  public static readonly GET_MY_INFO = 'GET_MY_INFO';
  public static readonly GET_USER_INFO = 'GET_USER_INFO';
  public static readonly GET_QR_CODE = 'GET_QR_CODE';

  public static readonly GET_LINKING_STATUS = 'GET_LINKING_STATUS';
  public static readonly LINK_THIRD_PARTY = 'LINK_THIRD_PARTY';

  public static readonly UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

  public static readonly GET_FOLLOWEE_LIST = 'GET_FOLLOWEE_LIST';
  public static readonly GET_FOLLOWEE_LIST_S = 'GET_FOLLOWEE_LIST_S';
  public static readonly GET_FOLLOWER_LIST = 'GET_FOLLOWER_LIST';
  public static readonly GET_FOLLOWER_LIST_S = 'GET_FOLLOWER_LIST_S';

  public static readonly GET_ACTIVITY = 'GET_ACTIVITY';
  public static readonly GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
}

export enum RegisterType {
  Phone = 0,
  Google = 1,
  Facebook = 2,
  Line = 3,
}

export enum UserRole {
  unknown = 0,
  normal = 1,
  streamer = 2,
  agent = 3,
  agentCompany = 4,
  admin = 5,
  superAdmin = 6,
}

export enum Gender {
  male = 0,
  female = 1,
}

export enum MemberStatus {
  inactive = 0,
  active,
}

export enum ActivityType {
  Like = 0,
  LeaveComment,
  Follow,
}

export enum StreamerRecommendationType {
  FollowedYou = 1,
  Newcomer,
}

export type LoginProvider = 'facebook' | 'google' | 'line';

export declare namespace UserModel {
  export interface State {
    auth: {
      token: string;
      refreshToken: string;
      authKey: string;
    } | null;
    info: MyInfo;
    cache: {[userId: string]: MemberInfo};
    qr: {
      image: string;
      link: string;
      invite: string;
    } | null;
    linking: {
      phone: string;
      facebook: boolean;
      google: boolean;
      line: boolean;
    };
    followers: {
      loading: boolean;
      cursor: string | null;
      list: string[];
    };
    followees: {
      loading: boolean;
      cursor: string | null;
      list: string[];
    };
    activity: Activity[];
    recommendation: RecommendedStreamer[];
  }

  export interface MemberInfo {
    id: number;
    userId: string;

    name: string;
    // role: UserRole;
    bio: string;
    voiceBio: string;
    gender: Gender;
    // birthday: number;
    picture: string;
    covers: string[];
    inviteCode: string;
    numFollower: number;
    numFollowee: number;
    numPosts: number;
    followed: boolean;

    streamId: string;
  }

  export interface MyInfo extends Omit<MemberInfo, 'followed'> {
    account: string;
    phone: string;
    note: string;
    pushToken: string;
    ip: string;
    credit: number;
    registerAt: string;
    loginAt: string;
    device: string;
    badge: number;
    age: number;
    status: MemberStatus;
    countryCode: string;
    registerType: RegisterType;

    role: UserRole;
    externalId: string;

    // point: number;
    // gold: number;
  }

  export interface Setting {
    autoCharge: boolean;
    message: {
      stream: {
        charge: number;
        maxLength: number;
        pictureCharge: number;
        maxAmountPerMinute: number;
      };
      private: {
        charge: number;
        maxLength: number;
        pictureCharge: number;
        maxAmountPerDay: number;
      };
    };
  }

  export interface Activity {
    type: ActivityType;
    following: boolean;
    cover: string;
    streamerName: string;
    streamerPicture: string;
    streamerId: string;
    createdAt: string;
    targetId: string;
  }

  export interface RecommendedStreamer {
    type: StreamerRecommendationType;
    userId: string;
    followers: number;
    name: string;
    picture: string;
    following: boolean;
  }

  export type SendSMSAction = PayloadAction<
    typeof UserAction['USER_SEND_SMS'],
    {
      phone: string;
      countryCode: string;
    }
  >;

  export type VerifySMSAction = PayloadAction<
    typeof UserAction['USER_VERIFY_SMS'],
    {
      phone: string;
      countryCode: string;
      otp: string;
    }
  >;

  export type RegisterAction = PayloadAction<
    typeof UserAction['USER_REGISTER'],
    {
      name: string;
      account?: string;
      password?: string;
      otp: string;
      type: RegisterType;
      countryCode?: string;
      phone?: string;
      invite?: string;
    }
  >;

  export type LoginAction = PayloadAction<
    typeof UserAction['USER_LOGIN'],
    {
      phone: string;
      countryCode: string;
      password: string;
    }
  >;

  export type ThirdPartyLoginAction = PayloadAction<
    typeof UserAction['USER_THIRD_PARTY_LOGIN'],
    {
      provider: LoginProvider;
      token: string;
    }
  >;

  export type AfterLoginAction = PayloadAction<
    typeof UserAction['USER_LOGIN_S'],
    {
      token: string;
      authKey: string;
    }
  >;

  export type ResetPasswordAction = PayloadAction<
    typeof UserAction['USER_RESET_PASSWORD'],
    {
      phone: string;
      countryCode: string;
      otp: string;
      password: string;
    }
  >;

  export type FollowUserAction = PayloadAction<typeof UserAction['USER_FOLLOW'], string>;

  export type UnfollowUserAction = PayloadAction<typeof UserAction['USER_UNFOLLOW'], string>;

  export type GetUserInfoAction = PayloadAction<typeof UserAction['GET_USER_INFO'], MemberInfo>;

  export type GetMyInfoAction = PayloadAction<typeof UserAction['GET_MY_INFO'], MyInfo>;

  export type GetQRCodeAction = PayloadAction<
    typeof UserAction['GET_QR_CODE'],
    {
      image: string;
      link: string;
      invite: string;
    }
  >;

  export type UpdateProfileAction = PayloadAction<
    typeof UserAction['UPDATE_USER_PROFILE'],
    {
      userId: string;
      bio: string | null;
      picture: Blob | null;
      name: string | null;
    }
  >;

  export type GetFollowerListAction = PureAction<typeof UserAction['GET_FOLLOWER_LIST']>;
  export type AfterGetFollowerListAction = PayloadAction<
    typeof UserAction['GET_FOLLOWER_LIST_S'],
    {
      refresh: boolean;
      cursor: string | null;
      list: UserModel.MemberInfo[];
    }
  >;
  export type GetFolloweeListAction = PureAction<typeof UserAction['GET_FOLLOWEE_LIST']>;
  export type AfterGetFolloweeListAction = PayloadAction<
    typeof UserAction['GET_FOLLOWEE_LIST_S'],
    {
      refresh: boolean;
      cursor: string | null;
      list: UserModel.MemberInfo[];
    }
  >;

  export type GetLinkingStatusAction = PayloadAction<
    typeof UserAction['GET_LINKING_STATUS'],
    {
      phone: string;
      facebook: boolean;
      google: boolean;
      line: boolean;
    }
  >;
  export type LinkThirdPartyLoginAction = PayloadAction<
    typeof UserAction['LINK_THIRD_PARTY'],
    {
      provider: LoginProvider;
    }
  >;

  export type LogoutAction = PureAction<typeof UserAction.USER_LOGOUT>;

  export type GetActivityAction = PayloadAction<typeof UserAction['GET_ACTIVITY'], Activity[]>;

  export type GetRecommendationsAction = PayloadAction<
    typeof UserAction['GET_RECOMMENDATIONS'],
    RecommendedStreamer[]
  >;

  export type ActionTypes =
    | VerifySMSAction
    | SendSMSAction
    | RegisterAction
    | LoginAction
    | ThirdPartyLoginAction
    | AfterLoginAction
    | ResetPasswordAction
    | LogoutAction
    | FollowUserAction
    | UnfollowUserAction
    | GetUserInfoAction
    | GetMyInfoAction
    | GetQRCodeAction
    | UpdateProfileAction
    | GetFollowerListAction
    | AfterGetFollowerListAction
    | GetFolloweeListAction
    | AfterGetFolloweeListAction
    | GetLinkingStatusAction
    | LinkThirdPartyLoginAction
    | GetActivityAction
    | GetRecommendationsAction;
}
