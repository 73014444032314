import {
  AppBar,
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography as Text,
} from '@material-ui/core';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouteLink} from 'react-router-dom';

import {logout} from 'model/user/UserActions';

import BackButton from 'components/BackButton';
import Icon from 'components/Icons';
import Typography from 'components/i18n/Typography';

import {ReactComponent as BallotIcon} from 'assets/icon/light/ballot-circle.svg';
import {ReactComponent as InfoIcon} from 'assets/icon/light/info-circle.svg';
import {ReactComponent as LogoutIcon} from 'assets/icon/light/logout-circle.svg';
import {ReactComponent as UserIcon} from 'assets/icon/light/user-circle.svg';
import {ReactComponent as ChevronRight} from 'assets/icon/regular/chevron-right.svg';

function ProfileSettingPage() {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/profile" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.setting
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <List component="nav">
        <Link color="textPrimary" component={RouteLink} to="/profile/setting/link">
          <ListItem button>
            <ListItemIcon>
              <Icon svg={UserIcon} />
            </ListItemIcon>
            <ListItemText primary={<Typography>title.linked</Typography>} />
            <Icon svg={ChevronRight} />
          </ListItem>
        </Link>

        <Link color="textPrimary" component={RouteLink} to="/profile/wallet/usage">
          <ListItem button>
            <ListItemIcon>
              <Icon svg={BallotIcon} />
            </ListItemIcon>
            <ListItemText primary={<Typography>title.wallet</Typography>} />
            <Icon svg={ChevronRight} />
          </ListItem>
        </Link>

        <Link color="textPrimary" component={RouteLink} to="/faq">
          <ListItem button>
            <ListItemIcon>
              <Icon svg={InfoIcon} />
            </ListItemIcon>
            <ListItemText primary={<Typography>title.faq</Typography>} />
            <Icon svg={ChevronRight} />
          </ListItem>
        </Link>

        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <Icon svg={LogoutIcon} />
          </ListItemIcon>
          <ListItemText primary={<Typography color="primary">input.logout</Typography>} />
        </ListItem>

        <ListItem>
          <ListItemText primary={<Typography>label.version</Typography>} />
          <Text>{process.env.REACT_APP_VERSION}</Text>
        </ListItem>
      </List>
    </>
  );
}

export default ProfileSettingPage;
