import {ServerError} from 'api';

import {
  AppThunk,
  articleResponseToModel,
  postResponseToModel,
  userResponseToModel,
} from 'model/helper';
import {updateWallet} from 'model/wallet/WalletAction';

import PostsAPI from 'api/posts';

import {genId} from 'utils/string';

import {CommentType, PostAction, PostModel} from './PostTypes';

export const loadSuggestedUsers = (): AppThunk<void> => async (dispatch) => {
  try {
    dispatch({type: PostAction.GET_SUGGESTED_USERS});
    const res = await PostsAPI.getSuggestedUsers();
    dispatch({
      type: PostAction.GET_SUGGESTED_USERS_S,
      payload: res.users.map(userResponseToModel),
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadPosts = (refresh: boolean): AppThunk<Promise<PostModel.Article[]>> => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: PostAction.GET_POSTS});
    const cursor = refresh ? null : getState().post.posts.cursor;
    const res = await PostsAPI.getPosts(cursor);

    const articles = res.list.map(articleResponseToModel);
    dispatch({
      type: PostAction.GET_POSTS_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        articles,
      },
    });

    return articles;
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadUnlockedPosts = (refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: PostAction.GET_UNLOCKED_POSTS});
    const cursor = refresh ? null : getState().post.unlockedPosts.cursor;
    const res = await PostsAPI.getUnlockedPosts(cursor);
    dispatch({
      type: PostAction.GET_UNLOCKED_POSTS_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        articles: res.posts.map(postResponseToModel),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadSavedPosts = (refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: PostAction.GET_SAVED_POSTS});
    const cursor = refresh ? null : getState().post.savedPosts.cursor;
    const res = await PostsAPI.getSavedPosts(cursor);
    dispatch({
      type: PostAction.GET_SAVED_POSTS_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        articles: res.posts.map(postResponseToModel),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadBanners = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await PostsAPI.getBanners();
    dispatch({
      type: PostAction.GET_BANNERS,
      payload: {
        banners: res.banners.map((banner) => ({
          link: banner.link ?? undefined,
          id: banner.banner_id,
          title: banner.title,
          picture: banner.picture,
          status: banner.status,
        })),
        interval: res.displaySec * 1000,
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const likePost = (postId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch({type: PostAction.LIKE_POST, payload: postId});
    await PostsAPI.likePost(postId);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};
export const unlikePost = (postId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch({type: PostAction.UNLIKE_POST, payload: postId});
    await PostsAPI.unlikePost(postId);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};
export const savePost = (postId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch({type: PostAction.SAVE_POST, payload: postId});
    await PostsAPI.savePost(postId);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};
export const unsavePost = (postId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch({type: PostAction.UNSAVE_POST, payload: postId});
    await PostsAPI.unsavePost(postId);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const unlockPost = (postId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await PostsAPI.unlockPost(postId);
    const {post} = await PostsAPI.getPost(postId);
    dispatch({
      type: PostAction.UNLOCK_POST,
      payload: postResponseToModel(post),
    });
    dispatch(updateWallet());
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadSinglePost = (postId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const {post} = await PostsAPI.getPost(postId);
    dispatch({type: PostAction.GET_SINGLE_POST, payload: postResponseToModel(post)});
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadComments = (postId: string, refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: PostAction.GET_COMMENTS, payload: {postId}});
    const cursor = refresh ? null : getState().post.comment[postId].cursor;
    const res = await PostsAPI.getComments(postId, cursor);
    dispatch({
      type: PostAction.GET_COMMENTS_S,
      payload: {
        postId,
        cursor: res.cursor || null,
        refresh,
        comments: res.comment_list.map((comment) => ({
          id: comment.id.toString(),
          type: comment.comment_type,
          userId: comment.user_id,
          userPicture: comment.cover,
          userName: comment.user_name,
          content: comment.content,
          createdAt: comment.created_at,
          updatedAt: comment.updated_at,

          giftName: comment.gift_name,
          giftPicture: comment.gift_picture,
        })),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const sendComment = (postId: string, message: string): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    await PostsAPI.postComment(postId, message);
    const now = new Date();
    dispatch({
      type: PostAction.SEND_COMMENT,
      payload: {
        postId,
        comment: {
          id: genId(6),
          type: CommentType.Text,
          userId: getState().user.info.userId,
          userPicture: getState().user.info.picture,
          userName: getState().user.info.name,
          content: message,
          createdAt: now.toISOString(),
          updatedAt: now.toISOString(),

          giftName: '',
          giftPicture: '',
        },
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export function playStoryVideo(postId: string, index: number): PostModel.PlayVideoAction {
  return {
    type: PostAction.PLAY_VIDEO,
    payload: `${postId}@${index}`,
  };
}

export function stopStoryVideo(): PostModel.StopVideoAction {
  return {type: PostAction.STOP_VIDEO};
}

export function muteVideo(): PostModel.MuteVideoAction {
  return {type: PostAction.MUTE_VIDEO};
}

export function unmuteVideo(): PostModel.UnmuteVideoAction {
  return {type: PostAction.UNMUTE_VIDEO};
}
