import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Toolbar,
  Typography as Text,
  useTheme,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {KeyboardEvent, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {openGiftDrawer} from 'model/gift/GiftAction';
import {GiftLocation} from 'model/gift/GiftTypes';
import {AppDispatch} from 'model/helper';
import {loadComments, loadSinglePost, sendComment} from 'model/post/PostAction';
import {getArticleById, getCommentsById} from 'model/post/PostSelector';
import {CommentType} from 'model/post/PostTypes';

import {useWindowSize} from 'utils/hook';
import {relativeTime} from 'utils/string';
import {sliceStringByLines} from 'utils/string';

import ArticleParagraph from 'components/ArticleParagraph';
import BackButton from 'components/BackButton';
import Icon from 'components/Icons';
import PointLabel from 'components/PointLabel';
import CoronaText from 'components/i18n/CoronaText';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

import {ReactComponent as GiftIcon} from 'assets/icon/regular/gift.svg';

function CommentsPage() {
  const params = useParams<{id: string}>();
  const dispatch = useDispatch<AppDispatch>();
  const width = useWindowSize();
  const styles = useThemeStyles();
  const theme = useTheme();

  const article = useSelector(getArticleById(params.id));
  const {comments, loading, cursor} = useSelector(getCommentsById(params.id));
  const hasNextPage = cursor !== null;

  const [message, setMessage] = useState('');
  const list = useRef<VariableSizeList>(null);
  const firstId = useRef('');

  useEffect(() => {
    dispatch(loadSinglePost(params.id));
    dispatch(loadComments(params.id, true));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (comments.length > 0 && firstId.current !== comments[0].id) {
      firstId.current = comments[0].id;
      list.current?.resetAfterIndex(0);
      list.current?.scrollToItem(0, 'start');
    }
  }, [comments]);

  const countHeight = useCallback(
    (index: number) => {
      const comment = comments[index];

      if (comment.type === CommentType.Text) {
        const content = `${comment.userName} ${comment.content}`;
        const lines = sliceStringByLines(content, width - 60);
        return 44 + (lines.length + 1) * 20;
      } else {
        const content = `${comment.userName}       ${comment.giftName}`;
        const lines = sliceStringByLines(content, width - 60);
        return 130 + (lines.length + 1) * 20;
      }
    },
    [width, comments],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < comments.length - 1;
    },
    [comments, hasNextPage],
  );

  const itemKey = useCallback((index: number) => comments[index].id, [comments]);

  const handleSubmitMessage = useCallback(() => {
    if (message) {
      setMessage('');
      dispatch(sendComment(params.id, message));
    }
  }, [message, dispatch, params.id]);

  const handleSubmitMessageByEnter = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        handleSubmitMessage();
      }
    },
    [handleSubmitMessage],
  );

  const loadMore = useCallback(() => {
    if (loading || !hasNextPage) {
      return null;
    }

    return dispatch(loadComments(params.id, false));
  }, [loading, hasNextPage, dispatch, params.id]);

  const renderComment = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const comment = comments[index];
      const [timeKey, timeDiff] = relativeTime(comment.createdAt || '');

      return (
        <Box display="flex" p={2} style={style} className={styles.commentItem}>
          <Avatar src={comment.userPicture} className="avatar" />
          <Paper>
            <Box p={1} display="flex" flexDirection="column">
              {comment.type === CommentType.Text ? (
                <Text className="mono">
                  {comment.userName} {comment.content}
                </Text>
              ) : (
                <>
                  <Text className="mono">
                    {comment.userName}{' '}
                    <CoronaText
                      className="mono"
                      text="message.gave"
                      shadow={theme.palette.primary[theme.palette.type]}
                    />{' '}
                    <CoronaText
                      className="mono"
                      text={comment.giftName}
                      shadow={theme.palette.primary[theme.palette.type]}
                    />
                  </Text>
                  <Box width={80} height={80} alignSelf="center" my={0.5}>
                    <img alt={comment.giftName} src={comment.giftPicture} className="fullMedia" />
                  </Box>
                </>
              )}

              <Box>
                <Typography color="textSecondary" keys={{n: timeDiff}}>
                  {timeKey}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      );
    },
    [comments, theme, styles],
  );

  const handleOpenGift = useCallback(() => {
    dispatch(
      openGiftDrawer({
        userId: article.userId,
        targetId: article.id,
        location: GiftLocation.Post,
        onSend() {
          dispatch(loadComments(article.id, true));
        },
      }),
    );
  }, [dispatch, article]);

  const [timeKey, timeDiff] = relativeTime(article?.createdAt || '');

  return (
    <Box className="full" display="flex" flexDirection="column">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/" />
          </Box>
          <Box display="flex" flex={2} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.comments
            </Typography>
          </Box>
          <PointLabel flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>

      <Box display="flex" flexDirection="column" flex={1}>
        {article && (
          <>
            <Box display="flex" m={2}>
              <Avatar src={article.userPicture} />
              <Box flex="1" mx={2} className={styles.commentHeader}>
                <Text className="name">{article.userName}</Text>
                <ArticleParagraph p={article.content} />
                <Typography component="p" color="textSecondary" keys={{n: timeDiff}}>
                  {timeKey}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box display="flex" flex="1">
              <AutoSizer>
                {({height, width}) => (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={comments.length}
                    loadMoreItems={loadMore}>
                    {({onItemsRendered, ref}) => {
                      return (
                        <VariableSizeList
                          key={width}
                          itemKey={itemKey}
                          onItemsRendered={onItemsRendered}
                          ref={(ele) => {
                            (ref as any)(ele);
                            (list as any).current = ele;
                          }}
                          width={width}
                          height={height}
                          itemSize={countHeight}
                          itemCount={comments.length}>
                          {renderComment}
                        </VariableSizeList>
                      );
                    }}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </Box>
          </>
        )}
      </Box>

      <Divider />
      <Box display="flex" p={2}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="input.comment_placeholder"
          fullWidth
          className={styles.roundInput}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleSubmitMessageByEnter}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button size="small" className="roundInputButton" onClick={handleSubmitMessage}>
                  <Typography color="primary">common.submit</Typography>
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <IconButton className={`outlinedIconButton ${styles.gift}`} onClick={handleOpenGift}>
          <Icon svg={GiftIcon} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default CommentsPage;
