import dayjs from 'dayjs';
import memoize from 'lodash.memoize';
import {createSelector} from 'reselect';

import {RootState} from 'model/helper';

import {MessageModel, MessageType} from './MessageTypes';

const getMessageState = (state: RootState) => state.message;
const getMailboxState = (state: RootState) => getMessageState(state).mailbox;

export const isMailboxLoading = (state: RootState) => getMailboxState(state).loading;
export const hasMailboxNextPage = (state: RootState) => getMailboxState(state).cursor !== null;
export const getMailbox = createSelector([getMailboxState], (mailbox) => {
  return mailbox.list.map((userId) => mailbox.cache[userId]);
});
export const messengerSelector = createSelector(
  (state: RootState) => state.message.mailbox.cache,
  (messengerCache) =>
    memoize<(id: string) => MessageModel.Messenger>((userId) => {
      return (
        messengerCache[userId] || {
          userId,
          userName: '',
          userPicture: '',
          price: 0,
          lastMessage: '',
          lastMessageTime: new Date().toISOString(),
          numUnread: 0,
          isCustomerService: false,
        }
      );
    }),
);

// message list for ui (from old to new, DateLabel)
export const messagesSelector = createSelector(
  (state: RootState) => state.message.conversation,
  (conversation) =>
    memoize<(id: string) => MessageModel.Message[]>((userId) => {
      const messages = conversation[userId]?.messages || [];
      const items: MessageModel.Message[] = [];
      messages.forEach((msg, index) => {
        items.push(msg);
        if (messages[index + 1] && msg.date !== messages[index + 1].date) {
          items.push({
            type: MessageType.DateLabel,

            id: dayjs(msg.createdAt).format('DD/MM/YYYY'),
            senderId: '',
            receiverId: '',
            message: msg.date,
            date: msg.date,
            createdAt: msg.createdAt,
            unlocked: true,
            unlockPoint: 0,

            giftName: '',
            giftPicture: '',
          });
        }
      });

      items.reverse();

      return items;
    }),
);

export const messagesNextPageSelector = createSelector(
  (state: RootState) => state.message.conversation,
  (conversation) =>
    memoize<(id: string) => boolean>((userId) => Boolean(conversation[userId]?.cursor)),
);

export const messagesLoadingSelector = createSelector(
  (state: RootState) => state.message.conversation,
  (conversation) =>
    memoize<(id: string) => boolean>(
      (userId) =>
        conversation[userId]?.loading === true || conversation[userId]?.loading === undefined,
    ),
);

const getChannel = (state: RootState) => getMessageState(state).channel;
export const getListeners = (state: RootState) => getChannel(state).listeners;

export const hasMessageUnreadSelector = createSelector(
  (state: RootState) => state.message.mailbox.cache,
  (cache) => Object.keys(cache).some((id) => cache[id].numUnread > 0),
);
