import {Box, ButtonBase, Typography as Text, useTheme} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {useDispatch} from 'react-redux';

import {sendGift} from 'model/gift/GiftAction';
import {GiftModel} from 'model/gift/GiftTypes';
import {AppDispatch} from 'model/helper';

interface GiftItemProps {
  item: GiftModel.Gift;
  enableCombo?: boolean;
}

function GiftItem({item, enableCombo = false}: GiftItemProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [count, setCount] = useState(0);
  const theme = useTheme();

  const handleSendGift = useCallback(() => {
    dispatch(sendGift(item.id)).then(() => {
      if (enableCombo) {
        setCount((v) => v + 1);
      }
    });
  }, [dispatch, item, enableCombo]);

  return (
    <Box position="relative" width="100%" height="100%">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={2}
        py={1}
        textAlign="center">
        <img alt={item.name} src={item.picture} className="full" />
        <Text variant="subtitle2">{item.name}</Text>
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="diamond" />
          <Text>{item.point}</Text>
        </Box>
      </Box>

      <Box hidden={count === 0} position="absolute" right={5} top={5}>
        <CountdownCircleTimer
          key={count}
          duration={item.combo || 2}
          isPlaying={count > 0}
          size={20}
          strokeWidth={2}
          colors={theme.palette.primary[theme.palette.type]}
          onComplete={() => setCount(0)}>
          {() => count}
        </CountdownCircleTimer>
      </Box>

      <ButtonBase focusRipple component="div" className="overlay" onClick={handleSendGift} />
    </Box>
  );
}

export default GiftItem;
