import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import HomePage from './HomePage';
import SinglePostPage from './SinglePostPage';

function HomeRoutes() {
  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>

      <Route exact path="/post/:id">
        <SinglePostPage />
      </Route>
      <Redirect to={{pathname: '/'}} />
    </Switch>
  );
}

export default HomeRoutes;
