import {TextField as MUITextField, TextFieldProps as MUITextFieldProps} from '@material-ui/core';
import {ErrorCode} from 'api';
import i18n, {LocaleContext} from 'context/Locale';
import React, {forwardRef, useContext, useState} from 'react';

type TextFieldProps = MUITextFieldProps & {
  placeholder?: string;
  label?: string;
  keys?: object;
  errorCode?: ErrorCode | boolean;
};

function TextField({
  placeholder,
  label,
  keys,
  errorCode,
  error,
  helperText,
  onKeyDown,
  ...props
}: TextFieldProps) {
  const [composition, setComposition] = useState(false);
  useContext(LocaleContext);
  if (!i18n.language) {
    placeholder = '';
    label = '';
  } else {
    placeholder = placeholder ? i18n.t(placeholder, keys) : '';
    label = label ? i18n.t(label, keys) : '';
  }

  const hasError = Boolean(errorCode || error);
  const errorText = errorCode ? i18n.t(`error.${errorCode}`, keys) : undefined;

  return (
    <MUITextField
      placeholder={placeholder}
      label={label}
      error={hasError}
      helperText={errorText || helperText}
      onCompositionStart={() => setComposition(true)}
      onCompositionEnd={() => setComposition(false)}
      onKeyDown={composition ? undefined : onKeyDown}
      {...props}
    />
  );
}

export default forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
  <TextField innerRef={ref} {...props} />
));
