import day from 'dayjs';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHRBackend from 'i18next-xhr-backend';
import {createContext} from 'react';

export type Locale = 'zh-tw' | 'zh-cn' | 'en-us' | 'loading';
export const LocaleContext = createContext<Locale>('loading');
export const LocaleProvider = LocaleContext.Provider;

i18next
  .use(XHRBackend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'zh-tw',
    load: 'currentOnly',
    lowerCaseLng: true,
    // ns: ['common'],
    // defaultNS: 'common',
    interpolation: {
      format(value, format, lng) {
        if (format === 'datetime') {
          switch (lng) {
            case 'zh-cn':
              return day(value).format('YYYY-MM-DD HH:mm:ss');
            case 'zh-tw':
              return day(value).format('YYYY/MM/DD HH:mm:ss');
            // case 'en-us':
            default:
              return day(value).format('MM/DD/YYYY HH:mm:ss');
          }
        }
        return value;
      },
      escapeValue: false,
    },
    detection: {
      lookupLocalStorage: 'lng',
      lookupCookie: 'lng',
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
      crossDomain: false,
    },
  });

export default i18next;
