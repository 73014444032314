import {AppBar, Box, Toolbar, Typography as Text} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {loadKeywordPosts} from 'model/explore/ExploreAction';
import {
  exploreArticlesSelector,
  hasExploreArticlesNextPage,
  isExploreArticlesLoading,
} from 'model/explore/ExploreSelector';
import {SearchType} from 'model/explore/ExploreTypes';
import {AppDispatch} from 'model/helper';
import {PostModel} from 'model/post/PostTypes';

import BackButton from 'components/BackButton';

import StoryGallery from './StoryGallery';

function ExploreTagPage() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const {tag} = useParams<{tag: string}>();

  const articles = useSelector(exploreArticlesSelector);
  const isLoading = useSelector(isExploreArticlesLoading);
  const hasNextPage = useSelector(hasExploreArticlesNextPage);

  useEffect(() => {
    dispatch(loadKeywordPosts(tag, SearchType.Tag, true));
  }, [dispatch, tag]);

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadKeywordPosts(tag, SearchType.Streamer, false));
  }, [isLoading, hasNextPage, tag, dispatch]);

  const toTagStoryList = useCallback(
    (item: PostModel.Article | undefined) => {
      return () => {
        if (item) {
          history.push({
            pathname: `/explore/tag/${tag}/list`,
            state: {start: item.id},
          });
        }
      };
    },
    [history, tag],
  );

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/explore" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Text variant="h6" component="h2">
              #{tag}
            </Text>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <StoryGallery
        items={articles}
        hasNextPage={hasNextPage}
        loadMore={loadMore}
        onClickItem={toTagStoryList}
      />
    </Box>
  );
}

export default ExploreTagPage;
