import {Box} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties, ReactNode, useCallback, useEffect, useState} from 'react';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface CarouselProps {
  children: ReactNode[];
  interval: number;
  style?: CSSProperties;
  disabled?: boolean;
}

function Carousel({children, interval, style, disabled = false}: CarouselProps) {
  const [index, setIndex] = useState(0);
  const styles = useThemeStyles();

  useEffect(() => {
    setIndex(0);
  }, [children]);

  const handleChangeIndex = useCallback((index: number) => {
    if (!isNaN(index)) {
      setIndex(index);
    }
  }, []);

  return (
    <Box position="relative" style={style}>
      <AutoPlaySwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        interval={interval}
        autoplay={!disabled}>
        {children}
      </AutoPlaySwipeableViews>
      <Box className={styles.bannerPagination}>
        {children.map((_, i) => (
          <span key={i} className={index === i ? 'active' : 'inactive'} />
        ))}
      </Box>
    </Box>
  );
}

export default Carousel;
