import {Box, IconButton, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useRef, useState} from 'react';

import {ReactComponent as WaveformIcon} from 'assets/icon/regular/waveform.svg';
import {ReactComponent as PlayIcon} from 'assets/icon/solid/play.svg';
import {ReactComponent as StopIcon} from 'assets/icon/solid/stop.svg';

import Icon from './Icons';

interface AudioButtonProps {
  src: string;
}

function AudioButton({src}: AudioButtonProps) {
  const styles = useThemeStyles();
  const player = useRef<HTMLAudioElement>(null);
  const [length, setLength] = useState(0);
  const [playing, setPlaying] = useState(false);

  const play = useCallback(() => {
    player.current?.play().then(() => {
      setPlaying(true);
    });
  }, []);

  const stop = useCallback(() => {
    if (player.current) {
      player.current.pause();
      player.current.currentTime = 0;
      setPlaying(false);
    }
  }, []);

  return (
    <Box
      display="flex"
      className={styles.gradient}
      borderRadius={16}
      height={34}
      alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        className={styles.bg}
        borderRadius={16}
        height={32}
        mx="1px">
        <Box ml={1}>
          <IconButton
            size="small"
            className={`${styles.gradient} audioPlay`}
            onClick={playing ? stop : play}>
            <Icon svg={playing ? StopIcon : PlayIcon} fontSize="small" />
          </IconButton>
        </Box>

        <Box display="flex" mx={1}>
          <Icon svg={WaveformIcon} color="primary" />
          <Icon svg={WaveformIcon} color="primary" />
        </Box>

        <Box mr={1}>
          <Text color="primary">{length}"</Text>
        </Box>
      </Box>

      <audio
        ref={player}
        onLoadedMetadata={() => {
          const seconds = player.current?.duration || 0;
          setLength(Math.round(seconds));
        }}
        onEnded={() => {
          setPlaying(false);
        }}>
        <source src={src} type="audio/mp3" />
      </audio>
    </Box>
  );
}

export default AudioButton;
