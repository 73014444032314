import {AppBar, Box, Toolbar} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {openGiftDrawer} from 'model/gift/GiftAction';
import {GiftLocation} from 'model/gift/GiftTypes';
import {AppDispatch} from 'model/helper';
import {
  likePost,
  loadSinglePost,
  savePost,
  unlikePost,
  unlockPost,
  unsavePost,
} from 'model/post/PostAction';
import {getArticleById} from 'model/post/PostSelector';

import BackButton from 'components/BackButton';
import Brand from 'components/Brand';
import PointLabel from 'components/PointLabel';
import StoryCard from 'components/StoryCard';
import PostActionDrawer from 'components/drawer/PostActionDrawer';

function SinglePostPage() {
  const params = useParams<{id: string}>();
  const dispatch = useDispatch<AppDispatch>();
  const article = useSelector(getArticleById(params.id));

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    dispatch(loadSinglePost(params.id));
  }, [dispatch, params.id]);

  const handleUnlock = useCallback(() => {
    if (article) {
      return dispatch(unlockPost(article.id));
    }
    return Promise.resolve();
  }, [dispatch, article]);

  const handleLike = useCallback(() => {
    if (article && article.like) {
      dispatch(unlikePost(article.id));
    } else if (article && !article.like) {
      dispatch(likePost(article.id));
    }
  }, [dispatch, article]);

  const handleSave = useCallback(() => {
    if (article && article.like) {
      dispatch(unsavePost(article.id));
    } else if (article && !article.like) {
      dispatch(savePost(article.id));
    }
  }, [dispatch, article]);

  const handleOpenGift = useCallback(() => {
    dispatch(
      openGiftDrawer({
        userId: article.userId,
        targetId: article.id,
        location: GiftLocation.Post,
      }),
    );
  }, [dispatch, article]);

  return (
    <Box className="full" display="flex" flexDirection="column" flex="1">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1} display="flex" alignItems="center">
            <BackButton to="/" />
            <Brand />
          </Box>
          <PointLabel flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>

      {article && (
        <StoryCard
          article={article}
          expanded
          onUnlock={handleUnlock}
          onClickLike={handleLike}
          onClickSave={handleSave}
          onClickGift={handleOpenGift}
          onClickMore={() => setOpened(true)}
        />
      )}

      <PostActionDrawer article={opened ? article : undefined} onClose={() => setOpened(false)} />
    </Box>
  );
}

export default SinglePostPage;
