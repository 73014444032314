import {List, ListSubheader} from '@material-ui/core';
import React, {CSSProperties, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {
  loadRecentSearch,
  loadSuggestedSearch,
  saveSearchResult,
  search,
} from 'model/explore/ExploreAction';
import {getRecentSearch, getSearchState, getSuggestSearch} from 'model/explore/ExploreSelector';
import {ExploreModel, SearchType} from 'model/explore/ExploreTypes';
import {AppDispatch} from 'model/helper';

import SearchItem from 'components/SearchItem';
import Typography from 'components/i18n/Typography';

interface SearchListProps {
  width: number;
  height: number;
  keyword: string;
  type: SearchType;
  showSuggested?: boolean;
}

function SearchList({width, height, keyword, type, showSuggested = false}: SearchListProps) {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const suggestedSearch = useSelector(getSuggestSearch);
  const allRecentSearch = useSelector(getRecentSearch);
  const recentSearch = useMemo(() => {
    if (type === SearchType.All) {
      return allRecentSearch;
    } else {
      return allRecentSearch.filter((item) => item.type === type);
    }
  }, [allRecentSearch, type]);

  const searchState = useSelector(getSearchState);
  const isLoading = searchState[type].loading;
  const hasNextPage = searchState[type].cursor !== null;
  const searchResults = searchState[type].list;

  const listStyle: CSSProperties = {width, height};

  useEffect(() => {
    if (keyword) {
      dispatch(search(keyword, type, true));
    } else {
      if (showSuggested) {
        dispatch(loadSuggestedSearch());
      }
      dispatch(loadRecentSearch());
    }
  }, [dispatch, keyword, type, showSuggested]);

  const toSearchResultPage = useCallback(
    (item: ExploreModel.SearchedItem) => {
      return () => {
        dispatch(saveSearchResult(item.keyword, item.type));

        if (item.type === SearchType.Streamer) {
          history.push(`/explore/user/${item.keyword}`);
        } else {
          history.push(`/explore/tag/${item.keyword}`);
        }
      };
    },
    [dispatch, history],
  );

  const renderRow = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const item = searchResults[index];
      return (
        <SearchItem
          item={item}
          style={style}
          ContainerComponent="div"
          onClick={toSearchResultPage(item)}
        />
      );
    },
    [searchResults, toSearchResultPage],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < searchResults.length - 1;
    },
    [hasNextPage, searchResults],
  );

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(search(keyword, type, false));
  }, [isLoading, hasNextPage, dispatch, keyword, type]);

  return keyword ? (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={searchResults.length}
      loadMoreItems={loadMore}>
      {({onItemsRendered, ref}) => {
        return (
          <FixedSizeList
            width={width}
            height={height}
            itemSize={70}
            itemCount={searchResults.length}
            onItemsRendered={onItemsRendered}
            ref={ref}>
            {renderRow}
          </FixedSizeList>
        );
      }}
    </InfiniteLoader>
  ) : (
    <List style={listStyle}>
      {showSuggested && (
        <>
          <ListSubheader hidden={suggestedSearch.length === 0}>
            <Typography variant="body1" color="textPrimary">
              label.recommended
            </Typography>
          </ListSubheader>
          {suggestedSearch.map((item) => (
            <SearchItem key={item.keyword} item={item} onClick={toSearchResultPage(item)} />
          ))}
        </>
      )}

      <ListSubheader hidden={recentSearch.length === 0}>
        <Typography variant="body1" color="textPrimary">
          label.recent_search
        </Typography>
      </ListSubheader>
      {recentSearch.map((item) => (
        <SearchItem key={item.keyword} item={item} onClick={toSearchResultPage(item)} />
      ))}
    </List>
  );
}

export default SearchList;
