import {Avatar, Box, Button, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React from 'react';

import {WatchMode} from 'model/livestream/LivestreamTypes';

import {format} from 'utils/number';

import Typography from './i18n/Typography';

interface ModeDialogProps {
  mode: WatchMode;
  userPicture?: string;
  userName: string;
  omCharge: number;
  ooCharge: number;
  balance: number;
  occupied: boolean;
  onClick?: () => void;
  onDeposit(): void;
}

function ModeDialog({
  mode,
  userPicture,
  userName,
  omCharge,
  ooCharge,
  balance,
  occupied,
  onClick,
  onDeposit,
}: ModeDialogProps) {
  const styles = useThemeStyles();
  const charge = mode === WatchMode.Free ? 0 : mode === WatchMode.OM ? omCharge : ooCharge;
  const needDeposit = balance < charge;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pb={2}
      className={`${styles.transparentBg} ${styles.livestreamDialog}`}
      borderRadius={5}>
      <Box className={styles.gradient} p={0.5} textAlign="center">
        <Typography>{`title.streaming.${mode}`}</Typography>
      </Box>
      <Box my={2} alignSelf="center" textAlign="center">
        <Avatar src={userPicture} />
        <Text variant="h6" component="h6">
          {userName}
        </Text>
      </Box>

      {!occupied && (
        <>
          <Box mx={3}>
            <Box
              position="relative"
              display="flex"
              width="100%"
              alignItems="center"
              className="gradientButtonWrapper"
              mb={2}>
              <Box
                borderRadius={16}
                className={[styles.gradient, 'gradientButton', 'overlay'].join(' ')}></Box>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={needDeposit ? onDeposit : onClick}>
                <Typography>
                  {needDeposit ? 'input.insufficient_point' : `input.enter_mode.${mode}`}
                </Typography>
              </Button>
            </Box>
          </Box>

          {mode !== WatchMode.Free && (
            <Box display="flex" justifyContent="center">
              <div className="diamond" />
              <Text variant="body2" color="primary">
                {format(mode === WatchMode.OM ? omCharge : ooCharge)}
              </Text>
              <Box ml={1}>
                <Typography variant="caption" color="textSecondary">
                  unit.perMinute
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default ModeDialog;
