import {ServerError} from 'api';

import {AppThunk} from 'model/helper';

import UserAPI from 'api/user';
import WalletAPI from 'api/wallet';

import {PaymentMethod, WalletAction} from './WalletTypes';

export const updateWallet = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await WalletAPI.getBalance();
    dispatch({type: WalletAction.GET_WALLET_BALANCE, payload: res.point});
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const updateGold = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await UserAPI.getMe();
    dispatch({type: WalletAction.GET_GOLD, payload: res.gold});
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadDistribution = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await WalletAPI.getDistributionSummary();
    dispatch({
      type: WalletAction.GET_DISTRIBUTION_SUMMARY,
      payload: {
        upline: res.up_line_user?.user_id
          ? {
              userId: res.up_line_user.user_id,
              userName: res.up_line_user.name,
              userPicture: res.up_line_user.picture,
            }
          : null,
        downline: {
          1: {
            total: res.first_level_info.user_count,
            profit: res.first_level_info.gold_gain,
            percentage: 6,
            list: [],
          },
          2: {
            total: res.second_level_info.user_count,
            profit: res.second_level_info.gold_gain,
            percentage: 3,
            list: [],
          },
          3: {
            total: res.third_level_info.user_count,
            profit: res.third_level_info.gold_gain,
            percentage: 1,
            list: [],
          },
        },
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadDistributionLevel = (level: number): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    const res = await WalletAPI.getDistributionDetail(level);
    dispatch({
      type: WalletAction.GET_DISTRIBUTION_LEVEL,
      payload: {
        level,
        list: res.map((member) => ({
          userName: member.name,
          userPicture: member.picture,
          downlineCount: {
            2: member.second_level_down_line_count,
            3: member.third_level_down_line_count,
          },
          uplineName: {
            1: member.up_line_name || member.first_up_line_name,
            2: member.second_up_line_name,
          },
        })),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadGoldLogs = (refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({type: WalletAction.GET_GOLD_LOGS});

  try {
    const cursor = refresh ? null : getState().wallet.goldLogs.cursor;
    const res = await WalletAPI.getGoldRecords(cursor);
    dispatch({
      type: WalletAction.GET_GOLD_LOGS_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        list: res.history.map((date) => ({
          time: date.date_title,
          net: date.total_receive_point,
          logs: date.histories.map((log) => ({
            type: log.gold_history_type,
            parent: date.date_title,
            name: log.name,
            level: log.level,
            number: log.number,
            gold: log.gold,
            createdAt: log.created_at,
          })),
        })),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadWalletUsageRecords = (refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: WalletAction.GET_WALLET_RECORDS,
    payload: {
      type: 'usage',
    },
  });

  try {
    const cursor = refresh ? null : getState().wallet.records.usage.cursor;
    const res = await WalletAPI.getConsumptionHistory(cursor);
    dispatch({
      type: WalletAction.GET_WALLET_RECORDS_S,
      payload: {
        type: 'usage',
        refresh,
        cursor: res.cursor || null,
        list: res.histories_list.map((item) => ({
          time: item.date_title,
          net: item.total_consume_point,
          logs: item.histories.map((log) => ({
            parent: item.date_title,
            tradeType: log.trade_type,
            createdAt: log.created_at,
            amount: log.amount,
            count: log.count,
            toUserId: log.to_user_id,
            toUserName: log.to_user_name,
            toUserPicture: log.picture,
            note: log.note,
          })),
        })),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadWalletPurchaseRecords = (refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: WalletAction.GET_WALLET_RECORDS,
    payload: {
      type: 'purchase',
    },
  });

  try {
    const cursor = refresh ? null : getState().wallet.records.purchase.cursor;
    const res = await WalletAPI.getCreditHistory(cursor);
    dispatch({
      type: WalletAction.GET_WALLET_RECORDS_S,
      payload: {
        type: 'purchase',
        refresh,
        cursor: res.cursor || null,
        list: res.history_list.map((item) => ({
          time: item.date_title,
          net: item.total_gain_point,
          logs: item.histories.map((log) => ({
            parent: item.date_title,
            // sourceType: log.source_type,
            createdAt: log.created_at,
            amount: log.point,
            count: log.price,
            note: log.order_id,
            status: log.status,
          })),
        })),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const openPaymentDrawer = (onSuccess?: () => void): AppThunk<void> => async (dispatch) => {
  dispatch({
    type: WalletAction.OPEN_PAYMENT_DRAWER,
    payload: {
      onPaid: onSuccess,
    },
  });
};

export const closePaymentDrawer = (): AppThunk<void> => async (dispatch) => {
  dispatch({
    type: WalletAction.CLOSE_PAYMENT_DRAWER,
  });
};

export const loadPaymentMethods = (): AppThunk<void> => async (dispatch) => {
  try {
    const res = await WalletAPI.getPayMethods();
    dispatch({
      type: WalletAction.GET_PAYMENT_METHODS,
      payload: res.pay_methods.map((m) => m.pay_method_id),
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadPaymentProducts = (): AppThunk<void> => async (dispatch) => {
  try {
    const res = await WalletAPI.getProducts();
    dispatch({
      type: WalletAction.GET_PAYMENT_PRODUCTS,
      payload: res.products.map((p) => ({
        id: p.product_id,
        point: p.point,
        price: p.price,
        bonus: p.bonus_point,
      })),
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const purchaseProduct = (
  method: PaymentMethod,
  product: string,
): AppThunk<Promise<string>> => async (dispatch) => {
  try {
    const res = await WalletAPI.purchase(method, product);
    return res.html;
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};
