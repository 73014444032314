import {createSelector} from 'reselect';

import {RootState} from 'model/helper';
import {getMemberCache} from 'model/user/UserSelector';

const getPostState = (state: RootState) => state.post;
export const getSuggestedUsers = (state: RootState) => getPostState(state).suggested;

export const suggestedUsersSelector = createSelector(
  getSuggestedUsers,
  getMemberCache,
  (suggested, members) => {
    return suggested.map((id) => members[id]);
  },
);

export const getBanner = (state: RootState) => getPostState(state).banner;

export const getPosts = (state: RootState) => getPostState(state).posts;
export const getPostCache = (state: RootState) => getPostState(state).cache;
export const isPostsLoading = (state: RootState) => getPosts(state).loading;
export const hasPostsNextPage = (state: RootState) => getPosts(state).cursor !== null;

export const getPostsList = (state: RootState) => getPosts(state).articles;
export const getArticles = createSelector(getPostsList, getPostCache, (list, cache) => {
  return list.map((id) => cache[id]);
});

const getUnlockedPostsState = (state: RootState) => getPostState(state).unlockedPosts;
export const isUnlockedListLoading = (state: RootState) => getUnlockedPostsState(state).loading;
export const hasUnlockedListNextPage = (state: RootState) =>
  getUnlockedPostsState(state).cursor !== null;
export const getUnlockedArticles = createSelector(
  (state) => getUnlockedPostsState(state).articles,
  getPostCache,
  (list, cache) => list.map((id) => cache[id]),
);

const getSavedPostsState = (state: RootState) => getPostState(state).savedPosts;
export const isSavedListLoading = (state: RootState) => getSavedPostsState(state).loading;
export const hasSavedListNextPage = (state: RootState) => getSavedPostsState(state).cursor !== null;
export const getSavedArticles = createSelector(
  (state) => getSavedPostsState(state).articles,
  getPostCache,
  (list, cache) => list.map((id) => cache[id]),
);

export const getArticleById = (id: string) => (state: RootState) => getPostCache(state)[id] || null;

const defaultComment = {
  loading: true,
  cursor: null,
  comments: [],
};

export const getCommentsById = (id: string) => (state: RootState) =>
  getPostState(state).comment[id] || defaultComment;

export const getVideoStatus = (state: RootState) => getPostState(state).video;
