import {Box, Button, CardMedia, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {ReactNode, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {muteVideo, playStoryVideo, stopStoryVideo, unmuteVideo} from 'model/post/PostAction';
import {getVideoStatus} from 'model/post/PostSelector';
import {MediaExtension} from 'model/post/PostTypes';

import CardMediaPlayer from './CardMediaPlayer';
import Typography from './i18n/Typography';

interface GradientButtonProps {
  children: ReactNode;
  disabled: boolean;
  onClick?: () => void;
}

function GradientButton({children, disabled, onClick}: GradientButtonProps) {
  const styles = useThemeStyles();

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      className="unlockButtonWrapper"
      my={2}>
      <Box className={[styles.gradient, 'overlay', 'unlockButton'].join(' ')}></Box>
      <Button variant="outlined" size="large" fullWidth disabled={disabled} onClick={onClick}>
        {children}
      </Button>
    </Box>
  );
}

interface StoryMediaProps {
  ext: MediaExtension;
  src: string;
  preview?: string;
  id: string;
  index: number;
  lockedOffset: number;
  unlocked: boolean;
  onUnlock?: () => void;
  unlockedDisabled?: boolean;
  unlockPoint?: number;
}

function StoryMedia({
  ext,
  src,
  preview,
  id,
  index,
  lockedOffset,
  unlocked,
  onUnlock,
  unlockedDisabled = false,
  unlockPoint = 0,
}: StoryMediaProps) {
  const videoStatus = useSelector(getVideoStatus);
  const dispatch = useDispatch();

  const handleMuted = useCallback(() => {
    if (videoStatus.muted) {
      dispatch(unmuteVideo());
    } else {
      dispatch(muteVideo());
    }
  }, [dispatch, videoStatus.muted]);

  return (
    <Box position="relative">
      {ext === MediaExtension.MP4 ? (
        <CardMediaPlayer
          className="storyMedia"
          src={src}
          preview={preview}
          muted={videoStatus.muted}
          onClickVolume={handleMuted}
          playIcon={unlocked || index < lockedOffset || !unlockPoint}
          loop
        />
      ) : (
        <CardMedia image={src} className="storyMedia" />
      )}

      {!unlocked && index >= lockedOffset && (
        <Box className="overlay" display="flex" alignItems="center" justifyContent="center">
          <GradientButton onClick={onUnlock} disabled={unlockedDisabled}>
            <Typography>
              {ext === MediaExtension.MP4 ? 'input.unlock_video' : 'input.unlock_images'}
            </Typography>
            <Box display="flex" alignItems="center" component="span" ml={3}>
              <span className="diamond" />
              <Text>{unlockPoint}</Text>
            </Box>
          </GradientButton>
        </Box>
      )}
    </Box>
  );
}

export default StoryMedia;
