import {Button} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import Cookies from 'js-cookie';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router';

import Icon from 'components/Icons';
import Typography from 'components/i18n/Typography';

import {ReactComponent as LineIcon} from 'assets/icon/brand/line.svg';

interface LineLoginButtonProps {
  text: string;
}

function LineLoginButton({text}: LineLoginButtonProps) {
  const styles = useThemeStyles();
  const history = useHistory();

  const handleLogin = useCallback(() => {
    const id =
      (document.querySelector('meta[name="line-signin-client_id"]') as HTMLMetaElement)?.content ??
      '';
    const redirect = encodeURIComponent(`${window.location.origin}/auth/line`);
    Cookies.set('search', history.location.search, {expires: 1});
    sessionStorage.setItem('search', history.location.search);

    const url =
      'https://access.line.me/oauth2/v2.1/authorize?' +
      'response_type=code' +
      `&client_id=${id}` +
      `&redirect_uri=${redirect}` +
      `&state=12345abcde` +
      `&scope=openid`;

    window.location.href = url;
  }, [history.location.search]);

  return (
    <Button
      variant="contained"
      size="large"
      startIcon={<Icon svg={LineIcon} />}
      fullWidth
      className={styles.line}
      onClick={handleLogin}>
      <Typography>{text}</Typography>
    </Button>
  );
}

export default LineLoginButton;
