import {Listener, PayloadAction, PureAction, Subscriber} from 'model/helper';
import {WatchMode} from 'model/livestream/LivestreamTypes';

import Alarm from './Alarm';

// import {LivestreamModel} from 'model/livestream/LivestreamTypes';
// import {GiftModel} from 'model/gift/GiftTypes';

export class MessageAction {
  public static readonly GET_MAILBOX = 'GET_MAILBOX';
  public static readonly GET_MAILBOX_S = 'GET_MAILBOX_S';
  public static readonly GET_MESSENGER = 'GET_MESSENGER';
  public static readonly GET_MESSAGES = 'GET_MESSAGES';
  public static readonly GET_MESSAGES_S = 'GET_MESSAGES_S';

  public static readonly SEND_TEXT_MESSAGE = 'SEND_TEXT_MESSAGE';
  public static readonly SEND_IMAGE_MESSAGE = 'SEND_IMAGE_MESSAGE';
  public static readonly SEND_VOICE_MESSAGE = 'SEND_VOICE_MESSAGE';

  public static readonly CREATE_PERSONAL_CHANNEL = 'CREATE_PERSONAL_CHANNEL';
  public static readonly LISTEN_PERSONAL_CHANNEL = 'LISTEN_PERSONAL_CHANNEL';
  public static readonly UNLISTEN_PERSONAL_CHANNEL = 'UNLISTEN_PERSONAL_CHANNEL';
  public static readonly RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
  public static readonly UNLOCK_MESSAGE = 'UNLOCK_MESSAGE';
  public static readonly ALERT_NEW_MESSAGE = 'ALERT_NEW_MESSAGE';
  public static readonly READ_MESSAGE = 'READ_MESSAGE';
}

export enum MessageType {
  DateLabel = 0,

  Gift = 1,
  Chat,
  Picture,
  ChargeRequest,
  ChargeInvite,
  StreamStatusChange,
  ChargeInviteAccept, // Audience accept invite
  ChargeRequestAccept, // Streamer accept invite
  StreamCancelDowntime,
  StreamNewViewer,
  FollowNotify,
  Video,
  Audio,
  UnlockPrivateMedia,
  PointDepletion = 15,
}

export declare namespace MessageModel {
  export interface State {
    mailbox: {
      cursor: string | null;
      loading: boolean;
      list: string[];
      cache: {[userId: string]: Messenger};
    };
    conversation: {
      [userId: string]: {
        loading: boolean;
        cursor: string | null;
        messages: Message[];
      };
    };
    channel: {
      sub: Subscriber | null;
      listeners: Listener[];
    };
    alarm: {
      sound: Alarm;
      timer: {[userId: string]: number | undefined};
    };
  }

  interface Messenger {
    userId: string;
    userName: string;
    userPicture: string;
    price?: number;
    lastMessage: string;
    lastMessageTime: string;
    freeLimit: number;

    numUnread: number;
    isCustomerService: boolean;
  }

  interface Message {
    id: string;
    senderId: string;
    receiverId: string;
    message: string; // url or text
    type: MessageType;
    date: string;
    createdAt: string;
    unlocked: boolean;
    unlockPoint: number;

    giftName: string;
    giftPicture: string;
  }

  interface ChatroomMessage {
    id: string;
    type: MessageType;
    senderId: string;
    senderName: string;
    senderPicture: string;
    message: string;
    giftId: string;
  }

  interface IRawStream<I extends MessageType, M> {
    msg: {
      id: number;
      created_at: number;
      type: I;
      meta_data: M;
    };
  }

  export type RawStream =
    | IRawStream<
        MessageType.Gift,
        {
          agent_id: string;
          channel: string;
          combo_count: string;
          company_id: string;
          gift_id: string;
          gift_name: string;
          gift_picture: string;
          gift_special_animate: boolean;
          user_id: string; // sender
          user_name: string; // sender
        }
      >
    | IRawStream<MessageType.Chat, ConversationMetadata>
    | IRawStream<MessageType.Picture, ConversationMetadata>
    | IRawStream<MessageType.Audio, ConversationMetadata>
    | IRawStream<MessageType.Video, ConversationMetadata>
    | IRawStream<MessageType.UnlockPrivateMedia, ConversationMetadata>
    | IRawStream<
        MessageType.StreamNewViewer,
        {
          user_id: string;
          name: string;
        }
      >
    | IRawStream<
        MessageType.StreamStatusChange,
        {
          new_status: WatchMode;
          down_time: number;
          target_user_id: string | null;
          channel_id: string;
        }
      >
    | IRawStream<MessageType.StreamCancelDowntime, {}>
    | IRawStream<
        MessageType.ChargeInvite,
        {
          user_id: string;
          request_id: string;
          mode: WatchMode;
          user_name: string;
        }
      >
    | IRawStream<
        MessageType.PointDepletion,
        {
          timestamp: number;
        }
      >;

  interface ConversationMetadata {
    agent_id: string;
    company_id: string;
    message: string;
    name: string;
    picture: string;
    streamer_id: string;
    to_user_id: string;
    unlock_point: number;
    user_id: string;
  }

  export type GetMailboxAction = PureAction<typeof MessageAction['GET_MAILBOX']>;
  export type AfterGetMailboxAction = PayloadAction<
    typeof MessageAction['GET_MAILBOX_S'],
    {
      cursor: string | null;
      refresh: boolean;
      items: Messenger[];
    }
  >;

  export type GetMessengerAction = PayloadAction<typeof MessageAction['GET_MESSENGER'], Messenger>;

  export type GetMessagesAction = PayloadAction<typeof MessageAction['GET_MESSAGES'], string>;
  export type AfterGetMessagesAction = PayloadAction<
    typeof MessageAction['GET_MESSAGES_S'],
    {
      userId: string;
      cursor: string | null;
      refresh: boolean;
      items: Message[];
    }
  >;

  export type SendTextMessageAction = PayloadAction<
    typeof MessageAction['SEND_TEXT_MESSAGE'],
    {
      userId: string;
      content: string;
    }
  >;
  export type SendImageMessageAction = PayloadAction<
    typeof MessageAction['SEND_IMAGE_MESSAGE'],
    {
      userId: string;
      content: File;
    }
  >;
  export type SendVoiceMessageAction = PayloadAction<
    typeof MessageAction['SEND_VOICE_MESSAGE'],
    {
      userId: string;
      content: File;
    }
  >;

  export type CreatePersonalChannelAction = PayloadAction<
    typeof MessageAction['CREATE_PERSONAL_CHANNEL'],
    Subscriber
  >;

  export type ListenPersonalChannelAction = PayloadAction<
    typeof MessageAction['LISTEN_PERSONAL_CHANNEL'],
    Listener
  >;

  export type UnlistenPersonalChannelAction = PayloadAction<
    typeof MessageAction['UNLISTEN_PERSONAL_CHANNEL'],
    Listener
  >;

  export type ReceiveMessage = PayloadAction<
    typeof MessageAction['RECEIVE_MESSAGE'],
    {
      userId: string; // target
      message: Message;
    }
  >;

  export type AlertNewMessageAction = PayloadAction<
    typeof MessageAction['ALERT_NEW_MESSAGE'],
    string
  >;
  export type ReadMessageAction = PayloadAction<typeof MessageAction['READ_MESSAGE'], string>;

  export type UnlockMessageAction = PayloadAction<typeof MessageAction['UNLOCK_MESSAGE'], {
    senderId: string;
    messageId: string;
    url: string;
  }>

  export type ActionTypes =
    | GetMailboxAction
    | AfterGetMailboxAction
    | GetMessengerAction
    | GetMessagesAction
    | AfterGetMessagesAction
    | SendTextMessageAction
    | SendImageMessageAction
    | SendVoiceMessageAction
    | CreatePersonalChannelAction
    | ListenPersonalChannelAction
    | UnlistenPersonalChannelAction
    | ReceiveMessage
    | AlertNewMessageAction
    | ReadMessageAction
    | UnlockMessageAction;
}
