import {Box, IconButton} from '@material-ui/core';
import Photoswipe from 'photoswipe';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/dist/photoswipe.css';
import React, {FC, useCallback, useEffect, useRef} from 'react';

import {ReactComponent as CloseIcon} from 'assets/icon/light/times.svg';

import Icon from './Icons';

export interface PhotoswipeProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  items: PhotoSwipe.Item[];
  options?: PhotoSwipe.Options;
  onClose: () => void;
}

const PhotoSwipe: FC<PhotoswipeProps> = ({open, onClose, items, options = {}, ...props}) => {
  const photoSwipe = useRef<Photoswipe<PhotoSwipe.Options>>();

  useEffect(() => {
    if (open) {
      photoSwipe.current?.init();
      return () => {
        photoSwipe.current?.close();
      };
    }
  }, [open]);

  useEffect(() => {
    if (photoSwipe.current && photoSwipe.current.items) {
      photoSwipe.current.items = [];
      items.forEach((item) => {
        photoSwipe.current?.items.push(item);
      });
      photoSwipe.current.invalidateCurrItems();
      photoSwipe.current.updateSize(true);
    }
  }, [items]);

  const handleClose = useCallback(() => {
    photoSwipe.current?.close();
    onClose();
  }, [onClose]);

  return (
    <div
      {...props}
      tabIndex={-1}
      role="dialog"
      aria-hidden={true}
      className={`pswp ${props.className ?? ''}`}
      ref={(ele) => {
        if (ele) {
          photoSwipe.current = new Photoswipe(ele!, PhotoswipeUIDefault, items, options);
          photoSwipe.current.listen('close', handleClose);
        }
      }}>
      <div className="pswp__bg" />
      <div className="pswp__scroll-wrap">
        <div className="pswp__container">
          <div className="pswp__item" />
          <div className="pswp__item" />
          <div className="pswp__item" />
        </div>
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            <div className="pswp__counter" />
            {/* <button className="pswp__button pswp__button--close" title="Close (Esc)" />
            <button className="pswp__button pswp__button--share" title="Share" />
            <button className="pswp__button pswp__button--fs" title="Toggle fullscreen" />
            <button className="pswp__button pswp__button--zoom" title="Zoom in/out" /> */}
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut" />
                </div>
              </div>
            </div>
            <Box position="absolute" right={0} top={0} onClick={handleClose}>
              <IconButton>
                <Icon svg={CloseIcon} />
              </IconButton>
            </Box>
          </div>
          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip" />
          </div>
          <div className="pswp__caption">
            <div className="pswp__caption__center" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoSwipe;
