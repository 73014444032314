import {Button} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {useScript} from 'utils/hook';

import Icon from 'components/Icons';
import Typography from 'components/i18n/Typography';

import {ReactComponent as FbIcon} from 'assets/icon/brand/facebook-square.svg';

interface FacebookLoginButtonProps {
  text: string;
}

function FacebookLoginButton({text}: FacebookLoginButtonProps) {
  const styles = useThemeStyles();
  const [fbLoaded] = useScript('https://connect.facebook.net/en_US/sdk.js');
  const history = useHistory();

  const handleLogin = useCallback(() => {
    if (!fbLoaded) {
      return;
    }

    FB.login((response) => {
      if (response.authResponse) {
        history.push({
          pathname: '/auth/facebook',
          search: history.location.search,
          state: {
            token: response.authResponse.accessToken,
          },
        });
      }
    });
  }, [fbLoaded, history]);

  return (
    <Button
      variant="contained"
      size="large"
      startIcon={<Icon svg={FbIcon} />}
      fullWidth
      className={styles.facebook}
      onClick={handleLogin}>
      <Typography>{text}</Typography>
    </Button>
  );
}

export default FacebookLoginButton;
