import {
  Avatar,
  Box,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import dayjs from 'dayjs';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {AppDispatch} from 'model/helper';
import {loadMailbox} from 'model/message/MessageAction';
import {getMailbox, hasMailboxNextPage, isMailboxLoading} from 'model/message/MessageSelector';
import {MessageModel} from 'model/message/MessageTypes';

interface MailboxProps {
  onClick: (messenger: MessageModel.Messenger) => void;
}

function Mailbox({onClick}: MailboxProps) {
  const dispatch = useDispatch<AppDispatch>();
  const styles = useThemeStyles();

  const mailbox = useSelector(getMailbox);
  const isLoading = useSelector(isMailboxLoading);
  const hasNextPage = useSelector(hasMailboxNextPage);

  useEffect(() => {
    dispatch(loadMailbox(true));

    // eslint-disable-next-line
  }, []);

  const today = dayjs().format('YYYY/MM/DD');

  const renderRow = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const item = mailbox[index];
      const time = dayjs(item.lastMessageTime);
      const timeLabel =
        time.format('YYYY/MM/DD') === today ? time.format('HH:mm') : time.format('MM/DD');

      return (
        <ListItem
          button
          ContainerProps={{style: style}}
          ContainerComponent="div"
          onClick={() => onClick(item)}>
          <ListItemAvatar>
            <Avatar className="avatar" src={item.userPicture} />
          </ListItemAvatar>
          <ListItemText
            className="oneLine"
            primary={item.userName}
            secondary={item.lastMessage || '　'}
          />

          <ListItemSecondaryAction>
            <Box textAlign="center" mt={-1}>
              <Text color="textSecondary" variant="body2">
                {timeLabel}
              </Text>
              {item.numUnread > 0 && (
                <Chip
                  label={item.numUnread}
                  variant="outlined"
                  size="small"
                  className={`chip ${styles.gradientBefore}`}
                />
              )}
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
      );
    },
    [mailbox, onClick, styles, today],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < mailbox.length - 1;
    },
    [hasNextPage, mailbox],
  );

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadMailbox(false));
  }, [isLoading, hasNextPage, dispatch]);

  return (
    <Box flex="1">
      <AutoSizer>
        {({height, width}) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={mailbox.length}
            loadMoreItems={loadMore}>
            {({onItemsRendered, ref}) => {
              return (
                <FixedSizeList
                  width={width}
                  height={height}
                  itemSize={70}
                  itemCount={mailbox.length}
                  onItemsRendered={onItemsRendered}
                  ref={ref}>
                  {renderRow}
                </FixedSizeList>
              );
            }}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </Box>
  );
}

export default Mailbox;
