import {
  AppBar,
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLastLocation} from 'react-router-last-location';

import {AppDispatch} from 'model/helper';
import {followUser, loadActivityAndRecommendation, unfollowUser} from 'model/user/UserActions';
import {getActivities, getRecommendedStreamers} from 'model/user/UserSelector';
import {ActivityType, UserModel} from 'model/user/UserTypes';

import {relativeTime} from 'utils/string';

import PointLabel from 'components/PointLabel';
import Typography from 'components/i18n/Typography';

function ActivityPage() {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const activities = useSelector(getActivities);
  const recommendations = useSelector(getRecommendedStreamers);
  const lastLocation = useLastLocation();

  useEffect(() => {
    if (lastLocation && lastLocation.pathname.startsWith('/profile/')) {
      return;
    }

    dispatch(loadActivityAndRecommendation());
  }, [lastLocation, dispatch]);

  const handleActivityClick = useCallback(
    (type: ActivityType, id: string) => {
      if (type === ActivityType.Like) {
        history.push(`/post/${id}`);
      }
      if (type === ActivityType.LeaveComment) {
        history.push(`/post/${id}/comments`);
      }
      if (type === ActivityType.Follow) {
        return;
      }
    },
    [history],
  );

  const handleFollowUser = useCallback(
    (item: UserModel.Activity | UserModel.RecommendedStreamer, id: string) => {
      if (item.following) {
        dispatch(unfollowUser(id));
      } else {
        dispatch(followUser(id));
      }
    },
    [dispatch],
  );

  const toProfile = useCallback(
    (id: string) => {
      history.push({
        pathname: `/profile/${id}`,
      });
    },
    [history],
  );

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1} />
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.activity
            </Typography>
          </Box>
          <PointLabel flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>

      <Box display="flex" flex={1} flexDirection="column" overflow="auto">
        <ListItem ContainerComponent="div">
          <Typography variant="body1" style={{fontWeight: 'bold'}}>
            title.new_activity
          </Typography>
        </ListItem>
        {activities.map((activity) => {
          const [timeKey, timeDiff] = relativeTime(activity.createdAt);

          return (
            <ListItem
              ContainerComponent="div"
              className="minContentHeight"
              button={(activity.type !== ActivityType.Follow) as any}
              key={`${activity.type} ${activity.targetId}`}
              onClick={() => handleActivityClick(activity.type, activity.targetId) as any}>
              <ListItemAvatar style={{minWidth: 0, marginRight: 12}}>
                <Avatar className="avatar44" src={activity.streamerPicture} />
              </ListItemAvatar>

              <Typography keys={{name: activity.streamerName}}>
                {`enum.activity.${activity.type}`}
              </Typography>

              <Box ml={0.5}>
                <Typography className="oneLine" color="textSecondary" keys={{n: timeDiff}}>
                  {timeKey}
                </Typography>
              </Box>

              <Box flex={1} />

              {activity.type === ActivityType.Follow ? (
                <Button
                  variant={activity.following ? 'outlined' : 'contained'}
                  color={activity.following ? 'default' : 'primary'}
                  size="small"
                  onClick={() => handleFollowUser(activity, activity.streamerId)}>
                  <Typography>{activity.following ? 'input.unfollow' : 'input.follow'}</Typography>
                </Button>
              ) : (
                <img src={activity.cover} alt="cover" className="avatar44" />
              )}
            </ListItem>
          );
        })}
        <ListItem ContainerComponent="div">
          <Typography variant="body1" style={{fontWeight: 'bold'}}>
            title.streamer_recommendation
          </Typography>
        </ListItem>
        {recommendations.map((streamer) => {
          return (
            <ListItem key={streamer.userId} ContainerComponent="div">
              <ListItemAvatar style={{minWidth: 0, marginRight: 12}}>
                <Avatar
                  className="avatar44"
                  src={streamer.picture}
                  onClick={() => toProfile(streamer.userId)}
                />
              </ListItemAvatar>

              <ListItemText
                primary={streamer.name}
                secondary={
                  <Box component="span" display="flex" className="oneLine">
                    <Typography keys={{n: streamer.followers}}>label.have_n_followers</Typography>
                    <Box mx={0.5} component="span">
                      <Typography>‧</Typography>
                    </Box>
                    <Typography>{`enum.recommendation.${streamer.type}`}</Typography>
                  </Box>
                }
              />

              <ListItemSecondaryAction>
                <Button
                  variant={streamer.following ? 'outlined' : 'contained'}
                  color={streamer.following ? 'default' : 'primary'}
                  size="small"
                  onClick={() => handleFollowUser(streamer, streamer.userId)}>
                  <Typography>{streamer.following ? 'input.unfollow' : 'input.follow'}</Typography>
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </Box>
    </Box>
  );
}

export default ActivityPage;
