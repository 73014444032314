import {
  Avatar,
  Box,
  Dialog,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {AppDispatch} from 'model/helper';
import {loadContributorsList} from 'model/livestream/LivestreamAction';
import {
  getContributors,
  hasContributorsListNextPage,
  isContributorsListLoading,
} from 'model/livestream/LivestreamSelector';

import {ReactComponent as CrownIcon} from 'assets/icon/solid/crown.svg';

import Icon from './Icons';
import Typography from './i18n/Typography';

interface ContributionDialogProps {
  streamId: string;
  open: boolean;
  onClose(): void;
}
function ContributionDialog({streamId, open, onClose}: ContributionDialogProps) {
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();
  const contributors = useSelector(getContributors);
  const loading = useSelector(isContributorsListLoading);
  const hasNextPage = useSelector(hasContributorsListNextPage);

  useEffect(() => {
    if (!open) {
      return;
    }

    dispatch(loadContributorsList(streamId, true));
  }, [dispatch, streamId, open]);

  const renderContributor = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const contributor = contributors[index];

      return (
        <Box style={style}>
          <ListItem className={styles.contributorListItem}>
            <Box position="relative" mr={2}>
              <Icon
                svg={CrownIcon}
                className={
                  contributor.rank === 1
                    ? 'first'
                    : contributor.rank === 2
                    ? 'second'
                    : contributor.rank === 3
                    ? 'third'
                    : 'hidden'
                }
              />
              <Box className="overlay" textAlign="center">
                <Text variant="h6" className="rank">
                  {contributor.rank}
                </Text>
              </Box>
            </Box>
            <ListItemAvatar>
              <Avatar src={contributor.userPicture} className="avatar50" />
            </ListItemAvatar>

            <ListItemText
              primary={contributor.userName}
              secondary={
                <Box display="flex">
                  <div className="diamond" />
                  {contributor.contribution}
                </Box>
              }
              primaryTypographyProps={{
                variant: 'h6',
              }}
              secondaryTypographyProps={{
                color: 'textPrimary',
                component: 'div',
              }}
            />
          </ListItem>
          <Divider variant="inset" />
        </Box>
      );
    },
    [contributors, styles],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < contributors.length - 1;
    },
    [contributors, hasNextPage],
  );

  const itemKey = useCallback((index: number) => contributors[index].userId, [contributors]);

  const loadMore = useCallback(() => {
    if (loading || !hasNextPage) {
      return null;
    }

    return dispatch(loadContributorsList(streamId, false));
  }, [loading, hasNextPage, dispatch, streamId]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={styles.noBackdrop}
      PaperProps={{className: styles.transparentBg}}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pb={2}
        width="90vw"
        maxWidth={300}
        height="80vh"
        maxHeight={400}>
        <Box className={styles.gradient} p={1} textAlign="center">
          <Typography variant="h5">title.contributors</Typography>
        </Box>

        <Box flex={1}>
          <AutoSizer>
            {({height, width}) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={contributors.length}
                loadMoreItems={loadMore}>
                {({onItemsRendered, ref}) => {
                  return (
                    <FixedSizeList
                      key={width}
                      itemKey={itemKey}
                      ref={ref}
                      onItemsRendered={onItemsRendered}
                      width={width}
                      height={height}
                      itemSize={75}
                      itemCount={contributors.length}>
                      {renderContributor}
                    </FixedSizeList>
                  );
                }}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ContributionDialog;
