import {ServerError} from 'api';
import {PubNubKey} from 'config';
import dayjs from 'dayjs';
import Pubnub from 'pubnub';

import {AppThunk, Listener} from 'model/helper';
import {loadUser} from 'model/user/UserActions';
import {getMyInfo, getUserAuth, getUserById} from 'model/user/UserSelector';
import {updateWallet} from 'model/wallet/WalletAction';

import MessageAPI, {FileType, MessageLocation} from 'api/message';

import {genId} from 'utils/string';

import {getListeners} from './MessageSelector';
import {MessageAction, MessageModel, MessageType} from './MessageTypes';

export const loadMailbox = (refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: MessageAction.GET_MAILBOX});
    const cursor = refresh ? null : getState().message.mailbox.cursor;
    const res = await MessageAPI.getMailbox(cursor);

    dispatch({
      type: MessageAction.GET_MAILBOX_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        items: (res.msg_list || []).map((item) => {
          return {
            userId: item.user_id,
            userName: item.name,
            userPicture: item.picture,
            lastMessage: item.message,
            lastMessageTime: item.created_at,
            isCustomerService: item.is_customer_service,
            numUnread: item.unread_msg_count ?? 0,
            freeLimit: 0,
          };
        }),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadMessenger = (userId: string): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  let messenger = getState().message.mailbox.cache[userId];
  if (!messenger) {
    await dispatch(loadUser(userId));

    const user = getUserById(getState())(userId);
    messenger = {
      userId: user?.userId ?? '',
      userName: user?.name ?? '',
      userPicture: user?.picture ?? '',
      lastMessage: '',
      lastMessageTime: new Date().toISOString(),
      numUnread: 0,
      isCustomerService: false,
      freeLimit: 0,
    };
  }

  // fetch the price setting
  const {point} = await MessageAPI.getMessagePrice(userId);
  messenger.price = point;

  const {free_message_count} = await MessageAPI.getNumFreeMessage(userId);
  messenger.freeLimit = free_message_count;

  dispatch({
    type: MessageAction.GET_MESSENGER,
    payload: messenger,
  });
};

export const loadMessages = (userId: string, refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: MessageAction.GET_MESSAGES, payload: userId});
    const cursor = refresh ? null : getState().message.conversation[userId].cursor;
    const res = await MessageAPI.getMessages(userId, cursor);
    dispatch({
      type: MessageAction.GET_MESSAGES_S,
      payload: {
        userId,
        refresh,
        cursor: res.cursor || null,
        items: (res.msg || []).map((message) => {
          return {
            id: message.id.toString(),
            senderId: message.user_id,
            receiverId: message.to_user_id,
            message: message.message,
            type: message.msg_type,
            date: dayjs(message.created_at).format('MM/DD'),
            createdAt: message.created_at,
            unlocked: message.has_charged,
            unlockPoint: message.unlock_point,

            giftName: message.gift_name,
            giftPicture: message.gift_picture,
          };
        }),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const sendTextMessage = (userId: string, content: string): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    await MessageAPI.sendMessage({
      targetId: userId,
      location: MessageLocation.Private,
      content,
      type: MessageType.Chat,
    });
    dispatch({
      type: MessageAction.SEND_TEXT_MESSAGE,
      payload: {
        userId,
        content,
      },
    });
    dispatch(updateWallet());
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const sendImageMessage = (userId: string, content: File): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    const {file_id} = await MessageAPI.upload(content, FileType.JPEG);
    await MessageAPI.sendMessage({
      targetId: userId,
      location: MessageLocation.Private,
      content: file_id,
      type: MessageType.Picture,
    });
    dispatch({
      type: MessageAction.SEND_IMAGE_MESSAGE,
      payload: {
        userId,
        content,
      },
    });
    dispatch(updateWallet());
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const sendVoiceMessage = (userId: string, content: File): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    const {file_id} = await MessageAPI.upload(content, FileType.M4A);
    await MessageAPI.sendMessage({
      targetId: userId,
      location: MessageLocation.Private,
      content: file_id,
      type: MessageType.Audio,
    });
    dispatch({
      type: MessageAction.SEND_VOICE_MESSAGE,
      payload: {
        userId,
        content,
      },
    });
    dispatch(updateWallet());
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const createPersonalChannel = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const authKey = getUserAuth(getState())?.authKey;
  const userId = getMyInfo(getState()).userId;

  const sub = new Pubnub({
    origin: 'funzhou.pubnubapi.com',
    subscribeKey: PubNubKey,
    authKey,
    uuid: userId,
    autoNetworkDetection: true,
    restore: true,
    keepAlive: true,
    listenToBrowserNetworkEvents: true,
  });

  sub.addListener({
    message(evt) {
      const {msg}: MessageModel.RawStream = evt.message;
      for (const listener of getListeners(getState())) {
        listener(msg);
      }
    },
  });

  sub.subscribe({channels: [userId]});

  dispatch({
    type: MessageAction.CREATE_PERSONAL_CHANNEL,
    payload: sub,
  });

  dispatch(
    listenPersonalChannel(async (msg) => {
      switch (msg.type) {
        case MessageType.Gift:
          return dispatch({
            type: MessageAction.RECEIVE_MESSAGE,
            payload: {
              userId: msg.meta_data.channel,
              message: {
                id: genId(10),
                senderId: msg.meta_data.user_id,
                receiverId: msg.meta_data.channel,
                message: msg.meta_data.gift_id,
                type: msg.type,
                date: dayjs(msg.created_at / 1000).format('MM/DD'),
                createdAt: new Date(msg.created_at / 1000).toISOString(),
                unlocked: true,
                unlockPoint: 0,

                giftName: msg.meta_data.gift_name,
                giftPicture: msg.meta_data.gift_picture,
              },
            },
          });
        case MessageType.Chat:
        case MessageType.Picture:
        case MessageType.Video:
        case MessageType.Audio: {
          const hasMessenger =
            getState().message.mailbox.cache[msg.meta_data.streamer_id] !== undefined;
          if (!hasMessenger) {
            await dispatch(loadMessenger(msg.meta_data.streamer_id));
          }

          dispatch({
            type: MessageAction.RECEIVE_MESSAGE,
            payload: {
              userId: msg.meta_data.streamer_id,
              message: {
                id: genId(10),
                senderId: msg.meta_data.user_id,
                receiverId: msg.meta_data.to_user_id,
                message: msg.meta_data.message,
                type: msg.type,
                date: dayjs(msg.created_at / 1000).format('MM/DD'),
                createdAt: new Date(msg.created_at / 1000).toISOString(),
                unlocked: !msg.meta_data.unlock_point,
                unlockPoint: msg.meta_data.unlock_point,

                giftName: '',
                giftPicture: '',
              },
            },
          });

          if (msg.meta_data.streamer_id === msg.meta_data.user_id) {
            dispatch({
              type: MessageAction.ALERT_NEW_MESSAGE,
              payload: msg.meta_data.streamer_id,
            });
          }

          return;
        }
      }
    }),
  );
};

export const listenPersonalChannel = (listener: Listener): AppThunk<void> => async (dispatch) => {
  dispatch({
    type: MessageAction.LISTEN_PERSONAL_CHANNEL,
    payload: listener,
  });
};

export const unlistenPersonalChannel = (listener: Listener): AppThunk<void> => async (dispatch) => {
  dispatch({
    type: MessageAction.UNLISTEN_PERSONAL_CHANNEL,
    payload: listener,
  });
};

export const readMessage = (userId: string): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: 'READ_MESSAGE',
    payload: userId,
  });

  const me = getMyInfo(getState());

  await MessageAPI.read(userId, me.userId);
};

export const unlockMessage = (userId: string, msgId: string): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    const {url} = await MessageAPI.unlockMedia(msgId);
    dispatch({
      type: MessageAction.UNLOCK_MESSAGE,
      payload: {
        senderId: userId,
        messageId: msgId,
        url,
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};
