import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {UserModel} from 'model/user/UserTypes';

import {ReactComponent as CloseIcon} from 'assets/icon/light/times.svg';

import AudioButton from './AudioButton';
import Icon from './Icons';
import Typography from './i18n/Typography';

interface ProfileDialogProps {
  onClose(): void;
  open: boolean;
  user?: UserModel.MemberInfo;
  onClickFollow?: () => void;
  onClickMessage?: () => void;
}

function ProfileDialog({onClose, open, user, onClickFollow, onClickMessage}: ProfileDialogProps) {
  const styles = useThemeStyles();
  const history = useHistory();

  const toProfile = useCallback(() => {
    if (!user) {
      return;
    }

    history.push(`/profile/${user.userId}`);
  }, [history, user]);

  return (
    <Dialog onClose={onClose} open={open} className={styles.profileDialog}>
      <Box
        width={288}
        textAlign="center"
        position="relative"
        borderRadius={8}
        className={`${styles.bg} gradientBg ${styles.gradientBefore}`}
        p={0.5}>
        <Box position="relative">
          <Avatar variant="square" />
          <Box position="absolute" left={5} bottom={5}>
            {user?.voiceBio && <AudioButton src={user.voiceBio} />}
          </Box>
        </Box>
        <Box m={1}>
          <Text variant="h6" component="h6">
            {user?.name ?? ''}
          </Text>
        </Box>

        <Box mx={4}>
          <Text>{user?.bio ?? ''}</Text>
        </Box>

        <Box display="flex" justifyContent="center" my={2}>
          <Box mx={2}>
            <Text color="primary" variant="h3" component="div">
              {user?.numFollower ?? 0}
            </Text>
            <Typography>label.numFollower</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box mx={2}>
            <Text color="secondary" variant="h3" component="div">
              {user?.numFollowee ?? 0}
            </Text>
            <Typography>label.numFollowing</Typography>
          </Box>
        </Box>

        <Box display="flex" my={1} justifyContent="space-around">
          <Button
            variant={user?.followed ? 'outlined' : 'contained'}
            color={user?.followed ? 'default' : 'primary'}
            onClick={onClickFollow}>
            <Typography>{user?.followed ? 'input.unfollow' : 'input.follow'}</Typography>
          </Button>
          <Button variant="outlined" onClick={onClickMessage}>
            <Typography>input.send_message</Typography>
          </Button>
          <Button variant="outlined" onClick={toProfile}>
            <Typography>link.personal_profile</Typography>
          </Button>
        </Box>
      </Box>

      <Box position="absolute" right={0} top={0} zIndex={2} onClick={onClose}>
        <IconButton>
          <Icon svg={CloseIcon} />
        </IconButton>
      </Box>
    </Dialog>
  );
}

export default ProfileDialog;
