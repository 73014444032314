import {API} from 'api';

import {GiftLocation} from 'model/gift/GiftTypes';

export default class GiftAPI {
  public static getCategories() {
    return API.get<{categories: string[]}>('/gifts/category');
  }

  public static getGifts() {
    return API.get<{gifts: GiftResponse[]}>('/gifts/list');
  }

  public static send(giftId: string, userId: string, location: GiftLocation, targetId?: string) {
    const body: any = {
      gift_id: giftId,
      to_user_id: userId,
      trade_location: location,
      // ref_id: targetId,
    };
    if (targetId) {
      body.ref_id = targetId;
    }

    return API.post('/gifts/send', body);
  }
}

interface GiftResponse {
  gift_id: string;
  name: string;
  picture: string;
  animate_id: string;
  special_animate_id: string;
  categoeries: Array<{
    category: string;
    is_valid: boolean;
  }>;
  point: number;
  special_combo: number;
  seq: number;
  status: boolean;
  toy_enable: number;
}
