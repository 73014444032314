import {Box, Button, useTheme} from '@material-ui/core';
import {ErrorCode} from 'api';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useRef, useState} from 'react';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {useDispatch} from 'react-redux';

import {AppDispatch} from 'model/helper';
import {sendSNS, verifySMS} from 'model/user/UserActions';

import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

interface AuthPhoneVerifyFormProps {
  phone: string;
  countryCode: string;
  show: boolean;
  onSuccess(otp: string): void;
}

function AuthPhoneVerifyForm({phone, countryCode, show, onSuccess}: AuthPhoneVerifyFormProps) {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const styles = useThemeStyles();

  const [disabled, setDisabled] = useState(true);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState<ErrorCode | undefined>(undefined);
  const resendTimes = useRef(0);

  const resend = useCallback(() => {
    dispatch(sendSNS({phone, countryCode}))
      .then(() => {
        setDisabled(true);
        resendTimes.current += 1;
      })
      .catch(setError);
  }, [dispatch, phone, countryCode]);

  const verifyOtp = useCallback(() => {
    dispatch(
      verifySMS({
        phone,
        countryCode,
        otp,
      }),
    )
      .then(() => {
        onSuccess(otp);
      })
      .catch(setError);
  }, [dispatch, phone, countryCode, otp, onSuccess]);

  return (
    <Box display="flex" flexDirection="column" mx={2} alignItems="center">
      <Box component="header" mb={2} whiteSpace="pre" textAlign="center">
        <Typography component="h3" variant="h4" keys={{phone}} className="multiline">
          title.phone_verify
        </Typography>
      </Box>
      <Box display="flex" mb={2}>
        <Button color="primary" disabled={disabled} onClick={resend}>
          <Typography>input.resend_sns</Typography>
        </Button>
        {show && (
          <CountdownCircleTimer
            key={resendTimes.current}
            duration={!disabled ? 0 : 60}
            isPlaying={disabled}
            size={30}
            strokeWidth={3}
            colors={[
              [theme.palette.primary[theme.palette.type], 0.9],
              [theme.palette.text.secondary, 0.1],
            ]}
            onComplete={() => setDisabled(false)}>
            {({remainingTime}) => remainingTime}
          </CountdownCircleTimer>
        )}
      </Box>
      <TextField
        required
        type="number"
        variant="outlined"
        color="secondary"
        fullWidth
        placeholder="input.otp_placeholder"
        autoComplete="one-time-code"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        inputProps={{
          pattern: '[0-9]*',
        }}
        errorCode={error}
      />

      <Box
        position="relative"
        display="flex"
        alignItems="center"
        className="gradientButtonWrapper"
        my={2}
        width="100%">
        <Box
          className={['overlay', styles.gradient, 'gradientButton'].join(' ')}
          top={-4}
          bottom={-4}
        />
        <Button variant="outlined" size="large" fullWidth disabled={otp === ''} onClick={verifyOtp}>
          <Typography>common.next</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default AuthPhoneVerifyForm;
