import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography as Text,
  Link,
  useTheme,
  Button,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouteLink, useHistory} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {LockedMediaType, PostModel} from 'model/post/PostTypes';
import {followUser} from 'model/user/UserActions';
import {isUserFollowed} from 'model/user/UserSelector';

import {format} from 'utils/number';
import {relativeTime} from 'utils/string';

import Icon from 'components/Icons';
import ZoomSlider from 'components/ZoomSlider';
import Typography from 'components/i18n/Typography';

import {ReactComponent as BookmarkIcon} from 'assets/icon/regular/bookmark.svg';
import {ReactComponent as CommentIcon} from 'assets/icon/regular/chat.svg';
import {ReactComponent as EllipsisIcon} from 'assets/icon/regular/ellipsis-h.svg';
import {ReactComponent as GiftIcon} from 'assets/icon/regular/gift.svg';
import {ReactComponent as HeartIcon} from 'assets/icon/regular/heart.svg';
import {ReactComponent as FilledBookmarkIcon} from 'assets/icon/solid/bookmark.svg';
import {ReactComponent as FilledHeartIcon} from 'assets/icon/solid/heart.svg';
import 'assets/story.css';

import ArticleParagraph from './ArticleParagraph';
import LivestreamCard from './LivestreamCard';
import StoryMedia from './StoryMedia';

interface StoryCardProps {
  article: PostModel.Article;
  expanded: boolean;
  width?: number;
  style?: CSSProperties;
  onExpand?: () => void;
  onUnlock: () => Promise<void>;
  onClickLike: () => void;
  onClickSave: () => void;
  onClickGift: () => void;
  onClickMore: (article: PostModel.Article) => void;
}

function StoryCard({
  article,
  expanded,
  width = 0,
  onExpand,
  onUnlock,
  onClickLike,
  onClickSave,
  onClickGift,
  onClickMore,
  style,
}: StoryCardProps) {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [unlocking, setUnlocking] = useState(false);
  const styles = useThemeStyles();
  const history = useHistory();
  const followed = useSelector(isUserFollowed)(article.userId) ?? article.followed;
  const cardStyle: CSSProperties = {width, height: width};

  const lockedOffset = article.mediaLock === LockedMediaType.All ? 0 : 1;

  const handleUnlock = useCallback(() => {
    setUnlocking(true);
    onUnlock().finally(() => {
      setUnlocking(false);
    });
  }, [onUnlock]);

  const [timeKey, timeDiff] = relativeTime(article.createdAt);

  const toLivestream = useCallback(() => {
    if (article.streamInfo) {
      history.push({
        pathname: `/livestream/${article.id}`,
      });
    }
  }, [history, article]);

  const toProfile = useCallback(() => {
    history.push({
      pathname: `/profile/${article.userId}`,
    });
  }, [history, article]);

  const follow = useCallback(() => {
    dispatch(followUser(article.userId));
  }, [dispatch, article]);

  if (article.streamInfo) {
    return (
      <Card style={style}>
        <CardHeader
          avatar={
            <IconButton onClick={toProfile} className="noPadding">
              <Avatar src={article.userPicture} className="avatar" />
            </IconButton>
          }
          action={
            <IconButton size="small" onClick={() => onClickMore(article)}>
              <Icon svg={EllipsisIcon} />
            </IconButton>
          }
          title={
            <Typography keys={{name: article.userName}} variant="body2">
              label.someone_is_streaming
            </Typography>
          }
        />
        <LivestreamCard
          livestream={article.streamInfo}
          style={cardStyle}
          onClick={toLivestream}
          showLive
          showAvatar={false}
        />

        <CardContent>
          <Text>{article.streamInfo.title}</Text>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card style={style} className="storyCard" data-id={article.id} data-type={article.media[0].ext}>
      <CardHeader
        avatar={
          <IconButton onClick={toProfile} className="noPadding">
            <Avatar src={article.userPicture} className="avatar" />
          </IconButton>
        }
        action={
          <IconButton size="small" onClick={() => onClickMore(article)}>
            <Icon svg={EllipsisIcon} />
          </IconButton>
        }
        title={
          <Box display="flex" alignItems="center">
            <Text variant="body2">{article.userName}</Text>
            {!followed && (
              <Button color="primary" size="small" className="followButton" onClick={follow}>
                <Typography variant="body2">input.follow</Typography>
              </Button>
            )}
          </Box>
        }
      />

      <ZoomSlider
        activeDotColor={theme.palette.primary[theme.palette.type]}
        dotColor={theme.palette.text.secondary}
        slides={article.media.map((media, index) => (
          <StoryMedia
            id={article.id}
            ext={media.ext}
            src={media.url}
            preview={media.preview}
            index={index}
            lockedOffset={lockedOffset}
            unlocked={article.unlocked || article.unlockPoint === 0}
            onUnlock={handleUnlock}
            unlockedDisabled={unlocking}
            unlockPoint={article.unlockPoint}
          />
        ))}
      />

      <CardActions
        disableSpacing
        className={article.media.length > 1 ? 'storySliderActions' : 'storyActions'}>
        <Box flex={1}>
          <IconButton
            className={`storyActionButton ${article.like ? styles.heart : ''}`}
            onClick={onClickLike}>
            <Icon svg={article.like ? FilledHeartIcon : HeartIcon} />
          </IconButton>
          <RouteLink to={`/post/${article.id}/comments`}>
            <IconButton className="storyActionButton">
              <Icon svg={CommentIcon} />
            </IconButton>
          </RouteLink>
          <IconButton className="storyActionButton" onClick={onClickGift}>
            <Icon svg={GiftIcon} />
          </IconButton>
        </Box>
        <Box flex={1}></Box>
        <Box display="flex" flex={1} justifyContent="flex-end">
          <IconButton className="storyActionButton" onClick={onClickSave}>
            <Icon svg={article.saved ? FilledBookmarkIcon : BookmarkIcon} />
          </IconButton>
        </Box>
      </CardActions>
      <CardContent className="storyContent">
        <Box display="flex">
          <Typography keys={{n: format(article.numLikes)}}>unit.numLikes</Typography>
          {article.numUnlock > 0 && (
            <>
              <Typography className="storyTextSeparator">{`‧`}</Typography>
              <Typography keys={{n: format(article.numUnlock)}}>unit.numUnlocks</Typography>
            </>
          )}
        </Box>
        <ArticleParagraph
          p={article.content}
          expanded={expanded}
          onExpand={onExpand}
          width={width}
        />

        <Box>
          <Link color="textSecondary" component={RouteLink} to={`/post/${article.id}/comments`}>
            <Typography keys={{n: article.numComments}}>input.look_comments</Typography>
          </Link>
        </Box>

        <Typography color="textSecondary" keys={{n: timeDiff}}>
          {timeKey}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default StoryCard;
