import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  Toolbar,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {AppDispatch} from 'model/helper';
import {loadFolloweeList, loadFollowerList} from 'model/user/UserActions';
import {
  followeesSelector,
  followersSelector,
  getMyInfo,
  hasFolloweesListNextPage,
  hasFollowersListNextPage,
  isFolloweesListLoading,
  isFollowersListLoading,
} from 'model/user/UserSelector';
import {UserModel} from 'model/user/UserTypes';

import BackButton from 'components/BackButton';
import Icon from 'components/Icons';
import TabPanel from 'components/TabPanel';
import Typography from 'components/i18n/Typography';

import {ReactComponent as PaperPlaneIcon} from 'assets/icon/regular/paper-plane-inactive.svg';

const ITEM_SIZE = 71;

function ProfileFollowPage() {
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();
  const {type} = useParams<{type: 'follower' | 'followee'}>();
  const history = useHistory();

  const user = useSelector(getMyInfo);
  const index = type === 'followee' ? 0 : 1;

  const followees = useSelector(followeesSelector);
  const isFolloweesLoading = useSelector(isFolloweesListLoading);
  const hasFolloweesNextPage = useSelector(hasFolloweesListNextPage);

  const followers = useSelector(followersSelector);
  const isFollowersLoading = useSelector(isFollowersListLoading);
  const hasFollowersNextPage = useSelector(hasFollowersListNextPage);

  useEffect(() => {
    if (!user.userId) {
      return;
    }

    if (type === 'followee') {
      dispatch(loadFolloweeList(user.userId, true));
    } else {
      dispatch(loadFollowerList(user.userId, true));
    }
  }, [dispatch, type, user.userId]);

  const handleTabChange = useCallback(
    (_, v: number) => {
      if (v === 0) {
        history.replace('/profile/follow/followee');
      } else {
        history.replace('/profile/follow/follower');
      }
    },
    [history],
  );

  const toMessageCallback = useCallback(
    (userId: string) => {
      return () => {
        history.push(`/message/${userId}`);
      };
    },
    [history],
  );

  const toProfileCallback = useCallback(
    (userId: string) => {
      return () => {
        history.push(`/profile/${userId}`);
      };
    },
    [history],
  );

  const UserListItem = useCallback(
    (props: {user: UserModel.MemberInfo; style: CSSProperties}) => {
      const {user, style} = props;
      return (
        <Box pb={1} style={style}>
          <ListItem ContainerComponent="div">
            <ListItemAvatar>
              <IconButton className="noPadding" onClick={toProfileCallback(user.userId)}>
                <Avatar className="avatar55" src={user.picture} />
              </IconButton>
            </ListItemAvatar>
            <ListItemText
              primary={user.name}
              secondary={user.bio || '　'}
              secondaryTypographyProps={{noWrap: true}}
            />
            <ListItemSecondaryAction>
              <IconButton
                className={`outlinedIconButton ${styles.private}`}
                onClick={toMessageCallback(user.userId)}>
                <Icon svg={PaperPlaneIcon} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" />
        </Box>
      );
    },
    [styles, toMessageCallback, toProfileCallback],
  );

  const renderFolloweeRow = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const followee = followees[index];
      return <UserListItem style={style} user={followee} />;
    },
    [followees, UserListItem],
  );
  const isFolloweeItemLoaded = useCallback(
    (index: number) => {
      return !hasFolloweesNextPage || index < followees.length - 1;
    },
    [hasFolloweesNextPage, followees],
  );

  const loadMoreFollowees = useCallback(() => {
    if (isFolloweesLoading || !hasFolloweesNextPage) {
      return null;
    }

    return dispatch(loadFolloweeList(user.userId, false));
  }, [isFolloweesLoading, hasFolloweesNextPage, dispatch, user.userId]);

  const renderFollowerRow = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const follower = followers[index];
      return <UserListItem style={style} user={follower} />;
    },
    [followers, UserListItem],
  );
  const isFollowerItemLoaded = useCallback(
    (index: number) => {
      return !hasFollowersNextPage || index < followees.length - 1;
    },
    [hasFollowersNextPage, followees],
  );

  const loadMoreFollowers = useCallback(() => {
    if (isFollowersLoading || !hasFollowersNextPage) {
      return null;
    }

    return dispatch(loadFollowerList(user.userId, false));
  }, [isFollowersLoading, hasFollowersNextPage, dispatch, user.userId]);

  return (
    <Box className="full" display="flex" flexDirection="column">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/profile" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Text variant="h6" component="h2">
              {user.name}
            </Text>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <AppBar position="static" color="default">
        <Tabs variant="fullWidth" value={index} onChange={handleTabChange}>
          <Tab
            label={
              <span>
                <Text component="span">{user.numFollowee} </Text>
                <Typography>label.numFollowing</Typography>
              </span>
            }
            aria-label="followee"
          />
          <Tab
            label={
              <span>
                <Text component="span">{user.numFollower} </Text>
                <Typography>label.numFollower</Typography>
              </span>
            }
            aria-label="follower"
          />
        </Tabs>
      </AppBar>

      <Box flex={1}>
        <AutoSizer>
          {({height, width}) => {
            const style: CSSProperties = {width};
            return (
              <SwipeableViews style={style} slideStyle={style} index={index} disabled>
                <TabPanel value={index} index={0}>
                  <InfiniteLoader
                    isItemLoaded={isFolloweeItemLoaded}
                    itemCount={followees.length}
                    loadMoreItems={loadMoreFollowees}>
                    {({onItemsRendered, ref}) => {
                      return (
                        <FixedSizeList
                          width={width}
                          height={height}
                          itemSize={ITEM_SIZE}
                          itemCount={followees.length}
                          onItemsRendered={onItemsRendered}
                          ref={ref}>
                          {renderFolloweeRow}
                        </FixedSizeList>
                      );
                    }}
                  </InfiniteLoader>
                </TabPanel>
                <TabPanel value={index} index={1}>
                  <InfiniteLoader
                    isItemLoaded={isFollowerItemLoaded}
                    itemCount={followers.length}
                    loadMoreItems={loadMoreFollowers}>
                    {({onItemsRendered, ref}) => {
                      return (
                        <FixedSizeList
                          width={width}
                          height={height}
                          itemSize={ITEM_SIZE}
                          itemCount={followers.length}
                          onItemsRendered={onItemsRendered}
                          ref={ref}>
                          {renderFollowerRow}
                        </FixedSizeList>
                      );
                    }}
                  </InfiniteLoader>
                </TabPanel>
              </SwipeableViews>
            );
          }}
        </AutoSizer>
      </Box>
    </Box>
  );
}

export default ProfileFollowPage;
