import { PayloadAction, PureAction } from "model/helper";
import { PostModel } from "model/post/PostTypes";

export class ExploreAction {
  public static readonly GET_RECOMMENDED_POSTS = 'GET_RECOMMENDED_POSTS';
  public static readonly GET_RECOMMENDED_POSTS_S = 'GET_RECOMMENDED_POSTS_S';
  public static readonly GET_KEYWORD_POSTS = 'GET_KEYWORD_POSTS';
  public static readonly GET_KEYWORD_POSTS_S = 'GET_KEYWORD_POSTS_S';

  public static readonly GET_SUGGESTED_TAGS = 'GET_SUGGESTED_TAGS';
  public static readonly GET_SUGGESTED_SEARCH_RESULT = 'GET_SUGGESTED_SEARCH_RESULT';
  public static readonly GET_RECENT_SEARCH_RESULT = 'GET_RECENT_SEARCH_RESULT';

  public static readonly SEARCH_KEYWORD = 'SEARCH_KEYWORD';
  public static readonly SEARCH_KEYWORD_S = 'SEARCH_KEYWORD_S';
  public static readonly SAVE_SEARCH_RESULT = 'SAVE_SEARCH_RESULT';
  public static readonly DELETE_ALL_SEARCH_RESULTS = 'DELETE_ALL_SEARCH_RESULTS';
}

export enum SearchType {
  All = 0,
  Streamer = 1,
  Tag,
}

export declare namespace ExploreModel {
  export interface State {
    suggested: SearchedItem[];
    recent: SearchedItem[];
    tags: string[];
    search: {
      [type in SearchType]: {
        loading: boolean;
        cursor: string | null;
        list: SearchedItem[];
      }
    };
    posts: {
      loading: boolean;
      cursor: string | null;
      list: string[];
    };
  }

  export interface SearchedItem {
    id: string;
    type: SearchType;
    keyword: string;
    title: string;
    subtitle: string;
    cover: string;
    followed: boolean;
  }

  export type GetRecommendedPostAction = PayloadAction<
    typeof ExploreAction['GET_RECOMMENDED_POSTS'],
    {refresh: boolean}
  >;
  export type AfterGetRecommendedPostAction = PayloadAction<
    typeof ExploreAction['GET_RECOMMENDED_POSTS_S'],
    {
      refresh: boolean;
      cursor: string | null;
      list: PostModel.Article[];
    }
  >;

  export type GetKeywordPostAction = PayloadAction<
    typeof ExploreAction['GET_KEYWORD_POSTS'],
    {refresh: boolean}
  >;
  export type AfterGetKeywordPostAction = PayloadAction<
    typeof ExploreAction['GET_KEYWORD_POSTS_S'],
    {
      refresh: boolean;
      cursor: string | null;
      list: PostModel.Article[];
    }
  >;

  export type GetSuggestedTagsAction = PayloadAction<
    typeof ExploreAction['GET_SUGGESTED_TAGS'],
    string[]
  >;

  export type GetSuggestedSearchResultAction = PayloadAction<
    typeof ExploreAction['GET_SUGGESTED_SEARCH_RESULT'],
    SearchedItem[]
  >;

  export type GetRecentSearchResultAction = PayloadAction<
    typeof ExploreAction['GET_RECENT_SEARCH_RESULT'],
    SearchedItem[]
  >;

  export type SearchAction = PayloadAction<
    typeof ExploreAction['SEARCH_KEYWORD'],
    {
      keyword: string;
      type: SearchType;
    }
  >;

  export type AfterSearchAction = PayloadAction<
    typeof ExploreAction['SEARCH_KEYWORD_S'],
    {
      keyword: string;
      type: SearchType;
      refresh: boolean;
      cursor: string | null;
      list: SearchedItem[];
    }
  >;

  export type SaveSearchResultAction = PayloadAction<
    typeof ExploreAction['SAVE_SEARCH_RESULT'],
    {
      keyword: string;
      type: SearchType;
    }
  >;

  export type DeleteAllSearchResultsAction = PureAction<typeof ExploreAction['DELETE_ALL_SEARCH_RESULTS']>;

  export type ActionTypes =
    | GetRecommendedPostAction
    | AfterGetRecommendedPostAction
    | GetKeywordPostAction
    | AfterGetKeywordPostAction
    | GetSuggestedTagsAction
    | GetSuggestedSearchResultAction
    | GetRecentSearchResultAction
    | SearchAction
    | AfterSearchAction
    | SaveSearchResultAction
    | DeleteAllSearchResultsAction;
}
