import axios, {AxiosError, AxiosInstance} from 'axios';

export enum ErrorCode {
  Anomaly = 1,

  PhoneNotVerified = 1001,
  PhoneVerifiedMismatch = 1002,
  AccountRegistered = 1003,
  PasswordEmpty = 1004,
  PasswordMismatch = 1005,
  InviteCodeInvalid = 1006,
  AccountNotRegistered = 1007,
  LackEnoughPoint = 2001,
  StreamerBusy = 3001,
  StreamEnded = 3002,
  StreamerIsShowing = 3004,
  BlockByStreamer = 3008,
  RequesterLeave = 3009,
  StreamMessageLengthLimit = 4001,
  StreamMessageFrequency = 4002,
  PrivateMessageLengthLimit = 4003,
  PrivateMessageMaxFreeAmount = 4004,
  LockedAccount = 6001,
}

export interface ServerError {
  readonly err_code: ErrorCode;
  readonly err_msg: string;
}

export class API {
  private static instance: AxiosInstance | undefined;
  private static defaultInstance: AxiosInstance = axios.create({
    baseURL: 'https://ced694aac645.ngrok.app',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  private static token = '';
  private static refreshToken = '';
  private static isRefreshing = false;
  private static refreshSubscribers: Array<(token: string) => void> = [];
  // public static authorizationHandler: () => void = () => {};
  public static depositHandler: () => void;

  private static pendingGetRequests: Array<{
    url: string;
    params?: any;
    resolve: (value?: any | PromiseLike<any> | undefined) => void;
  }> = [];

  private static pendingPostRequests: Array<{
    url: string;
    body: any;
    resolve: (value?: any | PromiseLike<any> | undefined) => void;
  }> = [];

  private static getInstance(): AxiosInstance {
    return API.instance || API.defaultInstance;
  }

  public static getToken(): string {
    return this.token;
  }

  public static setToken(token: string, refreshToken: string): void {
    API.token = token;
    API.refreshToken = refreshToken;
    API.instance = axios.create({
      baseURL: 'https://ced694aac645.ngrok.app',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    });
    // API.setupAuthInterceptor();

    this.pendingGetRequests.forEach((req) => {
      req.resolve(this.get(req.url, req.params));
    });
    this.pendingGetRequests = [];

    this.pendingPostRequests.forEach((req) => {
      req.resolve(this.post(req.url, req.body));
    });
    this.pendingPostRequests = [];
  }

  public static removeToken() {
    API.token = '';
    API.refreshToken = '';
    API.instance = undefined;
  }

  private static setupAuthInterceptor() {
    API.instance!.interceptors.response.use(
      (res) => res,
      (error: AxiosError<ServerError>) => {
        const {config: originalRequest} = error;
        const status = error.response!.status;

        if (status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }

        if (!API.isRefreshing) {
          API.isRefreshing = true;
          API.refreshAuth().then((res) => {
            API.isRefreshing = true;
            API.onRefreshed(res.token);

            API.setToken(res.token, res.refreshToken);
          });
        }

        const retryOrigReq = new Promise((resolve, reject) => {
          API.subscribeTokenRefresh((token) => {
            // replace the expired token and retry
            originalRequest.headers['Authorization'] = token;
            resolve(axios(originalRequest));
          });
        });
        return retryOrigReq;
      },
    );
  }

  private static subscribeTokenRefresh(cb: (token: string) => void) {
    API.refreshSubscribers.push(cb);
  }

  private static onRefreshed(token: string) {
    API.refreshSubscribers.map((cb) => cb(token));
  }

  private static refreshAuth() {
    return API.post<{Token: string; RefreshToken: string}>('/refresh', {
      RefreshToken: API.refreshToken,
    }).then((res) => ({
      token: res.Token,
      refreshToken: res.RefreshToken,
    }));
  }

  public static post<Response>(url: string, req?: any, needToken = false): Promise<Response> {
    if (needToken && !this.token) {
      return new Promise((resolve) => {
        this.pendingPostRequests.push({url, resolve, body: req});
      });
    }

    return this.getInstance()
      .post<Response>(url, req)
      .then((res) => res.data)
      .catch((err: AxiosError<ServerError>) => {
        if (err.response?.data.err_code === ErrorCode.LackEnoughPoint && API.depositHandler) {
          API.depositHandler();
        }

        throw err.response?.data;
      });
  }

  public static postWithStatus<Response>(
    url: string,
    req?: any,
    needToken = false,
  ): Promise<{res: Response; status: number}> {
    if (needToken && !this.token) {
      return new Promise((resolve) => {
        this.pendingPostRequests.push({url, resolve, body: req});
      });
    }

    return this.getInstance()
      .post<Response>(url, req)
      .then((res) => ({
        res: res.data,
        status: res.status,
      }))
      .catch((err: AxiosError<ServerError>) => {
        if (err.response?.data.err_code === ErrorCode.LackEnoughPoint && API.depositHandler) {
          API.depositHandler();
        }

        throw err.response?.data;
      });
  }

  public static patch<Response>(url: string, req: any): Promise<Response> {
    return this.getInstance()
      .patch<Response>(url, req)
      .then((res) => res.data)
      .catch((err: AxiosError<ServerError>) => {
        throw err.response?.data;
      });
  }

  public static put<Response>(url: string, req: any): Promise<Response> {
    return this.getInstance()
      .put<Response>(url, req)
      .then((res) => res.data)
      .catch((err: AxiosError<ServerError>) => {
        throw err.response?.data;
      });
  }

  public static delete<Response>(url: string, req: any = undefined): Promise<Response> {
    return this.getInstance()
      .delete<Response>(url, {data: req})
      .then((res) => res.data)
      .catch((err: AxiosError<ServerError>) => {
        throw err.response?.data;
      });
  }

  public static upload(
    url: string,
    req: any,
    listener?: (progress: number) => void,
  ): Promise<{file_id: string}> {
    return this.getInstance()
      .post(url, req, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress(progressEvent) {
          if (listener) {
            listener(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          }
        },
      })
      .then((res) => res.data);
  }

  public static get<Response>(url: string, params?: any, auth = true): Promise<Response> {
    if (!this.token && auth) {
      return new Promise((resolve) => {
        this.pendingGetRequests.push({url, params, resolve});
      });
    }

    return this.getInstance()
      .get<Response>(url, {params})
      .then((res) => res.data)
      .catch((err: AxiosError<ServerError>) => {
        throw err.response?.data;
      });
  }
}
