import {AppBar, Box, Button, Grid, Toolbar} from '@material-ui/core';
import {ErrorCode} from 'api';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import {AppDispatch} from 'model/helper';
import {resetPassword} from 'model/user/UserActions';

import BackButton from 'components/BackButton';
import {CountryCode} from 'components/PhoneInput';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

import AuthPhoneEnterForm from './AuthPhoneEnterForm';
import AuthPhoneVerifyForm from './AuthPhoneVerifyForm';

enum ResetPasswordStep {
  EnterPhone = 0,
  VerifyPhone,
  EnterNewPassword,
}

function AuthResetPage() {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const styles = useThemeStyles();

  const [step, setStep] = useState(ResetPasswordStep.EnterPhone);

  const [countryCode, setCountryCode] = useState(CountryCode.Taiwan);
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState<ErrorCode | undefined>(undefined);

  const goBack = useCallback(() => {
    if (step === 0) {
      history.goBack();
    } else {
      setStep(ResetPasswordStep.EnterPhone);
    }
  }, [step, history]);

  const handleReset = useCallback(() => {
    dispatch(
      resetPassword({
        phone,
        countryCode,
        otp,
        password,
      }),
    )
      .then(() => {
        history.push('/auth/login');
      })
      .catch(setError);
  }, [dispatch, phone, countryCode, otp, password, history]);

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <BackButton onClick={goBack} />
        </Toolbar>
      </AppBar>

      <SwipeableViews index={step} disabled>
        <AuthPhoneEnterForm
          title="title.reset_password"
          subtitle="title.sub_reset_password"
          onSuccess={(phone, countryCode) => {
            setPhone(phone);
            setCountryCode(countryCode);
            setStep(ResetPasswordStep.VerifyPhone);
          }}
        />
        <AuthPhoneVerifyForm
          phone={phone}
          countryCode={countryCode}
          show={step === ResetPasswordStep.VerifyPhone}
          onSuccess={(otp) => {
            setOtp(otp);
            setStep(ResetPasswordStep.EnterNewPassword);
          }}
        />

        <Box display="flex" flexDirection="column" mx={2} alignItems="center">
          <Box component="header" mb={2} textAlign="center">
            <Typography component="h3" variant="h4">
              title.set_new_password
            </Typography>
          </Box>
          <Grid container component="form" direction="column" spacing={3}>
            <Grid item>
              <TextField
                fullWidth
                required
                variant="outlined"
                color="secondary"
                label="input.password_label"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errorCode={error}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                required
                variant="outlined"
                color="secondary"
                label="input.repassword_label"
                type="password"
                value={repassword}
                onChange={(e) => setRePassword(e.target.value)}
                error={password !== repassword}
              />
            </Grid>
          </Grid>

          <Box
            position="relative"
            display="flex"
            alignItems="center"
            className="gradientButtonWrapper"
            mt={4}
            mb={2}
            width="100%">
            <Box
              className={['overlay', styles.gradient, 'gradientButton'].join(' ')}
              top={-4}
              bottom={-4}
            />
            <Button
              variant="outlined"
              size="large"
              fullWidth
              disabled={!password || password !== repassword}
              onClick={handleReset}>
              <Typography>common.confirm</Typography>
            </Button>
          </Box>
        </Box>
      </SwipeableViews>
    </>
  );
}

export default AuthResetPage;
