import {API} from 'api';

import {SearchType} from 'model/explore/ExploreTypes';

import {PostResponse} from './posts';

export default class ExploreAPI {
  public static getRecommendedPosts(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; list: PostResponse[]}>('/post/list/recommend', params, false);
  }

  public static getRecommendStreamers() {
    return API.get<{hot_search_result: SearchResultResponse[]}>('/post/search/suggested_user');
  }

  public static getRecommendTags() {
    return API.get<string[]>('/post/tag/hot');
  }

  public static searchAll(keyword: string, cursor: string | null) {
    const params: any = {
      result: keyword,
    };
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; list: SearchResultResponse[]}>('/post/search/total', params);
  }

  public static searchUsers(keyword: string, cursor: string | null) {
    const params: any = {
      result: keyword,
    };
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; list: SearchResultResponse[]}>('/post/search/users', params);
  }

  public static searchTags(keyword: string, cursor: string | null) {
    const params: any = {
      result: keyword,
    };
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; list: SearchResultResponse[]}>('/post/search/tags', params);
  }

  public static getPostList(keyword: string, type: SearchType, cursor: string | null) {
    const params: any = {
      query_type: type,
      query_string: keyword,
    };

    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; list: PostResponse[]}>('/post/list', params);
  }

  public static getSearchHistory(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; result: SearchResultResponse[]}>(
      '/post/search/histories',
      params,
    );
  }

  public static createSearchRecord(type: SearchType, searchId: string) {
    const body = {
      result: searchId,
      search_type: type,
    };

    return API.post('/post/search/histories', body);
  }

  public static deleteSearchRecord() {
    return API.delete('/post/search/histories');
  }
}

export interface SearchResultResponse {
  id: number;
  result: string;
  title: string;
  sub_title: string;
  cover: string;
  is_follow: boolean;
  search_type: SearchType;
}
