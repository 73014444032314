import {ErrorCode, ServerError} from 'api';
import {PubNubKey} from 'config';
import dayjs from 'dayjs';
import Pubnub from 'pubnub';

import {AppThunk, Listener} from 'model/helper';
import {MessageModel, MessageType} from 'model/message/MessageTypes';
import {getMyInfo} from 'model/user/UserSelector';

import LivestreamAPI, {StreamResponse} from 'api/livestream';
import MessageAPI, {MessageLocation} from 'api/message';

import {LivestreamAction, LivestreamModel, WatchMode} from './LivestreamTypes';

function streamResponseToModel(item: StreamResponse): LivestreamModel.StreamInfo {
  return {
    id: item.stream_id,
    title: item.title,
    name: item.name,
    rating: item.rating,
    channelId: item.channel_id,
    status: item.status,
    omCharge: item.om_charge,
    ooCharge: item.oo_charge,
    beginAt: item.begin_at,
    keepAliveAt: item.keep_alive_at,
    occupied: item.is_occupied,

    userId: item.user_id,
    userName: item.user_name,
    userPicture: item.picture,
    cover: item.cover,

    announcement: item.announcement,
    earning: item.total_point_received,
    numWatchers: item.current_user,
    score: item.score,
    downtime: dayjs().add(item.downtime_counter, 's').toDate(),
    resting: item.resting,
    toyEnabled: item.toy_enable,

    vod: item.mp4_info.is_mp4
      ? {
          url: item.mp4_info.mp4_url,
          offset: item.mp4_info.timestamp,
        }
      : undefined,
  };
}

export const loadLivestreamList = (refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: LivestreamAction.GET_LIVESTREAM_LIST});
    const cursor = refresh ? null : getState().livestream.list.cursor;
    const res = await LivestreamAPI.getLivestreamList(cursor);

    dispatch({
      type: LivestreamAction.GET_LIVESTREAM_LIST_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        items: res.streams.map((item) => streamResponseToModel(item)),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadLivestream = (
  streamId: string,
): AppThunk<Promise<LivestreamModel.StreamInfo>> => async (dispatch) => {
  try {
    const res = await LivestreamAPI.getLivestreamInfo(streamId);
    const payload = streamResponseToModel(res.stream);
    dispatch({
      type: LivestreamAction.GET_LIVESTREAM,
      payload,
    });
    return payload;
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

const WATCH_HEARTBEAT_RATE = 1000;
// const MAX_RETRY_TIMES = 15;

export const watchLivestream = (
  streamId: string,
  mode: WatchMode,
  errorHandler: (err: ErrorCode) => void,
  watchHandler?: () => void,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  try {
    const watching = getState().livestream.watching;
    const livestream =
      getState().livestream.cache[streamId] || (await dispatch(loadLivestream(streamId)));
    const freeMode =
      livestream.vod || (livestream.status !== mode && livestream.status !== WatchMode.Free);

    if (freeMode) {
      return dispatch(watchFreeLiveStream(streamId));
    }

    const res =
      watching && watching.channelId && watching.authKey
        ? {
            auth_key: watching.authKey,
            channel_id: watching.channelId,
            downtime_counter: 0,
            mode: livestream.status,
          }
        : await LivestreamAPI.startWatch(streamId, livestream.status);

    if (watchHandler) {
      watchHandler();
    }

    let errorCount = 0;
    const heartbeat = window.setInterval(() => {
      LivestreamAPI.keepWatch(streamId, mode)
        .then(() => (errorCount = 0))
        .catch((error) => {
          const err: ServerError = error;
          errorCount += 1;
          const handler = getState().livestream.watching?.heartbeatHandler;
          if (!handler) {
            return;
          }
          if (errorCount >= 15) {
            handler(ErrorCode.Anomaly);
          } else {
            handler(err.err_code);
          }
        });
    }, WATCH_HEARTBEAT_RATE);

    const me = getMyInfo(getState());

    const reuse = watching?.streamId === streamId && watching.channel.sub !== undefined;

    const sub = reuse
      ? (watching!.channel.sub as Pubnub)
      : new Pubnub({
          origin: 'funzhou.pubnubapi.com',
          subscribeKey: PubNubKey,
          authKey: res.auth_key,
          uuid: me.userId,
          autoNetworkDetection: true,
          restore: true,
          keepAlive: true,
          listenToBrowserNetworkEvents: true,
        });

    if (!reuse) {
      getState().livestream.watching?.channel.sub?.unsubscribeAll();
      getState().livestream.watching?.channel.sub?.stop();
    }

    dispatch({
      type: LivestreamAction.WATCH_LIVESTREAM,
      payload: {
        streamId,
        mode: res.mode,
        authKey: res.auth_key,
        channelId: res.channel_id,
        heartbeat,
        heartbeatHandler: errorHandler,
        sub,
      },
    });

    if (!reuse) {
      sub.addListener({
        message(evt) {
          const {msg}: MessageModel.RawStream = evt.message;
          const watchingStatus = getState().livestream.watching;
          if (!watchingStatus) {
            return;
          }

          for (const listener of watchingStatus.channel.listeners) {
            listener(msg);
          }
        },
      });

      sub.subscribe({channels: [streamId]});

      dispatch(
        listenLivestreamMessage((msg) => {
          switch (msg.type) {
            case MessageType.StreamStatusChange: {
              if (getMyInfo(getState()).userId !== msg.meta_data.target_user_id) {
                dispatch(updateWatchingStatus(msg.meta_data.new_status, msg.meta_data.channel_id));
              }
              return;
            }
            case MessageType.StreamCancelDowntime:
              return dispatch(loadLivestream(streamId));
          }
        }),
      );
    }
  } catch (err) {
    const error: ServerError = err;
    errorHandler(error.err_code);
  }
};

export const watchFreeLiveStream = (streamId: string): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const livestream = getState().livestream.cache[streamId];
  dispatch({
    type: LivestreamAction.WATCH_LIVESTREAM,
    payload: {
      streamId,
      mode: livestream.status,
      authKey: '',
      channelId: livestream.channelId,
      heartbeat: null,
      heartbeatHandler: () => {
        return;
      },
      sub: undefined,
    },
  });
};

export const listenLivestreamMessage = (listener: Listener): AppThunk<void> => async (dispatch) => {
  dispatch({
    type: LivestreamAction.LISTEN_LIVESTREAM_CHANNEL,
    payload: listener,
  });
};

export const updateWatchingStatus = (
  mode: WatchMode,
  channelId: string | null,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const watchingStatus = getState().livestream.watching;
  if (!watchingStatus) {
    return;
  }

  await dispatch(loadLivestream(watchingStatus.streamId));

  clearInterval(watchingStatus.heartbeat || undefined);
  dispatch({
    type: LivestreamAction.UPDATE_WATCHING_STATUS,
    payload: {
      mode,
      channelId,
    },
  });
};

export const acceptInvite = (requestId: string, mode: WatchMode): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    const {channel_id} = await LivestreamAPI.acceptUpgrade(requestId);
    dispatch(updateWatchingStatus(mode, channel_id));
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const rejectInvite = (requestId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await LivestreamAPI.rejectUpgrade(requestId);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const leaveLivestream = (
  streamId: string,
  mode: WatchMode,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const watchingStatus = getState().livestream.watching;
  if (watchingStatus) {
    clearInterval(watchingStatus.heartbeat || undefined);
    watchingStatus.channel.sub?.unsubscribeAll();
    watchingStatus.channel.sub?.stop();
  }

  dispatch({
    type: LivestreamAction.LEAVE_LIVESTREAM,
  });

  try {
    await LivestreamAPI.leave(streamId, mode);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadContributorsList = (
  streamId: string,
  refresh: boolean,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch({type: LivestreamAction.GET_CONTRIBUTORS_LIST});
  const cursor = refresh ? null : getState().livestream.contribution.cursor;

  try {
    const res = await LivestreamAPI.getContributionList(streamId, cursor);
    dispatch({
      type: LivestreamAction.GET_CONTRIBUTORS_LIST_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        contributors: (res.rank || []).map((contributor) => ({
          rank: contributor.rank,
          userName: contributor.name,
          userId: contributor.id,
          userPicture: contributor.picture,
          contribution: contributor.score,
        })),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const sendLivestreamChatMessage = (
  streamId: string,
  content: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await MessageAPI.sendMessage({
      targetId: streamId,
      location: MessageLocation.Stream,
      content,
      type: MessageType.Chat,
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const updateLocalSteam = (payload: boolean): AppThunk<void> => async (dispatch) => {
  dispatch({
    type: LivestreamAction.UPDATE_LOCAL_STREAM,
    payload,
  });
};
