import {API} from 'api';

import {GoldType, ConsumptionType, PaymentMethod, PaymentStatus} from 'model/wallet/WalletTypes';

export default class WalletAPI {
  public static getBalance() {
    return API.get<{point: number}>('/wallet/balance');
  }

  public static getDistributionSummary() {
    return API.get<{
      gold: number;
      up_line_user?: {
        user_id: string;
        name: string;
        picture: string;
      };
      first_level_info: {
        user_count: number;
        gold_gain: number;
      };
      second_level_info: {
        user_count: number;
        gold_gain: number;
      };
      third_level_info: {
        user_count: number;
        gold_gain: number;
      };
    }>('/wallet/distribution_summary');
  }

  public static getDistributionDetail(level: number) {
    return API.get<
      Array<{
        name: string;
        picture: string;
        second_level_down_line_count: number | undefined; // 1
        third_level_down_line_count: number | undefined; // 1, 2
        up_line_name: string | undefined; // 2
        first_up_line_name: string | undefined; // 3
        second_up_line_name: string | undefined; // 3
      }>
    >('/wallet/distribution_detail', {
      query_type: level,
    });
  }

  public static getGoldRecords(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{
      cursor: string;
      history: Array<{
        date_title: string;
        total_receive_point: number;
        histories: Array<{
          name: string;
          level: number;
          number: number;
          gold: number;
          created_at: string;
          gold_history_type: GoldType;
        }>;
      }>;
    }>('/wallet/gold/daily_histories', params);
  }

  public static transformGold(gold: number) {
    return API.post('/wallet/gold/transform', {amount: gold});
  }

  public static getConsumptionHistory(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{
      point: number;
      cursor?: string;
      histories_list: Array<{
        date_title: string;
        total_consume_point: number;
        histories: Array<{
          trade_type: ConsumptionType;
          amount: number;
          count: number;
          to_user_id: string;
          to_user_name: string;
          picture: string;
          created_at: string;
          note: string;
        }>;
      }>;
    }>('/wallet/consume_history', params);
  }

  public static getCreditHistory(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{
      point: number;
      cursor: string;
      history_list: Array<{
        date_title: string;
        total_gain_point: number;
        histories: Array<{
          created_at: string;
          deleted_at: string;
          order_id: string;
          point: number;
          price: number;
          status: PaymentStatus;
        }>;
      }>;
    }>('/wallet/credit_history', params);
  }

  public static getCreditDetail(orderId: string) {
    return API.get<{
      detail: {
        price: number;
        order_id: string;
        pay_method_id: PaymentMethod;
        expired_at: string;
      };
    }>('/wallet/credit_history/detail', {order: orderId});
  }

  public static getPayMethods() {
    return API.get<{
      pay_methods: Array<{
        pay_method_id: PaymentMethod;
      }>;
    }>('/product/paymethod');
  }

  public static getProducts() {
    return API.get<{
      products: Array<{
        bonus_point: number;
        point: number;
        price: number;
        product_id: string;
      }>;
    }>('/product/list');
  }

  public static purchase(method: PaymentMethod, product: string) {
    return API.post<{html: string}>('/payment/purchase', {
      channel_id: 'aurora_pay',
      pay_method: method,
      product_id: product,
    });
  }
}
