import {PayloadAction, PureAction} from 'model/helper';
import {LivestreamModel} from 'model/livestream/LivestreamTypes';
import {UserModel} from 'model/user/UserTypes';

export class PostAction {
  public static readonly GET_SUGGESTED_USERS = 'GET_SUGGESTED_USERS';
  public static readonly GET_SUGGESTED_USERS_S = 'GET_SUGGESTED_USERS_S';

  public static readonly GET_BANNERS = 'GET_BANNERS';
  public static readonly GET_POSTS = 'GET_POSTS';
  public static readonly GET_POSTS_S = 'GET_POSTS_S';
  public static readonly GET_UNLOCKED_POSTS = 'GET_UNLOCKED_POSTS';
  public static readonly GET_UNLOCKED_POSTS_S = 'GET_UNLOCKED_POSTS_S';
  public static readonly GET_SAVED_POSTS = 'GET_SAVED_POSTS';
  public static readonly GET_SAVED_POSTS_S = 'GET_SAVED_POSTS_S';

  public static readonly SAVE_POST = 'SAVE_POST';
  public static readonly UNSAVE_POST = 'UNSAVE_POST';
  public static readonly LIKE_POST = 'LIKE_POST';
  public static readonly UNLIKE_POST = 'UNLIKE_POST';
  public static readonly UNLOCK_POST = 'UNLOCK_POST';
  public static readonly GET_SINGLE_POST = 'GET_POST';

  public static readonly GET_COMMENTS = 'GET_COMMENTS';
  public static readonly GET_COMMENTS_S = 'GET_COMMENTS_S';
  public static readonly SEND_COMMENT = 'SEND_COMMENT';

  public static readonly PLAY_VIDEO = 'PLAY_VIDEO';
  public static readonly STOP_VIDEO = 'STOP_VIDEO';
  public static readonly MUTE_VIDEO = 'MUTE_VIDEO';
  public static readonly UNMUTE_VIDEO = 'UNMUTE_VIDEO';
}

export enum ArticleType {
  Livestream = 0,
  Post,
}

export enum PostStatus {
  Pass = 1,
  Block,
  Expired,
}

export enum LockedMediaType {
  Free = 0,
  WithoutFirst = 1, // only the first media is unlocked
  All, // all media is locked
}

export enum MediaExtension {
  OctetStream = 0,
  JPEG,
  PNG,
  JSON,
  MP4,
  M4A,
}

export enum CommentType {
  Text = 0,
  Gift = 1,
}

type BannerStatus = number;

export declare namespace PostModel {
  export interface State {
    banner: {
      interval: number;
      items: Banner[];
    };
    suggested: string[];
    posts: {
      loading: boolean;
      cursor: string | null;
      articles: string[];
    };
    unlockedPosts: {
      loading: boolean;
      cursor: string | null;
      articles: string[];
    };
    savedPosts: {
      loading: boolean;
      cursor: string | null;
      articles: string[];
    };
    comment: {
      [id: string]: {
        cursor: string | null;
        loading: boolean;
        comments: Comment[];
      };
    };
    cache: {[id: string]: Article};
    video: {
      playing: string | null; //id@index
      muted: boolean;
    };
  }

  export interface PublishVideo {
    id: string;
    userId: string;
    picture: string;
    userPicture: string;
    title: string;
    url?: string;
    unlockPoint: number;
    unlockCount: number;
    expireTime: Date;
    publishTime: Date;
    unlocked: boolean;
  }

  export interface Banner {
    link?: string;
    id: string;
    title: string;
    picture: string;
    status: BannerStatus;
  }

  export interface Article {
    id: string;

    userId: string;
    userName: string;
    userPicture: string;
    cover: {
      compressed: string;
      origin: string;
    };
    followed: boolean;

    content: string[];
    media: Array<{
      url: string;
      ext: MediaExtension;
      preview?: string;
    }>;
    mediaLock: LockedMediaType;
    unlocked: boolean;
    unlockPoint: number;
    numUnlock: number;
    like: boolean;
    numLikes: number;
    numComments: number;
    saved: boolean;
    status: unknown;
    createdAt: string;
    updatedAt: string;

    comments: unknown[];
    note: string;

    streamInfo?: LivestreamModel.StreamInfo;
  }

  export interface Comment {
    id: string;
    type: CommentType;
    userId: string;
    userPicture: string;
    userName: string;
    content: string;
    createdAt: string;
    updatedAt: string;

    giftName: string;
    giftPicture: string;
  }

  type GetSuggestedUsersAction = PureAction<typeof PostAction['GET_SUGGESTED_USERS']>;
  type AfterGetSuggestedUsersAction = PayloadAction<
    typeof PostAction['GET_SUGGESTED_USERS_S'],
    UserModel.MemberInfo[]
  >;

  type GetBannersAction = PayloadAction<
    typeof PostAction['GET_BANNERS'],
    {
      banners: Banner[];
      interval: number;
    }
  >;

  type GetPostsAction = PureAction<typeof PostAction['GET_POSTS']>;
  type AfterGetPostsAction = PayloadAction<
    typeof PostAction['GET_POSTS_S'],
    {
      refresh: boolean;
      cursor: string | null;
      articles: Article[];
    }
  >;

  type GetUnlockedPostAction = PureAction<typeof PostAction['GET_UNLOCKED_POSTS']>;
  type AfterGetUnlockedPostAction = PayloadAction<
    typeof PostAction['GET_UNLOCKED_POSTS_S'],
    {
      refresh: boolean;
      cursor: string | null;
      articles: Article[];
    }
  >;

  type GetSavedPostAction = PureAction<typeof PostAction['GET_SAVED_POSTS']>;
  type AfterGetSavedPostAction = PayloadAction<
    typeof PostAction['GET_SAVED_POSTS_S'],
    {
      refresh: boolean;
      cursor: string | null;
      articles: Article[];
    }
  >;

  type SavePostAction = PayloadAction<typeof PostAction['SAVE_POST'], string>;
  type UnsavePostAction = PayloadAction<typeof PostAction['UNSAVE_POST'], string>;
  type LikePostAction = PayloadAction<typeof PostAction['LIKE_POST'], string>;
  type UnlikePostAction = PayloadAction<typeof PostAction['UNLIKE_POST'], string>;
  type UnlockPostAction = PayloadAction<typeof PostAction['UNLOCK_POST'], Article>;

  type GetSinglePostAction = PayloadAction<typeof PostAction['GET_SINGLE_POST'], Article>;

  type GetCommentsAction = PayloadAction<typeof PostAction['GET_COMMENTS'], {postId: string}>;

  type AfterGetCommentsAction = PayloadAction<
    typeof PostAction['GET_COMMENTS_S'],
    {
      postId: string;
      refresh: boolean;
      cursor: string | null;
      comments: Comment[];
    }
  >;

  type SendCommentsAction = PayloadAction<
    typeof PostAction['SEND_COMMENT'],
    {
      postId: string;
      comment: Comment;
    }
  >;

  type PlayVideoAction = PayloadAction<typeof PostAction['PLAY_VIDEO'], string>;
  type StopVideoAction = PureAction<typeof PostAction['STOP_VIDEO']>;
  type MuteVideoAction = PureAction<typeof PostAction['MUTE_VIDEO']>;
  type UnmuteVideoAction = PureAction<typeof PostAction['UNMUTE_VIDEO']>;

  export type ActionTypes =
    | GetSuggestedUsersAction
    | AfterGetSuggestedUsersAction
    | GetBannersAction
    | GetPostsAction
    | AfterGetPostsAction
    | GetUnlockedPostAction
    | AfterGetUnlockedPostAction
    | GetSavedPostAction
    | AfterGetSavedPostAction
    | SavePostAction
    | UnsavePostAction
    | LikePostAction
    | UnlikePostAction
    | UnlockPostAction
    | GetSinglePostAction
    | GetCommentsAction
    | AfterGetCommentsAction
    | SendCommentsAction
    | PlayVideoAction
    | StopVideoAction
    | MuteVideoAction
    | UnmuteVideoAction;
}
