import {Box, Button, Divider} from '@material-ui/core';
import {ErrorCode} from 'api';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

import {AppDispatch} from 'model/helper';
import {sendSNS} from 'model/user/UserActions';

import PhoneInput, {CountryCode} from 'components/PhoneInput';
import Typography from 'components/i18n/Typography';

interface AuthPhoneEnterFormProps {
  title: string;
  subtitle: string;
  onSuccess(phone: string, country: CountryCode): void;
}

function AuthPhoneEnterForm({title, subtitle, onSuccess}: AuthPhoneEnterFormProps) {
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();

  const [countryCode, setCountryCode] = useState(CountryCode.Taiwan);
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorCode | undefined>(undefined);

  const send = useCallback(() => {
    dispatch(sendSNS({phone, countryCode}))
      .then(() => {
        onSuccess(phone, countryCode);
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, phone, countryCode, onSuccess]);

  return (
    <Box display="flex" flexDirection="column" mx={2} alignItems="stretch">
      <Box component="header" mb={2} textAlign="center">
        <Typography component="h3" variant="h4">
          {title}
        </Typography>
      </Box>

      <Box textAlign="center" mb={3}>
        <Typography variant="h6">{subtitle}</Typography>
        <Divider />
      </Box>

      <PhoneInput
        countryCode={countryCode}
        onChangeCountryCode={setCountryCode}
        phone={phone}
        onChangePhone={setPhone}
      />

      {error && <Typography>{`error.${error}`}</Typography>}

      <Box
        position="relative"
        display="flex"
        alignItems="center"
        className="gradientButtonWrapper"
        my={2}>
        <Box
          className={['overlay', styles.gradient, 'gradientButton'].join(' ')}
          top={-4}
          bottom={-4}
        />
        <Button
          variant="outlined"
          size="large"
          fullWidth
          disabled={loading || phone === ''}
          onClick={send}>
          <Typography>common.next</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default AuthPhoneEnterForm;
