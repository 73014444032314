import {AppBar, Box, Toolbar, Typography as Text} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import StoryList from 'routes/home/StoryList';

import {loadKeywordPosts} from 'model/explore/ExploreAction';
import {
  exploreArticlesSelector,
  hasExploreArticlesNextPage,
  isExploreArticlesLoading,
} from 'model/explore/ExploreSelector';
import {SearchType} from 'model/explore/ExploreTypes';
import {AppDispatch} from 'model/helper';

import BackButton from 'components/BackButton';
import PointLabel from 'components/PointLabel';

function ExploreTagStoryPage() {
  const dispatch = useDispatch<AppDispatch>();
  const {tag} = useParams<{tag: string}>();
  const location = useLocation<{start?: string}>();

  const articles = useSelector(exploreArticlesSelector);
  const isLoading = useSelector(isExploreArticlesLoading);
  const hasNextPage = useSelector(hasExploreArticlesNextPage);

  useEffect(() => {
    if (articles.length === 0) {
      dispatch(loadKeywordPosts(tag, SearchType.Tag, true));
    }
  }, [dispatch, tag, articles]);

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadKeywordPosts(tag, SearchType.Streamer, false));
  }, [isLoading, hasNextPage, tag, dispatch]);

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/explore" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Text variant="h6" component="h2">
              #{tag}
            </Text>
          </Box>
          <PointLabel flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <StoryList
        start={location.state.start}
        items={articles}
        hasNextPage={hasNextPage}
        loadMore={loadMore}
      />
    </Box>
  );
}

export default ExploreTagStoryPage;
