import {Box, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import {calculateStringWidth, sliceStringByLines} from 'utils/string';

import Typography from './i18n/Typography';

interface ArticleParagraphProps {
  p: string[];
  expanded?: boolean;
  width?: number;
  onExpand?: () => void;
}

const HASHTAG_REG = /#[a-z]+/gi;
const MAX_SHRINK_LINE = 2;
const MORE_TEXT_WIDTH = 60;
const MORE_TEXT_RESERVED_CHARS = 14;

function HashtagsParagraph(props: {s: string; hashtags: string[]}) {
  const styles = useThemeStyles();

  return (
    <>
      {props.s.split(HASHTAG_REG).map((span, index) => (
        <Text component="span" key={index}>
          {span}
          {props.hashtags[index] && (
            <Link to={`/explore/tag/${props.hashtags[index].slice(1)}`} className={styles.hashtag}>
              {props.hashtags[index]}
            </Link>
          )}
        </Text>
      ))}
    </>
  );
}

function ArticleParagraph({p, expanded = true, width = 1000, onExpand}: ArticleParagraphProps) {
  const nodes = useMemo(() => {
    let currentLines = 0;
    return p.slice(0, expanded ? undefined : MAX_SHRINK_LINE).map((sub, index) => {
      const hashtags = sub.match(HASHTAG_REG) || [];

      if (!expanded) {
        const lines = sliceStringByLines(sub, width - 32);
        currentLines += lines.length;

        if (currentLines === MAX_SHRINK_LINE) {
          const isLastLine = index === p.length - 1;
          return isLastLine ? (
            <Text className="mono" key={index}>
              <HashtagsParagraph s={sub} hashtags={hashtags} />
            </Text>
          ) : (
            <Text className="mono shrinkParagraph" key={index}>
              <HashtagsParagraph
                s={
                  calculateStringWidth(sub) < width - MORE_TEXT_WIDTH
                    ? sub + '...'
                    : sub.substr(0, sub.length - MORE_TEXT_RESERVED_CHARS) + '...'
                }
                hashtags={hashtags}
              />
              <Typography color="textSecondary" onClick={onExpand}>
                common.more
              </Typography>
            </Text>
          );
        } else if (currentLines > MAX_SHRINK_LINE) {
          const overline = currentLines - MAX_SHRINK_LINE;
          const newLine = lines.slice(0, lines.length - overline).join('');
          return (
            <Text className="mono shrinkParagraph" key={index}>
              <HashtagsParagraph
                s={`${newLine.substr(0, newLine.length - MORE_TEXT_RESERVED_CHARS)}...`}
                hashtags={hashtags}
              />
              <Typography color="textSecondary" onClick={onExpand}>
                common.more
              </Typography>
            </Text>
          );
        } else {
          return (
            <Text className="mono" key={index}>
              <HashtagsParagraph s={sub} hashtags={hashtags} />
            </Text>
          );
        }
      }

      return (
        <Text key={index}>
          <HashtagsParagraph s={sub} hashtags={hashtags} />
        </Text>
      );
    });
  }, [p, expanded, width, onExpand]);

  return <Box>{nodes}</Box>;
}

export default ArticleParagraph;
