import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Toolbar,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLastLocation} from 'react-router-last-location';
import SwipeableViews from 'react-swipeable-views';

import {AppDispatch} from 'model/helper';
import {loadPosts, loadSuggestedUsers} from 'model/post/PostAction';
import {
  getArticles,
  hasPostsNextPage,
  isPostsLoading,
  suggestedUsersSelector,
} from 'model/post/PostSelector';
import {followUser, unfollowUser} from 'model/user/UserActions';
import {UserModel} from 'model/user/UserTypes';

import {useWindowSize} from 'utils/hook';

import Banner, {BANNER_RATIO} from 'components/Banner';
import Brand from 'components/Brand';
import Carousel from 'components/Carousel';
import Icon from 'components/Icons';
import PointLabel from 'components/PointLabel';
import Typography from 'components/i18n/Typography';

import {ReactComponent as InactiveLiveIcon} from 'assets/icon/regular/live-inactive.svg';

import StoryList from './StoryList';

const COVER_RATIO = 16 / 9;

function HomePage() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const width = useWindowSize();
  const styles = useThemeStyles();
  const sliderStyle = {width: width - 80, height: (width - 80) / COVER_RATIO};

  const suggested = useSelector(suggestedUsersSelector);
  const [sliderIndex, setSliderIndex] = useState(0);

  const articles = useSelector(getArticles);
  const isLoading = useSelector(isPostsLoading);
  const hasNextPage = useSelector(hasPostsNextPage);
  const lastLocation = useLastLocation();
  const startArticleId =
    !lastLocation || lastLocation.pathname === '/'
      ? undefined
      : lastLocation.pathname.startsWith('/post/')
      ? lastLocation.pathname.split('/')[2]
      : undefined;

  useEffect(() => {
    if (!lastLocation || lastLocation.pathname === '/' || articles.length === 0) {
      dispatch(loadPosts(true)).then((articles) => {
        if (articles.length === 0) {
          dispatch(loadSuggestedUsers());
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadPosts(false));
  }, [isLoading, hasNextPage, dispatch]);

  const toLiveStream = useCallback(() => {
    history.push('/livestream');
  }, [history]);

  const handleFollow = useCallback(
    (user: UserModel.MemberInfo) => {
      return () => {
        if (user.followed) {
          dispatch(unfollowUser(user.userId));
        } else {
          dispatch(followUser(user.userId));
        }
      };
    },
    [dispatch],
  );

  return (
    <Box className="full" display="flex" flexDirection="column" flex="1" overflow="auto">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1} ml={-2}>
            <Brand />
          </Box>

          <IconButton onClick={toLiveStream}>
            <Icon viewBox="0 0 33.782 23" svg={InactiveLiveIcon} fontSize="large" />
          </IconButton>
          <PointLabel justifyContent="flex-end" />
        </Toolbar>
      </AppBar>

      {articles.length > 0 && (
        <StoryList
          start={startArticleId}
          items={articles}
          hasNextPage={hasNextPage}
          loadMore={loadMore}
          HeaderComponent={Banner}
          headerHeight={width / BANNER_RATIO}
        />
      )}

      <Box hidden={suggested.length === 0 || articles.length > 0} flex={1} overflow="auto">
        <Box textAlign="center" mx={10} mt={10} mb={5}>
          <Typography variant="h5" component="p" keys={{brand: process.env.REACT_APP_TITLE}}>
            paragraph.introduction.0
          </Typography>
          <Typography component="p">paragraph.introduction.1</Typography>
        </Box>

        <Box>
          <SwipeableViews
            className="coverflow"
            enableMouseEvents
            index={sliderIndex}
            onChangeIndex={(index) => {
              setSliderIndex(index);
            }}>
            {suggested.map((user, index) => {
              return (
                <Paper key={user.userId}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box p={2} display="flex" flexDirection="column" alignItems="center">
                      <Avatar src={user.picture} className="avatar100" />

                      <Box m={2} textAlign="center">
                        <Text variant="h6">{user.name}</Text>
                        <Text>{user.bio}</Text>
                      </Box>
                    </Box>

                    <Box
                      width={width - 200}
                      height={(width - 80) / COVER_RATIO}
                      position="relative"
                      alignSelf="flex-start">
                      <Box className="overlay">
                        <Carousel
                          interval={2000}
                          style={sliderStyle}
                          disabled={sliderIndex !== index}>
                          {(user?.covers ?? []).map((url, index) => (
                            <img
                              key={index}
                              alt="cover"
                              src={url}
                              className="fullMedia"
                              style={sliderStyle}
                            />
                          ))}
                        </Carousel>
                      </Box>
                    </Box>

                    <Box position="relative" className="gradientButtonSmallWrapper" my={2}>
                      <Box
                        hidden={user.followed}
                        className={['overlay', styles.gradient, 'gradientButtonSmall'].join(' ')}
                      />

                      <Button variant="outlined" size="small" onClick={handleFollow(user)}>
                        <Typography>{user.followed ? 'input.unfollow' : 'input.follow'}</Typography>
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              );
            })}
          </SwipeableViews>
        </Box>
      </Box>
    </Box>
  );
}

export default HomePage;
