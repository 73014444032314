import {combineReducers} from 'redux';

import {Listener, RootActions} from 'model/helper';

import {LivestreamAction, LivestreamModel} from './LivestreamTypes';

const initialState: LivestreamModel.State = {
  list: {
    loading: true,
    cursor: null,
    items: [],
  },
  cache: {},
  watching: null,
  contribution: {
    loading: true,
    cursor: null,
    contributors: [],
  },
};

function list(state = initialState.list, action: RootActions): LivestreamModel.State['list'] {
  switch (action.type) {
    case LivestreamAction.GET_LIVESTREAM_LIST:
      return {
        ...state,
        loading: true,
      };
    case LivestreamAction.GET_LIVESTREAM_LIST_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        items: action.payload.refresh
          ? action.payload.items.map((item) => item.id)
          : state.items.concat(action.payload.items.map((item) => item.id)),
      };
    default:
      return state;
  }
}

function cache(state = initialState.cache, action: RootActions): LivestreamModel.State['cache'] {
  switch (action.type) {
    case LivestreamAction.GET_LIVESTREAM_LIST_S:
      return action.payload.items.reduce(
        (cache, item) => {
          cache[item.id] = item;
          return cache;
        },
        {...state},
      );
    case LivestreamAction.GET_LIVESTREAM:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}

let tmpListeners: Listener[] = [];

function watching(
  state = initialState.watching,
  action: RootActions,
): LivestreamModel.State['watching'] {
  switch (action.type) {
    case LivestreamAction.WATCH_LIVESTREAM:
      return {
        streamId: action.payload.streamId,
        authKey: action.payload.authKey,
        channelId: action.payload.channelId,
        mode: action.payload.mode,
        heartbeat: action.payload.heartbeat,
        heartbeatHandler: action.payload.heartbeatHandler,
        hasLocalStream: false,
        channel: {
          sub: action.payload.sub,
          listeners: tmpListeners,
        },
      };
    case LivestreamAction.LISTEN_LIVESTREAM_CHANNEL: {
      if (!state) {
        tmpListeners.push(action.payload);
        return state;
      }

      state.channel.listeners.push(action.payload);
      return state;
    }
    case LivestreamAction.UPDATE_WATCHING_STATUS:
      return {
        ...state!,
        mode: action.payload.mode,
        channelId: action.payload.channelId,
        heartbeat: null,
      };
    case LivestreamAction.UPDATE_LOCAL_STREAM:
      return {
        ...state!,
        hasLocalStream: action.payload,
      };
    case LivestreamAction.LEAVE_LIVESTREAM: {
      tmpListeners = [];
      return null;
    }
    default:
      return state;
  }
}

function contribution(
  state = initialState.contribution,
  action: RootActions,
): LivestreamModel.State['contribution'] {
  switch (action.type) {
    case LivestreamAction.WATCH_LIVESTREAM:
      return initialState.contribution;
    case LivestreamAction.GET_CONTRIBUTORS_LIST:
      return {
        ...state,
        loading: true,
      };
    case LivestreamAction.GET_CONTRIBUTORS_LIST_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        contributors: action.payload.refresh
          ? action.payload.contributors
          : state.contributors.concat(action.payload.contributors),
      };
    case LivestreamAction.LEAVE_LIVESTREAM:
      return initialState.contribution;
    default:
      return state;
  }
}

export default combineReducers<LivestreamModel.State>({
  list,
  cache,
  watching,
  contribution,
});
