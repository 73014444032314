import {RootState} from 'model/helper';

import {GiftLocation} from './GiftTypes';

export const getGiftState = (state: RootState) => state.gift;

export const isGiftDrawerOpened = (state: RootState) => getGiftState(state).open !== null;
export const getGiftLocation = (state: RootState) =>
  getGiftState(state).open?.location ?? GiftLocation.Nowhere;

export const getGiftCategories = (state: RootState) => getGiftState(state).categories;
export const getGifts = (state: RootState) => getGiftState(state).cache;
