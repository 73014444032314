export function toFixed(num: number, precision: number, rightPadding = true) {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${precision}})?`);
  const matchPattern = num.toString().match(re);
  const n = matchPattern ? parseFloat(matchPattern[0]) : num;
  return rightPadding ? n.toFixed(precision) : n.toString();
}

export function getDecimalNumber(num: number) {
  const numStr = num.toString();
  const [, decimal] = numStr.split('.');
  if (decimal) {
    return decimal.length;
  }
  return 0;
}

export function format(
  num: number | string,
  precision: number | undefined = undefined,
  range = 3,
  symbol = ',',
) {
  if (num === undefined) {
    return '';
  }

  const value = typeof num === 'string' ? parseFloat(num) : num;
  const decimal = getDecimalNumber(value);
  const regex = `\\d(?=(\\d{${range}})+${decimal ? '\\.' : '$'})`;
  const fixedValue = precision === undefined ? value.toString() : toFixed(value, precision);

  return fixedValue.replace(new RegExp(regex, 'g'), `$&${symbol}`);
}

export function leftPadding(num: number | string, pad: number): string {
  const formatNumber = parseFloat(num as string);
  const normalizedNumber = Math.abs(formatNumber).toString();
  if (normalizedNumber.length > pad) {
    return formatNumber.toString();
  }

  return (
    (formatNumber < 0 ? '-' : '') +
    Array(pad)
      .fill('0')
      .join('')
      .substring(0, pad - normalizedNumber.length) +
    normalizedNumber
  );
}
