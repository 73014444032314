import {AppBar, Avatar, Box, Toolbar, Typography as Text} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Conversation from 'routes/mailbox/Conversation';

import {messengerSelector} from 'model/message/MessageSelector';

import BackButton from 'components/BackButton';
import PointLabel from 'components/PointLabel';

function ConversationPage() {
  const {id} = useParams<{id: string}>();
  const messenger = useSelector(messengerSelector)(id);

  return (
    <Box display="flex" flexDirection="column" className="full">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1} display="flex" alignItems="center">
            <BackButton to="/mailbox" />
            <Box mr={2}>
              <Avatar src={messenger.userPicture} className="avatar" />
            </Box>
            <Text variant="h6">{messenger.userName}</Text>
          </Box>
          <PointLabel justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <Conversation userId={id} />
    </Box>
  );
}

export default ConversationPage;
