import {combineReducers} from 'redux';

import {RootActions} from 'model/helper';
import {PostAction} from 'model/post/PostTypes';

import {UserModel, UserAction, UserRole, Gender, MemberStatus, RegisterType} from './UserTypes';

const initialState: UserModel.State = {
  auth: null,
  info: {
    id: 0,
    userId: '',
    externalId: '',

    name: '',
    role: UserRole.normal,
    bio: '',
    voiceBio: '',
    gender: Gender.male,
    // birthday: number;
    picture: '',
    covers: [],
    inviteCode: '',
    numFollower: 0,
    numFollowee: 0,
    numPosts: 0,

    streamId: '',

    account: '',
    phone: '',
    note: '',
    pushToken: '',
    ip: '',
    credit: 0,
    registerAt: '',
    loginAt: '',
    device: '',
    badge: 0,
    age: 0,
    status: MemberStatus.active,
    countryCode: '',
    registerType: RegisterType.Phone,
  },
  cache: {},
  qr: null,
  followers: {
    loading: true,
    cursor: null,
    list: [],
  },
  followees: {
    loading: true,
    cursor: null,
    list: [],
  },
  linking: {
    phone: '',
    facebook: true,
    google: true,
    line: true,
  },
  activity: [],
  recommendation: [],
};

function auth(state = initialState.auth, action: UserModel.ActionTypes): UserModel.State['auth'] {
  switch (action.type) {
    case UserAction.USER_LOGIN_S:
      return {
        token: action.payload.token,
        refreshToken: '',
        authKey: action.payload.authKey,
      };
    case UserAction.USER_LOGOUT:
      return initialState.auth;
    default:
      return state;
  }
}

function info(state = initialState.info, action: RootActions): UserModel.State['info'] {
  switch (action.type) {
    case UserAction.GET_MY_INFO:
      return action.payload;
    case UserAction.USER_LOGOUT:
      return initialState.info;
    default:
      return state;
  }
}

function cache(state = initialState.cache, action: RootActions): UserModel.State['cache'] {
  switch (action.type) {
    case PostAction.GET_SUGGESTED_USERS_S:
      return action.payload.reduce(
        (cache, user) => {
          cache[user.userId] = user;
          return cache;
        },
        {...state},
      );
    case UserAction.GET_FOLLOWEE_LIST_S:
    case UserAction.GET_FOLLOWER_LIST_S:
      return action.payload.list.reduce(
        (cache, user) => {
          cache[user.userId] = user;
          return cache;
        },
        {...state},
      );
    case UserAction.USER_FOLLOW:
    case UserAction.USER_UNFOLLOW:
      return {
        ...state,
        [action.payload]: {
          ...(state[action.payload] || {}),
          followed: action.type === UserAction.USER_FOLLOW,
          numFollower:
            (state[action.payload]?.numFollower ?? 0) +
            (action.type === UserAction.USER_FOLLOW ? 1 : -1),
        },
      };
    case UserAction.GET_USER_INFO:
      return {
        ...state,
        [action.payload.userId]: action.payload,
      };
    case UserAction.USER_LOGOUT:
      return initialState.cache;
    default:
      return state;
  }
}

function qr(state = initialState.qr, action: RootActions): UserModel.State['qr'] {
  switch (action.type) {
    case UserAction.GET_QR_CODE:
      return action.payload;
    case UserAction.USER_LOGOUT:
      return null;
    default:
      return state;
  }
}

function followers(
  state = initialState.followers,
  action: RootActions,
): UserModel.State['followers'] {
  switch (action.type) {
    case UserAction.GET_FOLLOWER_LIST:
      return {
        ...state,
        loading: true,
      };
    case UserAction.GET_FOLLOWER_LIST_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        list: action.payload.refresh
          ? action.payload.list.map((user) => user.userId)
          : state.list.concat(action.payload.list.map((user) => user.userId)),
      };
    case UserAction.USER_LOGOUT:
      return initialState.followers;
    default:
      return state;
  }
}

function followees(
  state = initialState.followees,
  action: RootActions,
): UserModel.State['followees'] {
  switch (action.type) {
    case UserAction.GET_FOLLOWEE_LIST:
      return {
        ...state,
        loading: true,
      };
    case UserAction.GET_FOLLOWEE_LIST_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        list: action.payload.refresh
          ? action.payload.list.map((user) => user.userId)
          : state.list.concat(action.payload.list.map((user) => user.userId)),
      };
    case UserAction.USER_LOGOUT:
      return initialState.followees;
    default:
      return state;
  }
}

function linking(state = initialState.linking, action: RootActions): UserModel.State['linking'] {
  switch (action.type) {
    case UserAction.GET_LINKING_STATUS:
      return action.payload;
    case UserAction.LINK_THIRD_PARTY:
      return {
        ...state,
        [action.payload.provider]: true,
      };
    case UserAction.USER_LOGOUT:
      return initialState.linking;
    default:
      return state;
  }
}

function activity(state = initialState.activity, action: RootActions): UserModel.State['activity'] {
  switch (action.type) {
    case UserAction.GET_ACTIVITY:
      return action.payload;
    case UserAction.USER_FOLLOW:
    case UserAction.USER_UNFOLLOW:
      return state.map((activity) => ({
        ...activity,
        following:
          action.payload === activity.streamerId
            ? action.type === UserAction.USER_FOLLOW
            : activity.following,
      }));
    case UserAction.USER_LOGOUT:
      return initialState.activity;
    default:
      return state;
  }
}

function recommendation(
  state = initialState.recommendation,
  action: RootActions,
): UserModel.State['recommendation'] {
  switch (action.type) {
    case UserAction.GET_RECOMMENDATIONS:
      return action.payload;
    case UserAction.USER_FOLLOW:
    case UserAction.USER_UNFOLLOW:
      return state.map((streamer) => ({
        ...streamer,
        following:
          action.payload === streamer.userId
            ? action.type === UserAction.USER_FOLLOW
            : streamer.following,
      }));
    case UserAction.USER_LOGOUT:
      return initialState.recommendation;
    default:
      return state;
  }
}

export default combineReducers<UserModel.State>({
  auth,
  info,
  cache,
  qr,
  followers,
  followees,
  linking,
  activity,
  recommendation,
});
