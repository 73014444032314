import {
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from '@material-ui/core';
import i18n, {LocaleContext} from 'context/Locale';
import React, {useContext} from 'react';

interface TypographyProps<C extends React.ElementType> extends MUITypographyProps {
  component?: C;
  children: string;
  keys?: object;
  fallback?: string;
}

const Typography = ({
  children,
  keys,
  fallback,
  component = 'span',
  ...props
}: TypographyProps<any>) => {
  useContext(LocaleContext);

  if (!i18n.language) return null;
  if (typeof children !== 'string') return null;

  const translation: string = i18n.t(children, keys);
  const text: string = translation === children ? fallback || translation : translation;

  return (
    <MUITypography component={component} {...props} dangerouslySetInnerHTML={{__html: text}} />
  );
};

export default Typography;
