import {Box, Divider, Link, Grid, Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {useThemeStyles} from 'context/Theme';
import React, {useMemo, useState} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';

import {getSearchQuery} from 'utils/string';

import Brand from 'components/Brand';
import FacebookLoginButton from 'components/auth/FacebookLoginButton';
import GoogleLoginButton from 'components/auth/GoogleLoginButton';
import LineLoginButton from 'components/auth/LineLoginButton';
import Typography from 'components/i18n/Typography';

function AuthCreatePage() {
  const history = useHistory();
  const styles = useThemeStyles();
  const invite = useMemo(() => {
    return getSearchQuery(history.location.search, 'invite');
  }, [history.location.search]);

  const [inviteHint, setInviteHint] = useState(Boolean(invite));

  return (
    <Box component="main" display="flex" flexDirection="column" className="main">
      <Box
        component="header"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        flex={3}
        className={styles.gradient}
        pb={9}>
        <Box mb={5}>
          <Brand size="medium" />
        </Box>
        <Typography variant="subtitle1" component="h2">
          title.slogan
        </Typography>
      </Box>
      <Box flex={7} m={2}>
        <Box m={4} textAlign="center">
          <Link
            component={RouterLink}
            to={{pathname: '/auth/phone', search: history.location.search}}>
            <Typography>link.mobile_signup</Typography>
          </Link>
        </Box>
        <Box display="flex" alignItems="center" m={3}>
          <Box flex={1}>
            <Divider></Divider>
          </Box>
          <Box mx={2}>
            <Typography>common.or</Typography>
          </Box>
          <Box flex={1}>
            <Divider></Divider>
          </Box>
        </Box>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FacebookLoginButton text="input.facebook_signup" />
          </Grid>
          <Grid item>
            <GoogleLoginButton text="input.google_signup" />
          </Grid>
          <Grid item>
            <LineLoginButton text="input.line_signup" />
          </Grid>
        </Grid>

        <Box mt={4} textAlign="center">
          <Typography>link.before_signup</Typography>
          <Link
            component={RouterLink}
            to={{
              pathname: '/auth/login',
              search: history.location.search,
            }}>
            <Typography>link.login</Typography>
          </Link>
        </Box>
      </Box>

      <Snackbar key={inviteHint ? 1 : 0} open={inviteHint}>
        <Alert onClose={() => setInviteHint(false)} severity="warning" variant="filled">
          <Typography keys={{code: invite}}>label.invite_code_used</Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AuthCreatePage;
