import {Box, Button, Dialog, Divider, Grid, IconButton, Link, makeStyles} from '@material-ui/core';
import {ErrorCode} from 'api';
import {useThemeStyles} from 'context/Theme';
import React, {FC, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink, useHistory} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {login} from 'model/user/UserActions';

import Brand from 'components/Brand';
import Icon from 'components/Icons';
import PhoneInput, {CountryCode} from 'components/PhoneInput';
import FacebookLoginButton from 'components/auth/FacebookLoginButton';
import GoogleLoginButton from 'components/auth/GoogleLoginButton';
import LineLoginButton from 'components/auth/LineLoginButton';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

import {ReactComponent as CloseIcon} from 'assets/icon/light/times.svg';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '90vw',
    padding: 0,
    margin: 0,
  },
}));

interface LoginDialogProps {
  open: boolean;
  onClose?(): void;
  onLogin?(): void;
}

const LoginDialog: FC<LoginDialogProps> = ({open, onClose, onLogin}) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const {dialog} = useStyles();
  const [password, setPassword] = useState('');
  const styles = useThemeStyles();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState<CountryCode>(CountryCode.Taiwan);
  const [error, setError] = useState<ErrorCode | undefined>();

  const disabled = !phone || !countryCode || !password || loading;

  const handleLogin = useCallback(() => {
    setLoading(true);
    dispatch(login({countryCode, phone, password}))
      .then(() => {
        onLogin?.call(null);
      })
      .catch((err: ErrorCode) => {
        setError(err);
        setLoading(false);
      });
  }, [dispatch, password, phone, countryCode, onLogin]);

  return (
    <Dialog open={open} classes={{paper: dialog}} onClose={onClose}>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Brand size="medium" />
      </Box>

      <Box component="form" className="page" mx={4}>
        <PhoneInput
          phone={phone}
          countryCode={countryCode}
          onChangeCountryCode={setCountryCode}
          onChangePhone={setPhone}
        />

        <TextField
          placeholder="input.password_placeholder"
          variant="outlined"
          color="secondary"
          margin="normal"
          name="password"
          type="password"
          autoComplete="current-password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          errorCode={error}
        />

        <Box
          position="relative"
          display="flex"
          alignItems="center"
          className="gradientButtonWrapper">
          <Box className={['overlay', styles.gradient, 'gradientButton'].join(' ')}></Box>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            disabled={disabled}
            onClick={handleLogin}>
            <Typography>input.login</Typography>
          </Button>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" my={1} mx={4}>
        <Box flex={1}>
          <Divider />
        </Box>
        <Box mx={2}>
          <Typography>common.or</Typography>
        </Box>
        <Box flex={1}>
          <Divider />
        </Box>
      </Box>

      <Box mx={8}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <FacebookLoginButton text="input.facebook_login" />
          </Grid>
          <Grid item>
            <GoogleLoginButton text="input.google_login" />
          </Grid>
          <Grid item>
            <LineLoginButton text="input.line_login" />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" justifyContent="center" my={1}>
        <Link component={RouterLink} to="/auth/reset">
          <Typography variant="body2">link.reset_password</Typography>
        </Link>
      </Box>

      <Divider />
      <Box my={2} display="flex" justifyContent="center">
        <Typography>link.before_login</Typography>
        <Link
          component={RouterLink}
          to={{
            pathname: '/auth/create',
            search: history.location.search,
          }}>
          <Typography>link.signup</Typography>
        </Link>
      </Box>
      <Box mb={2} mx={2}>
        <Divider />
      </Box>

      <Box position="absolute" right={0} top={0} onClick={onClose}>
        <IconButton>
          <Icon svg={CloseIcon} />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default LoginDialog;
