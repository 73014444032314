import {createMuiTheme, ThemeProvider, makeStyles, responsiveFontSizes} from '@material-ui/core';

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#FF2281',
        dark: '#FF2281',
        contrastText: '#fff',
      },
      secondary: {
        main: '#4192EF',
        dark: '#4192EF',
        contrastText: '#fff',
      },
      error: {
        main: '#F44336',
        dark: '#F44336',
      },
      warning: {
        main: '#B76CFD',
        dark: '#B76CFD',
      },
      info: {
        main: '#262626',
        dark: '#262626',
      },
      success: {
        main: '#EFE33A',
        dark: '#EFE33A',
      },
      // tonalOffset
      // contrastThreshold
      common: {
        black: '#000',
      },
      // grey
      text: {
        secondary: '#8E8E8E',
        primary: '#FFFFFF',
      },
      divider: '#363636',
      // action
      background: {
        default: '#000',
        paper: '#121212',
      },
    },
    typography: {
      fontSize: 12,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    overrides: {
      MuiInputBase: {
        input: {
          background: '#121212',
        },
      },
      MuiAppBar: {
        colorDefault: {
          backgroundColor: '#121212',
          color: '#fff',
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: '#fff',
        },
      },
      MuiCardHeader: {
        action: {
          flex: '0 0 auto',
          alignSelf: 'center',
          marginTop: 0,
          marginRight: 0,
        },
      },
    },
  }),
);

export const useThemeStyles = makeStyles(
  (theme) => ({
    bg: {
      backgroundColor: theme.palette.background.default,
    },
    transparentBg: {
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
    noBgInput: {
      backgroundColor: 'transparent',
      '& input': {
        backgroundColor: 'transparent',
      },
    },
    textarea: {
      '& .MuiInputBase-root': {
        padding: 12,
        backgroundColor: theme.palette.info[theme.palette.type],
      },
      '& textarea': {
        backgroundColor: theme.palette.info[theme.palette.type],
      },
    },
    gradient: {
      background: `linear-gradient(135deg, ${theme.palette.secondary[theme.palette.type]} 0%, ${
        theme.palette.warning[theme.palette.type]
      } 50%, ${theme.palette.primary[theme.palette.type]} 100%)`,
    },
    transparentGradient: {
      background: `linear-gradient(135deg, rgba(116, 213, 253, 0.75) 0%,rgba(183, 108, 253, 0.75) 50%, rgba(255, 34, 129, 0.75) 100%)`,
    },
    gradientBefore: {
      position: 'relative',
      '&::before': {
        background: `linear-gradient(135deg, ${theme.palette.secondary[theme.palette.type]} 0%, ${
          theme.palette.warning[theme.palette.type]
        } 50%, ${theme.palette.primary[theme.palette.type]} 100%)`,
      },
    },
    chip: {
      zIndex: 1,
      border: `1px solid ${theme.palette.text.primary}`,
    },
    gift: {
      zIndex: 1,
      border: `1px solid ${theme.palette.text.primary}`,
      '&::before': {
        background: `linear-gradient(135deg, #5DC9BD 0%, #8041CD 100%)`,
      },
    },
    camera: {
      zIndex: 1,
      border: `1px solid ${theme.palette.text.primary}`,
      '&::before': {
        background: `linear-gradient(135deg, #FFF3B0 0%, #CA26FF 100%)`,
      },
    },
    mic: {
      zIndex: 1,
      border: `1px solid ${theme.palette.text.primary}`,
      '&::before': {
        background: `linear-gradient(135deg, #81FFEF 0%, #F067B4 100%)`,
      },
    },
    chat: {
      zIndex: 1,
      border: `1px solid ${theme.palette.text.primary}`,
      '&::before': {
        background: `linear-gradient(135deg, #EA5455 0%, #FEB692 100%)`,
      },
    },
    private: {
      zIndex: 1,
      border: `1px solid ${theme.palette.text.primary}`,
      '&::before': {
        background: `linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)`,
      },
    },
    share: {
      zIndex: 1,
      border: `1px solid ${theme.palette.text.primary}`,
      '&::before': {
        background: `linear-gradient(135deg, #81FBB8 0%, #28C76F 100%)`,
      },
    },
    volume: {
      zIndex: 1,
      border: `1px solid ${theme.palette.text.primary}`,
      '&::before': {
        background: `linear-gradient(135deg, #D83A76 0%, #1A0BE4 100%)`,
      },
    },
    facebook: {
      color: '#fff',
      backgroundColor: '#285296',
      '&.MuiButton-contained:hover': {
        backgroundColor: '#285296',
      },
    },
    google: {
      color: '#fff',
      backgroundColor: '#e65c4f',
      '&.MuiButton-contained:hover': {
        backgroundColor: '#e65c4f',
      },
    },
    line: {
      color: '#fff',
      backgroundColor: '#39cd00',
      '&.MuiButton-contained:hover': {
        backgroundColor: '#39cd00',
      },
    },
    activity: {
      color: '#fff',
    },
    heart: {
      color: '#DB555A',
    },
    creditCard: {
      color: '#fff',
      backgroundColor: '#254C8C',
      '&.MuiButton-contained:hover': {
        backgroundColor: '#254C8C',
      },
    },
    wireTransfer: {
      color: '#fff',
      backgroundColor: '#70C301',
      '&.MuiButton-contained:hover': {
        backgroundColor: '#70C301',
      },
    },
    convenientStore: {
      color: '#fff',
      backgroundColor: '#DB287C',
      '&.MuiButton-contained:hover': {
        backgroundColor: '#DB287C',
      },
    },
    unionPay: {
      color: '#fff',
      backgroundColor: '#008286',
      '&.MuiButton-contained:hover': {
        backgroundColor: '#008286',
      },
    },
    search: {
      backgroundColor: theme.palette.info[theme.palette.type],
      borderRadius: 10,
      '& input': {
        backgroundColor: theme.palette.info[theme.palette.type],
        borderRadius: 10,
      },
      '& fieldset': {
        borderRadius: 10,
        border: 'none',
      },
    },
    roundInput: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 50,
      '& input': {
        backgroundColor: theme.palette.background.default,
        borderRadius: 50,
      },
      '& fieldset': {
        borderRadius: 50,
        // border: 'none',
      },
    },
    inactiveListItem: {
      backgroundColor: theme.palette.background.paper,
      border: `${theme.palette.divider} 1px solid`,
    },
    activeListItem: {
      backgroundColor: theme.palette.background.paper,
      border: `${theme.palette.primary[theme.palette.type]} 1px solid`,
    },
    chatBubble: {
      backgroundColor: 'rgba(0,0,0,0.75)',
      border: '1px solid #000',
      borderRadius: 5,
      '& .MuiAvatar-root': {
        width: 20,
        height: 20,
        marginRight: 5,
      },
    },
    giftBubble: {
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: 10,
    },
    textBubbleHollow: {
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: 24,
    },
    textBubbleSolid: {
      borderRadius: 24,
      backgroundColor: theme.palette.info[theme.palette.type],
    },
    ratingG: {
      width: 16,
      height: 16,
      backgroundColor: '#419A1A',
      borderRadius: 4,
      opacity: 0.8,
    },
    ratingPG: {
      width: 16,
      height: 16,
      backgroundColor: '#FB9905',
      borderRadius: 4,
      opacity: 0.8,
    },
    ratingNC: {
      width: 16,
      height: 16,
      backgroundColor: '#E92616',
      borderRadius: 4,
      opacity: 0.8,
    },
    streamerChip: {
      height: 'unset',
      backgroundColor: 'rgba(0,0,0,0.4)',
      '&:focus': {
        backgroundColor: 'inherit',
      },
      '& .MuiChip-deleteIcon': {
        width: 'unset',
        '&.active': {
          color: theme.palette.primary[theme.palette.type],
        },
        '&.inactive': {
          color: theme.palette.text.secondary,
        },
        '& .MuiChip-label': {
          paddingLeft: 6,
          paddingRight: 6,
        },
      },
      '& .MuiChip-avatar': {
        width: 'unset',
        height: 'unset',
        padding: 'unset',
      },

      '& svg': {
        color: theme.palette.text.secondary,
        marginRight: 3,
      },
    },
    freeChip: {
      zIndex: 1,
      border: 'none',
      '&::before': {
        backgroundColor: '#28CF75',
      },
    },
    omChip: {
      zIndex: 1,
      '&::before': {
        backgroundColor: '#E99930',
      },
    },
    ooChip: {
      zIndex: 1,
      '&::before': {
        backgroundColor: '#F34F4F',
      },
    },
    earningLabel: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      marginLeft: 6,
      '& svg': {
        color: '#fff',
      },
      '& .MuiChip-label': {
        minWidth: 40,
      },
      '& .diamond': {
        marginTop: 4,
      },
    },
    noBackdrop: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'transparent',
      },
    },
    editBadge: {
      backgroundColor: '#fff',
      '& svg': {
        color: '#000',
        fontSize: 12,
      },
    },
    storyCell: {
      outline: `${theme.palette.background.default} 1px solid`,
    },
    headerSelect: {
      textAlign: 'center',
      '&.MuiInput-underline:before': {
        display: 'none',
      },
      '&.MuiInput-underline:after': {
        display: 'none',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: 0,
      },
      '& .MuiSelect-icon': {
        left: 'calc(50% + 30px)',
      },
    },
    actionGroup: {
      width: '100%',
      '& .MuiButtonGroup-groupedContainedVertical:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    action: {
      backgroundColor: '#262626',
      color: theme.palette.text.primary,
      width: '100%',

      padding: '10px 0',
      '&.MuiButton-contained:hover': {
        backgroundColor: '#262626',
      },
    },
    hashtag: {
      color: theme.palette.secondary[theme.palette.type],
      textDecoration: 'none',
    },
    lockedButton: {
      background: 'rgba(244, 67, 54, .8)',
      border: '1px solid #fff',
      color: '#fff',
      width: 30,
      height: 30,
      '&.MuiIconButton-root:hover': {
        background: 'rgba(244, 67, 54, .8)',
      },
    },
    unlockedButton: {
      background: 'rgba(26, 155, 0, .5)',
      border: '1px solid #fff',
      color: '#fff',
      width: 30,
      height: 30,
      '&.MuiIconButton-root:hover': {
        background: 'rgba(26, 155, 0, .5)',
      },
    },
    bannerPagination: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      '& span': {
        height: 5,
        borderRadius: 5,
        margin: '10px 3px',
        transition: 'width 0.3s, background-color 0.2s',
      },
      '& .inactive': {
        width: 5,
        backgroundColor: theme.palette.text.secondary,
      },
      '& .active': {
        width: 20,
        backgroundColor: theme.palette.text.primary,
      },
    },
    contributorListItem: {
      height: 75,
      '& svg': {
        fontSize: 36,
      },
      '& .first': {
        color: '#F0D54F',
        opacity: 1,
      },
      '& .second': {
        color: '#C2C9E1',
        opacity: 1,
      },
      '& .third': {
        color: '#E58571',
        opacity: 1,
      },
      '& .hidden': {
        color: 'transparent',
        display: 'none',
      },
      '& .rank': {
        lineHeight: '44px',
      },
      '& ~ .MuiDivider-root': {
        marginLeft: 115,
        backgroundColor: '#878787',
      },
    },
    livestreamDialog: {
      width: 300,
      maxWidth: '90vw',
      '& .MuiAvatar-root': {
        width: 72,
        height: 72,
        marginBottom: 6,
      },
      '& button': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderRadius: 16,
      },
    },
    profileDialog: {
      '& .MuiDialog-paper': {
        overflowY: 'unset',
      },
      '& .MuiAvatar-root': {
        width: 280,
        height: 280,
      },
      '& button': {
        minWidth: 85,
      },
    },
    livestreamCard: {
      '& .watchers': {
        borderRadius: 2,
        backgroundColor: '#000',
        opacity: 0.8,
      },
      '& .live': {
        fontWeight: 'bold',
        borderRadius: 5,
      },
      '& .MuiListItemAvatar-root': {
        minWidth: 'unset',
        marginRight: 3,
      },
    },
    countdown: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      maxHeight: 100,
      borderRadius: '50px 0px 0px 50px',
      '& svg': {
        fontSize: 66,
        transform: 'rotate(-15deg)',
      },
      '& .MuiTypography-h1': {
        fontSize: 40,
      },
      '& button': {
        borderRadius: '20px 0px 0 20px',
        paddingLeft: 10,
      },
    },
    bottomNav: {
      background: theme.palette.background.default,
      '& button': {
        minWidth: 'unset',
      },
      '& button svg': {
        width: '100%',
      },
    },
    avoidListItemAction: {
      marginRight: 40,
    },
    commentHeader: {
      '& .name': {
        float: 'left',
        marginRight: 10,
      },
    },
    commentItem: {
      '& .MuiPaper-root': {
        marginLeft: 10,
        minHeight: 32,
      },
    },
    openQRButton: {
      borderRadius: '16px',
      '& svg': {
        fontSize: 15,
      },
    },
    distributionUpline: {
      justifyContent: 'center',
      background: theme.palette.info[theme.palette.type],
    },
    distributionListItem: {
      background: theme.palette.info[theme.palette.type],
    },
    log: {
      backgroundColor: '#262626',
      marginTop: 3,
      height: 53,
    },
    goldHeader: {
      border: '1px solid #fff',
      '& button': {
        borderRadius: 16,
        padding: '3px 30px',
      },
    },
    fullScreenMenu: {
      marginLeft: -16,
    },
  }),
  {
    index: 1,
  },
);

export {ThemeProvider};
