import {Box} from '@material-ui/core';
import React, {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  WheelEvent,
} from 'react';
import {useDispatch} from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {useDebouncedCallback} from 'use-debounce/lib';

import {openGiftDrawer} from 'model/gift/GiftAction';
import {GiftLocation} from 'model/gift/GiftTypes';
import {AppDispatch} from 'model/helper';
import {
  likePost,
  playStoryVideo,
  savePost,
  stopStoryVideo,
  unlikePost,
  unlockPost,
  unsavePost,
} from 'model/post/PostAction';
import {MediaExtension, PostModel} from 'model/post/PostTypes';

import {useWindowSize} from 'utils/hook';
import {sliceStringByLines} from 'utils/string';

import StoryCard from 'components/StoryCard';
import PostActionDrawer from 'components/drawer/PostActionDrawer';

interface IHeaderComponent {
  (props: {style: CSSProperties}): JSX.Element;
}

interface StoryListProps {
  // banner?: boolean;
  start?: string;
  items: PostModel.Article[];
  hasNextPage: boolean;
  loadMore: () => Promise<any> | null;
  HeaderComponent?: IHeaderComponent;
  headerHeight?: number;
}

function StoryList({
  start,
  items,
  hasNextPage,
  loadMore,
  HeaderComponent,
  headerHeight = 0,
}: StoryListProps) {
  const hasHeader = HeaderComponent ? 1 : 0;

  const width = useWindowSize();
  const [expansion, setExpansion] = useState<Set<string>>(new Set());
  const list = useRef<VariableSizeList>(null);
  const dispatch = useDispatch<AppDispatch>();
  const video = useRef<HTMLVideoElement>();

  const [target, setTarget] = useState<PostModel.Article | undefined>(undefined);

  useEffect(() => {
    if (start) {
      const index = items.findIndex((item) => item.id === start);
      setTimeout(() => {
        list.current?.scrollToItem(index + hasHeader, 'center');
      }, 0);
    }

    return () => {
      dispatch(stopStoryVideo());
    };
    // eslint-disable-next-line
  }, []);

  const countHeight = useCallback(
    (index: number) => {
      if (hasHeader && index === 0) {
        return headerHeight;
      }

      if (items.length === 0) {
        return 0;
      }

      const article = items[index - hasHeader];

      if (article.streamInfo) {
        return width + 114;
      }

      const expanded = expansion.has(article.id);
      let numLines = expanded ? 0 : 2;
      if (expanded) {
        article.content.forEach((str) => {
          const lines = sliceStringByLines(str, width - 32);
          numLines += lines.length;
        });
      }

      return width + 190 + numLines * 20;
    },
    [items, width, expansion, hasHeader, headerHeight],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < items.length + hasHeader - 1;
    },
    [hasNextPage, items, hasHeader],
  );

  const itemKey = useCallback(
    (index: number) => {
      if (hasHeader && index === 0) {
        return index;
      }
      return items[index - hasHeader].id;
    },
    [items, hasHeader],
  );

  const handleExpand = useCallback((id: string, index: number) => {
    return () => {
      setExpansion((oldExpansion) => {
        oldExpansion.add(id);
        return new Set(oldExpansion);
      });
      list.current?.resetAfterIndex(index);
    };
  }, []);

  const handleUnlock = useCallback(
    (id: string) => {
      return () => {
        return dispatch(unlockPost(id));
      };
    },
    [dispatch],
  );

  const handleLike = useCallback(
    (id: string, liked: boolean) => {
      return () => {
        if (liked) {
          dispatch(unlikePost(id));
        } else {
          dispatch(likePost(id));
        }
      };
    },
    [dispatch],
  );

  const handleSave = useCallback(
    (id: string, saved: boolean) => {
      return () => {
        if (saved) {
          dispatch(unsavePost(id));
        } else {
          dispatch(savePost(id));
        }
      };
    },
    [dispatch],
  );

  const handleOpenGift = useCallback(
    (id: string, userId: string) => {
      return () => {
        dispatch(
          openGiftDrawer({
            userId,
            targetId: id,
            location: GiftLocation.Post,
          }),
        );
      };
    },
    [dispatch],
  );

  const renderCard = useCallback(
    (props: ListChildComponentProps) => {
      if (HeaderComponent && props.index === 0) {
        return <HeaderComponent style={props.style} />;
      }

      const article = items[props.index - hasHeader];
      const expanded = expansion.has(article.id);

      return (
        <StoryCard
          article={article}
          expanded={expanded}
          width={width}
          style={props.style}
          onExpand={handleExpand(article.id, props.index)}
          onUnlock={handleUnlock(article.id)}
          onClickLike={handleLike(article.id, article.like)}
          onClickSave={handleSave(article.id, article.saved)}
          onClickGift={handleOpenGift(article.id, article.userId)}
          onClickMore={setTarget}
        />
      );
    },
    [
      items,
      width,
      handleExpand,
      expansion,
      hasHeader,
      HeaderComponent,
      handleUnlock,
      handleLike,
      handleSave,
      handleOpenGift,
    ],
  );

  const handleScroll = useCallback((currentTarget: HTMLDivElement) => {
    const currentScrollPosition = currentTarget.scrollTop;
    const visibleElement = getVisibleElements(currentTarget, currentScrollPosition);

    if (
      visibleElement?.dataset.type === MediaExtension.MP4.toString() &&
      visibleElement?.dataset.id
    ) {
      video.current?.pause();
      video.current = visibleElement.querySelector('video') || undefined;
      video.current?.play();
    } else {
      video.current?.pause();
    }

    function getVisibleElements(element: HTMLDivElement, currentScrollPosition: number) {
      let el: ChildNode | null =
        element.children[0].childNodes[element.children[0].childNodes.length - 1];
      do {
        const elementTop = parseInt((el as HTMLElement).style.top, 10);
        if (
          elementTop + Math.min(element.clientWidth, 500) <
          currentScrollPosition + element.clientHeight
        ) {
          break;
        }
      } while ((el = el.previousSibling));
      // return el
      return el as HTMLElement | null;
    }
  }, []);

  const onScroll = useDebouncedCallback(handleScroll, 16, {});

  const outerElementType = useMemo(() => {
    return forwardRef<any>((props, ref) => (
      <div
        ref={ref}
        onWheel={(e) => {
          onScroll(e.currentTarget);
        }}
        onTouchMove={(e) => {
          onScroll(e.currentTarget);
        }}
        {...props}
      />
    ));
  }, [onScroll]);

  return (
    <Box flex="1">
      <AutoSizer>
        {({height, width}) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={items.length + hasHeader}
            threshold={2}
            loadMoreItems={loadMore}>
            {({onItemsRendered, ref}) => {
              return (
                <VariableSizeList
                  key={`${width}:${items.length === 0 ? 0 : 1}`}
                  overscanCount={0}
                  itemKey={itemKey}
                  onItemsRendered={onItemsRendered}
                  ref={(ele) => {
                    (ref as any)(ele);
                    (list as any).current = ele;
                  }}
                  width={width}
                  height={height}
                  itemSize={countHeight}
                  itemCount={items.length + hasHeader}
                  outerElementType={outerElementType}>
                  {renderCard}
                </VariableSizeList>
              );
            }}
          </InfiniteLoader>
        )}
      </AutoSizer>

      <PostActionDrawer article={target} onClose={() => setTarget(undefined)} />
    </Box>
  );
}

export default StoryList;
