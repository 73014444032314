import React from 'react';
import {Switch, Route} from 'react-router-dom';

import ExplorePage from './ExplorePage';
import ExploreRecommendedPage from './ExploreRecommendedPage';
import ExploreTagPage from './ExploreTagPage';
import ExploreTagStoryPage from './ExploreTagStoryPage';
import UserStoryPage from './UserStoryPage';

function ExploreRoutes() {
  return (
    <Switch>
      <Route exact path="/explore">
        <ExplorePage />
      </Route>
      <Route path="/explore/recommended">
        <ExploreRecommendedPage />
      </Route>
      <Route path="/explore/user/:id">
        <UserStoryPage prev="/explore" />
      </Route>
      <Route path="/explore/tag/:tag/list">
        <ExploreTagStoryPage />
      </Route>
      <Route path="/explore/tag/:tag">
        <ExploreTagPage />
      </Route>
    </Switch>
  );
}

export default ExploreRoutes;
