import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography as Text,
} from '@material-ui/core';
import React, {FC} from 'react';

import Typography from './i18n/Typography';

interface TermsDialogProps {
  open: boolean;
  onClose?(): void;
}

const TermsDialog: FC<TermsDialogProps> = ({open, onClose}) => {
  return (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <DialogTitle>
        <Typography variant="h6">title.terms</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText color="textPrimary" tabIndex={-1}>
          {`
本規範載明您與 SOAK之間，就您使用 SOAK 服務及其軟體／網站（參照名詞定義）應遵守的相關條件及規範，請您務必詳細閱讀以保障您的權益。
您於初次註冊 SOAK 帳號，進入顯示「SOAK 服務條款」之網頁，如您繼續註冊登入使用 SOAK 服務，即表示您或及您的法定代理人已經合理審閱期間（建議 5 日以上），詳細審閱並確實瞭解該服務條款（包括使用者規範、隱私權政策、智慧財產權政策，下同），並願意遵守前述服務條款及政策。例在台灣地區，如您未滿 20 歲即應取得您的法定代理人（例父母、法定監護人）之允許始能註冊帳號。
針對您違反本規範的行為護或有違反之虞，SOAK 有權獨立判斷並視情況終止全部或部分之服務（包括但不限於預先警示、停止發佈傳輸內容、刪除已發布傳輸的內容、限制帳號部分或者全部功能直至永久關閉帳號等措施）並採取適當法律救濟措施。SOAK 有權公告處理結果，且有權根據實際情況決定是否恢復全部或部分之服務。對涉嫌侵權、犯罪、或及其他違反法律法規的行為將保存有關記錄，並依法向主管機關及其指派的網路內容防護機構、配合主管機關進行調查。對已刪除的內容，SOAK 不予回復。如果我們停用或刪除您的帳號，您充分瞭解並同意您無法再透過您的帳號取得您所有的資料。
因您違反本規範而引起投訴或訴訟索賠的，您應當自行承擔全部法律責任。因您的違法或違約行為導致 SOAK 集團旗下公司及承繼公司向任何第三人賠償或遭受國家機關處罰的，您還應足額賠償 SOAK 集團旗下公司及繼承公司因此遭受的全部損失（包括但不限於商譽損失及經濟損失如政府罰款、訴訟費，律師費，遭第三人求償損害賠償等）。
名詞定義：
SOAK 服務：本服務條款所稱 SOAK 服務指由 SOAK 透過其服務軟體（SOAK App）、線上影片、線上購物等網路服務。SOAK 集團旗下公司及承繼公司有權對就前述軟體及網站進行異動包括但不限更名、變更網域名稱及新增。
SOAK 集團：指 SOAK 及其關聯企業之合稱。
SOAK 服務及其軟體／網站：SOAK 服務、SOAK 服務軟體及 SOAK 服務網站之合稱。
使用者行為規範
您不得利用或針對 SOAK 服務及其軟體／網站進行任何危害電腦網路安全或其他侵權違法的行為，包括但不限於：
非法侵入他人網路、干擾他人網路正常功能、竊取網路資料等危害網路安全的活動。
提供專門用於從事侵入網路、干擾網路正常功能及防護措施、竊取網路資料等危害網路安全活動的程式、工具。
企圖以任何方式修改、侵入、干擾SOAK服務平台的功能、資料、完整性及／或服務介面。
企圖以任何方式例使用輔助程式、外掛程式、加速程式、修改程式、侵入SOAK平台、複製更改封包值等，影響SOAK平台的運作或干涉SOAK服務平台之相關活動（包括但不限於競賽，抽獎等）之進行及結果。
利用APP中非自然或錯誤BUG或漏洞，造成伺服器負擔加重或當機，取得APP中之虛擬禮物其他任何利益暴增等。
企圖以任何方式、竊取或修改SOAK平台資料、竊取或修改SOAK用戶資料、盜用他人SOAK帳號。
企圖以任何方式創造衍生性創作或其他智慧財產權。
明知他人從事危害網路安全的活動的，為其提供技術支持、廣告推廣、支付結算等幫助。
使用未經許可的資料或進入未經許可的伺服器／帳號。
未經允許進入公眾電腦網路或者他人電腦系統並刪除、修改、增加存儲資訊。
未經許可，企圖探查、掃描、測試 SOAK 服務及其軟體／網站的弱點或其它實施破壞網路安全的行為。
企圖干涉、破壞 SOAK 服務及其軟體／網站的正常運行，故意傳播惡意程式或病毒以及其他破壞干擾正常網路資訊服務的行為。
偽造 TCP/IP 資料包名稱或部分名稱。
對 SOAK 服務軟體進行反向工程、反向彙編、編譯或者以其他方式嘗試發現軟體的原始程式碼。
惡意註冊 SOAK 帳號，包括但不限於頻繁、大量註冊帳號。
洗錢。
違反法律法規、SOAK 的服務條款、政策、規範等及侵犯他人合法權益的其他行為。
在任何情況下，如果 SOAK 有理由認為您的任何行為違反或可能違反上述約定的，SOAK 可獨立進行判斷並處理，且在任何時候有權在進行任何事先通知的情況下終止向使用者提供服務，並追究相關責任。
您不得幫助、鼓勵、協同、代理（表）其他 SOAK 服務使用者向 SOAK 提出違反 SOAK 服務條款（含本規範）之要求，不論您是透過 SOAK 服務及其軟體／網站直接接觸 SOAK 人員而提出前述要求。
您不得以圖文、言語、或肢體等任何表達方式詆毀、霸凌、虐待、騷擾、威脅、脅迫或冒充其他 SOAK 服務及其軟體／網站使用者和 SOAK 集團旗下公司人員，不論您是否透過 SOAK 服務及其軟體／網站進行前述表達。
您不得幫助、鼓勵、協同、代理（表）其他 SOAK 服務使用者干涉或操縱 SOAK 服務及其軟體／網站之相關活動（包括但不限於競賽，抽獎等）之進行及結果。如您違反前述規範而涉嫌賭博或其他侵權違法行為，應自負相關法律責任。
資訊內容規範
您使用 SOAK 服務所發表的內容包括但不限文字、照片、影訊、音訊等（例發文，貼照片、短影片等）。您就您發表的內容，應自覺遵守法律法規及社會善良風俗，否則 SOAK 公將立即採取相應處理措施。
您不發表下列內容：
危害國家安全，洩露國家秘密。
任何政治議題言論及表達，以及任何可能造成嚴重負面後果之言論、行為、舉動、表達。
宣揚恐怖主義、極端主義的。
煽動地域歧視、地域仇恨的。
編造、散佈謠言、詐騙資訊、虛假資訊，擾亂社會秩序、破壞社會穩定的。
散佈、傳播淫穢、色情、賭博、暴力、兇殺、恐怖或者教唆犯罪的。
危害網路安全、利用網路從事危害國家安全。
以圖文、言語、或肢體等任何表達方式侮辱或恐嚇威脅他人。
散佈污言穢語，損害社會公序良俗的。
實施人肉搜索的或涉及他人隱私、個人資訊或資料的。
侵犯他人合法權益 （包括但不限於姓名權、肖像權、名譽權、隱私權、代理權、商標權、著作權、其他智慧財權等）的。
張貼、散佈、傳送、播送、公開播放或以其他任何方式展現私人的、特許的、專有的、攻擊性的、恫嚇的、暴力的、煽動暴力的、裸露的、歧視性的、不法的、仇恨性的、騷擾性的、猥褻色情的或具性暗示的資料、文字、檔案、資訊、使用者名稱、圖像、圖畫、圖片、圖示、照片、素描、聲音及影像剪輯、聲響、音樂作品、著作、連結或任何其他內容。
散佈商業廣告，或類似的商業招攬資訊、過度行銷資訊及垃圾資訊。
未獲他人允許，偷拍、偷錄他人，侵害他人合法權利的。
包含恐怖、暴力血腥、高危險性、危害表演者自身或他人身心健康內容的，包括但不限於以下情形：
任何暴力和／或自殘行為內容。
任何威脅生命健康、利用槍枝刀具等危險器械表演的危及自身或他人人身及／或財產權利的內容。
慫恿、誘導他人參與可能會造成人身傷害或導致死亡的危險或違法活動的內容。
死亡相關場景：屍體、標本、命案現場。
使用模糊不清或經改造等無法辨識您個人的照片影片。
將他人照片圖文加以編輯或增刪塗蓋文字／符號／資訊等致有損他人形象或遭致負面評論的行為。
您不得將 SOAK 服務及其軟體／網站用於提供性服務、買賣非法商品或藥物（即使該藥物在您的地區為合法藥物）。非法商品包含但不限於：盜版商品、槍枝、菸酒、活體動物等。
您不得透過 SOAK 服務及其軟體／網站來販賣商品，但經與 SOAK 正式合作者不在此限。
您不得透過 SOAK 服務及其軟體／網站來慫恿、誘導 SOAK 服務使用者去使用其他品牌的／短影片等服務。
您不得創建或上傳未經授權的商業性或騷擾性的訊息（例如垃圾訊息）給任何 SOAK 服務及其軟體／網站使用者。
您不得傳送任何電腦蠕蟲、電腦病毒、間諜軟體、惡意軟體或其他惡意代碼，或以其他任何方式干擾、中斷、使超載或破壞正常 SOAK 服務及其軟體／網站的運作、安全、秘密或介面。
您不得改變、修改或變更 SOAK 服務及其軟體／網站的功能、完整性及／或服務介面，或創造衍生性創作、破解或嘗試擷取我們的原始碼或其他智慧財產權。亦不得使用外掛軟體、反編碼或任何不正當方式使用 SOAK 服務。
您不得以任何方式限制或嘗試限制其他 SOAK 服務及其軟體／網站使用者使用 SOAK 服務及其軟體／網站，或干擾或嘗試干擾其他 SOAK 服務及其軟體／網站使用者的使用經驗。
您不得幫助或鼓勵任何人違反 SOAK 服務條款（含本規範）之任一條款及／或其他由 SOAK 集團旗下公司所發布的條款。
我們可以（但沒有義務）預先審查及／或監視您的 SOAK 帳號、其相關的活動及／或您在或透過 SOAK 服務及其軟體／網站發表的內容（以下簡稱「您發表的內容」）。
如我們認為您發表的內容違反了 SOAK 服務條款（含本規範）之任一條款規範，您同意我們有絕對的權利在有或沒有事先告知的情況下，移除、刪除、取消、編輯、改變、修改及／或封鎖您的 SOAK 帳號及／或您發表的全部或部分內容。
您對於您發表的內容及／或您與其他 SOAK 服務及其軟體／網站使用者的互動須負完全的責任，無論是上線或離線的狀態。您同意 SOAK 集團旗下公司對於任何 SOAK 服務及其軟體／網站使用者的行為無須負任何責任；但如我們認為合適時，我們得參與您與其他 SOAK 服務及其軟體／網站使用者間的爭議。
資料使用規範
未經SOAK 書面許可，您不得自行或授權、允許、協助任何第三人對 SOAK 服務及其軟體／網站中的資訊內容進行如下行為：
複製、讀取、採用 SOAK 服務及其軟體／網站的資訊內容，用於包括但不限於宣傳、增加閱讀量、流覽量等商業用途。
擅自編輯、整理、編排 SOAK 服務及其軟體／網站的資訊內容後在 SOAK 服務及其軟體／網站的源頁面以外的管道進行展示。
採用包括但不限於特殊標識、特殊代碼等任何形式的識別方法，自行或協助第三人對 SOAK 服務及其軟體／網站的資訊或內容產生流量、閱讀量引導、轉移、劫持等不利影響。
其他非法獲取 SOAK 服務及其軟體／網站的資訊內容的行為。
經 SOAK 書面許可後，您對 SOAK 服務及其軟體／網站的資訊和內容的分享、轉發等行為，還應符合以下規範：
對獲取、統計、獲得的相關搜尋熱門詞彙、命中率、分類、搜尋量、點擊率、閱讀量等相關資料，未經 SOAK 事先書面同意，不得將上述資料以任何方式公示、提供、洩露給任何第三人。
不得對 SOAK 服務及其軟體／網站的源網頁進行任何形式的任何改動，包括但不限於 SOAK 服務及其軟體／網站的首頁連結入口，也不得對 SOAK 服務及其軟體／網站的源頁面的展示進行任何形式的遮擋、插入、彈窗等妨礙。
應當採取安全、有效、嚴密的措施，防止 SOAK 服務及其軟體／網站的資訊內容被協力廠商通過包括但不限於"蜘蛛"（spider）程式等任何形式進行非法獲取。
不得把相關資料內容用於 SOAK 書面許可範圍之外的目的，進行任何形式的銷售和商業使用，或向協力廠商洩露、提供或允許協力廠商為任何方式的使用。
您向任何第三人分享、轉發、複製 SOAK 服務及其軟體／網站的資訊內容的行為，還應遵守 SOAK 為此制定的其他規範。
若您對 SOAK 使用者規範有任何問題或依條款規定需要聯繫 SOAK，歡迎聯繫 SOAK 客服：support@SOAK.com
          `
            .split('\n')
            .map((text, index) => (
              <Box mb={1} key={index}>
                <Text component="p">{text}</Text>
              </Box>
            ))}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default TermsDialog;
