import {Backdrop, Box, Button, CircularProgress, Divider, Grid, Link} from '@material-ui/core';
import {ErrorCode} from 'api';
import {useThemeStyles} from 'context/Theme';
import {Location} from 'history';
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink, useHistory, useLocation} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {login} from 'model/user/UserActions';

import Brand from 'components/Brand';
import PhoneInput, {CountryCode} from 'components/PhoneInput';
import FacebookLoginButton from 'components/auth/FacebookLoginButton';
import GoogleLoginButton from 'components/auth/GoogleLoginButton';
import LineLoginButton from 'components/auth/LineLoginButton';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

function AuthLoginPage() {
  // const [account, setAccount] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation<{from?: Location}>();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const styles = useThemeStyles();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState<CountryCode>(CountryCode.Taiwan);
  const [error, setError] = useState<ErrorCode | undefined>();

  const disabled = !phone || !countryCode || !password;

  const handleLogin = useCallback(() => {
    setLoading(true);
    dispatch(login({countryCode, phone, password}))
      .then(() => {
        const to = location.state?.from ? location.state.from.pathname : '/';

        history.replace(to.startsWith('/auth') ? '/' : to);
      })
      .catch((err: ErrorCode) => {
        setError(err);
        setLoading(false);
      });
  }, [history, location, dispatch, password, phone, countryCode]);

  return (
    <Box className="full" display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" flexDirection="row" justifyContent="center" mb={5}>
        <Brand size="medium" />
      </Box>

      <form className="page">
        <PhoneInput
          phone={phone}
          countryCode={countryCode}
          onChangeCountryCode={setCountryCode}
          onChangePhone={setPhone}
        />

        <TextField
          placeholder="input.password_placeholder"
          variant="outlined"
          color="secondary"
          margin="normal"
          name="password"
          type="password"
          autoComplete="current-password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          errorCode={error}
        />

        <Box display="flex" justifyContent="flex-end" className="resetPasswordLink">
          <Link component={RouterLink} to="/auth/reset">
            <Typography>link.reset_password</Typography>
          </Link>
        </Box>

        <Box
          position="relative"
          display="flex"
          alignItems="center"
          className="gradientButtonWrapper">
          <Box className={['overlay', styles.gradient, 'gradientButton'].join(' ')}></Box>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            disabled={disabled}
            onClick={handleLogin}>
            <Typography>input.login</Typography>
          </Button>
        </Box>
      </form>

      <Box mt={4} display="flex" justifyContent="center">
        <Typography>link.before_login</Typography>
        <Link
          component={RouterLink}
          to={{
            pathname: '/auth/create',
            search: history.location.search,
          }}>
          <Typography>link.signup</Typography>
        </Link>
      </Box>

      <Box display="flex" alignItems="center" m={3}>
        <Box flex={1}>
          <Divider />
        </Box>
        <Box mx={2}>
          <Typography>common.or</Typography>
        </Box>
        <Box flex={1}>
          <Divider />
        </Box>
      </Box>

      <Box mx={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FacebookLoginButton text="input.facebook_login" />
          </Grid>
          <Grid item>
            <GoogleLoginButton text="input.google_login" />
          </Grid>
          <Grid item>
            <LineLoginButton text="input.line_login" />
          </Grid>
        </Grid>
      </Box>

      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default AuthLoginPage;
