import {Box} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {AppDispatch} from 'model/helper';
import {loadSavedPosts} from 'model/post/PostAction';
import {getSavedArticles, hasSavedListNextPage, isSavedListLoading} from 'model/post/PostSelector';

import StoryCell from 'components/StoryCell';

const CELL_PER_ROW = 3;

interface SavedCollectionProps {
  width: number;
  height: number;
}

function SavedCollectionCollection({width, height}: SavedCollectionProps) {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const articles = useSelector(getSavedArticles);
  const isLoading = useSelector(isSavedListLoading);
  const hasNextPage = useSelector(hasSavedListNextPage);

  useEffect(() => {
    dispatch(loadSavedPosts(true));
  }, [dispatch]);

  const toSavedStories = useCallback(
    (id?: string) => {
      return () => {
        history.push({
          pathname: '/profile/saved',
          state: {
            start: id,
          },
        });
      };
    },
    [history],
  );

  const renderCell = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const starrIndex = index * CELL_PER_ROW;
      return (
        <Box style={style} display="flex" flexDirection="row">
          <StoryCell
            article={articles[starrIndex]}
            onClick={toSavedStories(articles[starrIndex].id)}
          />
          <StoryCell
            article={articles[starrIndex + 1]}
            onClick={toSavedStories(articles[starrIndex + 1]?.id)}
          />
          <StoryCell
            article={articles[starrIndex + 2]}
            onClick={toSavedStories(articles[starrIndex + 2]?.id)}
          />
        </Box>
      );
    },
    [articles, toSavedStories],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index * CELL_PER_ROW < articles.length;
    },
    [hasNextPage, articles],
  );

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadSavedPosts(false));
  }, [isLoading, hasNextPage, dispatch]);

  const itemKey = useCallback(
    (index: number) => {
      const i = index * CELL_PER_ROW;
      return `${articles[i]?.id || ''} ${articles[i + 1]?.id || ''} ${articles[i + 2]?.id || ''}`;
    },
    [articles],
  );

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={articles.length}
      loadMoreItems={loadMore}>
      {({onItemsRendered, ref}) => {
        return (
          <FixedSizeList
            itemKey={itemKey}
            itemCount={Math.ceil(articles.length / CELL_PER_ROW)}
            itemSize={width / CELL_PER_ROW}
            width={width}
            height={height}
            onItemsRendered={onItemsRendered}
            ref={ref}>
            {renderCell}
          </FixedSizeList>
        );
      }}
    </InfiniteLoader>
  );
}

export default SavedCollectionCollection;
