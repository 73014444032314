import {API} from 'api';

import {Rating, WatchMode} from 'model/livestream/LivestreamTypes';
import {CommentType, MediaExtension, PostStatus} from 'model/post/PostTypes';

import {UserResponse} from './user';

export default class PostsAPI {
  public static getSuggestedUsers() {
    return API.get<{users: UserResponse[]}>('/feed/suggested_user');
  }

  public static getBanners() {
    return API.get<{banners: BannerResponse[]; displaySec: number}>('/feed/banner');
  }

  public static getPosts(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; list: ArticleResponse[]}>('/home_page/user', params);
  }

  public static getPost(postId: string) {
    return API.get<{post: PostResponse}>(`/post/wildcard/${postId}`);
  }

  public static getUnlockedPosts(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; posts: PostResponse[]}>('/post/unlock', params);
  }

  public static getSavedPosts(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; posts: PostResponse[]}>('/post/save', params);
  }

  public static likePost(postId: string) {
    return API.post(`/post/like/${postId}`);
  }

  public static unlikePost(postId: string) {
    return API.post(`/post/unlike/${postId}`);
  }

  public static unlockPost(postId: string) {
    return API.post(`/post/unlock/${postId}`);
  }

  public static savePost(postId: string) {
    return API.post(`/post/save/${postId}`);
  }

  public static unsavePost(postId: string) {
    return API.post(`/post/unsave/${postId}`);
  }

  public static getComments(postId: string, cursor: string | null) {
    const params: any = {post_id: postId};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{comment_list: CommentResponse[]; cursor: string}>('/post/comment_list', params);
  }

  public static postComment(postId: string, message: string) {
    return API.post(`/post/comment/${postId}`, {content: message});
  }
}

interface BannerResponse {
  link: string | null;
  picture: string;
  banner_id: string;
  seq: number;
  status: number;
  title: string;
}

export interface PostResponse {
  post_id: string;

  user_id: string;
  user_name: string;
  cover: string;
  is_follow: boolean;

  content: string;
  unlock_point: number;
  unlock_count: number;
  is_paid: boolean;

  like_count: number;
  comment_count: number;
  comment_cursor: string;
  like_relation: boolean;
  media_ids: Array<{
    file_id: string;
    content_type: MediaExtension;
    media_picture?: string;
  }>;
  post_cover: {
    compressed: string;
    origin: string;
  };
  locked_media_count: number;
  save_post_relation: boolean;
  comments: any[];
  status: PostStatus;
  note: string;
  created_at: string;
  updated_at: string;
}

export interface ArticleResponse
  extends Omit<PostResponse, 'cover' | 'post_id' | 'is_follow' | 'post_cover'> {
  article_id: string;
  article_picture: string;
  article_type: 0;

  user_cover: string;

  om_charge: number;
  oo_charge: number;
  stream_status: WatchMode;
  rating: Rating;
  stream_channel_id: string;
  current_user: number;
  mp4_info: {
    is_mp4: boolean;
    timestamp: number;
    mp4_url: string;
  };
  toy_enable: number;
  is_occupied: boolean;
}

interface CommentResponse {
  id: number;
  user_id: string;
  post_id: string;
  comment_type: CommentType;
  cover: string;
  user_name: string;
  content: string;
  created_at: string;
  updated_at: string;

  gift_name: string;
  gift_picture: string;
}
