import {AppBar, Box, Toolbar} from '@material-ui/core';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import Mailbox from 'routes/mailbox/Mailbox';

import {MessageModel} from 'model/message/MessageTypes';

import PointLabel from 'components/PointLabel';
import Typography from 'components/i18n/Typography';

function MailboxPage() {
  const history = useHistory();

  const gotoConversation = useCallback(
    (messenger: MessageModel.Messenger) => {
      history.push(`/message/${messenger.userId}`);
    },
    [history],
  );

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1} />
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.message
            </Typography>
          </Box>
          <PointLabel flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>

      <Mailbox onClick={gotoConversation} />
    </Box>
  );
}

export default MailboxPage;
