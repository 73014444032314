import {RootState} from 'model/helper';

const getWalletState = (state: RootState) => state.wallet;

export const getBalance = (state: RootState) => getWalletState(state).balance;
export const getGold = (state: RootState) => getWalletState(state).gold;
export const getDistribution = (state: RootState) => getWalletState(state).distribution;

const getGoldLogsState = (state: RootState) => getWalletState(state).goldLogs;
export const isGoldLogsLoading = (state: RootState) => getGoldLogsState(state).loading;
export const hasGoldLogsNextPage = (state: RootState) => getGoldLogsState(state).cursor !== null;
export const getGoldLogs = (state: RootState) => getGoldLogsState(state).list;

export const getWalletRecords = (state: RootState) => getWalletState(state).records;

export const isPaymentDrawerOpened = (state: RootState) => getWalletState(state).payment.open;

export const getPaymentMethods = (state: RootState) => getWalletState(state).payment.methods;
export const getProducts = (state: RootState) => getWalletState(state).payment.products;
