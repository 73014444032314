import {API} from 'api';
import {createStore as _createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import {AppDispatch, rootReducer, StorageKeys} from './helper';
import {loadLoginInfo} from './user/UserActions';
import {closePaymentDrawer, openPaymentDrawer} from './wallet/WalletAction';

// import Cookie from 'js-cookie';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
  }
}

export default function createStore() {
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = _createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const token = window.localStorage.getItem(StorageKeys.token);
  const authKey = window.localStorage.getItem(StorageKeys.authKey);
  if (token && authKey) {
    (store.dispatch as AppDispatch)(loadLoginInfo(token, authKey));
  }

  API.depositHandler = () => {
    (store.dispatch as AppDispatch)(
      openPaymentDrawer(() => {
        (store.dispatch as AppDispatch)(closePaymentDrawer());
      }),
    );
  };

  return store;
}
