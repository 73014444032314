import React from 'react';

interface BrandProps {
  size?: 'small' | 'medium';
}

function Brand({size = 'small'}: BrandProps) {
  return <div className={size === 'small' ? 'logo' : 'logoMedium'} />;
}

export default Brand;
