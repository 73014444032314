import createStore from 'model';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {LastLocationProvider} from 'react-router-last-location';
import App from 'routes/App';

import reportWebVitals from 'utils/reportWebVitals';
import * as serviceWorkerRegistration from 'utils/serviceWorkerRegistration';

import 'assets/index.css';

function getOS(): 'android' | 'ios' | 'other' {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }
  return 'other';
}

const isInStandaloneMode = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  (window.navigator as any).standalone ||
  document.referrer.includes('android-app://');

ReactDOM.render(
  <Provider store={createStore()}>
    <BrowserRouter>
      <LastLocationProvider>
        <App os={getOS()} standalone={isInStandaloneMode()} />
      </LastLocationProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (process.env.REACT_APP_PWA) {
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
