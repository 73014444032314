import {createSelector} from 'reselect';

import {RootState} from 'model/helper';
import {getPostCache} from 'model/post/PostSelector';

const getExploreState = (state: RootState) => state.explore;

const getExplorePostsState = (state: RootState) => getExploreState(state).posts;

export const isExploreArticlesLoading = (state: RootState) => getExplorePostsState(state).loading;
export const hasExploreArticlesNextPage = (state: RootState) =>
  getExplorePostsState(state).cursor !== null;
export const exploreArticlesSelector = createSelector(
  [(state) => getExplorePostsState(state).list, getPostCache],
  (list, cache) => {
    return list.map((id) => cache[id]);
  },
);

export const getTags = (state: RootState) => getExploreState(state).tags;

export const getSuggestSearch = (state: RootState) => getExploreState(state).suggested;
export const getRecentSearch = (state: RootState) => getExploreState(state).recent;

export const getSearchState = (state: RootState) => getExploreState(state).search;
