import {Avatar, Box} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {AppDispatch} from 'model/helper';
import {loadContributorsList} from 'model/livestream/LivestreamAction';
import {
  getContributors,
  hasContributorsListNextPage,
  isContributorsListLoading,
} from 'model/livestream/LivestreamSelector';

import {ReactComponent as FirstRankIcon} from 'assets/icon/solid/first-rank.svg';
import {ReactComponent as SecondRankIcon} from 'assets/icon/solid/second-rank.svg';
import {ReactComponent as ThirdRankIcon} from 'assets/icon/solid/third-rank.svg';

import Icon from './Icons';

function Crown({rank}: {rank: number}) {
  switch (rank) {
    case 0:
      return <Icon svg={FirstRankIcon} className="crownFirst" />;
    case 1:
      return <Icon svg={SecondRankIcon} className="crownSecond" />;
    case 2:
      return <Icon svg={ThirdRankIcon} className="crownThird" />;
    default:
      return null;
  }
}

interface ContributionHListProps {
  streamId: string;
}

function ContributionHList({streamId}: ContributionHListProps) {
  const dispatch = useDispatch<AppDispatch>();
  const contributors = useSelector(getContributors);
  const loading = useSelector(isContributorsListLoading);
  const hasNextPage = useSelector(hasContributorsListNextPage);

  const renderContributor = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const contributor = contributors[index];
      return (
        <Box style={style} display="flex" alignItems="center">
          <Box position="relative" ml={1}>
            <Crown rank={index} />
            <Avatar className="avatar" src={contributor.userPicture} />
          </Box>
        </Box>
      );
    },
    [contributors],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < contributors.length - 1;
    },
    [contributors, hasNextPage],
  );

  const itemKey = useCallback((index: number) => contributors[index].userId, [contributors]);

  const loadMore = useCallback(() => {
    if (loading || !hasNextPage) {
      return null;
    }

    return dispatch(loadContributorsList(streamId, false));
  }, [loading, hasNextPage, dispatch, streamId]);

  useEffect(() => {
    dispatch(loadContributorsList(streamId, true));
  }, [dispatch, streamId]);

  return (
    <Box flex={1} display="flex" height={62} mr={4}>
      <AutoSizer>
        {({width}) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={contributors.length}
            loadMoreItems={loadMore}>
            {({onItemsRendered, ref}) => {
              return (
                <FixedSizeList
                  key={width}
                  itemKey={itemKey}
                  layout="horizontal"
                  ref={ref}
                  onItemsRendered={onItemsRendered}
                  width={width}
                  height={62}
                  itemSize={45}
                  itemCount={contributors.length}>
                  {renderContributor}
                </FixedSizeList>
              );
            }}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </Box>
  );
}

export default ContributionHList;
