import {API} from 'api';

import {MessageType} from 'model/message/MessageTypes';

export default class MessageAPI {
  public static getMailbox(cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }
    return API.get<{cursor: string; msg_list: MessengerResponse[]}>('/message/list', params);
  }

  // sort from new to old
  public static getMessages(userId: string, cursor: string | null) {
    const params: any = {chatter_id: userId};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; msg: MessageResponse[] | null}>('/message', params);
  }

  public static getNumFreeMessage(targetId: string) {
    return API.get<{free_message_count: number}>('/message/free/count', {
      channel_id: targetId,
    });
  }

  public static sendMessage(req: SendMessageRequest) {
    return API.post('/message', {
      channel_id: req.targetId,
      location: req.location,
      message: req.content,
      msg_type: req.type,
      auto_charge: true,
    });
  }

  public static getMessagePrice(userId: string) {
    return API.get<{point: number}>(`/message/setting/personal`, {user_id: userId});
  }

  public static read(senderId: string, receiverId: string) {
    return API.put('/message/unread', {
      from_user_id: senderId,
      user_id: receiverId,
    });
  }

  public static upload(file: File | Blob, type: FileType, listener?: (progress: number) => void) {
    const data = new FormData();
    data.append('content_type', type.toString());
    data.append('file', file);

    return API.upload('/upload', data, listener);
  }

  public static unlockMedia(id: string) {
    return API.post<{url: string}>(`/message/media/unlock/${id}`);
  }
}

interface MessageResponse {
  id: number;
  user_id: string;
  to_user_id: string;
  message: string;
  msg_type: MessageType;
  created_at: string;
  location: MessageLocation;
  has_charged: boolean;
  unlock_point: number;

  gift_name: string;
  gift_picture: string;
}

interface MessengerResponse {
  created_at: string;
  is_customer_service: boolean;
  user_id: string;
  name: string;
  picture: string;
  message: string;
  unread_msg_count?: number;
}

export enum MessageLocation {
  Other = 0,
  Stream,
  Private,
}

interface SendMessageRequest {
  targetId: string;
  location: MessageLocation;
  content: string; // text or id
  type: MessageType;
}

export enum FileType {
  OctetStream = 0,
  JPEG,
  PNG,
  JSON,
  MP4,
  M4A,
}
