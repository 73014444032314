import {API} from 'api';

import {Rating, WatchMode} from 'model/livestream/LivestreamTypes';

export default class LivestreamAPI {
  public static getLivestreamList(
    cursor: string | null,
    orderBy: 'performance' | 'register_at' = 'performance',
    sort: 'asc' | 'desc' = 'asc',
  ) {
    const params: any = {
      order_by: orderBy,
      sort,
    };
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; streams: StreamResponse[]}>('/livestream/list', params);
  }

  public static getLivestreamInfo(streamId: string) {
    return API.get<{stream: StreamResponse}>(`/livestream/info/${streamId}`);
  }

  public static startWatch(streamId: string, mode: WatchMode) {
    return API.post<{
      auth_key: string;
      channel_id: string;
      downtime_counter: number;
      mode: WatchMode;
    }>('/livestream/enter_room', {stream_id: streamId, mode});
  }

  // heartbeat rate: 1 per second
  public static keepWatch(streamId: string, mode: WatchMode) {
    return API.post('/livestream/keep_view', {stream_id: streamId, mode});
  }

  public static leave(stream: string, mode: WatchMode) {
    return API.post('/livestream/leave_room', {
      stream_id: stream,
      mode: mode,
    });
  }

  public static requestUpgrade(streamId: string, mode: WatchMode) {
    return API.post('/livestream/request_charge', {stream_id: streamId, mode});
  }

  public static acceptUpgrade(requestId: string) {
    return API.post<{channel_id: string}>('/livestream/accept_invite', {request_id: requestId});
  }

  public static rejectUpgrade(requestId: string) {
    return API.post('/livestream/reject_invite', {request_id: requestId});
  }

  public static getContributionList(streamId: string, cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{rank: ContributionResponse[] | null; cursor: string}>(
      `/point/list/${streamId}`,
      params,
    );
  }
}

export interface StreamResponse {
  user_id: string;
  user_name: string;
  picture: string;
  cover: string;
  stream_id: string;
  title: string;
  announcement: string;
  om_charge: number;
  oo_charge: number;
  begin_at: string;
  end_at: string | null;
  status: WatchMode;
  rating: Rating;
  total_point_received: number;
  channel_id: string;
  current_user: number;
  score: number;
  downtime_counter: number;
  mp4_info: {
    is_mp4: boolean;
    timestamp: number;
    mp4_url: string;
  };
  resting: boolean;
  name: string;
  toy_enable: boolean;
  keep_alive_at: string | null;
  is_occupied: boolean;
}

interface ContributionResponse {
  rank: number;
  id: string;
  name: string;
  score: number;
  picture: string;
  is_follow: boolean;
  stream_id: string;
}
