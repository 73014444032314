import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Toolbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {ErrorCode} from 'api';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import {AppDispatch} from 'model/helper';
import {register} from 'model/user/UserActions';
import {RegisterType} from 'model/user/UserTypes';

import UserAPI from 'api/user';

import {getSearchQuery} from 'utils/string';

import BackButton from 'components/BackButton';
import TermsDialog from 'components/TermsDialog';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

import AuthPhoneEnterForm from './AuthPhoneEnterForm';
import AuthPhoneVerifyForm from './AuthPhoneVerifyForm';

enum MobileSignupStep {
  EnterPhone = 0,
  VerifyPhone,
  EnterInfo,
}

interface GradientButtonProps {
  text?: string;
  disabled?: boolean;
  onClick?: () => void;
}

function GradientButton({text = 'common.next', disabled, onClick}: GradientButtonProps) {
  const styles = useThemeStyles();

  return (
    <Box
      position="relative"
      display="flex"
      width="100%"
      alignItems="center"
      className="gradientButtonWrapper"
      my={2}>
      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        className={[styles.gradient, 'gradientButton'].join(' ')}></Box>
      <Button variant="outlined" size="large" fullWidth disabled={disabled} onClick={onClick}>
        <Typography>{text}</Typography>
      </Button>
    </Box>
  );
}

function AuthCreateByPhone() {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState<ErrorCode | undefined>();

  const [step, setStep] = useState(MobileSignupStep.EnterPhone);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+886');
  const [otp, setOtp] = useState('');

  const [name, setName] = useState('');
  // const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [invite, setInvite] = useState(getSearchQuery(history.location.search, 'invite'));
  const [termsOpen, setTermsOpen] = useState(false);

  const signupDisabled = !name || !password || password !== repassword;

  const goBack = useCallback(() => {
    if (step === 0) {
      history.goBack();
    } else {
      setError(undefined);
      setStep(MobileSignupStep.EnterPhone);
    }
  }, [step, history]);

  const signup = useCallback(() => {
    setLoading(true);
    dispatch(
      register({
        name,
        account: UserAPI.normalizePhone(countryCode, phone),
        password,
        otp,
        type: RegisterType.Phone,
        countryCode,
        phone,
        invite,
      }),
    )
      .then(() => {
        history.push('/recommended');
      })
      .catch((err: ErrorCode) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, history, name, password, otp, countryCode, phone, invite]);

  const closeAlert = useCallback(() => {
    setError(undefined);
    if (error === ErrorCode.AccountRegistered) {
      history.push('/auth/login');
    }
  }, [error, history]);

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <BackButton onClick={goBack} />
        </Toolbar>
      </AppBar>
      <Box component="main">
        <SwipeableViews index={step} disabled>
          <AuthPhoneEnterForm
            title="title.phone_enter"
            subtitle="title.sub_phone_enter"
            onSuccess={(phone, countryCode) => {
              setStep(MobileSignupStep.VerifyPhone);
              setPhone(phone);
              setCountryCode(countryCode);
            }}
          />
          <AuthPhoneVerifyForm
            phone={phone}
            countryCode={countryCode}
            show={step === MobileSignupStep.VerifyPhone}
            onSuccess={(otp) => {
              setOtp(otp);
              setStep(MobileSignupStep.EnterInfo);
            }}
          />
          <Box display="flex" flexDirection="column" mx={2} alignItems="center">
            <Box component="header" mb={2} textAlign="center">
              <Typography component="h3" variant="h4">
                title.personal_info
              </Typography>
            </Box>
            <Grid container component="form" direction="column" spacing={3}>
              <Grid item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  label="input.name_label"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              {/* <Grid item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  label="input.account_label"
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                />
              </Grid> */}
              <Grid item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  label="input.password_label"
                  type="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  label="input.repassword_label"
                  type="password"
                  value={repassword}
                  onChange={(e) => setRePassword(e.target.value)}
                  error={password !== repassword}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  label="input.invite_label"
                  value={invite}
                  onChange={(e) => setInvite(e.target.value)}
                />
              </Grid>
            </Grid>

            <Box my={1}>
              <Button
                onClick={() => {
                  setTermsOpen(true);
                }}>
                <Typography color="primary">title.terms</Typography>
              </Button>
            </Box>

            <GradientButton text="input.signup" disabled={signupDisabled} onClick={signup} />
          </Box>
        </SwipeableViews>
      </Box>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={error === ErrorCode.AccountRegistered}
        autoHideDuration={4000}
        onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error" variant="filled">
          <Typography>{`error.${error}`}</Typography>
        </Alert>
      </Snackbar>
      <TermsDialog
        open={termsOpen}
        onClose={() => {
          setTermsOpen(false);
        }}
      />
    </>
  );
}

export default AuthCreateByPhone;
