import {ErrorCode} from 'api';

import {Listener, PayloadAction, PureAction, Subscriber} from 'model/helper';

export class LivestreamAction {
  public static readonly GET_LIVESTREAM_LIST = 'GET_LIVESTREAM_LIST';
  public static readonly GET_LIVESTREAM_LIST_S = 'GET_LIVESTREAM_LIST_S';

  public static readonly GET_LIVESTREAM = 'GET_LIVESTREAM';
  public static readonly WATCH_LIVESTREAM = 'WATCH_LIVESTREAM';
  public static readonly UPDATE_WATCHING_STATUS = 'UPDATE_WATCHING_STATUS';
  public static readonly LISTEN_LIVESTREAM_CHANNEL = 'LISTEN_LIVESTREAM_CHANNEL';
  public static readonly LEAVE_LIVESTREAM = 'LEAVE_LIVESTREAM';
  public static readonly UPDATE_LOCAL_STREAM = 'UPDATE_LOCAL_STREAM';

  public static readonly GET_CONTRIBUTORS_LIST = 'GET_CONTRIBUTORS_LIST';
  public static readonly GET_CONTRIBUTORS_LIST_S = 'GET_CONTRIBUTORS_LIST_S';
}

export enum WatchMode {
  Free = 1,
  OM,
  OO,
}

export enum Rating {
  G = 0, // 普遍級
  // PG,
  PG13 = 1, // 輔導級
  // R
  NC17 = 2, // 限制級
}

export declare namespace LivestreamModel {
  export interface State {
    list: {
      loading: boolean;
      cursor: string | null;
      items: string[];
    };
    cache: {[streamId: string]: StreamInfo};
    watching: {
      streamId: string;
      authKey: string;
      channelId: string | null;
      mode: WatchMode;
      heartbeat: number | null;
      heartbeatHandler(err: ErrorCode): void;
      hasLocalStream: boolean;
      channel: {
        sub?: Subscriber;
        listeners: Listener[];
      };
    } | null;
    contribution: {
      loading: boolean;
      cursor: string | null;
      contributors: Contributor[];
    };
  }

  export interface StreamInfo {
    id: string;
    title: string;
    name: string;
    rating: Rating;
    status: WatchMode;
    channelId: string;
    omCharge: number;
    ooCharge: number;
    beginAt: string | null;
    keepAliveAt: string | null;
    occupied: boolean;

    userId: string;
    userName: string;
    userPicture: string;
    cover: string;

    announcement: string;
    earning: number;
    numWatchers: number;
    score: number;
    downtime: Date;
    resting: boolean;
    toyEnabled: boolean;

    vod?: {
      url: string;
      offset: number;
    };
  }

  export interface Contributor {
    rank: number;
    userName: string;
    userId: string;
    userPicture: string;
    contribution: number;
  }

  // export interface StreamerInvite {
  //   body: {
  //     id: string;
  //     mode: WatchMode;
  //   };
  //   timer: number;
  // }

  export interface StreamQuality {
    maxFps: number;
    maxBitrate: number;
    minFps: number;
    minBitrate: number;
    resolutionHeight: number;
    resolutionWidth: number;
    codec: 'vp8' | 'h264';
  }

  export type GetLivestreamListAction = PureAction<typeof LivestreamAction['GET_LIVESTREAM_LIST']>;
  export type AfterGetLivestreamListAction = PayloadAction<
    typeof LivestreamAction['GET_LIVESTREAM_LIST_S'],
    {
      refresh: boolean;
      cursor: string | null;
      items: StreamInfo[];
    }
  >;

  export type GetLivestreamAction = PayloadAction<
    typeof LivestreamAction['GET_LIVESTREAM'],
    StreamInfo
  >;

  export type WatchLivestreamAction = PayloadAction<
    typeof LivestreamAction['WATCH_LIVESTREAM'],
    {
      streamId: string;
      mode: WatchMode;
      channelId: string;
      authKey: string;
      heartbeat: number | null;
      heartbeatHandler(err: ErrorCode): void;
      sub?: Subscriber;
    }
  >;

  export type LeaveLivestreamAction = PureAction<typeof LivestreamAction['LEAVE_LIVESTREAM']>;

  export type ListenLivestreamChannelAction = PayloadAction<
    typeof LivestreamAction['LISTEN_LIVESTREAM_CHANNEL'],
    Listener
  >;

  export type GetContributorsListAction = PureAction<
    typeof LivestreamAction['GET_CONTRIBUTORS_LIST']
  >;
  export type AfterGetContributorsListAction = PayloadAction<
    typeof LivestreamAction['GET_CONTRIBUTORS_LIST_S'],
    {
      refresh: boolean;
      cursor: string | null;
      contributors: Contributor[];
    }
  >;

  export type UpdateWatchingStatusAction = PayloadAction<
    typeof LivestreamAction['UPDATE_WATCHING_STATUS'],
    {
      mode: WatchMode;
      channelId: string | null;
    }
  >;

  export type UpdateLocalStreamAction = PayloadAction<
    typeof LivestreamAction['UPDATE_LOCAL_STREAM'],
    boolean
  >;

  export type ActionTypes =
    | GetLivestreamListAction
    | AfterGetLivestreamListAction
    | GetLivestreamAction
    | WatchLivestreamAction
    | ListenLivestreamChannelAction
    | UpdateWatchingStatusAction
    | LeaveLivestreamAction
    | GetContributorsListAction
    | AfterGetContributorsListAction
    | UpdateLocalStreamAction;
}
