import {combineReducers} from 'redux';

import {RootActions} from 'model/helper';

import {ExploreAction, ExploreModel, SearchType} from './ExploreTypes';

const initialState: ExploreModel.State = {
  suggested: [],
  tags: [],
  recent: [],
  search: {
    [SearchType.All]: {
      loading: true,
      cursor: null,
      list: [],
    },
    [SearchType.Streamer]: {
      loading: true,
      cursor: null,
      list: [],
    },
    [SearchType.Tag]: {
      loading: true,
      cursor: null,
      list: [],
    },
  },
  posts: {
    loading: true,
    cursor: null,
    list: [],
  },
};

function suggested(
  state = initialState.suggested,
  action: RootActions,
): ExploreModel.State['suggested'] {
  switch (action.type) {
    case ExploreAction.GET_SUGGESTED_SEARCH_RESULT:
      return action.payload;
    default:
      return state;
  }
}

function tags(state = initialState.tags, action: RootActions): ExploreModel.State['tags'] {
  switch (action.type) {
    case ExploreAction.GET_SUGGESTED_TAGS:
      return action.payload;
    default:
      return state;
  }
}

function recent(state = initialState.recent, action: RootActions): ExploreModel.State['recent'] {
  switch (action.type) {
    case ExploreAction.GET_RECENT_SEARCH_RESULT:
      return action.payload;
    default:
      return state;
  }
}

function search(state = initialState.search, action: RootActions): ExploreModel.State['search'] {
  switch (action.type) {
    case ExploreAction.SEARCH_KEYWORD:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: true,
        },
      };
    case ExploreAction.SEARCH_KEYWORD_S:
      return {
        ...state,
        [action.payload.type]: {
          loading: false,
          cursor: action.payload.cursor,
          list: action.payload.refresh
            ? action.payload.list
            : state[action.payload.type].list.concat(action.payload.list),
        },
      };
    default:
      return state;
  }
}

function posts(state = initialState.posts, action: RootActions): ExploreModel.State['posts'] {
  switch (action.type) {
    case ExploreAction.GET_RECOMMENDED_POSTS:
    case ExploreAction.GET_KEYWORD_POSTS:
      return {
        loading: true,
        cursor: action.payload.refresh ? null : state.cursor,
        list: action.payload.refresh ? [] : state.list,
      };
    case ExploreAction.GET_RECOMMENDED_POSTS_S:
    case ExploreAction.GET_KEYWORD_POSTS_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        list: action.payload.refresh
          ? action.payload.list.map((article) => article.id)
          : state.list.concat(action.payload.list.map((article) => article.id)),
      };
    default:
      return state;
  }
}

export default combineReducers<ExploreModel.State>({
  suggested,
  tags,
  recent,
  search,
  posts,
});
