import {combineReducers} from 'redux';

import {RootActions} from 'model/helper';
import {UserAction} from 'model/user/UserTypes';

import {WalletAction, WalletModel} from './WalletTypes';

const initialState: WalletModel.State = {
  balance: 0,
  gold: 0,
  distribution: {
    upline: {
      userId: '',
      userName: '',
      userPicture: '',
    },
    downline: {},
  },
  goldLogs: {
    loading: true,
    cursor: null,
    list: [],
  },
  records: {
    usage: {
      loading: true,
      cursor: null,
      list: [],
    },
    purchase: {
      loading: true,
      cursor: null,
      list: [],
    },
    bonus: {
      loading: true,
      cursor: null,
      list: [],
    },
  },
  payment: {
    open: false,
    onPaid: null,
    methods: [],
    products: [],
  },
};

function balance(state = initialState.balance, action: RootActions): WalletModel.State['balance'] {
  switch (action.type) {
    case WalletAction.GET_WALLET_BALANCE:
      return action.payload;
    case UserAction.USER_LOGOUT:
      return initialState.balance;
    default:
      return state;
  }
}

function gold(state = initialState.gold, action: RootActions): WalletModel.State['gold'] {
  switch (action.type) {
    case WalletAction.GET_GOLD:
      return action.payload;
    case UserAction.USER_LOGOUT:
      return initialState.balance;
    default:
      return state;
  }
}

function distribution(
  state = initialState.distribution,
  action: RootActions,
): WalletModel.State['distribution'] {
  switch (action.type) {
    case WalletAction.GET_DISTRIBUTION_SUMMARY:
      return action.payload;
    case WalletAction.GET_DISTRIBUTION_LEVEL: {
      return {
        ...state,
        downline: {
          ...state.downline,
          [action.payload.level]: {
            ...state.downline[action.payload.level],
            list: action.payload.list,
          },
        },
      };
    }
    case UserAction.USER_LOGOUT:
      return initialState.distribution;
    default:
      return state;
  }
}

function goldLogs(
  state = initialState.goldLogs,
  action: RootActions,
): WalletModel.State['goldLogs'] {
  switch (action.type) {
    case WalletAction.GET_GOLD_LOGS:
      return {
        ...state,
        loading: true,
      };
    case WalletAction.GET_GOLD_LOGS_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        list: action.payload.refresh ? action.payload.list : state.list.concat(action.payload.list),
      };
    case UserAction.USER_LOGOUT:
      return initialState.goldLogs;
    default:
      return state;
  }
}

function records(state = initialState.records, action: RootActions) {
  switch (action.type) {
    case WalletAction.GET_WALLET_RECORDS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: true,
        },
      };
    case WalletAction.GET_WALLET_RECORDS_S:
      return {
        ...state,
        [action.payload.type]: {
          loading: false,
          cursor: action.payload.cursor,
          list: action.payload.refresh
            ? action.payload.list
            : state[action.payload.type].list.concat(action.payload.list),
        },
      };
    case UserAction.USER_LOGOUT:
      return initialState.records;
    default:
      return state;
  }
}

function open(
  state = initialState.payment.open,
  action: RootActions,
): WalletModel.State['payment']['open'] {
  switch (action.type) {
    case WalletAction.OPEN_PAYMENT_DRAWER:
      return true;
    case WalletAction.CLOSE_PAYMENT_DRAWER:
    case UserAction.USER_LOGOUT:
      return false;
    default:
      return state;
  }
}

function onPaid(
  state = initialState.payment.onPaid,
  action: RootActions,
): WalletModel.State['payment']['onPaid'] {
  switch (action.type) {
    case WalletAction.OPEN_PAYMENT_DRAWER:
      return action.payload.onPaid || null;
    case WalletAction.CLOSE_PAYMENT_DRAWER:
    case UserAction.USER_LOGOUT:
      return null;
    default:
      return state;
  }
}

function methods(
  state = initialState.payment.methods,
  action: RootActions,
): WalletModel.State['payment']['methods'] {
  switch (action.type) {
    case WalletAction.GET_PAYMENT_METHODS:
      return action.payload;
    default:
      return state;
  }
}

function products(
  state = initialState.payment.products,
  action: RootActions,
): WalletModel.State['payment']['products'] {
  switch (action.type) {
    case WalletAction.GET_PAYMENT_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers<WalletModel.State>({
  balance,
  gold,
  distribution,
  goldLogs,
  records,
  payment: combineReducers({
    open,
    onPaid,
    methods,
    products,
  }),
});
