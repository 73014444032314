import {
  AppBar,
  Avatar,
  Box,
  Button,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouteLink} from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, ListChildComponentProps} from 'react-window';

import {AppDispatch} from 'model/helper';
import {loadSuggestedUsers} from 'model/post/PostAction';
import {suggestedUsersSelector} from 'model/post/PostSelector';
import {followUser, unfollowUser} from 'model/user/UserActions';

import Typography from 'components/i18n/Typography';

interface GradientButtonProps {
  followed: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

function GradientButton({followed, disabled, onClick}: GradientButtonProps) {
  const styles = useThemeStyles();

  return (
    <Box
      position="relative"
      display="flex"
      width="100%"
      alignItems="center"
      className="gradientButtonSmallWrapper"
      mb={2}
      mt={3}>
      {!followed && (
        <Box className={['overlay', styles.gradient, 'gradientButtonSmall'].join(' ')} />
      )}

      <Button variant="outlined" size="small" disabled={disabled} onClick={onClick}>
        <Typography>{followed ? 'input.unfollow' : 'input.follow'}</Typography>
      </Button>
    </Box>
  );
}

function RecommendationPage() {
  const dispatch = useDispatch<AppDispatch>();
  const styles = useThemeStyles();

  useEffect(() => {
    dispatch(loadSuggestedUsers());

    // eslint-disable-next-line
  }, []);

  const suggested = useSelector(suggestedUsersSelector);

  const renderRow = useCallback(
    (props: ListChildComponentProps) => {
      const {index, style} = props;
      const user = suggested[index];

      return (
        <ListItem key={user.userId} ContainerProps={{style: style}} ContainerComponent="div">
          <ListItemAvatar>
            <Avatar src={user.picture} />
          </ListItemAvatar>
          <ListItemText
            primary={user.name}
            secondary={user.bio || '　'}
            secondaryTypographyProps={{noWrap: true}}
            className={styles.avoidListItemAction}
          />
          <ListItemSecondaryAction>
            <GradientButton
              followed={user.followed}
              onClick={() => {
                if (user.followed) {
                  dispatch(unfollowUser(user.userId));
                } else {
                  dispatch(followUser(user.userId));
                }
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      );
    },
    [dispatch, suggested, styles],
  );

  return (
    <Box className="full">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1} />
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.recommendation
            </Typography>
          </Box>
          <Box flex={1} textAlign="end">
            <Link color="primary" component={RouteLink} to="/">
              <Typography>common.next</Typography>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" className="main">
        <AutoSizer>
          {({height, width}) => (
            <FixedSizeList width={width} height={height} itemSize={62} itemCount={suggested.length}>
              {renderRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
}

export default RecommendationPage;
