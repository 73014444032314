import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography as Text,
} from '@material-ui/core';
import React, {CSSProperties, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import AutoSizer from 'react-virtualized-auto-sizer';

import {AppDispatch, StorageKeys} from 'model/helper';
import {loadMyInfo, loadUser} from 'model/user/UserActions';
import {getMyInfo} from 'model/user/UserSelector';
import {closePaymentDrawer, openPaymentDrawer} from 'model/wallet/WalletAction';
import {getBalance, getGold} from 'model/wallet/WalletSelector';

import {format} from 'utils/number';
import {deleteSearchQuery, genSearchQuery, getSearchQuery} from 'utils/string';

import Icon from 'components/Icons';
import QRCodeDialog from 'components/QRCodeDialog';
import TabPanel from 'components/TabPanel';
import Typography from 'components/i18n/Typography';

import {ReactComponent as GearIcon} from 'assets/icon/regular/gear.svg';
import {ReactComponent as QRCodeIcon} from 'assets/icon/regular/qr-code.svg';

import SavedCollection from './SavedCollection';
import UnlockedCollection from './UnlockedCollection';

function ProfilePage() {
  const history = useHistory();
  const location = useLocation();
  const openQR = getSearchQuery(location.search, StorageKeys.qrCode) === '1';
  const dispatch = useDispatch<AppDispatch>();
  const tab = useMemo(() => {
    return getSearchQuery(location.search, StorageKeys.tab);
  }, [location.search]);
  const tabIndex = tab === 'saved' ? 1 : 0;

  const info = useSelector(getMyInfo);
  const balance = useSelector(getBalance);
  const gold = useSelector(getGold);

  const handleChangeTab = useCallback(
    (_, v: number) => {
      if (v === 1) {
        history.replace({
          pathname: '/profile',
          search: genSearchQuery(history.location.search, StorageKeys.tab, 'saved'),
        });
      } else {
        history.replace({
          pathname: '/profile',
          search: genSearchQuery(history.location.search, StorageKeys.tab, 'unlocked'),
        });
      }
    },
    [history],
  );

  const toSetting = useCallback(() => {
    history.push('/profile/setting');
  }, [history]);

  const toEdit = useCallback(() => {
    history.push('/profile/edit');
  }, [history]);

  const toFollowerDetails = useCallback(() => {
    history.push('/profile/follow/follower');
  }, [history]);

  const toFolloweeDetails = useCallback(() => {
    history.push('/profile/follow/followee');
  }, [history]);

  const toGoldDetails = useCallback(() => {
    history.push('/profile/gold/distribution');
  }, [history]);

  const openQRCode = useCallback(() => {
    history.replace({
      pathname: '/profile',
      search: genSearchQuery(history.location.search, StorageKeys.qrCode, '1'),
    });
  }, [history]);

  const closeQRCode = useCallback(() => {
    history.replace({
      pathname: '/profile',
      search: deleteSearchQuery(history.location.search, StorageKeys.qrCode),
    });
  }, [history]);

  const handleOpenPayment = useCallback(() => {
    dispatch(
      openPaymentDrawer(() => {
        dispatch(closePaymentDrawer());
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadMyInfo());
  }, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <Paper component="header" square>
        <Box display="flex" alignItems="flex-start" py={1} mx={2}>
          <Box flex={1} display="flex" alignItems="center" py={0.5}>
            <Box mr={2}>
              <IconButton size="small" onClick={toSetting}>
                <Icon svg={GearIcon} fontSize="small" />
              </IconButton>
            </Box>

            <IconButton size="small" onClick={openQRCode}>
              <Icon svg={QRCodeIcon} fontSize="small" />
            </IconButton>
          </Box>
          <Box
            flex="1"
            textAlign="center"
            height={30}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Text variant="h6" component="h3">
              {info.name}
            </Text>
          </Box>
          <Box flex="1" display="flex" textAlign="end" justifyContent="flex-end">
            <Box display="flex" flexDirection="column" mr={1}>
              <Box height={30} display="flex" alignItems="center">
                <IconButton size="small" color="primary" onClick={handleOpenPayment}>
                  +
                </IconButton>
              </Box>
              <Box height={30} />
            </Box>

            <Box display="flex" flexDirection="column" mr={1}>
              <Box height={30} display="flex" alignItems="center">
                <div className="diamond" />
              </Box>
              <Box height={30} display="flex" alignItems="center" onClick={toGoldDetails}>
                <div className="coin" />
              </Box>
            </Box>

            <Box display="flex" flexDirection="column">
              <Box height={30} display="flex" alignItems="center" justifyContent="flex-end">
                <Text>{format(balance)}</Text>
              </Box>
              <Box
                height={30}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                onClick={toGoldDetails}>
                <Text>{format(gold)}</Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mx={2} mt={-3}>
          <Avatar src={info.picture} className="avatar85" />
          <Button onClick={toFolloweeDetails}>
            <Box textAlign="center">
              <Text variant="h6">{info.numFollowee}</Text>
              <Typography variant="subtitle1">label.numFollowing</Typography>
            </Box>
          </Button>
          <Button onClick={toFollowerDetails}>
            <Box textAlign="center">
              <Text variant="h6">{info.numFollower}</Text>
              <Typography variant="subtitle1">label.numFollower</Typography>
            </Box>
          </Button>
        </Box>
        <Box mx={2} my={1}>
          <Text>{info.bio}</Text>
        </Box>
        <Box mx={2} pb={2}>
          <Button variant="outlined" fullWidth onClick={toEdit}>
            <Typography>input.edit_profile</Typography>
          </Button>
        </Box>
      </Paper>

      <AppBar position="static" color="default">
        <Tabs variant="fullWidth" value={tabIndex} onChange={handleChangeTab}>
          <Tab label={<Typography>title.unlocked</Typography>} aria-label="unlocked" />
          <Tab label={<Typography>title.saved</Typography>} aria-label="saved" />
        </Tabs>
      </AppBar>

      <Box flex={1}>
        <AutoSizer>
          {({height, width}) => {
            const style: CSSProperties = {width};
            return (
              <SwipeableViews style={style} slideStyle={style} index={tabIndex} disabled>
                <TabPanel value={tabIndex} index={0}>
                  <UnlockedCollection width={width} height={height} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <SavedCollection width={width} height={height} />
                </TabPanel>
              </SwipeableViews>
            );
          }}
        </AutoSizer>
      </Box>

      <QRCodeDialog
        open={openQR}
        onClose={closeQRCode}
        userId={info.userId}
        userPicture={info.picture}
        userName={info.name}
        showLink
      />
    </Box>
  );
}

export default ProfilePage;
