import {combineReducers} from 'redux';

import {RootActions} from 'model/helper';

import {GiftAction, GiftModel} from './GiftTypes';

const initialState: GiftModel.State = {
  categories: [],
  cache: {},
  open: null,
};

function open(state = initialState.open, action: RootActions): GiftModel.State['open'] {
  switch (action.type) {
    case GiftAction.OPEN_GIFT_DRAWER:
      return action.payload;
    case GiftAction.CLOSE_GIFT_DRAWER:
      return null;
    default:
      return state;
  }
}

function categories(
  state = initialState.categories,
  action: RootActions,
): GiftModel.State['categories'] {
  switch (action.type) {
    case GiftAction.GET_GIFTS:
      return action.payload.categories;
    default:
      return state;
  }
}

function cache(state = initialState.cache, action: RootActions): GiftModel.State['cache'] {
  switch (action.type) {
    case GiftAction.GET_GIFTS:
      return {
        ...state,
        ...action.payload.gifts.reduce<GiftModel.State['cache']>((cache, gift) => {
          cache[gift.id] = gift;
          return cache;
        }, {}),
      };
    default:
      return state;
  }
}

export default combineReducers<GiftModel.State>({
  open,
  categories,
  cache,
});
