import {Box} from '@material-ui/core';
import React, {useCallback, useMemo} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {MediaExtension, PostModel} from 'model/post/PostTypes';

import StoryCell from 'components/StoryCell';

interface StoryGalleryProps {
  start?: string;
  items: PostModel.Article[];
  hasNextPage: boolean;
  loadMore: () => Promise<any> | null;
  onClickItem: (item: PostModel.Article | undefined) => () => void;
}

const CELLS_PER_ROW = 3;
const FREQUENCY = 3;

function StoryGallery({start, items, hasNextPage, loadMore, onClickItem}: StoryGalleryProps) {
  const rowCount = useMemo<number>(() => {
    return Math.ceil(items.length / CELLS_PER_ROW);
  }, [items]);

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < rowCount - 1;
    },
    [hasNextPage, rowCount],
  );

  const renderCell = useCallback(
    ({index: rowIndex, style}: ListChildComponentProps) => {
      const hasAnimCell =
        rowIndex % FREQUENCY === 0 &&
        items[rowIndex * CELLS_PER_ROW].media[0].ext === MediaExtension.MP4;
      const reverse = hasAnimCell && (Math.floor(rowIndex / FREQUENCY) & 1) === 1;
      const startIndex = rowIndex * CELLS_PER_ROW;

      return (
        <Box style={style}>
          {hasAnimCell ? (
            <Box display="flex" className="full">
              <Box flex={2} order={reverse ? 2 : 1}>
                <StoryCell
                  article={items[startIndex]}
                  className="full"
                  onClick={onClickItem(items[startIndex])}
                  large
                />
              </Box>

              <Box flex={1} display="flex" flexDirection="column" order={reverse ? 1 : 2}>
                <StoryCell
                  className="halfHeight"
                  flex={1}
                  article={items[startIndex + 1]}
                  onClick={onClickItem(items[startIndex + 1])}
                />
                <StoryCell
                  className="halfHeight"
                  flex={1}
                  article={items[startIndex + 2]}
                  onClick={onClickItem(items[startIndex + 2])}
                />
              </Box>
            </Box>
          ) : (
            <Box flex={1} display="flex" className="full">
              <StoryCell article={items[startIndex]} onClick={onClickItem(items[startIndex])} />
              <StoryCell
                article={items[startIndex + 1]}
                onClick={onClickItem(items[startIndex + 1])}
              />
              <StoryCell
                article={items[startIndex + 2]}
                onClick={onClickItem(items[startIndex + 2])}
              />
            </Box>
          )}
        </Box>
      );
    },
    [items, onClickItem],
  );

  return (
    <AutoSizer>
      {({height, width}) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={rowCount}
          threshold={0}
          loadMoreItems={loadMore}>
          {({onItemsRendered, ref}) => {
            return (
              <VariableSizeList
                // itemKey={itemKey}
                itemCount={rowCount}
                overscanCount={0}
                itemSize={(rowIndex: number) => {
                  const hasAnimCell =
                    rowIndex % FREQUENCY === 0 &&
                    items[rowIndex * CELLS_PER_ROW].media[0].ext === MediaExtension.MP4;
                  const cellHeight = width / CELLS_PER_ROW;

                  return hasAnimCell ? cellHeight * 2 : cellHeight;
                }}
                width={width}
                height={height}
                onItemsRendered={onItemsRendered}
                ref={ref}>
                {renderCell}
              </VariableSizeList>
            );
          }}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

export default StoryGallery;
