import {API, ServerError} from 'api';
import dayjs from 'dayjs';

import {AppThunk, StorageKeys, userResponseToModel} from 'model/helper';
import {createPersonalChannel} from 'model/message/MessageAction';
import {WalletAction} from 'model/wallet/WalletTypes';

import Analysis from 'api/analysis';
import UserAPI from 'api/user';

import {UserModel, UserAction, RegisterType, LoginProvider} from './UserTypes';

// import Cookie from 'js-cookie';

export const sendSNS = (
  payload: UserModel.SendSMSAction['payload'],
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch({type: UserAction.USER_SEND_SMS, payload});
    await UserAPI.sendSMS(payload);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const verifySMS = (
  payload: UserModel.VerifySMSAction['payload'],
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch({type: UserAction.USER_VERIFY_SMS, payload});
    await UserAPI.verifyPhone(payload);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const register = (
  payload: UserModel.RegisterAction['payload'],
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch({type: UserAction.USER_REGISTER, payload});
    await UserAPI.register(payload);

    if (payload.type === RegisterType.Phone) {
      await dispatch(
        login({
          phone: payload.phone ?? '',
          countryCode: payload.countryCode ?? '',
          password: payload.password ?? '',
        }),
      );
    } else {
      await dispatch(
        loginByThirdParty({
          provider:
            payload.type === RegisterType.Google
              ? 'google'
              : payload.type === RegisterType.Facebook
              ? 'facebook'
              : 'line',
          token: payload.otp,
        }),
      );
    }

    Analysis.signup();
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

// const TOKEN_VALID_DAYS = 14;

export const login = (payload: UserModel.LoginAction['payload']): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    dispatch({type: UserAction.USER_LOGIN, payload});
    const res = await UserAPI.login(payload);
    window.localStorage.setItem(StorageKeys.token, res.token);
    window.localStorage.setItem(StorageKeys.authKey, res.auth_key);
    Analysis.login('phone');

    dispatch(loadLoginInfo(res.token, res.auth_key));
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loginByThirdParty = (
  payload: UserModel.ThirdPartyLoginAction['payload'],
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const {res, status} = await UserAPI.thirdPartyLogin(payload.provider, payload.token);

    if (status === 201) {
      return false;
    } else {
      window.localStorage.setItem(StorageKeys.token, res!.token);
      window.localStorage.setItem(StorageKeys.authKey, res!.auth_key);
      Analysis.login(payload.provider);

      dispatch(loadLoginInfo(res!.token, res!.auth_key));
    }
    return true;
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const resetPassword = (
  payload: UserModel.ResetPasswordAction['payload'],
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await UserAPI.resetPassword(payload);
    dispatch({
      type: UserAction.USER_RESET_PASSWORD,
      payload,
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadMyInfo = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const info = await UserAPI.getMe();
    dispatch({
      type: UserAction.GET_MY_INFO,
      payload: {
        id: info.id,
        userId: info.user_id,
        externalId: info.user_id,

        name: info.name,
        role: info.role,
        bio: info.bio,
        voiceBio: info.voice_bio,
        gender: info.gender,
        // birthday: number;
        picture: info.picture,
        covers: info.covers,
        inviteCode: info.invite_code,
        numFollower: info.follower_count,
        numFollowee: info.followee_count,
        numPosts: info.post_count,

        streamId: info.stream_id,

        account: info.account,
        phone: info.phone_number,
        note: info.note,
        pushToken: info.push_token,
        ip: info.ip,
        credit: info.credit_money,
        registerAt: info.register_at,
        loginAt: info.login_at,
        device: info.device_type,
        badge: info.badge,
        age: info.age,
        status: info.status,
        countryCode: info.country_code,
        registerType: info.register_type,
      },
    });

    dispatch({type: WalletAction.GET_WALLET_BALANCE, payload: info.point});
    dispatch({type: WalletAction.GET_GOLD, payload: info.gold});
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadLoginInfo = (token: string, authKey: string): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    API.setToken(token, '');
    dispatch({
      type: UserAction.USER_LOGIN_S,
      payload: {
        token,
        authKey,
      },
    });

    await dispatch(loadMyInfo());
    // const info = getMyInfo(getState());
    dispatch(createPersonalChannel());
    // console.log(info);
  } catch (err) {
    throw err;
  }
};

export const logout = (): AppThunk<void> => async (dispatch) => {
  API.removeToken();
  // Cookie.remove(StorageKeys.authKey);
  // Cookie.remove(StorageKeys.token);
  window.localStorage.removeItem(StorageKeys.authKey);
  window.localStorage.removeItem(StorageKeys.token);
  dispatch({type: UserAction.USER_LOGOUT});
};

export const followUser = (
  payload: UserModel.FollowUserAction['payload'],
): AppThunk<void> => async (dispatch) => {
  try {
    dispatch({type: UserAction.USER_FOLLOW, payload});
    await UserAPI.follow(payload);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const unfollowUser = (
  payload: UserModel.UnfollowUserAction['payload'],
): AppThunk<void> => async (dispatch) => {
  try {
    dispatch({type: UserAction.USER_UNFOLLOW, payload});
    await UserAPI.unfollow(payload);
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadUser = (userId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const {user} = await UserAPI.getInfo(userId);
    dispatch({
      type: UserAction.GET_USER_INFO,
      payload: userResponseToModel(user),
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadQRCode = (userId: string): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await UserAPI.getQRCode(userId);
    dispatch({
      type: UserAction.GET_QR_CODE,
      payload: {
        image: res.qr_url,
        link: res.invite_url,
        invite: res.invite_code,
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const updateProfile = (
  userId: string,
  pic: Blob | null,
  bio: string | null,
  name: string | null,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch({
    type: UserAction.UPDATE_USER_PROFILE,
    payload: {
      userId,
      picture: pic,
      bio,
      name,
    },
  });
  try {
    await UserAPI.updateProfile(userId, pic, bio, name);
    dispatch(loadMyInfo());
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadFollowerList = (
  userId: string,
  refresh: boolean,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch({type: UserAction.GET_FOLLOWER_LIST});
  const cursor = refresh ? null : getState().user.followers.cursor;

  try {
    const res = await UserAPI.getFollowerList(userId, cursor);
    dispatch({
      type: UserAction.GET_FOLLOWER_LIST_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        list: (res.users || []).map((user) => userResponseToModel(user)),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadFolloweeList = (
  userId: string,
  refresh: boolean,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch({type: UserAction.GET_FOLLOWEE_LIST});
  const cursor = refresh ? null : getState().user.followees.cursor;

  try {
    const res = await UserAPI.getFolloweeList(userId, cursor);
    dispatch({
      type: UserAction.GET_FOLLOWEE_LIST_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        list: (res.users || []).map((user) => userResponseToModel(user)),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadLinkingStatus = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await UserAPI.getLinkingStatus();
    dispatch({
      type: UserAction.GET_LINKING_STATUS,
      payload: {
        phone: res.status.phone_number,
        facebook: res.status.facebook,
        google: res.status.google,
        line: res.status.line,
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const linkThirdPartyLogin = (
  provider: LoginProvider,
  token: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await UserAPI.linkAccount(provider, token);
    dispatch({
      type: UserAction.LINK_THIRD_PARTY,
      payload: {
        provider,
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const unlinkThirdPartyLogin = (provider: LoginProvider): AppThunk<Promise<void>> => async (
  dispatch,
) => {
  try {
    await UserAPI.unlinkAccount(provider);
    dispatch(logout());
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadActivityAndRecommendation = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await UserAPI.getActivity();
    dispatch({
      type: UserAction.GET_ACTIVITY,
      payload: res.activities
        .map((activity) => ({
          type: activity.record.type,
          following: activity.is_follow,
          cover: activity.post_picture,
          streamerName: activity.streamer_name,
          streamerPicture: activity.streamer_picture,
          streamerId: activity.record.user_id,
          createdAt: activity.record.created_at,
          targetId: activity.record.object_id,
        }))
        .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()),
    });
    dispatch({
      type: UserAction.GET_RECOMMENDATIONS,
      payload: res.recommended_streamer_list.map((streamer) => ({
        type: streamer.streamer_type,
        userId: streamer.user_id,
        followers: streamer.follower_count,
        name: streamer.name,
        picture: streamer.picture,
        following: false,
      })),
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};
