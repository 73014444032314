import {Chip} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React from 'react';

import {WatchMode} from 'model/livestream/LivestreamTypes';

import Typography from './i18n/Typography';

interface WatchModeLabelProps {
  mode: WatchMode;
}

function WatchModeLabel({mode}: WatchModeLabelProps) {
  const styles = useThemeStyles();

  const style =
    mode === WatchMode.Free
      ? styles.freeChip
      : mode === WatchMode.OM
      ? styles.omChip
      : styles.ooChip;

  return (
    <Chip
      label={<Typography>{`enum.mode.${mode}`}</Typography>}
      size="small"
      className={`smallChip ${style}`}
      variant="outlined"
    />
  );
}

export default WatchModeLabel;
