import {Box} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React from 'react';

import {Rating} from 'model/livestream/LivestreamTypes';

import Typography from './i18n/Typography';

interface RatingLabelProps {
  rating: Rating;
}

function RatingLabel({rating}: RatingLabelProps) {
  const styles = useThemeStyles();
  const style =
    rating === Rating.G
      ? styles.ratingG
      : rating === Rating.NC17
      ? styles.ratingNC
      : styles.ratingPG;

  return (
    <Box className={style} textAlign="center">
      <Typography variant="body2">{`enum.rating.${rating}`}</Typography>
    </Box>
  );
}

export default RatingLabel;
