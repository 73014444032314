import {FormControl, Grid, MenuItem, Select} from '@material-ui/core';
import React from 'react';

import TextField from './i18n/TextField';
import Typography from './i18n/Typography';

export enum CountryCode {
  Taiwan = '+886',
  China = '+86',
}

interface PhoneInputProps {
  countryCode: CountryCode;
  phone: string;
  onChangeCountryCode(code: CountryCode): void;
  onChangePhone(phone: string): void;
}

function PhoneInput({countryCode, phone, onChangeCountryCode, onChangePhone}: PhoneInputProps) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={5} md={1}>
        <FormControl variant="outlined" fullWidth className="countryCodeInput">
          <Select
            color="secondary"
            value={countryCode}
            onChange={(e) => onChangeCountryCode(e.target.value as CountryCode)}
            name="country-code"
            renderValue={(value: any) => value}>
            <MenuItem value={CountryCode.Taiwan}>
              <Typography>country.code.taiwan</Typography>
            </MenuItem>
            <MenuItem value={CountryCode.China}>
              <Typography>country.code.china</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7} md={11}>
        <TextField
          required
          fullWidth
          variant="outlined"
          color="secondary"
          className="phoneInput"
          placeholder="input.phone_placeholder"
          type="tel"
          autoComplete="tel-national"
          value={phone}
          onChange={(e) => onChangePhone(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

export default PhoneInput;
