import {Box, IconButton} from '@material-ui/core';
import {ErrorCode} from 'api';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppDispatch} from 'model/helper';
import AgoraRTC from 'model/livestream/AgoraRTC';
import {
  loadLivestream,
  updateLocalSteam,
  watchLivestream,
  updateWatchingStatus,
} from 'model/livestream/LivestreamAction';
import {livestreamInfoSelector, livestreamSelector} from 'model/livestream/LivestreamSelector';
import {WatchMode} from 'model/livestream/LivestreamTypes';
import {getMyInfo} from 'model/user/UserSelector';

import Icon from 'components/Icons';
import VodPlayer from 'components/VodPlayer';
import Typography from 'components/i18n/Typography';

import {ReactComponent as CloseIcon} from 'assets/icon/light/times.svg';

function waitUntil(d: Date) {
  return new Promise<void>((resolve) => {
    const ms = d.getTime() - Date.now();
    if (ms > 0) {
      setTimeout(resolve, ms);
    } else {
      resolve();
    }
  });
}

interface LivestreamPlayerProps {
  streamId: string;
  mode: WatchMode;
  muted: boolean;
  onExit: () => void;
  onError: (err: ErrorCode) => void;
  onChangeMode: (mode: WatchMode) => void;
}

function LivestreamPlayer({
  streamId,
  mode,
  muted,
  onExit,
  onError,
  onChangeMode,
}: LivestreamPlayerProps) {
  const [playerStop, setPlayerStop] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getMyInfo);
  const livestreamInfo = useSelector(livestreamInfoSelector);
  const livestream = useSelector(livestreamSelector);
  const blurMode = useMemo(() => {
    return livestreamInfo?.status !== WatchMode.Free && livestreamInfo?.status !== mode;
  }, [livestreamInfo?.status, mode]);
  const canOpenCamera = useRef(false);

  const handleAutoPlayFailed = () => {
    setPlayerStop(true);
  };

  const handleResumePlayer = () => {
    setPlayerStop(false);
    AgoraRTC.instance.resume();
  };

  useEffect(() => {
    let freeMode = false;
    let lastMode = mode;

    dispatch(loadLivestream(streamId)).then((res) => {
      lastMode = res.status;
      freeMode = res.status !== mode && res.status !== WatchMode.Free;

      dispatch(
        watchLivestream(streamId, mode, onError, () => {
          if (mode === WatchMode.OO) {
            canOpenCamera.current = true;
          }
        }),
      );
    });

    const timer = window.setInterval(() => {
      dispatch(loadLivestream(streamId)).then((res) => {
        if (freeMode && res.status !== lastMode) {
          dispatch(updateWatchingStatus(res.status, res.channelId));
        }
        lastMode = res.status;
        freeMode = res.status !== mode && res.status !== WatchMode.Free;
      });
    }, 10000);

    return () => {
      canOpenCamera.current = true;
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, streamId, mode]);

  useEffect(() => {
    if (muted) {
      AgoraRTC.instance.muteRemoteStream();
    } else {
      AgoraRTC.instance.unmuteRemoteStream();
    }
  }, [muted]);

  useEffect(() => {
    if (!livestream?.channelId || livestreamInfo?.vod) {
      return;
    }

    (async () => {
      await AgoraRTC.instance.destroy();

      if (livestream.mode === WatchMode.OO) {
        if (!blurMode && canOpenCamera.current) {
          try {
            await AgoraRTC.instance.prepare(user.id, livestream.mode, 'camera');

            dispatch(updateLocalSteam(true));
          } catch (err) {
            dispatch(updateLocalSteam(false));
          }
        }
      }

      if (livestreamInfo?.downtime) {
        await waitUntil(livestreamInfo.downtime);
      }

      try {
        await AgoraRTC.instance.join(user.id, livestream.channelId!, livestream.mode, () => {
          AgoraRTC.instance
            .play('player', handleAutoPlayFailed)
            .catch((err) => console.error('play occurs some err', err));
        });
      } catch (err) {
        console.error(err);
      }
    })();

    return () => {
      AgoraRTC.instance.destroy();
    };

    // eslint-disable-next-line
  }, [livestream?.channelId, livestream?.mode, mode, user.id]);

  return (
    <Box className="overlay">
      {playerStop && (
        <Box
          zIndex="49"
          position="fixed"
          top="0"
          left="0"
          display="flex"
          width="100vw"
          height="100vh"
          justifyContent="center"
          alignItems="center"
          onClick={handleResumePlayer}>
          <Typography>{'player.resume'}</Typography>
        </Box>
      )}
      {livestreamInfo && livestreamInfo.vod ? (
        <VodPlayer src={livestreamInfo.vod.url} offset={livestreamInfo.vod.offset} muted={muted} />
      ) : (
        <div id="player" className={`full ${blurMode ? 'blur' : ''}`} />
      )}

      <Box position="absolute" right={0} top={6} zIndex={2}>
        <IconButton onClick={onExit}>
          <Icon fontSize="large" svg={CloseIcon} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default LivestreamPlayer;
