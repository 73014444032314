import i18n from 'context/Locale';
import {combineReducers} from 'redux';

import {RootActions} from 'model/helper';
import {UserAction} from 'model/user/UserTypes';

import Alarm from './Alarm';
import {MessageAction, MessageModel, MessageType} from './MessageTypes';

const initialState: MessageModel.State = {
  mailbox: {
    cursor: null,
    loading: true,
    list: [],
    cache: {},
  },
  conversation: {},
  channel: {
    sub: null,
    listeners: [],
  },
  alarm: {
    sound: new Alarm(),
    timer: {},
  },
};

function mailbox(state = initialState.mailbox, action: RootActions): MessageModel.State['mailbox'] {
  switch (action.type) {
    case MessageAction.GET_MAILBOX:
      return {
        ...state,
        loading: true,
      };
    case MessageAction.GET_MAILBOX_S: {
      const cache = {...state.cache};
      const list = new Set(state.list);
      action.payload.items.forEach((item) => {
        list.add(item.userId);
        cache[item.userId] = {
          ...(cache[item.userId] || {}),
          ...item,
        };
      });

      return {
        loading: false,
        cursor: action.payload.cursor,
        list: Array.from(list),
        cache,
      };
    }
    case MessageAction.GET_MESSENGER: {
      const cache: MessageModel.Messenger | undefined = state.cache[action.payload.userId];
      return {
        ...state,
        list:
          state.list.indexOf(action.payload.userId) === -1
            ? [action.payload.userId, ...state.list]
            : state.list,
        cache: {
          ...state.cache,
          [action.payload.userId]: {
            userId: cache?.userId || action.payload.userId,
            userName: cache?.userName || action.payload.userName,
            userPicture: cache?.userPicture || action.payload.userPicture,
            price: cache?.price || action.payload.price,
            lastMessage: cache?.lastMessage || action.payload.lastMessage,
            lastMessageTime: cache?.lastMessageTime || action.payload.lastMessageTime,
            numUnread: cache?.numUnread || action.payload.numUnread,
            isCustomerService: cache?.isCustomerService || action.payload.isCustomerService,
            freeLimit: cache?.freeLimit || action.payload.freeLimit,
          },
        },
      };
    }
    case MessageAction.RECEIVE_MESSAGE: {
      const cache: MessageModel.Messenger | undefined = state.cache[action.payload.userId];
      const isFromSelf = action.payload.userId !== action.payload.message.senderId;
      const list = new Set(state.list);
      list.delete(action.payload.userId);

      return {
        ...state,
        list: [action.payload.userId, ...Array.from(list)],
        cache: {
          ...state.cache,
          [action.payload.userId]: {
            userId: cache?.userId || action.payload.userId,
            userName: cache?.userName,
            userPicture: cache?.userPicture,
            price: cache?.price,
            lastMessage: i18n.t(`message.send.${action.payload.message.type}`, {
              x:
                action.payload.message.type === MessageType.Chat
                  ? action.payload.message.message
                  : cache?.userName || '',
            }),
            lastMessageTime: action.payload.message.createdAt,
            numUnread: (cache?.numUnread || 0) + (isFromSelf ? 0 : 1),
            isCustomerService: false,
            freeLimit: isFromSelf ? Math.max(0, (cache.freeLimit ?? 0) - 1) : cache.freeLimit ?? 0,
          },
        },
      };
    }
    case UserAction.USER_LOGOUT:
      return initialState.mailbox;
    default:
      return state;
  }
}

function conversation(
  state = initialState.conversation,
  action: RootActions,
): MessageModel.State['conversation'] {
  switch (action.type) {
    case MessageAction.GET_MESSAGES:
      return {
        ...state,
        [action.payload]: {
          loading: true,
          cursor: state[action.payload]?.cursor || null,
          messages: state[action.payload]?.messages || [],
        },
      };
    case MessageAction.GET_MESSAGES_S:
      return {
        ...state,
        [action.payload.userId]: {
          loading: false,
          cursor: action.payload.cursor,
          messages: action.payload.refresh
            ? action.payload.items
            : state[action.payload.userId].messages.concat(action.payload.items),
        },
      };
    case MessageAction.RECEIVE_MESSAGE:
      return {
        ...state,
        [action.payload.userId]: {
          ...(state[action.payload.userId] || {}),
          messages: [action.payload.message, ...(state[action.payload.userId]?.messages || [])],
        },
      };
    case MessageAction.UNLOCK_MESSAGE:
      return {
        ...state,
        [action.payload.senderId]: {
          ...(state[action.payload.senderId] || {}),
          messages: (state[action.payload.senderId]?.messages || []).map((msg) => {
            if (msg.id === action.payload.messageId) {
              return {
                ...msg,
                unlocked: true,
                message: action.payload.url,
              };
            }
            return msg;
          }),
        },
      };
    case UserAction.USER_LOGOUT:
      return initialState.conversation;
    default:
      return state;
  }
}

function channel(state = initialState.channel, action: RootActions): MessageModel.State['channel'] {
  switch (action.type) {
    case MessageAction.CREATE_PERSONAL_CHANNEL:
      return {
        ...state,
        sub: action.payload,
      };
    case MessageAction.LISTEN_PERSONAL_CHANNEL:
      return {
        ...state,
        listeners:
          state.listeners.indexOf(action.payload) === -1
            ? state.listeners.concat(action.payload)
            : state.listeners,
      };
    case MessageAction.UNLISTEN_PERSONAL_CHANNEL:
      return {
        ...state,
        listeners: state.listeners.filter((item) => item !== action.payload),
      };
    case UserAction.USER_LOGOUT: {
      state.sub?.unsubscribeAll();
      state.sub?.stop();
      return initialState.channel;
    }
    default:
      return state;
  }
}

function alarm(state = initialState.alarm, action: RootActions): MessageModel.State['alarm'] {
  switch (action.type) {
    case MessageAction.ALERT_NEW_MESSAGE: {
      return {
        ...state,
        timer: {
          ...state.timer,
          [action.payload]: window.setTimeout(() => {
            state.sound.play();
          }, 1000),
        },
      };
    }
    case MessageAction.READ_MESSAGE: {
      if (state.timer[action.payload]) {
        clearTimeout(state.timer[action.payload]);
      }
      return {
        ...state,
        timer: {
          ...state.timer,
          [action.payload]: undefined,
        },
      };
    }
    case UserAction.USER_LOGOUT: {
      for (const id in state.timer) {
        clearTimeout(state.timer[id]);
      }
      return initialState.alarm;
    }
    default:
      return state;
  }
}

export default combineReducers<MessageModel.State>({
  mailbox,
  conversation,
  channel,
  alarm,
});
