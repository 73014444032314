import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Toolbar,
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {
  linkThirdPartyLogin,
  loadLinkingStatus,
  unlinkThirdPartyLogin,
} from 'model/user/UserActions';
import {getLinkingStatus, getMyInfo} from 'model/user/UserSelector';
import {LoginProvider} from 'model/user/UserTypes';

import {useScript} from 'utils/hook';
import {getSearchQuery} from 'utils/string';

import BackButton from 'components/BackButton';
import Typography from 'components/i18n/Typography';

function ProfileLinkedPage() {
  const dispatch = useDispatch<AppDispatch>();
  const linkingStatus = useSelector(getLinkingStatus);
  const user = useSelector(getMyInfo);
  const history = useHistory();

  const [fbLoaded] = useScript('https://connect.facebook.net/en_US/sdk.js');
  const [gapiLoaded] = useScript('https://apis.google.com/js/api:client.js');
  const lineToken = getSearchQuery(history.location.search, 'code');
  const [unlinkConfirm, setUnlinkConfirm] = useState<LoginProvider | undefined>(undefined);

  useEffect(() => {
    if (!lineToken) {
      return;
    }

    dispatch(linkThirdPartyLogin('line', lineToken));
    history.replace({
      search: '',
    });

    // eslint-disable-next-line
  }, [lineToken]);

  useEffect(() => {
    dispatch(loadLinkingStatus());
  }, [dispatch]);

  useEffect(() => {
    if (!gapiLoaded) {
      return;
    }

    gapi.load('auth2', function () {
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      const auth2 = gapi.auth2.init({
        cookiepolicy: 'single_host_origin',
      } as any);
      auth2.attachClickHandler(
        document.getElementById('google_login'),
        {},
        (googleUser) => {
          dispatch(linkThirdPartyLogin('google', googleUser.getAuthResponse().id_token));
        },
        (error: any) => {},
      );
    });
  }, [gapiLoaded, dispatch]);

  const handleFBLogin = useCallback(() => {
    if (!fbLoaded) {
      return;
    }

    FB.login((response) => {
      if (response.authResponse) {
        dispatch(linkThirdPartyLogin('facebook', response.authResponse.accessToken));
      }
    });
  }, [fbLoaded, dispatch]);

  const handleLineLogin = useCallback(() => {
    const id =
      (document.querySelector('meta[name="line-signin-client_id"]') as HTMLMetaElement)?.content ??
      '';
    const redirect = encodeURIComponent(`${window.location.origin}/profile/setting/link`);

    const url =
      'https://access.line.me/oauth2/v2.1/authorize?' +
      'response_type=code' +
      `&client_id=${id}` +
      `&redirect_uri=${redirect}` +
      `&state=12345abcde` +
      `&scope=openid`;

    window.location.href = url;
  }, []);

  const handleUnlink = useCallback(() => {
    if (unlinkConfirm) {
      dispatch(unlinkThirdPartyLogin(unlinkConfirm));
    }
  }, [unlinkConfirm, dispatch]);

  return (
    <Box className="full">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/profile/setting" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.linked
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <Box overflow="auto">
        <Box display="flex" px={2} my={2} alignItems="center">
          <Box mr={4}>
            <Typography>label.linking</Typography>
          </Box>
          <Typography color="textSecondary">paragraph.linking</Typography>
        </Box>

        <Box display="flex" px={2} my={2} alignItems="center">
          <Box mr={4}>
            <Typography>label.user_name</Typography>
          </Box>

          <Box flex={1}>
            <Typography color="textSecondary">{user.name}</Typography>
            <Divider />
          </Box>
        </Box>

        <Box m={2}>
          <Paper>
            <Box
              display="flex"
              px={2}
              justifyContent="space-between"
              height={50}
              alignItems="center">
              <Typography>label.phone</Typography>
              <Typography>{linkingStatus.phone}</Typography>
            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper>
            <Box
              display="flex"
              px={2}
              justifyContent="space-between"
              height={50}
              alignItems="center">
              <Typography>label.facebook</Typography>
              <Button
                variant="outlined"
                color={linkingStatus.facebook ? 'default' : 'primary'}
                onClick={
                  linkingStatus.facebook ? () => setUnlinkConfirm('facebook') : handleFBLogin
                }>
                <Typography>{linkingStatus.facebook ? 'input.linked' : 'input.link'}</Typography>
              </Button>
            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper>
            <Box
              display="flex"
              px={2}
              justifyContent="space-between"
              height={50}
              alignItems="center">
              <Typography>label.google</Typography>
              <Button
                variant="outlined"
                color="primary"
                disabled={linkingStatus.google}
                id="google_login">
                <Typography>{linkingStatus.google ? 'input.linked' : 'input.link'}</Typography>
              </Button>
            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper>
            <Box
              display="flex"
              px={2}
              justifyContent="space-between"
              height={50}
              alignItems="center">
              <Typography>label.line</Typography>
              <Button
                variant="outlined"
                color="primary"
                disabled={linkingStatus.line}
                onClick={handleLineLogin}>
                <Typography>{linkingStatus.line ? 'input.linked' : 'input.link'}</Typography>
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog open={unlinkConfirm !== undefined} onClose={() => setUnlinkConfirm(undefined)}>
        <DialogTitle>
          <Typography variant="h6">{`title.unlink.${unlinkConfirm || ''}`}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography component="p" variant="body2">
            {`paragraph.unlink.${unlinkConfirm || ''}`}
          </Typography>
          <Typography component="p" variant="body2">
            paragraph.unlink.agreement
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setUnlinkConfirm(undefined)}>
            <Typography>common.cancel</Typography>
          </Button>
          <Button color="primary" autoFocus onClick={handleUnlink}>
            <Typography>common.agree</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ProfileLinkedPage;
