import {Button} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {useScript} from 'utils/hook';
import {genSearchQuery} from 'utils/string';

import Icon from 'components/Icons';
import Typography from 'components/i18n/Typography';

import {ReactComponent as GoogleIcon} from 'assets/icon/brand/google.svg';

interface GoogleLoginButtonProps {
  text: string;
}

function GoogleLoginButton({text}: GoogleLoginButtonProps) {
  const styles = useThemeStyles();
  const [gapiLoaded] = useScript('https://apis.google.com/js/api:client.js');
  const history = useHistory();

  useEffect(() => {
    if (!gapiLoaded) {
      return;
    }

    gapi.load('auth2', function () {
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      const auth2 = gapi.auth2.init({
        cookiepolicy: 'single_host_origin',
      } as any);
      auth2.attachClickHandler(
        document.getElementById('google_login'),
        {},
        (googleUser) => {
          history.push({
            pathname: '/auth/google',
            search: history.location.search,
            state: {
              token: googleUser.getAuthResponse().id_token,
            },
          });
        },
        (error: any) => {
          history.push({
            pathname: '/auth/google',
            search: genSearchQuery(history.location.search, 'error', error.error),
          });
          // console.error(typeof error);
        },
      );
    });
  }, [history, gapiLoaded]);

  return (
    <Button
      id="google_login"
      variant="contained"
      size="large"
      startIcon={<Icon svg={GoogleIcon} />}
      fullWidth
      className={styles.google}>
      <Typography>{text}</Typography>
    </Button>
  );
}

export default GoogleLoginButton;
