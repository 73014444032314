import {AppBar, Box, Toolbar, Typography as Text} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {getUserById} from 'model/user/UserSelector';

import BackButton from 'components/BackButton';
import PointLabel from 'components/PointLabel';

import UserStoryList from './UserStoryList';

interface UserStoryPageProps {
  prev: string;
}

function UserStoryPage({prev}: UserStoryPageProps) {
  const {id} = useParams<{id: string}>();

  const user = useSelector(getUserById)(id);

  return (
    <Box className="full" display="flex" flexDirection="column" flex="1">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to={prev} />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Text variant="h6" component="h2">
              {user?.name ?? ''}
            </Text>
          </Box>
          <PointLabel flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <UserStoryList userId={id} />
    </Box>
  );
}

export default UserStoryPage;
