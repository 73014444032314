import {Box, ButtonBase} from '@material-ui/core';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import {useThemeStyles} from 'context/Theme';
import React from 'react';

import {MediaExtension, PostModel} from 'model/post/PostTypes';

import {ReactComponent as CloneIcon} from 'assets/icon/solid/clone.svg';

import CardMediaPlayer from './CardMediaPlayer';
import Icon from './Icons';

interface StoryCellProps {
  article?: PostModel.Article;
  flex?: number;
  style?: CSSProperties;
  onClick?: () => void;
  className?: string;
  large?: boolean;
}

function StoryCell({article, flex = 1, style, className, onClick, large = false}: StoryCellProps) {
  const styles = useThemeStyles();

  if (!article) {
    return <Box flex={1} style={style} />;
  }

  return (
    <Box
      flex={flex}
      display="flex"
      position="relative"
      style={style}
      className={className}
      justifyContent="center"
      alignItems="center">
      {large && article.media[0].ext === MediaExtension.MP4 ? (
        <CardMediaPlayer
          className={`fullMedia ${styles.storyCell}`}
          src={article.media[0].url}
          preview={article.cover.compressed}
          muted
          autoPlay
          loop
          playIcon={false}
          showLength={false}
          volumeIcon={false}
        />
      ) : (
        <img
          alt="cover"
          src={large ? article.cover.origin : article.cover.compressed}
          className={`fullMedia ${styles.storyCell}`}
        />
      )}

      {article.media.length > 1 && (
        <Box position="absolute" top={5} right={5}>
          <Icon svg={CloneIcon} fontSize="small" className="mirror" />
        </Box>
      )}

      <ButtonBase focusRipple component="div" className="overlay" onClick={onClick} />
    </Box>
  );
}

export default StoryCell;
