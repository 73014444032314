import {LoginProvider} from 'model/user/UserTypes';

interface AnalysisConfig {
  id: string;
}

declare global {
  interface Window {
    gtag: any;
  }
}

export default class Analysis {
  private static readonly GA_MEASUREMENT_ID = process.env.GA ?? '';
  private static userId = '';

  public static setConfig(config: AnalysisConfig) {
    Analysis.userId = config.id;
    window.gtag('set', {user_id: config.id});
  }

  public static view() {
    window.gtag('config', Analysis.GA_MEASUREMENT_ID, {
      page_path: window.location.pathname,
    });
  }

  public static sendError(err: any, fatal: boolean = false) {
    window.gtag('event', 'exception', {
      description: err,
      fatal, // set to true if the error is fatal
    });
  }

  public static login(method: 'phone' | LoginProvider) {
    window.gtag('event', 'login', {event_category: 'engagement', event_label: method});
  }

  public static signup() {
    window.gtag('event', 'sign_up');
  }

  public static purchase(
    item: 'stream-gift' | 'post-gift' | 'post-content' | 'message-gift' | 'message',
    point: number,
  ) {
    window.gtag('event', 'purchase', {
      event_category: 'ecommerce',
      event_label: item,
      value: point,
    });
  }

  public static search(label: string) {
    window.gtag('event', 'search', {
      event_category: 'engagement',
      event_label: label,
    });
  }
}
