import {Box, Typography as Text} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppDispatch} from 'model/helper';
import {closePaymentDrawer, openPaymentDrawer, updateWallet} from 'model/wallet/WalletAction';
import {getBalance} from 'model/wallet/WalletSelector';

import {format} from 'utils/number';

interface PointLabelProps {
  flex?: number | string;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
}

function PointLabel({flex, justifyContent}: PointLabelProps) {
  const dispatch = useDispatch<AppDispatch>();
  const balance = useSelector(getBalance);

  const handleOpenPayment = useCallback(() => {
    dispatch(
      openPaymentDrawer(() => {
        dispatch(closePaymentDrawer());
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateWallet());
  }, [dispatch]);

  return (
    <Box flex={flex} display="flex" justifyContent={justifyContent} alignItems="center">
      <div className="diamond" />
      <Text variant="body2" onClick={handleOpenPayment}>
        {format(balance)}
      </Text>
    </Box>
  );
}

export default PointLabel;
