import {
  AppBar,
  Box,
  Paper,
  InputAdornment,
  Button,
  Tabs,
  Tab,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Prompt, useHistory, useLocation} from 'react-router-dom';
import {useLastLocation} from 'react-router-last-location';
import SwipeableViews from 'react-swipeable-views';
import AutoSizer from 'react-virtualized-auto-sizer';
import {useDebounce} from 'use-debounce/lib';

import {loadRecommendedPosts, loadRecommendedTags} from 'model/explore/ExploreAction';
import {
  exploreArticlesSelector,
  getTags,
  hasExploreArticlesNextPage,
  isExploreArticlesLoading,
} from 'model/explore/ExploreSelector';
import {SearchType} from 'model/explore/ExploreTypes';
import {AppDispatch, StorageKeys} from 'model/helper';
import {PostModel} from 'model/post/PostTypes';
import {authorized} from 'model/user/UserSelector';

import {deleteSearchQuery, genSearchQuery, getSearchQuery} from 'utils/string';

import Icon from 'components/Icons';
import TabPanel from 'components/TabPanel';
import LoginDialog from 'components/auth/LoginDialog';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

import {ReactComponent as SearchIcon} from 'assets/icon/light/search.svg';

import SearchList from './SearchList';
import StoryGallery from './StoryGallery';

function ExplorePage() {
  const styles = useThemeStyles();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const lastLocation = useLastLocation();
  const tab = useMemo(() => {
    return getSearchQuery(location.search, StorageKeys.tab);
  }, [location.search]);
  const tabIndex = tab === 'hot' ? 0 : tab === 'user' ? 1 : 2;
  const showTabs = tab !== '';
  const search = useMemo(() => {
    return getSearchQuery(location.search, StorageKeys.search);
  }, [location.search]);

  const tags = useSelector(getTags);
  const articles = useSelector(exploreArticlesSelector);
  const isLoading = useSelector(isExploreArticlesLoading);
  const hasNextPage = useSelector(hasExploreArticlesNextPage);
  const auth = useSelector(authorized);

  const [loginDialog, setLoginDialog] = useState<{open: boolean; redirect: string}>({
    open: false,
    redirect: '/',
  });
  const [input, setInput] = useState(search);
  const [delayedInput] = useDebounce(input, 1000);

  useEffect(() => {
    if (delayedInput) {
      history.replace({
        pathname: '/explore',
        search: genSearchQuery(history.location.search, StorageKeys.search, delayedInput),
      });
    }
  }, [history, delayedInput]);

  useEffect(() => {
    if (lastLocation && lastLocation.pathname === '/explore/recommended') {
      return;
    }

    dispatch(loadRecommendedPosts(true));
    dispatch(loadRecommendedTags());

    // eslint-disable-next-line
  }, []);

  const closeTab = useCallback(() => {
    setInput('');
    history.replace({
      pathname: '/explore',
      search: deleteSearchQuery(
        deleteSearchQuery(history.location.search, StorageKeys.tab),
        StorageKeys.search,
      ),
    });
  }, [history]);

  const handleChangeTab = useCallback(
    (_, v: number) => {
      if (v === 0) {
        history.replace({
          pathname: '/explore',
          search: genSearchQuery(history.location.search, StorageKeys.tab, 'hot'),
        });
      } else if (v === 1) {
        history.replace({
          pathname: '/explore',
          search: genSearchQuery(history.location.search, StorageKeys.tab, 'user'),
        });
      } else {
        history.replace({
          pathname: '/explore',
          search: genSearchQuery(history.location.search, StorageKeys.tab, 'tag'),
        });
      }
    },
    [history],
  );

  const toRecommended = useCallback(
    (item: PostModel.Article | undefined) => {
      return () => {
        if (item) {
          history.push({
            pathname: '/explore/recommended',
            state: {start: item.id},
          });
        }
      };
    },
    [history],
  );

  const toTagPage = useCallback(
    (keyword: string) => {
      return () => {
        history.push(`/explore/tag/${keyword}`);
      };
    },
    [history],
  );

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadRecommendedPosts(false));
  }, [isLoading, hasNextPage, dispatch]);

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <Paper component="header" square>
        <Box display="flex" m={2}>
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            size="small"
            className={styles.search}
            placeholder="common.search"
            onFocus={() => {
              if (!tab) {
                handleChangeTab(null, 0);
              }
            }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon svg={SearchIcon} fontSize="small" color="disabled" />
                </InputAdornment>
              ),
            }}
          />

          {showTabs && (
            <Button size="small" onClick={closeTab}>
              <Typography>common.cancel</Typography>
            </Button>
          )}
        </Box>

        <Box className="horizontalScroll" ml={2} pr={2} mb={1} display={showTabs ? 'none' : 'flex'}>
          {tags.map((tag) => (
            <Box mr={1} key={tag}>
              <Button variant="outlined" onClick={toTagPage(tag)}>
                <Text>{tag}</Text>
              </Button>
            </Box>
          ))}
        </Box>

        {showTabs && (
          <AppBar position="static" color="default">
            <Tabs variant="fullWidth" value={tabIndex} onChange={handleChangeTab}>
              <Tab value={0} label={<Typography>label.hot</Typography>} aria-label="hot" />
              <Tab value={1} label={<Typography>label.user</Typography>} aria-label="account" />
              <Tab value={2} label={<Typography>label.tag</Typography>} aria-label="tag" />
            </Tabs>
          </AppBar>
        )}
      </Paper>
      <Box flex={1} hidden={!showTabs}>
        <AutoSizer>
          {({height, width}) => {
            const style: CSSProperties = {width};
            return (
              <SwipeableViews index={tabIndex} style={style} slideStyle={style} disabled>
                <TabPanel value={tabIndex} index={0}>
                  <SearchList
                    width={width}
                    height={height}
                    keyword={search}
                    type={SearchType.All}
                    showSuggested
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <SearchList
                    width={width}
                    height={height}
                    keyword={search}
                    type={SearchType.Streamer}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <SearchList
                    width={width}
                    height={height}
                    keyword={search}
                    type={SearchType.Tag}
                  />
                </TabPanel>
              </SwipeableViews>
            );
          }}
        </AutoSizer>
      </Box>
      <Box flex="1" mx={2} mt={1} hidden={showTabs}>
        <StoryGallery
          items={articles}
          hasNextPage={hasNextPage}
          loadMore={loadMore}
          onClickItem={toRecommended}
        />
      </Box>

      <Prompt
        when={!auth}
        message={(location) => {
          setLoginDialog({open: true, redirect: location.pathname});
          return location.pathname.startsWith('/auth');
        }}
      />
      <LoginDialog
        open={loginDialog.open}
        onClose={() => setLoginDialog((v) => ({...v, open: false}))}
        onLogin={() => {
          history.push(loginDialog.redirect ?? '/');
        }}
      />
    </Box>
  );
}

export default ExplorePage;
