import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Toolbar,
  Typography as Text,
} from '@material-ui/core';
import React, {useState} from 'react';

import BackButton from 'components/BackButton';
import Typography from 'components/i18n/Typography';

const questions = [
  {
    title: `如何註冊${process.env.REACT_APP_TITLE_SHORT}？`,
    content: '目前提供手機號碼 / Facebook / Google / Line四種註冊方式。',
  },
  {
    title: `如何使用${process.env.REACT_APP_TITLE_SHORT}？`,
    content: `目前全面使用網頁版，無需下載即可享受。<br />
您也可將網頁加到桌面，操作方式如同APP。<br />
蘋果系統→使用Safari瀏覽器開啟https://app.instg.live/，點擊　 ，選擇加入主畫面，新增即可。<br />
安卓系統→使用Chrome瀏覽器開啟https://app.instg.live/，點擊下方免下載安裝，新增即可。`,
  },
  {
    title: `如何成為${process.env.REACT_APP_TITLE_SHORT}女郎？`,
    content: `目前僅提供官方認證的${process.env.REACT_APP_TITLE_SHORT}女郎發佈貼文，若有意願成為${process.env.REACT_APP_TITLE_SHORT}女郎，請聯繫${process.env.REACT_APP_TITLE_SHORT}客服LINE@：@INSTG5353。`,
  },
  {
    title: `如何在${process.env.REACT_APP_TITLE_SHORT}儲值？`,
    content: `目前提供信用卡、網路轉帳、超商代繳等儲值方式。 <br />
點擊右上角鑽石進入購買鑽石頁面，選擇欲購買金額，再選擇付款方式，付款成功即收到鑽石。`,
  },
  {
    title: `如何推廣${process.env.REACT_APP_TITLE_SHORT}？`,
    content:
      '邀請您的好友掃描您的推廣QR Code或在註冊時填入您的邀請碼，即可成為您的下屬，獲得額外的金幣分潤，金幣可轉換為鑽石使用。',
  },
  {
    title: `如何查詢在${process.env.REACT_APP_TITLE_SHORT}上的交易記錄？`,
    content: '請先至個人頁面點擊左上角設定，再進入錢包日誌，查詢使用狀況。',
  },
];

function FAQPage() {
  const [expanded, setExpanded] = useState<Set<number>>(new Set());

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/profile/setting" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.faq
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>

      <Box m={2}>
        {questions.map((q, index) => (
          <Accordion
            key={index}
            expanded={expanded.has(index)}
            onChange={(_, v) => {
              setExpanded((set) => {
                if (v) {
                  set.add(index);
                  return new Set(set);
                } else {
                  set.delete(index);
                  return new Set(set);
                }
              });
            }}>
            <AccordionSummary
              expandIcon={<Text variant="h2">{expanded.has(index) ? '-' : '+'}</Text>}>
              <Typography>{q.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Text dangerouslySetInnerHTML={{__html: q.content}} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
}

export default FAQPage;
