import {Action, CombinedState, combineReducers} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';

import explore from 'model/explore/ExploreReducer';
import gift from 'model/gift/GiftReducer';
import livestream from 'model/livestream/LivestreamReducer';
import {LivestreamModel} from 'model/livestream/LivestreamTypes';
import message from 'model/message/MessageReducer';
import {MessageModel} from 'model/message/MessageTypes';
import post from 'model/post/PostReducer';
import {ArticleType, PostModel} from 'model/post/PostTypes';
import user from 'model/user/UserReducer';
import {UserModel} from 'model/user/UserTypes';
import wallet from 'model/wallet/WalletReducer';
import {WalletModel} from 'model/wallet/WalletTypes';

import {ArticleResponse, PostResponse} from 'api/posts';
import {UserResponse} from 'api/user';

import {ExploreModel} from './explore/ExploreTypes';
import {GiftModel} from './gift/GiftTypes';

type ExtractState<P> = P extends CombinedState<infer T> ? T : never;

export const rootReducer = combineReducers({
  user,
  post,
  explore,
  wallet,
  message,
  livestream,
  gift,
});

export type RootState = ExtractState<ReturnType<typeof rootReducer>>;
export type RootActions =
  | UserModel.ActionTypes
  | PostModel.ActionTypes
  | WalletModel.ActionTypes
  | MessageModel.ActionTypes
  | LivestreamModel.ActionTypes
  | ExploreModel.ActionTypes
  | GiftModel.ActionTypes;

export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, RootActions>;
export type AppDispatch = ThunkDispatch<RootState, unknown, RootActions>;

export type PureAction<T extends string> = Action<T>;

export interface PayloadAction<T extends string, P> extends Action<T> {
  readonly type: T;
  readonly payload: P;
}

export interface Subscriber {
  unsubscribeAll(): void;
  stop(): void;
}

export interface Listener {
  (msg: MessageModel.RawStream['msg']): void;
}

export const StorageKeys = {
  token: 't',
  authKey: 'k',
  mode: 'm',
  channelId: 'c',
  tab: 'tab',
  qrCode: 'qr',
  search: 's',
  payment: 'p',
};

export function postResponseToModel(post: PostResponse): PostModel.Article {
  return {
    id: post.post_id,
    userId: post.user_id,
    userName: post.user_name,
    userPicture: post.cover,
    followed: post.is_follow,
    content: post.content.trim().split('\n'),
    cover: {
      compressed: post.post_cover.compressed,
      origin: post.post_cover.origin,
    },
    media: post.media_ids.map((media) => ({
      url: media.file_id,
      ext: media.content_type,
      preview: media.media_picture,
    })),
    mediaLock: post.locked_media_count,
    unlocked: post.is_paid,
    unlockPoint: post.unlock_point,
    numUnlock: post.unlock_count,
    like: post.like_relation,
    numLikes: post.like_count,
    numComments: post.comment_count,
    saved: post.save_post_relation,
    status: post.status,
    createdAt: post.created_at,
    updatedAt: post.updated_at,
    comments: [],
    note: post.note,
  };
}

export function articleResponseToModel(post: ArticleResponse): PostModel.Article {
  return {
    id: post.article_id,
    userId: post.user_id,
    userName: post.user_name,
    userPicture: post.user_cover,
    cover: {
      compressed: '',
      origin: '',
    },
    followed: true,
    content: post.content.trim().split('\n'),
    media: post.media_ids.map((media) => ({
      url: media.file_id,
      ext: media.content_type,
      preview: media.media_picture,
    })),
    mediaLock: post.locked_media_count,
    unlocked: post.is_paid,
    unlockPoint: post.unlock_point,
    numUnlock: post.unlock_count,
    like: post.like_relation,
    numLikes: post.like_count,
    numComments: post.comment_count,
    saved: post.save_post_relation,
    status: post.status,
    createdAt: post.created_at,
    updatedAt: post.updated_at,
    comments: [],
    note: post.note,

    streamInfo:
      post.article_type === ArticleType.Livestream
        ? {
            id: post.article_id,
            title: '',
            name: '',
            rating: post.rating,
            channelId: post.stream_channel_id,
            status: post.stream_status,
            omCharge: post.om_charge,
            ooCharge: post.oo_charge,
            beginAt: null,
            keepAliveAt: null,
            occupied: post.is_occupied,

            userId: post.user_id,
            userName: post.user_name,
            userPicture: post.user_cover,
            cover: post.article_picture,

            announcement: '',
            earning: 0,
            numWatchers: post.current_user,
            score: 0,
            downtime: new Date(),
            resting: false,
            toyEnabled: post.toy_enable !== 0,
          }
        : undefined,
  };
}

export function userResponseToModel(user: UserResponse): UserModel.MemberInfo {
  return {
    id: user.id,
    userId: user.user_id,

    name: user.name,
    bio: user.bio,
    voiceBio: user.voice_bio,
    gender: user.gender,
    // birthday: number;
    picture: user.picture,
    covers: user.covers,
    inviteCode: user.invite_code,
    numFollower: user.follower_count,
    numFollowee: user.followee_count,
    numPosts: user.post_count,
    followed: user.is_follow,

    streamId: user.stream_id,
  };
}
