import {Avatar, Box, Typography as Text, useTheme} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useEffect, useRef} from 'react';
import {VariableSizeList, ListChildComponentProps} from 'react-window';

import {GiftModel} from 'model/gift/GiftTypes';
import {MessageModel, MessageType} from 'model/message/MessageTypes';

import {useWindowSize} from 'utils/hook';
import {calculateStringWidth} from 'utils/string';

import CoronaText from './i18n/CoronaText';
import Typography from './i18n/Typography';

interface ChatroomProps {
  chats: MessageModel.ChatroomMessage[];
  gift: MessageModel.ChatroomMessage | undefined;
  gifts: {
    [giftId: string]: GiftModel.Gift;
  };
  streamerId: string;
}

function Chatroom({chats, gift, gifts, streamerId}: ChatroomProps) {
  const width = useWindowSize();
  const list = useRef<VariableSizeList>(null);
  const styles = useThemeStyles();
  const theme = useTheme();

  const renderChatBubble = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const message = chats[index];
      const content = (() => {
        switch (message.type) {
          case MessageType.Gift:
            return (
              <Box display="flex">
                <CoronaText
                  className="mono"
                  shadow={theme.palette.warning[theme.palette.type]}
                  text="message.someone_gave"
                  keys={{name: message.senderName}}
                />
                <CoronaText
                  className="mono"
                  shadow={theme.palette.warning[theme.palette.type]}
                  text={` ${gifts[message.giftId].name}(${gifts[message.giftId].point}) x${
                    message.message
                  }`}
                />
              </Box>
            );
          case MessageType.StreamNewViewer:
            return (
              <CoronaText
                className="mono"
                shadow={theme.palette.warning[theme.palette.type]}
                text="message.someone_join"
                keys={{name: message.senderName}}
              />
            );
          case MessageType.Chat:
          default:
            return (
              <Text>
                <CoronaText
                  className="mono"
                  text={message.senderName}
                  shadow={
                    message.senderId === streamerId
                      ? theme.palette.primary[theme.palette.type]
                      : theme.palette.secondary[theme.palette.type]
                  }
                />
                <Text className="mono" component="span">
                  {' '}
                  {message.message}
                </Text>
              </Text>
            );
        }
      })();

      return (
        <Box style={style} px={2}>
          <Box p={1} display="flex" width="fit-content" className={styles.chatBubble}>
            <Avatar src={message.senderPicture} />
            {content}
          </Box>
        </Box>
      );
    },
    [chats, gifts, theme, streamerId, styles],
  );

  const countHeight = useCallback(
    (index: number) => {
      const message = chats[index];
      // const type = message.type;
      const numLines = Math.ceil(
        calculateStringWidth(`${message.senderName} ${message.message}`) / (width - 80),
      );
      return numLines * 20 + 25;
    },
    [width, chats],
  );

  const itemKey = useCallback(
    (index: number) => {
      return chats[index].id;
    },
    [chats],
  );

  useEffect(() => {
    if (chats.length > 0) {
      list.current?.scrollToItem(chats.length - 1, 'end');
    }
  }, [chats]);

  return (
    <Box>
      {gift && (
        <Box
          className={styles.gift}
          display="flex"
          position="relative"
          width="fit-content"
          p={1}
          m={2}
          zIndex={1}
          alignItems="center"
          borderRadius={5}>
          <Box className={`overlay giftMessage ${styles.gradientBefore}`} zIndex={-1} />
          <Avatar className="avatar" />
          <Box ml={1}>
            <Text>{gift.senderName}</Text>
            <Text>
              <Typography>message.gave</Typography>
              {gifts[gift.giftId]?.name}
            </Text>
          </Box>
          <Box width={80} position="relative">
            <img
              className="giftMessageImg"
              alt={gifts[gift.giftId]?.name}
              src={gifts[gift.giftId]?.picture}
            />
          </Box>
          <Text variant="h3" className="giftMessageCombo">
            X{gift.message}
          </Text>
        </Box>
      )}

      <VariableSizeList
        key={width}
        itemKey={itemKey}
        ref={list}
        width={width}
        height={160}
        itemSize={countHeight}
        itemCount={chats.length}>
        {renderChatBubble}
      </VariableSizeList>
    </Box>
  );
}

export default Chatroom;
