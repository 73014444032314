import {PayloadAction, PureAction} from 'model/helper';

export class WalletAction {
  public static readonly GET_WALLET_BALANCE = 'GET_WALLET_BALANCE';
  public static readonly GET_GOLD = 'GET_GOLD';

  public static readonly GET_DISTRIBUTION_SUMMARY = 'GET_DISTRIBUTION_SUMMARY';
  public static readonly GET_DISTRIBUTION_LEVEL = 'GET_DISTRIBUTION_LEVEL';

  public static readonly GET_GOLD_LOGS = 'GET_GOLD_LOGS';
  public static readonly GET_GOLD_LOGS_S = 'GET_GOLD_LOGS_S';

  public static readonly GET_WALLET_RECORDS = 'GET_WALLET_USAGE_RECORDS'
  public static readonly GET_WALLET_RECORDS_S = 'GET_WALLET_USAGE_RECORDS_S'

  public static readonly OPEN_PAYMENT_DRAWER = 'OPEN_PAYMENT_DRAWER';
  public static readonly CLOSE_PAYMENT_DRAWER = 'CLOSE_PAYMENT_DRAWER';
  public static readonly GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
  public static readonly GET_PAYMENT_PRODUCTS = 'GET_PAYMENT_PRODUCTS';
}

export enum GoldType {
  Transform = 1,
  Distribute = 2,
}

export enum ConsumptionType {
  All = 0,
  Stream_Om,
  UnlockVideo,
  Stream_Oo,
  Gift_Stream,
  Gift_Chat,
  Gift_Video,
  Msg,
  Post,
  MsgLockMedia,
  Gift_Post,
}

export enum CreditSourceType {
  Deposit = 1,
  Transform,
}

export type WalletRecordType = 'usage' | 'purchase' | 'bonus';

export enum PaymentMethod {
  CreditCard = 'credit_card',
  WireTransfer = 'atm',
  ConvenientStore = 'convenience_store',
  UnionPay = 'union_pay',
}

export enum PaymentStatus {
  Pending = 0,
  Success,
	Failed,
	Expired,
}

export declare namespace WalletModel {
  export interface State {
    balance: number;
    gold: number;
    distribution: {
      upline: {
        userId: string;
        userName: string;
        userPicture: string;
      } | null;
      downline: {
        [level: number]: {
          total: number;
          profit: number;
          percentage: number;
          list: DownlineMember[];
        };
      };
    };
    records: {
      [type in WalletRecordType]: {
        loading: boolean;
        cursor: string | null;
        list: WalletLogSummary[];
      };
    };
    goldLogs: {
      loading: boolean;
      cursor: string | null;
      list: GoldLogSummary[];
    };
    payment: {
      open: boolean;
      onPaid: (() => void) | null;
      methods: PaymentMethod[];
      products: Product[];
    },
  }

  interface DownlineMember {
    userName: string;
    userPicture: string;
    downlineCount: {
      [level: number]: number | undefined;
    };
    uplineName: {
      [level: number]: string | undefined;
    };
  }

  interface GoldLogSummary {
    time: string; // key
    net: number;
    logs: GoldLog[];
  }

  interface GoldLog {
    type: GoldType;
    parent: string;
    name: string;
    level: number;
    number: number;
    gold: number;
    createdAt: string;
  }

  interface WalletLogSummary {
    time: string; // key
    net: number;
    logs: WalletLog[];
  }

  interface WalletLog {
    tradeType?: ConsumptionType;
    // sourceType?: CreditSourceType;
    parent: string;
    createdAt: string;
    amount: number; // amount / point_gain 
    count: number; // count / spend

    toUserId?: string;
    toUserName?: string;
    toUserPicture?: string;
    note: string;
    status?: PaymentStatus;
  }

  export interface Product {
    id: string;
    point: number;
    price: number;
    bonus: number;
  }

  export interface ConsumptionRecord {
    type: ConsumptionType;
    note: string;
    count: number;
    amount: number;
    userId: string;
    userPicture: string;
    time: Date;
  }

  export interface ConsumptionHistory {
    date: string;
    totalAmount: number;
    details: ConsumptionRecord[];
  }

  export enum GoldUsageType {
    Transform = 1,
    Withdrawal = 2,
  }

  export interface GoldUsage {
    date: string;
    total: number;
    records: Array<{
      type: GoldUsageType;
      time: string;
      usage: number;
      gain: number;
    }>;
  }

  export interface GoldIncome {
    date: string;
    total: number;
    records: Array<{
      user: {
        id: string;
        name: string;
        level: number;
        purchase: number;
      };
      time: string;
      gain: number;
    }>;
  }

  export enum DepositSource {
    Deposit = 1,
    Transform = 2,
  }

  export interface DepositHistory {
    date: string;
    total: number;
    records: Array<{
      source: DepositSource;
      time: string;
      gateway: string;
      method: string;
      spend: number;
      gain: number;
    }>;
  }

  export interface DistributionSummary {
    upline: null | {
      id: string;
      name: string;
      picture: string;
    };
    downline: {
      [level: number]: {
        persons: number;
        profit: number;
      };
    };
  }

  export interface DistributionMember {
    name: string;
    downlineCount: {
      [level: number]: number | undefined;
    };
    uplineName: {
      [level: number]: string | undefined;
    };
  }

  export type GetWalletAction = PayloadAction<typeof WalletAction['GET_WALLET_BALANCE'], number>;

  export type GetGoldAction = PayloadAction<typeof WalletAction['GET_GOLD'], number>;

  export type GetDistributionAction = PayloadAction<
    typeof WalletAction['GET_DISTRIBUTION_SUMMARY'],
    State['distribution']
  >;

  export type GetDistributionLevelAction = PayloadAction<
    typeof WalletAction['GET_DISTRIBUTION_LEVEL'],
    {
      level: number;
      list: DownlineMember[];
    }
  >;

  export type GetGoldLogsAction = PureAction<typeof WalletAction['GET_GOLD_LOGS']>;
  export type AfterGetGoldLogsAction = PayloadAction<
    typeof WalletAction['GET_GOLD_LOGS_S'],
    {
      refresh: boolean;
      cursor: string | null;
      list: GoldLogSummary[];
    }
  >;

  // GET_WALLET_USAGE_RECORDS
  export type GetWalletRecordsAction = PayloadAction<
    typeof WalletAction['GET_WALLET_RECORDS'],
    {
      type: WalletRecordType;
    }
  >;
  export type AfterGetWalletRecordsAction = PayloadAction<
    typeof WalletAction['GET_WALLET_RECORDS_S'],
    {
      type: WalletRecordType;
      refresh: boolean;
      cursor: string | null;
      list: WalletLogSummary[];
    }
  >;

  export type OpenPaymentDrawerAction = PayloadAction<
    typeof WalletAction['OPEN_PAYMENT_DRAWER'],
    {
      onPaid?: () => void;
    }
  >

  export type ClosePaymentDrawerAction = PureAction<typeof WalletAction['CLOSE_PAYMENT_DRAWER']>;

  export type GetPaymentMethodsAction = PayloadAction<typeof WalletAction['GET_PAYMENT_METHODS'], PaymentMethod[]>
  export type GetPaymentProductsAction = PayloadAction<typeof WalletAction['GET_PAYMENT_PRODUCTS'], Product[]>

  export type ActionTypes =
    | GetWalletAction
    | GetGoldAction
    | GetDistributionAction
    | GetDistributionLevelAction
    | GetGoldLogsAction
    | AfterGetGoldLogsAction
    | GetWalletRecordsAction
    | AfterGetWalletRecordsAction
    | OpenPaymentDrawerAction
    | ClosePaymentDrawerAction
    | GetPaymentMethodsAction
    | GetPaymentProductsAction;
}
