import {Box, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React from 'react';
import Ticker from 'react-ticker';

import {ReactComponent as MegaphoneIcon} from 'assets/icon/light/bullhorn.svg';

import Icon from './Icons';

interface MarqueeProps {
  text: string;
}

function Marquee({text}: MarqueeProps) {
  const styles = useThemeStyles();

  return (
    <Box
      flex={1}
      height={28}
      position="relative"
      whiteSpace="nowrap"
      className={styles.transparentGradient}
      ml={1}
      borderRadius={4}
      lineHeight="28px"
      pl={4}>
      <Box position="absolute" left={5} top={4} bottom={0}>
        <Icon fontSize="small" svg={MegaphoneIcon} />
      </Box>
      <Ticker speed={3} key={text}>
        {({index}) => <Text component="span">{text}</Text>}
      </Ticker>
    </Box>
  );
}

export default Marquee;
