import {AppBar, Box, Toolbar, Grid} from '@material-ui/core';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {AppDispatch} from 'model/helper';
import {loadLivestreamList} from 'model/livestream/LivestreamAction';
import {
  hasLivestreamListNextPage,
  isLivestreamListLoading,
  livestreamListSelector,
} from 'model/livestream/LivestreamSelector';

import {useWindowSize} from 'utils/hook';

import BackButton from 'components/BackButton';
import LivestreamCard from 'components/LivestreamCard';
import PointLabel from 'components/PointLabel';

const NUM_PER_GRID = 4;
const GRID_REPEAT_INTERVAL = 3;
const ITEM_REPEAT_INTERVAL = NUM_PER_GRID * (GRID_REPEAT_INTERVAL - 1) + 1;
const GRID_MARGIN = 8;

function LivestreamGridPage() {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const width = useWindowSize();

  const isLoading = useSelector(isLivestreamListLoading);
  const hasNextPage = useSelector(hasLivestreamListNextPage);
  const livestreams = useSelector(livestreamListSelector);

  useEffect(() => {
    dispatch(loadLivestreamList(true));

    // eslint-disable-next-line
  }, []);

  const enterRoom = useCallback(
    (id: string) => {
      return () => {
        history.push(`/livestream/${id}`);
      };
    },
    [history],
  );

  const renderCell = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const large = index % GRID_REPEAT_INTERVAL === 0;
      const numLarge = Math.ceil(index / GRID_REPEAT_INTERVAL);
      const startIndex = numLarge + (index - numLarge) * NUM_PER_GRID;

      const size = width - 32;
      const smallSize = ((size - GRID_MARGIN) / NUM_PER_GRID) * 2;

      const cardStyle: CSSProperties = large
        ? {width: size, height: size}
        : {
            width: smallSize,
            height: smallSize,
            marginRight: index % 2 === 0 ? GRID_MARGIN / 2 : 0,
            marginLeft: index % 2 === 0 ? 0 : GRID_MARGIN / 2,
            marginBottom: 8,
          };

      return (
        <Box style={style}>
          {large ? (
            <LivestreamCard
              livestream={livestreams[startIndex]}
              style={cardStyle}
              onClick={enterRoom(livestreams[startIndex]?.id)}
            />
          ) : (
            <Grid container>
              {Array(NUM_PER_GRID)
                .fill(true)
                .map((_, index) => (
                  <Grid item xs={6} key={livestreams[startIndex + index]?.id || index}>
                    <LivestreamCard
                      livestream={livestreams[startIndex + index]}
                      style={cardStyle}
                      onClick={enterRoom(livestreams[startIndex + index]?.id)}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      );
    },
    [livestreams, enterRoom, width],
  );

  const rowCount = useMemo<number>(() => {
    const numLargeRow = Math.ceil(livestreams.length / ITEM_REPEAT_INTERVAL);
    const numSmallRow = Math.ceil((livestreams.length - numLargeRow) / NUM_PER_GRID);

    return numLargeRow + numSmallRow;
  }, [livestreams.length]);

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < rowCount - 1;
    },
    [hasNextPage, rowCount],
  );

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadLivestreamList(false));
  }, [isLoading, hasNextPage, dispatch]);

  return (
    <Box className="full" display="flex" flexDirection="column" flex="1">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center" />
          <PointLabel flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>

      <Box flex="1" m={2}>
        <AutoSizer>
          {({height, width}) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={rowCount}
              loadMoreItems={loadMore}>
              {({onItemsRendered, ref}) => {
                return (
                  <FixedSizeList
                    key={width}
                    itemCount={rowCount}
                    itemSize={width + GRID_MARGIN}
                    width={width}
                    height={height}
                    onItemsRendered={onItemsRendered}
                    ref={ref}>
                    {renderCell}
                  </FixedSizeList>
                );
              }}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
}

export default LivestreamGridPage;
