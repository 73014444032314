import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import React, {FunctionComponent, SVGProps} from 'react';

export type SvgComponent = FunctionComponent<
  SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

interface IconProps extends SvgIconProps {
  svg: SvgComponent;
}

function Icon({svg, ...props}: IconProps) {
  const Svg = svg;
  return (
    <SvgIcon {...props}>
      <Svg />
    </SvgIcon>
  );
}

export default Icon;
