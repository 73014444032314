import {Box, ListItem, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import dayjs from 'dayjs';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {AppDispatch} from 'model/helper';
import {loadGoldLogs} from 'model/wallet/WalletAction';
import {hasGoldLogsNextPage, isGoldLogsLoading, getGoldLogs} from 'model/wallet/WalletSelector';
import {WalletModel, GoldType} from 'model/wallet/WalletTypes';

import {format} from 'utils/number';

import Icon from 'components/Icons';
import Typography from 'components/i18n/Typography';

import {ReactComponent as CaretIcon} from 'assets/icon/solid/caret-up.svg';

interface GoldLogsProps {
  width: number;
  height: number;
}

function GoldLogs({width, height}: GoldLogsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const styles = useThemeStyles();

  const isLoading = useSelector(isGoldLogsLoading);
  const hasNextPage = useSelector(hasGoldLogsNextPage);
  const logs = useSelector(getGoldLogs);

  const [openList, setOpenList] = useState(new Set<string>());
  const itemCount = useMemo(() => {
    return logs.reduce((acc, item) => acc + item.logs.length, logs.length);
  }, [logs]);
  const list = useRef<VariableSizeList>(null);

  useEffect(() => {
    if (logs.length === 0) {
      dispatch(loadGoldLogs(true));
    }

    // eslint-disable-next-line
  }, []);

  const getElementByIndex = useCallback(
    (index: number) => {
      let acc = -1;
      for (const item of logs) {
        acc += 1;
        if (index === acc) {
          return item;
        }

        acc += item.logs.length;
        if (acc >= index) {
          return item.logs[index - (acc - item.logs.length + 1)];
        }
      }
    },
    [logs],
  );

  const toggleExpanded = useCallback((time: string, index: number) => {
    return () => {
      list.current?.resetAfterIndex(index);

      setOpenList((list) => {
        if (list.has(time)) {
          list.delete(time);
          return new Set(list);
        } else {
          list.add(time);
          return new Set(list);
        }
      });
    };
  }, []);

  const countHeight = useCallback(
    (index: number) => {
      const ele = getElementByIndex(index)!;
      if (ele && (ele as WalletModel.GoldLog).parent) {
        const log = ele as WalletModel.GoldLog;
        return openList.has(log.parent) ? 56 : 0;
      } else {
        return 37;
      }
    },
    [getElementByIndex, openList],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return !hasNextPage || index < itemCount - 1;
    },
    [hasNextPage, itemCount],
  );

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadGoldLogs(false));
  }, [isLoading, hasNextPage, dispatch]);

  const renderRow = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const ele = getElementByIndex(index)!;
      // console.log(style);

      if (ele && (ele as WalletModel.GoldLog).parent) {
        const log = ele as WalletModel.GoldLog;
        const open = openList.has(log.parent);

        return (
          <Box display={open ? 'flex' : 'none'} style={style}>
            <ListItem className={styles.log}>
              <Box flex={1} textAlign="center">
                <Text>{dayjs(log.createdAt).format('YYYY/MM/DD')}</Text>
                <Text>{dayjs(log.createdAt).format('HH:mm')}</Text>
              </Box>
              <Box flex={1} textAlign="center">
                {log.type === GoldType.Distribute && <Text>{log.name}</Text>}
                <Typography
                  color="textSecondary"
                  keys={{n: log.level}}>{`label.gold_history_type.${log.type}`}</Typography>
              </Box>
              <Box flex={1} textAlign="center">
                <Text>{format(log.number)}</Text>
                <Typography color="textSecondary">{`label.amount_to_gold.${log.type}`}</Typography>
              </Box>
              <Box flex={1} textAlign="center">
                <Text>{format(log.gold)}</Text>
                <Typography color="textSecondary">{`label.gold.${log.type}`}</Typography>
              </Box>
            </ListItem>
          </Box>
        );
      } else {
        const item = ele as WalletModel.GoldLogSummary;
        return (
          <ListItem
            button
            style={style}
            ContainerProps={{style}}
            onClick={toggleExpanded(item.time, index)}>
            <Text>{item.time}月</Text>
            <Box flex={1} />
            <Box display="flex" alignItems="center">
              <div className="coin" />
              <Text>{format(item.net)}</Text>
            </Box>
            <Icon svg={CaretIcon} className={`arrow ${openList.has(item.time) ? 'down' : 'up'}`} />
          </ListItem>
        );
      }
    },
    [openList, getElementByIndex, toggleExpanded, styles],
  );

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMore}>
      {({onItemsRendered, ref}) => {
        return (
          <VariableSizeList
            width={width}
            height={height}
            itemSize={countHeight}
            itemCount={itemCount}
            onItemsRendered={onItemsRendered}
            ref={(ele) => {
              (ref as any)(ele);
              (list as any).current = ele;
            }}>
            {renderRow}
          </VariableSizeList>
        );
      }}
    </InfiniteLoader>
  );
}

export default GoldLogs;
