import React from 'react';
import {animated, interpolate} from 'react-spring';

import {defaultProps} from '../../constants';
import {useZoom} from '../../hooks';
import {Slide as StyledSlide} from './Slide.css';

const AnimatedSlide = animated(StyledSlide);

export default function Slide({children, onScale, minScale, maxScale}) {
  const [element, scale, translateX, translateY, middleTouchOnElement] = useZoom({
    minScale,
    maxScale,
    onScale,
  });

  return (
    <AnimatedSlide
      ref={element}
      style={{
        transform: interpolate(
          [scale, translateX, translateY],
          (sc, x, y) => `translate3d(${x}px, ${y}px, 0) scale3d(${sc}, ${sc}, 1)`,
        ),
        transformOrigin: middleTouchOnElement.interpolate((x, y) => `${x}px ${y}px 0`),
      }}>
      {children}
    </AnimatedSlide>
  );
}

Slide.defaultProps = {
  onScale: undefined,
  maxScale: defaultProps.maxScale,
  minScale: defaultProps.minScale,
};
