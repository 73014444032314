import {
  AppBar,
  Avatar,
  Box,
  Button,
  ListItem,
  MenuItem,
  Select,
  Toolbar,
  Typography as Text,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import dayjs from 'dayjs';
import React, {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {AppDispatch} from 'model/helper';
import {loadWalletPurchaseRecords, loadWalletUsageRecords} from 'model/wallet/WalletAction';
import {getWalletRecords} from 'model/wallet/WalletSelector';
import {WalletModel, WalletRecordType, PaymentStatus} from 'model/wallet/WalletTypes';

import WalletAPI from 'api/wallet';

import {format} from 'utils/number';

import BackButton from 'components/BackButton';
import Icon from 'components/Icons';
import Typography from 'components/i18n/Typography';

import {ReactComponent as CloseIcon} from 'assets/icon/light/times.svg';
import {ReactComponent as CaretIcon} from 'assets/icon/solid/caret-up.svg';

interface PaymentDetail {
  id: string;
  expireAt: string;
  price: number;
}

function WalletPage() {
  const {type} = useParams<{type: WalletRecordType}>();
  const styles = useThemeStyles();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const records = useSelector(getWalletRecords)[type];

  const [openList, setOpenList] = useState(new Set<string>());
  const [detail, setDetail] = useState<undefined | PaymentDetail>(undefined);
  const itemCount = useMemo(() => {
    return records.list.reduce((acc, item) => acc + item.logs.length, records.list.length);
  }, [records]);
  const list = useRef<VariableSizeList>(null);

  useEffect(() => {
    switch (type) {
      case 'usage':
        dispatch(loadWalletUsageRecords(true)).then(() => {
          setOpenList(new Set());
          list.current?.resetAfterIndex(0);
        });
        break;
      case 'purchase':
        dispatch(loadWalletPurchaseRecords(true)).then(() => {
          setOpenList(new Set());
          list.current?.resetAfterIndex(0);
        });
        break;
    }
  }, [dispatch, type]);

  const handleChangeType = useCallback(
    (e: ChangeEvent<{name?: string; value: unknown}>) => {
      history.replace(`/profile/wallet/${e.target.value}`);
    },
    [history],
  );

  const getElementByIndex = useCallback(
    (index: number) => {
      let acc = -1;
      for (const item of records.list) {
        acc += 1;
        if (index === acc) {
          return item;
        }

        acc += item.logs.length;
        if (acc >= index) {
          return item.logs[index - (acc - item.logs.length + 1)];
        }
      }
    },
    [records],
  );

  const toggleExpanded = useCallback((time: string, index: number) => {
    return () => {
      list.current?.resetAfterIndex(index);

      setOpenList((list) => {
        if (list.has(time)) {
          list.delete(time);
          return new Set(list);
        } else {
          list.add(time);
          return new Set(list);
        }
      });
    };
  }, []);

  const countHeight = useCallback(
    (index: number) => {
      const ele = getElementByIndex(index)!;
      if (ele && (ele as WalletModel.WalletLog).parent) {
        const log = ele as WalletModel.WalletLog;
        return openList.has(log.parent) ? 56 : 0;
      } else {
        return 37;
      }
    },
    [getElementByIndex, openList],
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      return records.cursor === null || index < itemCount - 1;
    },
    [records, itemCount],
  );

  const loadMore = useCallback(() => {
    if (records.loading || records.cursor === null) {
      return null;
    }

    switch (type) {
      case 'usage':
        return dispatch(loadWalletUsageRecords(true));
      case 'purchase':
        return dispatch(loadWalletPurchaseRecords(true));
      default:
        return null;
    }
  }, [records, type, dispatch]);

  const loadDetail = useCallback((orderId: string) => {
    return () => {
      WalletAPI.getCreditDetail(orderId).then((res) => {
        setDetail({
          id: res.detail.order_id,
          expireAt: dayjs(res.detail.expired_at).format('YYYY/MM/DD HH:mm'),
          price: res.detail.price,
        });
      });
    };
  }, []);

  const closeDetail = useCallback(() => {
    setDetail(undefined);
  }, []);

  const renderRow = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const ele = getElementByIndex(index)!;

      if (ele && (ele as WalletModel.WalletLog).parent) {
        const log = ele as WalletModel.WalletLog;
        const open = openList.has(log.parent);

        return (
          <Box display={open ? 'flex' : 'none'} style={style}>
            <ListItem className={styles.log}>
              <Box flex={1} textAlign="center">
                <Text>{dayjs(log.createdAt).format('YYYY/MM/DD')}</Text>
                <Text>{dayjs(log.createdAt).format('HH:mm')}</Text>
              </Box>
              {log.toUserId && (
                <Box
                  flex={1}
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center">
                  <Avatar src={log.toUserPicture} className="avatar" />
                  <Text>{log.toUserName}</Text>
                </Box>
              )}

              {log.tradeType !== undefined && (
                <Box flex={1} textAlign="center" display="flex" flexDirection="column">
                  <Typography keys={{count: log.count, note: log.note}}>
                    {`enum.consumption_detail.${log.tradeType}`}
                  </Typography>
                  <Typography color="textSecondary">{`enum.consume.${log.tradeType}`}</Typography>
                </Box>
              )}

              {log.status !== undefined && (
                <Box flex={1} textAlign="center" display="flex" flexDirection="column">
                  {log.status === PaymentStatus.Pending ? (
                    <Box alignSelf="center">
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={loadDetail(log.note)}>
                        <Typography>{`enum.payment_status.${log.status}`}</Typography>
                      </Button>
                    </Box>
                  ) : (
                    <Typography>{`enum.payment_status.${log.status}`}</Typography>
                  )}

                  <Typography color="textSecondary">common.status</Typography>
                </Box>
              )}

              {log.tradeType === undefined && (
                <Box flex={1} textAlign="center" display="flex" flexDirection="column">
                  <Text>{format(log.count)}</Text>
                  <Typography color="textSecondary">label.deposit</Typography>
                </Box>
              )}

              <Box flex={1} textAlign="center">
                <Text>{format(log.amount)}</Text>
                <Typography color="textSecondary">
                  {log.tradeType ? 'label.point_usage' : 'label.point_earning'}
                </Typography>
              </Box>
            </ListItem>
          </Box>
        );
      } else {
        const item = ele as WalletModel.WalletLogSummary;
        return (
          <ListItem
            button
            style={style}
            ContainerProps={{style}}
            onClick={toggleExpanded(item.time, index)}>
            <Text>{`${item.time}`}</Text>
            <Box flex={1} />
            <Box display="flex" alignItems="center">
              <div className="diamond" />
              <Text>{format(item.net)}</Text>
            </Box>
            <Icon svg={CaretIcon} className={`arrow ${openList.has(item.time) ? 'down' : 'up'}`} />
          </ListItem>
        );
      }
    },
    [openList, getElementByIndex, toggleExpanded, styles, loadDetail],
  );

  return (
    <Box className="full" display="flex" flexDirection="column">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/profile/setting" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.wallet
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" />
        </Toolbar>
        <Select
          className={styles.headerSelect}
          value={type}
          onChange={handleChangeType}
          MenuProps={{
            className: styles.fullScreenMenu,
          }}>
          <MenuItem value="usage">
            <Typography>label.usage</Typography>
          </MenuItem>
          <MenuItem value="purchase">
            <Typography>label.purchase</Typography>
          </MenuItem>
          <MenuItem value="bonus">
            <Typography>label.bonus</Typography>
          </MenuItem>
        </Select>
      </AppBar>

      <Box flex={1}>
        <AutoSizer>
          {({height, width}) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMore}>
              {({onItemsRendered, ref}) => {
                return (
                  <VariableSizeList
                    width={width}
                    height={height}
                    itemSize={countHeight}
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={(ele) => {
                      (ref as any)(ele);
                      (list as any).current = ele;
                    }}>
                    {renderRow}
                  </VariableSizeList>
                );
              }}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>

      <Dialog open={Boolean(detail)} onClose={closeDetail}>
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h5">title.convenient_store_payment</Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar src="/711-logo.png" className="avatar50" />
            <Box my={2}>
              <Text variant="h6" component="h6">
                {detail?.id}
              </Text>
            </Box>

            <Box>
              <table>
                <tbody>
                  <tr>
                    <th>
                      <Box textAlign="right">
                        <Typography component="span" className="oneLine">
                          common.expiration
                        </Typography>
                      </Box>
                    </th>
                    <th>
                      <Box ml={5} textAlign="left">
                        <Text component="span" className="oneLine">
                          {detail?.expireAt}
                        </Text>
                      </Box>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <Box textAlign="right">
                        <Typography component="span" className="oneLine">
                          label.order_price
                        </Typography>
                      </Box>
                    </th>
                    <th>
                      <Box ml={5} textAlign="left">
                        <Text component="span" className="oneLine">
                          ${detail?.price}
                        </Text>
                      </Box>
                    </th>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
        </DialogContent>

        <Divider />

        <Box>
          <ul>
            <li>
              <Typography>paragraph.convenient_store_instruction.0</Typography>
            </li>
            <li>
              <Typography>paragraph.convenient_store_instruction.1</Typography>
            </li>
          </ul>
        </Box>

        <Box position="absolute" right={0} top={0} zIndex={2}>
          <IconButton onClick={closeDetail}>
            <Icon fontSize="large" svg={CloseIcon} />
          </IconButton>
        </Box>
      </Dialog>
    </Box>
  );
}

export default WalletPage;
