import React, {useCallback, useRef} from 'react';

interface VodPlayerProps {
  muted: boolean;
  src: string;
  offset: number;
}

function VodPlayer({src, muted, offset}: VodPlayerProps) {
  const video = useRef<HTMLVideoElement>(null);
  const initTime = useRef(Date.now());

  const handleTimeOffset = useCallback(() => {
    const delaySec = (Date.now() - initTime.current) / 1000;
    if (video.current) {
      video.current.currentTime = offset + delaySec;
    }

    // eslint-disable-next-line
  }, []);

  return (
    <video
      ref={video}
      className="full"
      playsInline
      preload="auto"
      muted={muted}
      autoPlay
      onLoadedData={handleTimeOffset}>
      <source src={src} />
    </video>
  );
}

export default VodPlayer;
