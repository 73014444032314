import {Box} from '@material-ui/core';
import {ErrorCode} from 'api';
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useGesture} from 'react-use-gesture';

import {AppDispatch, StorageKeys} from 'model/helper';
import AgoraRTC from 'model/livestream/AgoraRTC';
import {leaveLivestream} from 'model/livestream/LivestreamAction';
import {WatchMode} from 'model/livestream/LivestreamTypes';

import {genSearchQuery, getSearchQuery} from 'utils/string';

import LivestreamControls from './LivestreamControls';
import LivestreamPlayer from './LivestreamPlayer';

function LivestreamPage() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const history = useHistory();
  const {id} = useParams<{id: string}>();
  const [hide, setHide] = useState(false);

  const mode: WatchMode =
    parseInt(getSearchQuery(location.search, StorageKeys.mode)) || WatchMode.Free;
  const [muted, setMuted] = useState(true);

  const handleVolume = useCallback(() => {
    setMuted((v) => !v);
  }, []);

  const handleExit = useCallback(() => {
    AgoraRTC.instance.destroy();
    dispatch(leaveLivestream(id, mode));
    AgoraRTC.instance.muteRemoteStream();
    history.goBack();
  }, [dispatch, history, id, mode]);

  const handleError = useCallback(
    (err) => {
      switch (err) {
        case ErrorCode.StreamEnded:
        case ErrorCode.LackEnoughPoint:
        case ErrorCode.BlockByStreamer:
        case ErrorCode.Anomaly:
          return handleExit();
      }
    },
    [handleExit],
  );

  const handleChangeMode = useCallback(
    (mode: WatchMode) => {
      history.replace({
        search: genSearchQuery(history.location.search, StorageKeys.mode, mode.toString()),
      });
      setMuted(true);
    },
    [history],
  );

  const bind = useGesture({
    onDrag: ({vxvy: [vx], last}) => {
      if (last && vx < -0.3) {
        setHide(true);
      } else if (last && vx > 0.3) {
        setHide(false);
      }
    },
  });

  return (
    <Box className="full noSelect" position="relative" {...bind()}>
      <LivestreamPlayer
        streamId={id}
        mode={mode}
        muted={muted}
        onExit={handleExit}
        onError={handleError}
        onChangeMode={handleChangeMode}
      />
      {!hide && (
        <LivestreamControls
          streamId={id}
          mode={mode}
          muted={muted}
          onClickVolume={handleVolume}
          onChangeMode={handleChangeMode}
        />
      )}
    </Box>
  );
}

export default LivestreamPage;
