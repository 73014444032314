import {createSelector} from 'reselect';

import {RootState} from 'model/helper';

import {UserModel} from './UserTypes';

const getUserState = (state: RootState) => state.user;

export const getUserAuth = (state: RootState) => getUserState(state).auth;
export const authorized = createSelector([getUserAuth], (auth) => Boolean(auth?.token));

export const getMyInfo = (state: RootState) => getUserState(state).info;

// export const getAccount = (state: RootState) => getMyInfo(state).userId || '';

export const getMemberCache = (state: RootState) => getUserState(state).cache;

export const getUserById = (state: RootState) => (id: string): UserModel.MemberInfo | undefined =>
  getMemberCache(state)[id];

export const isUserFollowed = (state: RootState) => (id: string): boolean | undefined =>
  getUserById(state)(id)?.followed;

export const getQRCode = (state: RootState) => getUserState(state).qr;

export const getFollowersState = (state: RootState) => getUserState(state).followers;
export const isFollowersListLoading = (state: RootState) => getFollowersState(state).loading;
export const hasFollowersListNextPage = (state: RootState) =>
  getFollowersState(state).cursor !== null;
export const followersSelector = createSelector(
  [(state) => getFollowersState(state).list, getMemberCache],
  (followers, cache) => {
    return followers.map((userId) => cache[userId]);
  },
);

export const getFolloweesState = (state: RootState) => getUserState(state).followees;
export const isFolloweesListLoading = (state: RootState) => getFolloweesState(state).loading;
export const hasFolloweesListNextPage = (state: RootState) =>
  getFolloweesState(state).cursor !== null;
export const followeesSelector = createSelector(
  [(state) => getFolloweesState(state).list, getMemberCache],
  (followees, cache) => {
    return followees.map((userId) => cache[userId]);
  },
);

export const getLinkingStatus = (state: RootState) => getUserState(state).linking;

export const getActivities = (state: RootState) => getUserState(state).activity;
export const getRecommendedStreamers = (state: RootState) => getUserState(state).recommendation;
