import {ServerError} from 'api';

import {AppThunk, postResponseToModel} from 'model/helper';

import ExploreAPI, {SearchResultResponse} from 'api/explore';

import {ExploreAction, ExploreModel, SearchType} from './ExploreTypes';

function searchResponseToModel(response: SearchResultResponse): ExploreModel.SearchedItem {
  return {
    id: response.id.toString(),
    type: response.search_type,
    keyword: response.result,
    title: response.title,
    subtitle: response.sub_title,
    cover: response.cover,
    followed: response.is_follow,
  };
}

export const loadRecommendedPosts = (refresh: boolean): AppThunk<Promise<void>> => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({type: ExploreAction.GET_RECOMMENDED_POSTS, payload: {refresh}});
    const cursor = refresh ? null : getState().explore.posts.cursor;
    const res = await ExploreAPI.getRecommendedPosts(cursor);

    dispatch({
      type: ExploreAction.GET_RECOMMENDED_POSTS_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        list: res.list.map(postResponseToModel),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadKeywordPosts = (
  keyword: string,
  type: SearchType,
  refresh: boolean,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  try {
    dispatch({type: ExploreAction.GET_KEYWORD_POSTS, payload: {refresh}});
    const cursor = refresh ? null : getState().explore.posts.cursor;
    const res = await ExploreAPI.getPostList(keyword, type, cursor);

    dispatch({
      type: ExploreAction.GET_RECOMMENDED_POSTS_S,
      payload: {
        refresh,
        cursor: res.cursor || null,
        list: res.list.map(postResponseToModel),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadRecommendedTags = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const tags = await ExploreAPI.getRecommendTags();
    dispatch({type: ExploreAction.GET_SUGGESTED_TAGS, payload: tags});
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadSuggestedSearch = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await ExploreAPI.getRecommendStreamers();
    dispatch({
      type: ExploreAction.GET_SUGGESTED_SEARCH_RESULT,
      payload: res.hot_search_result.map(searchResponseToModel),
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const loadRecentSearch = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const res = await ExploreAPI.getSearchHistory(null);
    dispatch({
      type: ExploreAction.GET_RECENT_SEARCH_RESULT,
      payload: res.result.map(searchResponseToModel),
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const search = (
  keyword: string,
  type: SearchType,
  refresh: boolean,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ExploreAction.SEARCH_KEYWORD,
    payload: {
      keyword,
      type,
    },
  });

  try {
    const cursor = refresh ? null : getState().explore.search[type].cursor;
    const res =
      type === SearchType.All
        ? await ExploreAPI.searchAll(keyword, cursor)
        : type === SearchType.Streamer
        ? await ExploreAPI.searchUsers(keyword, cursor)
        : await ExploreAPI.searchTags(keyword, cursor);
    dispatch({
      type: ExploreAction.SEARCH_KEYWORD_S,
      payload: {
        keyword,
        type,
        refresh,
        cursor: res.cursor || null,
        list: res.list.map(searchResponseToModel),
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const saveSearchResult = (
  keyword: string,
  type: SearchType,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await ExploreAPI.createSearchRecord(type, keyword);
    dispatch({
      type: ExploreAction.SAVE_SEARCH_RESULT,
      payload: {
        keyword,
        type,
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const deleteSearchResults = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await ExploreAPI.deleteSearchRecord();
    dispatch({type: ExploreAction.DELETE_ALL_SEARCH_RESULTS});
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};
