import {Box, Button, ClickAwayListener, IconButton, InputAdornment} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {openGiftDrawer} from 'model/gift/GiftAction';
import {GiftLocation} from 'model/gift/GiftTypes';
import {AppDispatch} from 'model/helper';
import {
  loadMessages,
  loadMessenger,
  readMessage,
  sendImageMessage,
  sendTextMessage,
} from 'model/message/MessageAction';
import {
  messagesLoadingSelector,
  messagesNextPageSelector,
  messagesSelector,
  messengerSelector,
} from 'model/message/MessageSelector';
import {MessageType} from 'model/message/MessageTypes';

import {useWindowSize} from 'utils/hook';
import {calculateStringWidth} from 'utils/string';

import ChatBubble from 'components/ChatBubble';
import Icon from 'components/Icons';
import PhotoSwipe, {PhotoswipeProps} from 'components/PhotoSwipe';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

import {ReactComponent as CameraIcon} from 'assets/icon/light/camera.svg';
import {ReactComponent as GiftIcon} from 'assets/icon/regular/gift.svg';

// import {ReactComponent as MicrophoneIcon} from 'assets/icon/light/microphone.svg';

interface ConversationProps {
  userId: string;
}

function Conversation({userId}: ConversationProps) {
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();
  const list = useRef<VariableSizeList>(null);
  const lastId = useRef('');
  const firstId = useRef('');

  const messages = useSelector(messagesSelector)(userId);
  const hasNextPage = useSelector(messagesNextPageSelector)(userId);
  const isLoading = useSelector(messagesLoadingSelector)(userId);
  const messenger = useSelector(messengerSelector)(userId);
  const width = useWindowSize();
  const [inputFocused, setInputFocused] = useState(false);
  const [text, setText] = useState('');
  const [photo, setPhoto] = useState<PhotoswipeProps['items']>([]);

  useEffect(() => {
    dispatch(loadMessenger(userId));
    dispatch(loadMessages(userId, true));

    lastId.current = '';
    firstId.current = '';
  }, [dispatch, userId]);

  useEffect(() => {
    if (!lastId.current && messages.length > 0 && messages[messages.length - 1]) {
      setTimeout(() => {
        list.current?.scrollToItem(messages.length - 1, 'end');
      }, 200);
      lastId.current = messages[messages.length - 1].id;
      dispatch(readMessage(userId));
    } else if (lastId.current && messages[messages.length - 1].id !== lastId.current) {
      list.current?.scrollToItem(messages.length - 1, 'end');
      lastId.current = messages[messages.length - 1].id;

      if (messages[messages.length - 1].senderId === userId) {
        dispatch(readMessage(userId));
      }
    }

    if (!firstId.current && messages.length > 0) {
      firstId.current = messages[0].id;
    } else if (firstId.current && messages[0].id !== firstId.current) {
      const index = messages.findIndex((msg) => msg.id === firstId.current);
      list.current?.resetAfterIndex(0);
      list.current?.scrollToItem(index, 'start');
      firstId.current = messages[0].id;
    }
  }, [dispatch, messages, userId]);

  const isItemLoaded = useCallback(
    (index: number) => {
      return index !== 0 || !lastId.current || !hasNextPage;
    },
    [hasNextPage],
  );

  const countHeight = useCallback(
    (index: number) => {
      const message = messages[index];
      const type = message.type;

      switch (type) {
        case MessageType.Chat:
          return Math.ceil(calculateStringWidth(message.message) / (width - 104)) * 20 + 30;
        case MessageType.Video:
        case MessageType.Picture:
          return 175;
        case MessageType.Gift:
          return 150;
        case MessageType.Audio:
          return 70;
        default:
          return 50;
      }
    },
    [messages, width],
  );

  const renderChatBubble = useCallback(
    ({index, style}: ListChildComponentProps) => {
      const message = messages[index];

      return (
        <Box style={style} py={1}>
          <ChatBubble
            message={message}
            senderId={userId}
            avatarSrc={messenger.userPicture}
            showAvatar={messages[index + 1]?.senderId !== message.senderId}
            onClickContent={() => {
              if (message.type === MessageType.Picture) {
                setPhoto([
                  {
                    src: message.message,
                    w: Math.max(window.innerWidth, window.innerHeight) + 100,
                    h: Math.max(window.innerWidth, window.innerHeight) + 100,
                  },
                ]);
              }
            }}
          />
        </Box>
      );
    },
    [messages, userId, messenger],
  );

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadMessages(userId, false));
  }, [isLoading, hasNextPage, dispatch, userId]);

  const itemKey = useCallback(
    (index: number) => {
      return messages[index].id;
    },
    [messages],
  );

  const handleSendMessage = useCallback(() => {
    if (text) {
      dispatch(sendTextMessage(userId, text));
      setText('');
    }
  }, [dispatch, text, userId]);

  const handleSendMessageByEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSendMessage();
      }
    },
    [handleSendMessage],
  );

  const handleSendImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      dispatch(sendImageMessage(userId, file));
      e.target.value = '';
    },
    [dispatch, userId],
  );

  const handleOpenGift = useCallback(() => {
    dispatch(
      openGiftDrawer({
        userId,
        location: GiftLocation.Chat,
      }),
    );
  }, [dispatch, userId]);

  return (
    <>
      <PhotoSwipe
        open={photo.length > 0}
        items={photo}
        onClose={() => {
          setPhoto([]);
        }}
      />
      <Box display="flex" flexDirection="column" flex="1">
        <Box flex="1">
          <AutoSizer>
            {({height, width}) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={messages.length}
                loadMoreItems={loadMore}
                threshold={2}>
                {({onItemsRendered, ref}) => {
                  return (
                    <VariableSizeList
                      key={width}
                      itemKey={itemKey}
                      onItemsRendered={onItemsRendered}
                      ref={(ele) => {
                        (ref as any)(ele);
                        (list as any).current = ele;
                      }}
                      width={width}
                      height={height}
                      itemSize={countHeight}
                      itemCount={messages.length}>
                      {renderChatBubble}
                    </VariableSizeList>
                  );
                }}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Box>

        <Box display="flex" p={2} className="safeBottom">
          <ClickAwayListener onClickAway={() => setInputFocused(false)}>
            <Box position="relative" width="100%">
              <TextField
                variant="outlined"
                size="small"
                placeholder={inputFocused ? undefined : 'input.send_message_placeholder'}
                fullWidth
                className={styles.roundInput}
                onFocus={() => setInputFocused(true)}
                focused={inputFocused}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleSendMessageByEnter}
                InputProps={{
                  endAdornment: inputFocused && (
                    <InputAdornment position="end">
                      <Button size="small" className="roundInputButton" onClick={handleSendMessage}>
                        <Typography color="primary">common.submit</Typography>
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                position="absolute"
                display={inputFocused && !text ? 'flex' : 'none'}
                left={12}
                top={0}
                bottom={0}
                alignItems="center">
                <Typography color="textSecondary">common.by</Typography>
                <Box display="flex" alignItems="center" mx={1}>
                  <div className="diamond" />
                  <Typography color="primary">
                    {messenger.freeLimit > 0
                      ? 'label.free_limit'
                      : messenger.price?.toString() ?? '0'}
                  </Typography>
                </Box>
                <Typography color="textSecondary">input.send_message_hint</Typography>
              </Box>
            </Box>
          </ClickAwayListener>

          {!inputFocused && (
            <>
              <IconButton className={`outlinedIconButton ${styles.gift}`} onClick={handleOpenGift}>
                <Icon svg={GiftIcon} />
              </IconButton>

              <IconButton component="label" className={`outlinedIconButton ${styles.camera}`}>
                <Icon svg={CameraIcon} />
                <input className="hidden" type="file" accept=".jpg" onChange={handleSendImage} />
              </IconButton>

              {/* <IconButton className={`outlinedIconButton ${styles.mic}`}>
              <Icon svg={MicrophoneIcon} />
            </IconButton> */}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Conversation;
