import {AppBar, Box, Button, Toolbar} from '@material-ui/core';
import {ErrorCode} from 'api';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {getGold} from 'model/wallet/WalletSelector';

import WalletAPI from 'api/wallet';

import {useDebounce} from 'utils/hook';

import BackButton from 'components/BackButton';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

function GoldTransformPage() {
  const history = useHistory();
  const gold = useSelector(getGold);
  const [goldInput, setGoldInput] = useState('');
  const [inputValue] = useDebounce(goldInput, 1000);
  const [error, setError] = useState<ErrorCode | undefined>(undefined);

  const transform = useCallback(() => {
    // console.log('transform', gold);
    WalletAPI.transformGold(parseFloat(goldInput))
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        setError(error?.err_code || error);
      });
  }, [history, goldInput]);

  useEffect(() => {
    const n = parseFloat(inputValue);
    if (n > gold) {
      setGoldInput(gold.toString());
    }
  }, [inputValue, gold]);

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/profile/gold/distribution" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.gold_transform
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end">
            <Box mr={-1}>
              <Button onClick={transform}>
                <Typography color="primary">common.submit</Typography>
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box m={2}>
        <TextField
          placeholder="input.gold_transform_placeholder"
          variant="outlined"
          fullWidth
          type="number"
          inputProps={{
            pattern: '[0-9]*',
          }}
          value={goldInput}
          onChange={(e) => setGoldInput(e.target.value)}
          errorCode={error}
        />
        <Box my={1}>
          <Typography color="textSecondary">unit.gold_to_point</Typography>
        </Box>
      </Box>
    </>
  );
}

export default GoldTransformPage;
