import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

import {AppDispatch} from 'model/helper';
import {unlockMessage} from 'model/message/MessageAction';
import {MessageModel, MessageType} from 'model/message/MessageTypes';

import {ReactComponent as LockIcon} from 'assets/icon/regular/lock.svg';
import {ReactComponent as UnlockIcon} from 'assets/icon/regular/unlock.svg';

import AudioButton from './AudioButton';
import Icon from './Icons';
import Typography from './i18n/Typography';

interface ChatBubbleProps {
  message: MessageModel.Message;
  senderId: string;
  showAvatar: boolean;
  avatarSrc?: string;
  onClickContent?: () => void;
}

const videoHeight: CSSProperties = {
  height: 150,
  width: 267,
  maxWidth: '60vw',
};

function ChatBubble({message, senderId, showAvatar, avatarSrc, onClickContent}: ChatBubbleProps) {
  const isOther = message.senderId === senderId;
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();

  const [showDialog, setShowDialog] = useState(false);

  const openDialog = useCallback(() => {
    if (!message.unlocked) {
      setShowDialog(true);
    }
  }, [message]);

  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  const unlock = useCallback(() => {
    if (message.unlocked) return;

    setShowDialog(false);
    dispatch(unlockMessage(message.senderId, message.id));
  }, [dispatch, message]);

  const UnlockDialog = useCallback(
    (props: {open: boolean}) => {
      return (
        <Dialog open={props.open} onClose={() => setShowDialog(false)}>
          <DialogTitle>
            <Typography variant="h6">{`enum.unlockMedia.${message.type}`}</Typography>
          </DialogTitle>
          <DialogContent>
            <Box display="flex">
              <Typography component="span" color="textSecondary">
                paragraph.unlock_message.0
              </Typography>
              <Box display="flex" component="span" alignItems="center" mx={1}>
                <div className="diamond" />
                <Text>{message.unlockPoint}</Text>
              </Box>
              <Typography component="span" color="textSecondary">
                paragraph.unlock_message.1
              </Typography>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={closeDialog}>
              <Typography>common.cancel</Typography>
            </Button>
            <Button color="primary" autoFocus onClick={unlock}>
              <Typography>common.agree</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    [message, closeDialog, unlock],
  );

  switch (message.type) {
    case MessageType.DateLabel:
      return (
        <Box display="flex" justifyContent="center">
          <Chip label={message.message} size="small" />
        </Box>
      );
    case MessageType.Picture:
      return (
        <Box
          display="flex"
          justifyContent={isOther ? 'flex-start' : 'flex-end'}
          ml={isOther ? 7 : 2}
          mr={2}>
          {isOther && showAvatar && (
            <Box position="absolute" left={0} bottom={16} ml={2}>
              <Avatar className="avatar" src={avatarSrc} />
            </Box>
          )}

          <Badge
            component="div"
            badgeContent={
              <IconButton
                className={message.unlocked ? styles.unlockedButton : styles.lockedButton}
                onClick={openDialog}>
                <Icon svg={message.unlocked ? UnlockIcon : LockIcon} fontSize="small" />
              </IconButton>
            }
            invisible={message.unlockPoint === 0}>
            <img className="messagePic" src={message.message} alt="" onClick={onClickContent} />
          </Badge>

          <UnlockDialog open={showDialog} />
        </Box>
      );
    case MessageType.Gift:
      return (
        <Box display="flex" justifyContent="center">
          <Box
            className={styles.giftBubble}
            display="flex"
            flexDirection="column"
            px={4}
            py={1}
            textAlign="center"
            alignItems="center">
            <Text component="span" color="textSecondary">
              <Typography>message.gave</Typography> {message.giftName}
            </Text>

            <Box width={80} height={80}>
              <img
                alt={message.giftName}
                src={message.giftPicture}
                className="fullMedia"
                onClick={onClickContent}
              />
            </Box>
          </Box>
        </Box>
      );
    case MessageType.Audio:
      return (
        <Box
          display="flex"
          justifyContent={isOther ? 'flex-start' : 'flex-end'}
          ml={isOther ? 7 : 2}
          mr={2}>
          {isOther && showAvatar && (
            <Box position="absolute" left={0} top={10} ml={2}>
              <Avatar className="avatar" src={avatarSrc} />
            </Box>
          )}
          <AudioButton src={message.message} />
        </Box>
      );
    case MessageType.Video:
      return (
        <Box
          display="flex"
          justifyContent={isOther ? 'flex-start' : 'flex-end'}
          ml={isOther ? 7 : 2}
          mr={2}>
          {isOther && showAvatar && (
            <Box position="absolute" left={0} bottom={16} ml={2}>
              <Avatar className="avatar" src={avatarSrc} />
            </Box>
          )}

          <Badge
            component="div"
            badgeContent={
              <IconButton
                className={message.unlocked ? styles.unlockedButton : styles.lockedButton}
                onClick={openDialog}>
                <Icon svg={message.unlocked ? UnlockIcon : LockIcon} fontSize="small" />
              </IconButton>
            }
            invisible={message.unlockPoint === 0}>
            <video
              preload="auto"
              controls
              style={videoHeight}
              muted
              autoPlay
              playsInline
              onLoadedData={(e) => {
                e.currentTarget.pause();
                e.currentTarget.currentTime = 0;
              }}
              onClick={onClickContent}>
              <source src={message.message} type="video/mp4" />
            </video>
          </Badge>
          <UnlockDialog open={showDialog} />
        </Box>
      );
    case MessageType.Chat:
    default:
      return (
        <Box
          display="flex"
          justifyContent={isOther ? 'flex-start' : 'flex-end'}
          ml={isOther ? 7 : 2}
          mr={2}>
          {isOther && showAvatar && (
            <Box position="absolute" left={0} bottom={8} ml={2}>
              <Avatar className="avatar" src={avatarSrc} />
            </Box>
          )}
          <Box py={1} px={2} className={isOther ? styles.textBubbleHollow : styles.textBubbleSolid}>
            <Text className="mono" onClick={onClickContent}>
              {message.message}
            </Text>
          </Box>
        </Box>
      );
  }
}

export default ChatBubble;
