import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import AuthCreateByPhone from './AuthCreateByPhone';
import AuthCreatePage from './AuthCreatePage';
import AuthLoginPage from './AuthLoginPage';
import ThirdPartyAuthPage from './ThirdPartyAuthPage';

function AuthRoutes() {
  return (
    <Switch>
      <Route path="/auth/login">
        <AuthLoginPage />
      </Route>
      <Route path="/auth/create">
        <AuthCreatePage />
      </Route>
      <Route path="/auth/phone">
        <AuthCreateByPhone />
      </Route>
      <Route path="/auth/:provider(facebook|google|line)">
        <ThirdPartyAuthPage />
      </Route>
      <Redirect to={{pathname: '/auth/login'}} />
    </Switch>
  );
}

export default AuthRoutes;
