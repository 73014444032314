import {
  AppBar,
  Box,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography as Text,
  useTheme,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import AutoSizer from 'react-virtualized-auto-sizer';

import {StorageKeys} from 'model/helper';
import {getMyInfo} from 'model/user/UserSelector';
import {getGold} from 'model/wallet/WalletSelector';

import {format} from 'utils/number';
import {deleteSearchQuery, genSearchQuery, getSearchQuery} from 'utils/string';

import BackButton from 'components/BackButton';
import QRCodeDialog from 'components/QRCodeDialog';
import TabPanel from 'components/TabPanel';
import CoronaText from 'components/i18n/CoronaText';
import Typography from 'components/i18n/Typography';

import GoldDistribution from './GoldDistribution';
import GoldLogs from './GoldLogs';

function GoldRecordsPage() {
  const styles = useThemeStyles();
  const theme = useTheme();
  const history = useHistory();
  const {tab} = useParams<{tab: 'distribution' | 'logs'}>();
  const tabIndex = tab === 'distribution' ? 0 : 1;
  const info = useSelector(getMyInfo);

  const location = useLocation();
  const openQR = getSearchQuery(location.search, StorageKeys.qrCode) === '1';

  const gold = useSelector(getGold);

  const toTransform = useCallback(() => {
    history.push('/profile/gold/transform');
  }, [history]);

  const handleTabChange = useCallback(
    (_, v: number) => {
      if (v === 0) {
        history.replace('/profile/gold/distribution');
      } else {
        history.replace('/profile/gold/logs');
      }
    },
    [history],
  );

  const openQRCode = useCallback(() => {
    history.replace({
      pathname: '/profile/gold/distribution',
      search: genSearchQuery(history.location.search, StorageKeys.qrCode, '1'),
    });
  }, [history]);

  const closeQRCode = useCallback(() => {
    history.replace({
      pathname: '/profile/gold/distribution',
      search: deleteSearchQuery(history.location.search, StorageKeys.qrCode),
    });
  }, [history]);

  return (
    <Box className="full" display="flex" flexDirection="column">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/profile" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.gold
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <Box
        textAlign="center"
        position="relative"
        borderRadius={2.5}
        className={`${styles.bg} gradientBorder ${styles.gradientBefore} ${styles.goldHeader}`}
        p={1}
        pb={4}
        m={2}
        mb={4}>
        <CoronaText text="label.current_gold" shadow={theme.palette.success[theme.palette.type]} />

        <Text variant="h3">{format(gold)}</Text>

        <Box
          position="absolute"
          bottom={-15}
          left={0}
          right={0}
          display="flex"
          justifyContent="center">
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            className="gradientButtonWrapper">
            <Box
              borderRadius={16}
              className={[styles.gradient, 'gradientButton', 'overlay'].join(' ')}></Box>
            <Button variant="outlined" size="small" onClick={toTransform}>
              <Typography>link.gold_transform</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <AppBar position="static" color="default">
        <Tabs variant="fullWidth" value={tabIndex} className={styles.bg} onChange={handleTabChange}>
          <Tab label={<Typography>title.distribution</Typography>} aria-label="distribution" />
          <Tab label={<Typography>title.logs</Typography>} aria-label="logs" />
        </Tabs>
      </AppBar>

      <Box flex={1}>
        <AutoSizer>
          {({height, width}) => {
            const style: CSSProperties = {width};
            return (
              <SwipeableViews
                width={width}
                style={style}
                slideStyle={style}
                index={tabIndex}
                enableMouseEvents
                disabled>
                <TabPanel value={tabIndex} index={0}>
                  <GoldDistribution onClickQR={openQRCode} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <GoldLogs width={width} height={height} />
                </TabPanel>
              </SwipeableViews>
            );
          }}
        </AutoSizer>
      </Box>

      <QRCodeDialog
        open={openQR}
        onClose={closeQRCode}
        userId={info.userId}
        userPicture={info.picture}
        userName={info.name}
      />
    </Box>
  );
}

export default GoldRecordsPage;
