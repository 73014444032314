import {
  Avatar,
  Box,
  ButtonBase,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties} from 'react';

import {LivestreamModel} from 'model/livestream/LivestreamTypes';

import {ReactComponent as EyeIcon} from 'assets/icon/solid/eye.svg';

import RatingLabel from './AgeRestrictionLabel';
import Icon from './Icons';

interface LivestreamCardProps {
  livestream?: LivestreamModel.StreamInfo;
  style?: CSSProperties;
  onClick?: () => void;
  showLive?: boolean;
  showAvatar?: boolean;
}

function LivestreamCard({
  livestream,
  style,
  onClick,
  showLive = false,
  showAvatar = true,
}: LivestreamCardProps) {
  const styles = useThemeStyles();

  return (
    <Box position="relative" style={style} className={styles.livestreamCard}>
      {livestream && (
        <>
          <img alt="cover" src={livestream?.cover} className="fullMedia" />
          <ButtonBase focusRipple component="div" className="overlay" onClick={onClick}>
            <Box display="flex" flexDirection="column" className="full">
              <Box p={1} display="flex" justifyContent="space-between">
                <Chip
                  size="small"
                  icon={<Icon svg={EyeIcon} />}
                  label={livestream.numWatchers}
                  className="watchers"
                />

                {showLive && (
                  <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    className="gradientButtonSmallWrapper">
                    <Box
                      className={['overlay', styles.gradient, 'gradientButtonSmall'].join(' ')}
                    />

                    <Chip
                      label={<Text>LIVE</Text>}
                      variant="outlined"
                      className={`${styles.chip} live`}
                    />
                  </Box>
                )}
              </Box>
              <Box flex="1" />
              <Box px={1} display="flex" justifyContent="space-between" pb={1}>
                {showAvatar && (
                  <ListItem component="div" disableGutters className="noPadding">
                    <ListItemAvatar>
                      <Avatar
                        className="avatar"
                        alt={livestream.userName}
                        src={livestream.userPicture}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      inset={false}
                      primary={livestream.userName}
                      secondary={livestream.title}
                      primaryTypographyProps={{variant: 'body2', className: 'bold'}}
                      secondaryTypographyProps={{color: 'textPrimary', variant: 'body1'}}
                      className="noMargin"
                    />
                  </ListItem>
                )}

                <Box>
                  <Box>
                    <span className="toy" />
                  </Box>
                  <Box>
                    <RatingLabel rating={livestream.rating} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </ButtonBase>
        </>
      )}
    </Box>
  );
}

export default LivestreamCard;
