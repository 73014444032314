import {Avatar, Box, Button, Dialog, IconButton, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {loadQRCode} from 'model/user/UserActions';
import {getQRCode} from 'model/user/UserSelector';

import {ReactComponent as DownloadIcon} from 'assets/icon/regular/download.svg';

import Icon from './Icons';
import Typography from './i18n/Typography';

interface QRCodeDialogProps {
  open: boolean;
  onClose(): void;

  userId: string;
  userPicture: string;
  userName: string;

  showLink?: boolean;
}

const buttonStyle: CSSProperties = {borderRadius: 16};

function QRCodeDialog({
  open,
  onClose,
  userId,
  userPicture,
  userName,
  showLink = false,
}: QRCodeDialogProps) {
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();
  const qrcode = useSelector(getQRCode);

  useEffect(() => {
    if (open && userId && !qrcode) {
      dispatch(loadQRCode(userId));
    }
  }, [dispatch, open, userId, qrcode]);

  const download = useCallback(() => {
    if (!qrcode) {
      return;
    }

    const a = document.createElement('a');
    a.href = qrcode.image;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [qrcode]);

  const copyInviteCode = useCallback(() => {
    navigator.clipboard.writeText(qrcode?.link ?? '');
  }, [qrcode?.link]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{style: {overflowY: 'unset'}, className: styles.transparentBg}}>
      <Box
        width="90vw"
        maxWidth={288}
        textAlign="center"
        position="relative"
        borderRadius={8}
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={`gradientBg ${styles.gradientBefore}`}
        p={0.5}
        py={2}>
        <Avatar src={userPicture} className="avatar85" />

        <Box m={1}>
          <Text variant="h5">{userName}</Text>
        </Box>

        <Box mb={1}>
          <Typography component="p">paragraph.invite.0</Typography>
          <Typography component="p">paragraph.invite.1</Typography>
          <Typography component="p">paragraph.invite.2</Typography>
          <Typography component="p">paragraph.invite.3</Typography>
          <Typography component="p">paragraph.invite.4</Typography>
        </Box>

        {showLink && (
          <Link to="/profile/gold/distribution">
            <Typography>common.see_details</Typography>
          </Link>
        )}

        <Box
          position="relative"
          display="flex"
          alignItems="center"
          className="gradientButtonWrapper"
          my={2}>
          <Box
            borderRadius={16}
            className={[styles.gradient, 'gradientButton', 'overlay'].join(' ')}></Box>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            style={buttonStyle}
            onClick={copyInviteCode}>
            <Typography keys={{code: qrcode?.invite}}>input.copy_qr</Typography>
          </Button>
        </Box>

        <Box width={100} height={100} mb={1}>
          {qrcode && <img src={qrcode.image} alt="qr" className="full" />}
        </Box>

        <IconButton onClick={download}>
          <Icon svg={DownloadIcon} />
        </IconButton>
      </Box>
    </Dialog>
  );
}

export default QRCodeDialog;
