import {AppBar, Box, Button, Drawer, Paper, Tab, Tabs, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeGrid, GridChildComponentProps} from 'react-window';

import {closeGiftDrawer, loadGifts} from 'model/gift/GiftAction';
import {
  getGiftCategories,
  getGiftLocation,
  getGifts,
  isGiftDrawerOpened,
} from 'model/gift/GiftSelector';
import {GiftLocation} from 'model/gift/GiftTypes';
import {closePaymentDrawer, openPaymentDrawer, updateWallet} from 'model/wallet/WalletAction';
import {getBalance} from 'model/wallet/WalletSelector';

import {format} from 'utils/number';

import TabPanel from 'components/TabPanel';
import Typography from 'components/i18n/Typography';

import GiftItem from './GiftItem';

const CELL_PER_ROW = 4;

function GiftDrawer() {
  const styles = useThemeStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [index, setIndex] = useState(0);

  const opened = useSelector(isGiftDrawerOpened);
  const giftLocation = useSelector(getGiftLocation);
  const categories = useSelector(getGiftCategories);
  const gifts = useSelector(getGifts);
  const balance = useSelector(getBalance);

  const handleCloseDrawer = useCallback(() => {
    dispatch(closeGiftDrawer());
  }, [dispatch]);

  const handleOpenPayment = useCallback(() => {
    dispatch(
      openPaymentDrawer(() => {
        dispatch(closePaymentDrawer());
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadGifts());
  }, [dispatch]);

  useEffect(() => {
    handleCloseDrawer();
  }, [history.location, handleCloseDrawer]);

  useEffect(() => {
    if (opened) {
      dispatch(updateWallet());
    }
  }, [dispatch, opened]);

  const renderCell = useCallback(
    ({rowIndex, columnIndex, style, data}: GridChildComponentProps) => {
      const list = categories[data].list;
      const giftId = list[rowIndex * CELL_PER_ROW + columnIndex] || undefined;

      if (!giftId) {
        return <Box style={style}></Box>;
      }

      const gift = gifts[giftId];

      return (
        <Box style={style}>
          <GiftItem item={gift} enableCombo={giftLocation === GiftLocation.Stream} />
        </Box>
      );
    },
    [gifts, categories, giftLocation],
  );

  return (
    <Drawer
      className="giftDrawer"
      anchor="bottom"
      open={opened}
      onClose={handleCloseDrawer}
      PaperProps={{className: styles.transparentBg}}>
      <AppBar position="static" color="default" className="transparentTabs">
        <Tabs
          value={index}
          onChange={(_, i) => setIndex(i)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto">
          {categories.map((c, i) => (
            <Tab key={i} label={c.name} />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews index={index} onChangeIndex={(i) => setIndex(i)} enableMouseEvents>
        {categories.map((c, i) => (
          <TabPanel key={i} value={index} index={i}>
            <Box height="30vh">
              <AutoSizer>
                {({height, width}) => (
                  <FixedSizeGrid
                    columnCount={4}
                    rowCount={Math.ceil(c.list.length / CELL_PER_ROW)}
                    columnWidth={width / CELL_PER_ROW}
                    rowHeight={width / CELL_PER_ROW + 30}
                    width={width}
                    height={height}
                    itemData={i}>
                    {renderCell}
                  </FixedSizeGrid>
                )}
              </AutoSizer>
            </Box>
          </TabPanel>
        ))}
      </SwipeableViews>
      <Paper>
        <Box display="flex" alignItems="center" px={2} py={1} className="safeBottom">
          <div className="diamond" />
          <Text>{format(balance)}</Text>
          <Box flex="1" />
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            className="gradientChipWrapper">
            <Box
              position="absolute"
              left={0}
              right={0}
              top={0}
              className={[styles.gradient, 'gradientChip'].join(' ')}
            />
            <Button variant="outlined" size="small" onClick={handleOpenPayment}>
              <Typography>input.deposit</Typography>
            </Button>
          </Box>
        </Box>
      </Paper>
    </Drawer>
  );
}

export default GiftDrawer;
