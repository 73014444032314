import {API} from 'api';

import {
  UserRole,
  Gender,
  MemberStatus,
  RegisterType,
  LoginProvider,
  ActivityType,
  StreamerRecommendationType,
} from 'model/user/UserTypes';

import MessageAPI, {FileType} from './message';

export default class UserAPI {
  public static normalizePhone(countryCode: string, phone: string) {
    return `${countryCode.substr(1)}${phone[0] === '0' ? phone.substr(1) : phone}`;
  }

  public static sendSMS(req: SMSRequest) {
    return API.post(
      '/me/sms',
      {
        phone_number: req.phone,
        country_code: req.countryCode,
      },
      false,
    );
  }

  public static verifyPhone(req: VerifySNSRequest) {
    return API.post(
      '/me/phoneVerification',
      {
        phone_number: req.phone,
        country_code: req.countryCode,
        verify_code: req.otp,
      },
      false,
    );
  }

  public static register(req: RegisterRequest) {
    const body: any = {
      name: req.name,
      account: req.account,
      register_type: req.type,
    };

    if (req.countryCode) {
      body.phone_number = req.phone;
      body.country_code = req.countryCode;
    }

    if (req.password) {
      body.password = req.password;
    }

    if (req.otp) {
      body.verify_code = req.otp;
    }

    if (req.invite) {
      body.invite_code = req.invite;
    }

    return API.post('/users', body, false);
  }

  public static login(req: LoginRequest) {
    return API.post<{token: string; auth_key: string}>(
      '/me/token',
      {
        account: UserAPI.normalizePhone(req.countryCode, req.phone),
        password: req.password,
      },
      false,
    );
  }

  public static resetPassword(req: ResetPasswordRequest) {
    return API.post('/me/reset_password', {
      phone_number: req.phone,
      verify_code: req.otp,
      country_code: req.countryCode,
      new_password: req.password,
    });
  }

  public static thirdPartyLogin(provider: LoginProvider, accessToken: string) {
    return API.postWithStatus<{token: string; auth_key: string} | undefined>(
      `/me/login/${provider}`,
      {token: accessToken},
      false,
    );
  }

  public static linkAccount(provider: LoginProvider, accessToken: string) {
    return API.post(`/me/account_linked/${provider}`, {token: accessToken});
  }

  public static unlinkAccount(provider: LoginProvider) {
    return API.delete(`/me/info/${provider}`);
  }

  public static getLinkingStatus() {
    return API.get<{
      status: {
        account: string;
        facebook: boolean;
        google: boolean;
        line: boolean;
        name: string;
        phone_number: string;
      };
    }>('/me/account_linked/state');
  }

  public static follow(id: string) {
    return API.put('/me/following', {
      ids: [id],
    });
  }

  public static unfollow(id: string) {
    return API.delete('/me/following', {
      ids: [id],
    });
  }

  public static getInfo(userId: string) {
    return API.get<{user: UserResponse}>(`/users/${userId}`);
  }

  public static getMe() {
    return API.get<MyUserResponse>('/me');
  }

  public static getQRCode(userId: string) {
    return API.get<{
      invite_code: string;
      invite_url: string;
      qr_url: string;
    }>(`/users/${userId}/invite_qrcode`);
  }

  public static async updateProfile(
    userId: string,
    picture: Blob | null,
    bio: string | null,
    name: string | null,
  ) {
    const updater: any = {};
    if (picture) {
      const {file_id} = await MessageAPI.upload(picture, FileType.JPEG);
      updater.picture = file_id;
    }
    if (bio) {
      updater.bio = bio;
    }
    if (name) {
      updater.name = name;
    }

    return API.patch(`/users/${userId}`, {updater});
  }

  public static getFolloweeList(userId: string, cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; users: UserResponse[] | null}>(
      `/users/${userId}/following/list`,
      params,
    );
  }

  public static getFollowerList(userId: string, cursor: string | null) {
    const params: any = {};
    if (cursor) {
      params.cursor = cursor;
    }

    return API.get<{cursor: string; users: UserResponse[] | null}>(
      `/users/${userId}/follower/list`,
      params,
    );
  }

  public static getActivity() {
    return API.get<{
      activities: Array<{
        is_follow: boolean;
        post_picture: string;
        streamer_name: string;
        streamer_picture: string;
        record: {
          created_at: string;
          object_id: string;
          type: ActivityType;
          updated_at: string;
          user_id: string;
        };
      }>;
      recommended_streamer_list: Array<{
        follower_count: number;
        name: string;
        picture: string;
        streamer_type: StreamerRecommendationType;
        user_id: string;
      }>;
    }>('/me/activity');
  }
}

interface SMSRequest {
  phone: string;
  countryCode: string;
}

interface VerifySNSRequest extends SMSRequest {
  otp: string;
}

interface RegisterRequest {
  name: string;
  countryCode?: string;
  phone?: string;
  account?: string;
  password?: string;
  otp: string;
  type: RegisterType;
  invite?: string;
}

interface ResetPasswordRequest {
  phone: string;
  countryCode: string;
  otp: string;
  password: string;
}

interface LoginRequest extends SMSRequest {
  password: string;
}

export interface UserResponse {
  id: number;
  user_id: string;

  bio: string;
  covers: string[];
  followee_count: number;
  follower_count: number;
  post_count: number;
  gender: Gender;
  invite_code: string;
  is_follow: boolean;
  name: string;
  picture: string;
  stream_id: string;
  voice_bio: string;
}

export interface MyUserResponse extends UserResponse {
  external_id: number;

  role: UserRole;
  register_at: string;
  login_at: string;
  device_type: string;
  badge: number;
  age: number;
  status: MemberStatus;
  country_code: string;
  register_type: RegisterType;
  account: string;
  phone_number: string;
  point: number;
  gold: number;
  note: string;
  push_token: string;
  ip: string;
  credit_money: number;
}
