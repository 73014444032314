import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  Typography as Text,
} from '@material-ui/core';
import React, {CSSProperties, MouseEvent, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ExploreModel, SearchType} from 'model/explore/ExploreTypes';
import {AppDispatch} from 'model/helper';
import {followUser, unfollowUser} from 'model/user/UserActions';
import {isUserFollowed} from 'model/user/UserSelector';

import Typography from './i18n/Typography';

interface SearchItemProps {
  item: ExploreModel.SearchedItem;
  onClick?: () => void;
  ContainerComponent?: ListItemProps['ContainerComponent'];
  style?: CSSProperties;
}

function SearchItem({item, onClick, ContainerComponent, style}: SearchItemProps) {
  const dispatch = useDispatch<AppDispatch>();
  const followed = useSelector(isUserFollowed)(item.keyword) ?? item.followed;

  const handleFollow = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (item.type === SearchType.Tag) {
        return;
      }

      if (followed) {
        dispatch(unfollowUser(item.keyword));
      } else {
        dispatch(followUser(item.keyword));
      }
    },
    [dispatch, followed, item],
  );

  return (
    <ListItem
      button
      onClick={onClick}
      ContainerComponent={ContainerComponent}
      style={style}
      ContainerProps={{style}}>
      <ListItemAvatar>
        {item.type === SearchType.Streamer ? (
          <Avatar className="avatar44" src={item.cover} />
        ) : (
          <Avatar className="avatar44 noBgColor" src={item.cover}>
            <Text variant="h2" color="textPrimary">
              #
            </Text>
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Text>{item.title} </Text>

            {item.type === SearchType.Streamer && (
              <Button size="small" className="followButton" onClick={handleFollow}>
                <Typography color={followed ? 'textPrimary' : 'primary'}>
                  {followed ? 'input.unfollow' : 'input.follow'}
                </Typography>
              </Button>
            )}
          </Box>
        }
        secondary={item.subtitle || '　'}
        secondaryTypographyProps={{
          className: 'oneLine',
        }}
      />
    </ListItem>
  );
}

export default SearchItem;
