import {CssBaseline} from '@material-ui/core';
import i18next, {Locale, LocaleProvider} from 'context/Locale';
import {theme, ThemeProvider} from 'context/Theme';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import {Route, Switch, useLocation} from 'react-router-dom';

import {authorized, getMyInfo} from 'model/user/UserSelector';

import Analysis from 'api/analysis';

import PWANotification from 'components/PWANotification';
import GiftDrawer from 'components/drawer/GiftDrawer';
import PaymentDrawer from 'components/drawer/PaymentDrawer';

import BottomNavigationRoutes from './BottomNavigationRoutes';
import AuthResetPage from './auth/AuthResetPage';
import AuthRoutes from './auth/AuthRoutes';
import RecommendationPage from './auth/RecommendationPage';
import UserStoryPage from './explore/UserStoryPage';
import CommentsPage from './home/CommentsPage';
import LivestreamGridPage from './livestream/LivestreamGridPage';
import LivestreamPage from './livestream/LivestreamPage';
import ConversationPage from './mailbox/ConverationPage';
import FAQPage from './profile/FAQPage';
import GoldRecordsPage from './profile/GoldRecordsPage';
import GoldTransformPage from './profile/GoldTransformPage';
import ProfileEditPage from './profile/ProfileEditPage';
import ProfileFollowPage from './profile/ProfileFollowPage';
import ProfileLinkedPage from './profile/ProfileLinkedPage';
import ProfileSettingPage from './profile/ProfileSettingPage';
import SavedStoriesPage from './profile/SavedStoriesPage';
import UnlockedStoriesPage from './profile/UnlockedStoriesPage';
import WalletPage from './profile/WalletPage';

interface AppProps {
  os: 'ios' | 'android' | 'other';
  standalone: boolean;
}

function App({os, standalone}: AppProps) {
  const location = useLocation();
  const user = useSelector(getMyInfo);
  const [lang, setLang] = useState<Locale>('loading');
  const isAuth = useSelector(authorized);
  const [pwa, setPwa] = useState(!standalone && os === 'ios');

  useEffect(() => {
    i18next.on('languageChanged', (lng: Locale) => {
      setLang(lng);
    });
  }, []);

  useEffect(() => {
    Analysis.setConfig({id: user.userId});
  }, [user.userId]);

  useEffect(() => {
    Analysis.view();
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <LocaleProvider value={lang}>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA}`}
          />
        </Helmet>

        <CssBaseline />

        <PaymentDrawer />
        <GiftDrawer />

        <Switch>
          <Route path="/faq">
            <FAQPage />
          </Route>

          <Route path="/auth/reset">
            <AuthResetPage />
          </Route>

          {!isAuth && (
            <Route path="/auth">
              <AuthRoutes />
            </Route>
          )}

          {isAuth && (
            <Route path="/recommended">
              <RecommendationPage />
            </Route>
          )}

          {isAuth && (
            <Route path="/post/:id/comments">
              <CommentsPage />
            </Route>
          )}

          {isAuth && (
            <Route path="/livestream/:id">
              <LivestreamPage />
            </Route>
          )}

          {isAuth && (
            <Route path="/livestream">
              <LivestreamGridPage />
            </Route>
          )}

          {isAuth && (
            <Route path="/message/:id">
              <ConversationPage />
            </Route>
          )}

          {isAuth && (
            <Route path="/profile/unlocked">
              <UnlockedStoriesPage />
            </Route>
          )}
          {isAuth && (
            <Route path="/profile/saved">
              <SavedStoriesPage />
            </Route>
          )}

          {isAuth && (
            <Route exact path="/profile/setting">
              <ProfileSettingPage />
            </Route>
          )}
          {isAuth && (
            <Route path="/profile/setting/link">
              <ProfileLinkedPage />
            </Route>
          )}

          {isAuth && (
            <Route path="/profile/edit">
              <ProfileEditPage />
            </Route>
          )}
          {isAuth && (
            <Route path="/profile/follow/:type(follower|followee)">
              <ProfileFollowPage />
            </Route>
          )}
          {isAuth && (
            <Route path="/profile/gold/transform">
              <GoldTransformPage />
            </Route>
          )}
          {isAuth && (
            <Route path="/profile/gold/:tab(distribution|logs)">
              <GoldRecordsPage />
            </Route>
          )}
          {isAuth && (
            <Route exact path="/profile/wallet/:type(usage|purchase|bonus)">
              <WalletPage />
            </Route>
          )}
          {isAuth && (
            <Route path="/profile/:id">
              <UserStoryPage prev="/" />
            </Route>
          )}
          <Route path="/">
            <BottomNavigationRoutes />
          </Route>
        </Switch>

        <PWANotification
          os={os}
          open={isAuth && pwa && !location.pathname.startsWith('/livestream/')}
          onClose={() => setPwa(false)}
          onOpen={() => setPwa(true)}
        />
      </LocaleProvider>
    </ThemeProvider>
  );
}

export default App;
