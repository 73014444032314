import React, {CSSProperties, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {loadBanners} from 'model/post/PostAction';
import {getBanner} from 'model/post/PostSelector';

import {useWindowSize} from 'utils/hook';

import Carousel from './Carousel';

export const BANNER_RATIO = 2;

interface BannerProps {
  style?: CSSProperties;
}

function Banner({style}: BannerProps) {
  const banner = useSelector(getBanner);
  const dispatch = useDispatch<AppDispatch>();
  const width = useWindowSize();
  const height = width / BANNER_RATIO;

  const bannerStyle: CSSProperties = {
    width,
    height,
  };

  useEffect(() => {
    if (banner.items.length === 0) {
      dispatch(loadBanners());
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Carousel interval={banner.interval} style={style}>
      {banner.items.map((banner) =>
        banner.link?.startsWith('http') ? (
          <a key={banner.id} href={banner.link} target="_blank" rel="noreferrer">
            <img src={banner.picture} alt={banner.title} style={bannerStyle} />
          </a>
        ) : (
          <Link key={banner.id} to={banner.link || '#'}>
            <img src={banner.picture} alt={banner.title} style={bannerStyle} />
          </Link>
        ),
      )}
    </Carousel>
  );
}

export default Banner;
