import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography as Text,
  useTheme,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppDispatch, StorageKeys} from 'model/helper';
import {
  closePaymentDrawer,
  loadPaymentMethods,
  loadPaymentProducts,
  purchaseProduct,
} from 'model/wallet/WalletAction';
import {getPaymentMethods, getProducts, isPaymentDrawerOpened} from 'model/wallet/WalletSelector';
import {PaymentMethod} from 'model/wallet/WalletTypes';

import Icon from 'components/Icons';
import CoronaText from 'components/i18n/CoronaText';
import Typography from 'components/i18n/Typography';

import {ReactComponent as ATMIcon} from 'assets/icon/regular/atm.svg';
import {ReactComponent as ConvenientStoreIcon} from 'assets/icon/regular/convenient-store.svg';
import {ReactComponent as CreditCardIcon} from 'assets/icon/regular/credit-card.svg';
import {ReactComponent as CurrencyIcon} from 'assets/icon/regular/currency-swap.svg';
import {ReactComponent as UnionPayIcon} from 'assets/icon/regular/union-pay.svg';
import {ReactComponent as CaretIcon} from 'assets/icon/solid/caret-up.svg';

let defaultMethod =
  (localStorage.getItem(StorageKeys.payment) as PaymentMethod) || PaymentMethod.CreditCard;

function PaymentDrawer() {
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const [selectedProduct, setSelectedProduct] = useState('');
  const opened = useSelector(isPaymentDrawerOpened);
  const methods = useSelector(getPaymentMethods);
  const products = useSelector(getProducts);

  const [more, setMore] = useState(false);
  const [method, setMethod] = useState(defaultMethod);
  const [paymentPage, setPaymentPage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadPaymentMethods());
    dispatch(loadPaymentProducts());

    // eslint-disable-next-line
  }, []);

  const close = useCallback(() => {
    setSelectedProduct('');
    dispatch(closePaymentDrawer());
  }, [dispatch]);

  const handleChangeMethod = useCallback((method: PaymentMethod) => {
    return () => {
      setMore(false);
      setMethod(method);
      defaultMethod = method;
      localStorage.setItem(StorageKeys.payment, method.toString());
    };
  }, []);

  const handleCheckout = useCallback(() => {
    if (selectedProduct) {
      setLoading(true);
      dispatch(purchaseProduct(method, selectedProduct))
        .then((body) => setPaymentPage(body))
        .finally(() => setLoading(false));
    }
  }, [dispatch, method, selectedProduct]);

  const renderPaymentButton = useCallback(
    (method: PaymentMethod, onClick: () => void) => {
      const [icon, style] = ((): [any, string] => {
        switch (method) {
          case PaymentMethod.CreditCard:
            return [CreditCardIcon, styles.creditCard];
          case PaymentMethod.WireTransfer:
            return [ATMIcon, styles.wireTransfer];
          case PaymentMethod.ConvenientStore:
            return [ConvenientStoreIcon, styles.convenientStore];
          default:
          case PaymentMethod.UnionPay:
            return [UnionPayIcon, styles.unionPay];
        }
      })();

      return (
        <Box py={0.5} key={method}>
          <Button
            size="large"
            variant="contained"
            className={style}
            fullWidth
            startIcon={<Icon svg={icon} />}
            onClick={onClick}>
            <Typography>{`enum.pay.${method}`}</Typography>
          </Button>
        </Box>
      );
    },
    [styles],
  );

  return (
    <>
      <Drawer anchor="bottom" open={opened} PaperProps={{className: styles.bg}} onClose={close}>
        <Box display="flex" alignItems="center" m={1}>
          <Box mr={1}>
            <div className="diamond" />
          </Box>
          <CoronaText text="title.purchase" shadow={theme.palette.success[theme.palette.type]} />
          <Box display="flex" flex="1" />
          <Chip color="secondary" avatar={<Icon svg={CurrencyIcon} />} label="TWD" size="small" />
        </Box>

        <Box mx={2} maxHeight="30vh" overflow="auto">
          <List className="selectableList">
            {products.map((p) => (
              <ListItem
                key={p.id}
                button
                className={
                  selectedProduct === p.id ? styles.activeListItem : styles.inactiveListItem
                }
                selected={selectedProduct === p.id}
                onClick={() => setSelectedProduct(p.id)}>
                <Box mr={1}>
                  <div className="diamond" />
                </Box>
                <ListItemText primary={p.point} primaryTypographyProps={{color: 'primary'}} />
                <ListItemSecondaryAction>
                  <Text color="textSecondary">${p.price}</Text>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={selectedProduct !== ''}
        PaperProps={{className: styles.bg}}
        onClose={() => {
          setSelectedProduct('');
          setMore(false);
        }}>
        <Collapse in={more} timeout="auto">
          <Box p={2} textAlign="center">
            <Typography variant="h5">title.payment_method</Typography>
            {methods.map((m) => renderPaymentButton(m, handleChangeMethod(m)))}
          </Box>
        </Collapse>
        <Box p={2} py={1} display="flex">
          <Box flex={1}>{renderPaymentButton(method, handleCheckout)}</Box>
          <IconButton onClick={() => setMore((v) => !v)}>
            <Icon fontSize="small" svg={CaretIcon} className={`arrow ${more ? 'up' : 'down'}`} />
          </IconButton>
        </Box>

        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={paymentPage !== ''}
        onClose={() => {
          setPaymentPage('');
          close();
        }}>
        <Box maxHeight="80vh" width="100%" overflow="auto">
          <div dangerouslySetInnerHTML={{__html: paymentPage}} />
        </Box>
      </Drawer>
    </>
  );
}

export default PaymentDrawer;
