import {createSelector} from 'reselect';

import {RootState} from 'model/helper';

const getLivestreamState = (state: RootState) => state.livestream;

const getLivestreamIdList = (state: RootState) => getLivestreamState(state).list.items;
const getLivestreamCache = (state: RootState) => getLivestreamState(state).cache;

export const livestreamListSelector = createSelector(
  [getLivestreamIdList, getLivestreamCache],
  (ids, cache) => ids.map((id) => cache[id]),
);
export const isLivestreamListLoading = (state: RootState) => getLivestreamState(state).list.loading;
export const hasLivestreamListNextPage = (state: RootState) =>
  getLivestreamState(state).list.cursor !== null;

export const getWatchingStatus = (state: RootState) => getLivestreamState(state).watching;

export const livestreamInfoSelector = createSelector(
  [getWatchingStatus, getLivestreamCache],
  (watchingStatus, cache) => {
    if (!watchingStatus) {
      return undefined;
    }
    return cache[watchingStatus.streamId];
  },
);

export const livestreamSelector = createSelector(
  [getWatchingStatus, getLivestreamCache],
  (watchingStatus, cache) => {
    if (!watchingStatus) {
      return null;
    } else if (watchingStatus && !cache[watchingStatus.streamId]) {
      return null;
    } else {
      return watchingStatus;
    }
  },
);

const getContributionState = (state: RootState) => getLivestreamState(state).contribution;

export const isContributorsListLoading = (state: RootState) => getContributionState(state).loading;
export const hasContributorsListNextPage = (state: RootState) =>
  getContributionState(state).cursor !== null;
export const getContributors = (state: RootState) => getContributionState(state).contributors;

// export const livestreamChannel = createSelector(
//   [getWatchingStatus, getLivestreamCache],
//   (watchingStatus, cache) => {
//     if (!watchingStatus) {
//       return undefined;
//     }
//     return cache[watchingStatus.streamId];
//   },
// );
