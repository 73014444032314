import {
  Avatar,
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppDispatch} from 'model/helper';
import {loadDistribution, loadDistributionLevel} from 'model/wallet/WalletAction';
import {getDistribution} from 'model/wallet/WalletSelector';

import Icon from 'components/Icons';
import Typography from 'components/i18n/Typography';

import {ReactComponent as QRCodeIcon} from 'assets/icon/regular/qr-code.svg';
import {ReactComponent as CaretIcon} from 'assets/icon/solid/caret-up.svg';

interface GoldDistributionProps {
  onClickQR(): void;
}

const levels = [1, 2, 3];

function GoldDistribution({onClickQR}: GoldDistributionProps) {
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();

  const distribution = useSelector(getDistribution);
  const [expanded, setExpanded] = useState<Set<number>>(new Set());

  useEffect(() => {
    if (Object.keys(distribution.downline).length === 0) {
      dispatch(loadDistribution());
    }

    // eslint-disable-next-line
  }, []);

  const toggleExpanded = useCallback(
    (level: number) => {
      return () => {
        if (distribution.downline[level].list.length !== distribution.downline[level].total) {
          dispatch(loadDistributionLevel(level));
        }

        setExpanded((s) => {
          if (s.has(level)) {
            s.delete(level);
          } else {
            s.add(level);
          }
          return new Set(s);
        });
      };
    },
    [dispatch, distribution],
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        className="gradientButtonWrapper"
        my={2}>
        <Box
          borderRadius={16}
          className={[styles.gradient, 'gradientButton', 'overlay'].join(' ')}></Box>
        <Button
          variant="outlined"
          size="small"
          className={styles.openQRButton}
          endIcon={<Icon svg={QRCodeIcon} />}
          onClick={onClickQR}>
          <Typography>input.open_qr</Typography>
        </Button>
      </Box>

      <List className="full">
        {distribution.upline && (
          <>
            <Box mx={1}>
              <Typography variant="caption" color="textSecondary">
                label.my_upline
              </Typography>
            </Box>
            <ListItem className={styles.distributionUpline}>
              <Box mr={2}>
                <Avatar src={distribution.upline.userPicture} className="avatar" />
              </Box>

              <Text variant="h6">{distribution.upline.userName}</Text>
            </ListItem>
          </>
        )}

        {levels.map((level) => {
          const downline = distribution.downline[level];
          return (
            <React.Fragment key={level}>
              <Box mx={1}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  keys={{n: level, percent: downline?.percentage ?? 0}}>
                  label.nth_downline_summary
                </Typography>
              </Box>

              <ListItem
                button
                className={styles.distributionListItem}
                onClick={toggleExpanded(level)}>
                <Typography keys={{n: downline?.total ?? 0}}>unit.persons</Typography>
                <ListItemSecondaryAction>
                  <Icon
                    fontSize="small"
                    svg={CaretIcon}
                    className={`arrow ${expanded.has(level) ? 'down' : 'up'}`}
                  />
                </ListItemSecondaryAction>
              </ListItem>

              <Collapse in={expanded.has(level)} timeout="auto">
                <List component="div" disablePadding>
                  {(downline?.list ?? []).map((member, index) => (
                    <ListItem key={index}>
                      <Box mr={2}>
                        <Avatar src={member.userPicture} className="avatar" />
                      </Box>

                      <Box flex={1.5}>
                        <Text>{member.userName}</Text>
                      </Box>
                      {member.uplineName[1] && (
                        <Box flex={1} textAlign="center">
                          <Text variant="body2">{member.uplineName[1]}</Text>
                          <Typography color="textSecondary" variant="body2" keys={{n: 1}}>
                            label.nth_upline
                          </Typography>
                        </Box>
                      )}
                      {member.uplineName[2] && (
                        <Box flex={1} textAlign="center">
                          <Text variant="body2">{member.uplineName[1]}</Text>
                          <Typography color="textSecondary" variant="body2" keys={{n: 2}}>
                            label.nth_upline
                          </Typography>
                        </Box>
                      )}
                      {member.downlineCount[2] !== undefined && (
                        <Box flex={1} textAlign="center">
                          <Text variant="body2">{member.downlineCount[2]}</Text>
                          <Typography variant="body2" color="textSecondary" keys={{n: 2}}>
                            label.nth_downline
                          </Typography>
                        </Box>
                      )}
                      {member.downlineCount[3] !== undefined && (
                        <Box flex={1} textAlign="center">
                          <Text variant="body2">{member.downlineCount[3]}</Text>
                          <Typography variant="body2" color="textSecondary" keys={{n: 3}}>
                            label.nth_downline
                          </Typography>
                        </Box>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
}

export default GoldDistribution;
