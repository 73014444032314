import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Drawer,
  IconButton,
  InputAdornment,
  Typography as Text,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Conversation from 'routes/mailbox/Conversation';
import Mailbox from 'routes/mailbox/Mailbox';
import SVGA from 'svgaplayerweb';

import {openGiftDrawer} from 'model/gift/GiftAction';
import {getGifts} from 'model/gift/GiftSelector';
import {GiftLocation} from 'model/gift/GiftTypes';
import {AppDispatch, Listener} from 'model/helper';
import AgoraRTC from 'model/livestream/AgoraRTC';
import {
  listenLivestreamMessage,
  sendLivestreamChatMessage,
  updateLocalSteam,
} from 'model/livestream/LivestreamAction';
import {livestreamInfoSelector, livestreamSelector} from 'model/livestream/LivestreamSelector';
import {WatchMode} from 'model/livestream/LivestreamTypes';
import {listenPersonalChannel, unlistenPersonalChannel} from 'model/message/MessageAction';
import {MessageModel, MessageType} from 'model/message/MessageTypes';
import {followUser, loadUser, unfollowUser} from 'model/user/UserActions';
import {getUserById} from 'model/user/UserSelector';
import {closePaymentDrawer, openPaymentDrawer, updateWallet} from 'model/wallet/WalletAction';
import {getBalance} from 'model/wallet/WalletSelector';

import {format} from 'utils/number';
import {genId} from 'utils/string';

import Chatroom from 'components/Chatroom';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ContributionDialog from 'components/ContributionDialog';
import ContributionHList from 'components/ContributionHList';
import Icon from 'components/Icons';
import Marquee from 'components/Marquee';
import ModeDialog from 'components/ModeDialog';
import ProfileDialog from 'components/ProfileDialog';
import WatchModeLabel from 'components/WatchModeLabel';
import WatchingCountdown from 'components/WatchingCountdown';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

import {ReactComponent as ShareIcon} from 'assets/icon/light/share.svg';
import {ReactComponent as ChatIcon} from 'assets/icon/regular/chat.svg';
import {ReactComponent as ChevronLeft} from 'assets/icon/regular/chevron-left.svg';
import {ReactComponent as ChevronRight} from 'assets/icon/regular/chevron-right.svg';
import {ReactComponent as GiftIcon} from 'assets/icon/regular/gift.svg';
import {ReactComponent as PaperPlaneIcon} from 'assets/icon/regular/paper-plane-inactive.svg';
import {ReactComponent as EyeIcon} from 'assets/icon/solid/eye.svg';
import {ReactComponent as CameraIcon} from 'assets/icon/solid/photo-camera.svg';
import {ReactComponent as MuteIcon} from 'assets/icon/solid/volume-slash.svg';
import {ReactComponent as UnmuteIcon} from 'assets/icon/solid/volume.svg';

interface LivestreamControlsProps {
  streamId: string;
  mode: WatchMode;
  muted: boolean;
  onClickVolume: () => void;
  onChangeMode: (mode: WatchMode) => void;
}

enum DrawerOption {
  None = 0,
  Chat,
  PM,
}

const GIFT_STAY_DURATION = 4000;
const INVITE_DURATION = 10000;

interface Invite {
  id: string;
  mode: WatchMode;
}

function LivestreamControls({
  streamId,
  mode,
  muted,
  onClickVolume,
  onChangeMode,
}: LivestreamControlsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const styles = useThemeStyles();

  const balance = useSelector(getBalance);
  const livestreamInfo = useSelector(livestreamInfoSelector);
  const livestream = useSelector(livestreamSelector);
  const userInfo = useSelector(getUserById)(livestreamInfo?.userId ?? '');
  const gifts = useSelector(getGifts);

  const [drawer, setDrawer] = useState(DrawerOption.None);
  const [pmTarget, setPmTarget] = useState<Pick<MessageModel.Messenger, 'userId' | 'userName'>>();

  const [openProfile, setOpenProfile] = useState(false);
  const [openContribution, setContribution] = useState(false);
  const [message, setMessage] = useState('');

  const [chats, setChats] = useState<MessageModel.ChatroomMessage[]>([]);
  const [giftQueue, setGiftQueue] = useState<MessageModel.ChatroomMessage[]>([]);
  const [animationQueue, setAnimationQueue] = useState<Array<{giftId: string; special: boolean}>>(
    [],
  );

  const headGiftMessage = useRef<{id: string; combo: string}>({id: '', combo: ''});
  const headGiftMessageTimer = useRef(0);

  const svgaParser = useMemo(() => new SVGA.Parser(), []);
  const svgaPlayer = useRef<SVGA.Player | undefined>();

  const [playingAnimation, setPlayingAnimation] = useState(false);

  const [invite, setInvite] = useState<Invite | undefined>(undefined);
  const [finalTime, setFinalTime] = useState(Date.now());
  const [pmBadge, setPmBadge] = useState(false);
  const [cameraDialog, setCameraDialog] = useState(false);

  const blurMode = useMemo(() => {
    return livestreamInfo?.status !== WatchMode.Free && livestreamInfo?.status !== mode;
  }, [livestreamInfo?.status, mode]);

  useEffect(() => {
    dispatch(updateWallet());
    const timer = window.setInterval(() => {
      dispatch(updateWallet());
    }, 5000);

    dispatch(
      listenLivestreamMessage((msg) => {
        switch (msg.type) {
          case MessageType.Gift: {
            setAnimationQueue((q) => {
              return q.concat({
                giftId: msg.meta_data.gift_id,
                special: msg.meta_data.gift_special_animate,
              });
            });
            setGiftQueue((q) => {
              const index = q.findIndex(
                (gift) =>
                  gift.senderId === msg.meta_data.user_id && gift.giftId === msg.meta_data.gift_id,
              );

              if (index > -1) {
                q[index] = {
                  ...q[index],
                  message: msg.meta_data.combo_count,
                };
              } else {
                q.push({
                  id: genId(8),
                  type: msg.type,
                  senderId: msg.meta_data.user_id,
                  senderName: msg.meta_data.user_name,
                  senderPicture: '',
                  message: msg.meta_data.combo_count,
                  giftId: msg.meta_data.gift_id,
                });
              }
              return [...q];
            });

            return;
          }
          case MessageType.StreamNewViewer:
            return setChats((chats) => {
              return chats.concat({
                id: msg.id.toString(),
                type: msg.type,
                senderId: msg.meta_data.user_id,
                senderName: msg.meta_data.name,
                senderPicture: '',
                message: '             ',
                giftId: '',
              });
            });
          case MessageType.Chat: {
            return setChats((chats) => {
              return chats.concat({
                id: msg.id.toString(),
                type: msg.type,
                senderId: msg.meta_data.user_id,
                senderName: msg.meta_data.name,
                senderPicture: msg.meta_data.picture,
                message: msg.meta_data.message,
                giftId: '',
              });
            });
          }
          case MessageType.PointDepletion:
            return setFinalTime(msg.meta_data.timestamp);
        }
      }),
    );

    const personalChannelListener: Listener = (msg) => {
      switch (msg.type) {
        case MessageType.ChargeInvite:
          return setInvite({
            id: msg.meta_data.request_id,
            mode: msg.meta_data.mode,
          });
        case MessageType.Gift:
        case MessageType.Chat:
        case MessageType.Picture:
          return setPmBadge(true);
      }
    };

    dispatch(listenPersonalChannel(personalChannelListener));

    return () => {
      clearInterval(timer);
      clearTimeout(headGiftMessageTimer.current);
      dispatch(unlistenPersonalChannel(personalChannelListener));
    };

    // eslint-disable-next-line
  }, []);

  // animation queue
  useEffect(() => {
    if (Object.keys(gifts).length === 0 || playingAnimation || animationQueue.length === 0) {
      return;
    }

    setPlayingAnimation(true);

    const url = animationQueue[0].special
      ? gifts[animationQueue[0].giftId].comboAnimateId
      : gifts[animationQueue[0].giftId].animateId;

    svgaParser.load(url, function (videoItem) {
      svgaPlayer.current?.setVideoItem(videoItem);
      svgaPlayer.current?.startAnimation();

      svgaPlayer.current?.onFinished(() => {
        setAnimationQueue((q) => {
          q.shift();
          return [...q];
        });
        setPlayingAnimation(false);
      });
    });
  }, [animationQueue, gifts, playingAnimation, svgaParser]);

  useEffect(() => {
    if (livestreamInfo?.userId) {
      dispatch(loadUser(livestreamInfo.userId));
      setPmTarget({
        userId: livestreamInfo.userId,
        userName: '',
      });
    }
  }, [dispatch, livestreamInfo?.userId]);

  useEffect(() => {
    setGiftQueue([]);
    setAnimationQueue([]);
    setChats([]);
    setPlayingAnimation(false);

    headGiftMessage.current = {id: '', combo: ''};
    clearTimeout(headGiftMessageTimer.current);
    headGiftMessageTimer.current = 0;
  }, [mode]);

  // gift queue cleaner
  useEffect(() => {
    if (giftQueue.length === 0) {
      return;
    }

    if (headGiftMessage.current.id !== giftQueue[0].id) {
      headGiftMessage.current.id = giftQueue[0].id;
      headGiftMessage.current.combo = giftQueue[0].message;
      headGiftMessageTimer.current = window.setTimeout(() => {
        setGiftQueue((q) => {
          return q.slice(1);
        });
        setChats((q) => {
          return q.concat(giftQueue[0]);
        });
      }, GIFT_STAY_DURATION);
    } else if (
      headGiftMessage.current.id === giftQueue[0].id &&
      headGiftMessage.current.combo !== giftQueue[0].message
    ) {
      headGiftMessage.current.id = giftQueue[0].id;
      headGiftMessage.current.combo = giftQueue[0].message;

      clearTimeout(headGiftMessageTimer.current);
      headGiftMessageTimer.current = window.setTimeout(() => {
        setGiftQueue((q) => {
          return q.slice(1);
        });
        setChats((q) => {
          return q.concat(giftQueue[0]);
        });
      }, GIFT_STAY_DURATION);
    }
  }, [giftQueue]);

  useEffect(() => {
    if (!invite) {
      return;
    }

    const timer = window.setTimeout(() => {
      setInvite(undefined);
    }, INVITE_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [invite]);

  const handleFollowUser = useCallback(() => {
    if (userInfo === undefined) {
      return;
    } else if (userInfo.followed) {
      dispatch(unfollowUser(userInfo.userId));
    } else {
      dispatch(followUser(userInfo.userId));
    }
  }, [dispatch, userInfo]);

  const handleOpenGift = useCallback(() => {
    dispatch(
      openGiftDrawer({
        userId: userInfo?.userId ?? '',
        targetId: streamId,
        location: GiftLocation.Stream,
      }),
    );
  }, [dispatch, streamId, userInfo?.userId]);

  const closeDrawer = useCallback(() => {
    setDrawer(DrawerOption.None);
  }, []);

  const handleSendMessage = useCallback(() => {
    if (message) {
      dispatch(sendLivestreamChatMessage(streamId, message));
      setMessage('');
    }
  }, [dispatch, streamId, message]);

  const handleSendMessageByEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSendMessage();
      }
    },
    [handleSendMessage],
  );

  const handleOpenPayment = useCallback(() => {
    dispatch(
      openPaymentDrawer(() => {
        setFinalTime(Date.now());
        dispatch(closePaymentDrawer());
      }),
    );
  }, [dispatch]);

  const share = useCallback(() => {
    if (navigator.share && livestreamInfo) {
      navigator.share({
        url: window.location.href,
        title: livestreamInfo.title,
        text: livestreamInfo.title,
      });
    }
  }, [livestreamInfo]);

  const handleToggleCamera = useCallback(() => {
    AgoraRTC.instance.toggleLocalStream();
    dispatch(updateLocalSteam(!livestream?.hasLocalStream));
    setCameraDialog(false);
  }, [dispatch, livestream?.hasLocalStream]);

  if (!livestreamInfo) {
    return null;
  }

  return (
    <Box className="overlay" display="flex" flexDirection="column">
      <canvas
        ref={(ele) => {
          if (ele) {
            ele.width = window.innerWidth;
            ele.height = window.innerHeight;
          }

          if (!svgaPlayer.current && ele) {
            const player = new SVGA.Player(ele!);
            player.loops = 1;
            svgaPlayer.current = player;
          }
        }}
        id="gift-animation"
        className="overlay"
      />

      <Box display="flex" p={2} py={0} zIndex={1}>
        <Box display="flex" alignItems="center">
          <Chip
            avatar={
              <IconButton onClick={() => setOpenProfile(true)}>
                <Avatar src={userInfo?.picture} className="avatar" />
              </IconButton>
            }
            className={styles.streamerChip}
            label={
              <Box minWidth={60}>
                <Text variant="body2" component="span">
                  {userInfo?.name}
                </Text>
                <Box display="flex" alignItems="center">
                  <Icon svg={EyeIcon} fontSize="small" color="inherit" />
                  <Text color="textSecondary" variant="body2">
                    {livestreamInfo.numWatchers}
                  </Text>
                </Box>
              </Box>
            }
            deleteIcon={
              <Chip
                className={userInfo?.followed ? 'inactive' : 'active'}
                label={
                  <Typography variant="body2">
                    {userInfo?.followed ? 'input.unfollow' : 'input.follow'}
                  </Typography>
                }
                variant="outlined"
                color={userInfo?.followed ? 'default' : 'primary'}
              />
            }
            onDelete={handleFollowUser}
          />
        </Box>

        <ContributionHList streamId={streamId} />
      </Box>
      <Box display="flex" p={2} py={1} alignItems="center" zIndex={1}>
        <WatchModeLabel mode={livestreamInfo.status} />
        <Chip
          className={styles.earningLabel}
          icon={<div className="diamond" />}
          label={format(livestreamInfo.earning)}
          deleteIcon={<Icon svg={ChevronRight} />}
          onDelete={() => setContribution(true)}
          size="small"
        />
        <Marquee text={`${livestreamInfo.announcement}　　　　　　　　　　　　　　　　　　　`} />
      </Box>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        position="relative"
        zIndex={1}>
        <Box position="absolute" left={15} top={0}>
          <IconButton
            hidden={mode !== WatchMode.OO || blurMode}
            className={mode !== WatchMode.OO || blurMode ? 'hidden' : ''}
            style={{backgroundColor: '#000', padding: 8, zIndex: 1}}
            onClick={() => {
              setCameraDialog(true);
            }}>
            <Icon fontSize="small" svg={CameraIcon} />
          </IconButton>
          <Box
            id="camera"
            mt={-2}
            ml={2}
            width={130}
            height={180}
            hidden={!livestream?.hasLocalStream}
          />
        </Box>
        {livestreamInfo.status !== mode && (
          <Box className="overlay" bottom="unset" display="flex" justifyContent="center">
            <ModeDialog
              mode={livestreamInfo.status}
              userName={userInfo?.name ?? ''}
              userPicture={userInfo?.picture}
              balance={balance}
              omCharge={livestreamInfo!.omCharge}
              ooCharge={livestreamInfo!.ooCharge}
              occupied={livestreamInfo.occupied}
              onClick={() => {
                onChangeMode(livestreamInfo.status);
              }}
              onDeposit={handleOpenPayment}
            />
          </Box>
        )}

        {cameraDialog && (
          <Box className="overlay" bottom="unset" display="flex" justifyContent="center">
            <ConfirmationDialog
              onConfirm={handleToggleCamera}
              onCancel={() => {
                setCameraDialog(false);
              }}
              title={
                <Typography>
                  {livestream?.hasLocalStream ? 'title.turn_off_camera' : 'title.turn_on_camera'}
                </Typography>
              }
            />
          </Box>
        )}

        <Box className="overlay" left="unset" bottom="unset">
          <WatchingCountdown time={finalTime} onClickDeposit={handleOpenPayment} />
        </Box>

        <Chatroom
          chats={chats}
          gifts={gifts}
          gift={giftQueue[0]}
          streamerId={livestreamInfo.userId}
        />
      </Box>
      <Box display="flex" p={1} zIndex={1} className="safeBottom">
        <IconButton
          className={`outlinedIconButton ${styles.chat}`}
          onClick={() => {
            setDrawer(DrawerOption.Chat);
          }}>
          <Icon svg={ChatIcon} />
        </IconButton>
        <Box flex={1} />
        <Badge color="primary" overlap="circle" badgeContent=" " variant="dot" invisible={!pmBadge}>
          <IconButton
            className={`outlinedIconButton ${styles.private}`}
            onClick={() => {
              setPmBadge(false);
              setDrawer(DrawerOption.PM);
            }}>
            <Icon svg={PaperPlaneIcon} />
          </IconButton>
        </Badge>

        {navigator.share !== undefined && (
          <IconButton className={`outlinedIconButton ${styles.share}`} onClick={share}>
            <Icon svg={ShareIcon} />
          </IconButton>
        )}

        <IconButton className={`outlinedIconButton ${styles.volume}`} onClick={onClickVolume}>
          <Icon svg={muted ? MuteIcon : UnmuteIcon} />
        </IconButton>
        <IconButton className={`outlinedIconButton ${styles.gift}`} onClick={handleOpenGift}>
          <Icon svg={GiftIcon} />
        </IconButton>
      </Box>

      <Drawer
        open={drawer === DrawerOption.Chat && !blurMode}
        anchor="bottom"
        className={styles.noBackdrop}
        PaperProps={{className: styles.bg}}
        onClose={closeDrawer}>
        <Box p={2} py={1}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="input.livestream_chat_placeholder"
            fullWidth
            className={styles.roundInput}
            autoFocus
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleSendMessageByEnter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button size="small" className="roundInputButton" onClick={handleSendMessage}>
                    <Typography color="primary">common.submit</Typography>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Drawer>
      <Drawer
        open={drawer === DrawerOption.PM}
        anchor="bottom"
        className={styles.noBackdrop}
        PaperProps={{className: styles.transparentBg, style: {borderRadius: '10px 10px'}}}
        onClose={closeDrawer}>
        <Box height="40vh" display="flex">
          {!pmTarget && (
            <Mailbox
              onClick={(id) => {
                setPmTarget(id);
              }}
            />
          )}
          {pmTarget && (
            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex" p={1}>
                <Box flex={1}>
                  <IconButton
                    size="small"
                    onClick={(target) => {
                      setPmTarget(undefined);
                    }}>
                    <Icon svg={ChevronLeft} />
                  </IconButton>
                </Box>
                <Box flex={1} textAlign="center">
                  <Text variant="h6" component="h6">
                    {pmTarget.userName || userInfo?.name}
                  </Text>
                </Box>
                <Box flex={1}></Box>
              </Box>
              <Conversation userId={pmTarget.userId} />
            </Box>
          )}
        </Box>
      </Drawer>

      <ProfileDialog
        user={userInfo}
        open={openProfile}
        onClose={() => setOpenProfile(false)}
        onClickFollow={handleFollowUser}
        onClickMessage={() => {
          console.log(userInfo);
          setPmTarget({
            userId: userInfo?.userId ?? '',
            userName: userInfo?.name ?? '',
          });
          setOpenProfile(false);
          setDrawer(DrawerOption.PM);
        }}
      />

      <ContributionDialog
        streamId={streamId}
        open={openContribution}
        onClose={() => setContribution(false)}
      />
    </Box>
  );
}

export default LivestreamControls;
