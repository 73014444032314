import {Box} from '@material-ui/core';
import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useLastLocation} from 'react-router-last-location';

import {ReactComponent as ChevronLeft} from 'assets/icon/regular/chevron-left.svg';

import Icon from './Icons';

interface BackButtonProps extends IconButtonProps {
  to?: string;
}

function BackButton({to, onClick, ...props}: BackButtonProps) {
  const history = useHistory();
  const lastLocation = useLastLocation();

  const goBack = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        return onClick(e);
      } else if (lastLocation) {
        history.goBack();
      } else {
        history.replace(to ?? '/');
      }
    },
    [onClick, to, history, lastLocation],
  );

  return (
    <Box ml={-2}>
      <IconButton onClick={goBack} {...props} aria-label="back">
        <Icon svg={ChevronLeft} />
      </IconButton>
    </Box>
  );
}

export default BackButton;
