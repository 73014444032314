import {ServerError} from 'api';

import {AppThunk} from 'model/helper';
import {updateWallet} from 'model/wallet/WalletAction';

import GiftAPI from 'api/gift';

import {GiftAction, GiftLocation} from './GiftTypes';

interface OpenGiftDrawerParams {
  userId: string;
  location: GiftLocation;
  targetId?: string;
  onSend?: () => void;
}

export const openGiftDrawer = (payload: OpenGiftDrawerParams): AppThunk<void> => async (
  dispatch,
) => {
  dispatch({
    type: GiftAction.OPEN_GIFT_DRAWER,
    payload,
  });
};

export const closeGiftDrawer = (): AppThunk<void> => async (dispatch) => {
  dispatch({type: GiftAction.CLOSE_GIFT_DRAWER});
};

export const loadGifts = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    // const categories = await GiftAPI.getCategories();
    const [{categories}, res] = await Promise.all([GiftAPI.getCategories(), GiftAPI.getGifts()]);

    const categoryModel = categories.reduce<{[name: string]: string[]}>((acc, category) => {
      acc[category] = [];
      return acc;
    }, {});

    const gifts = res.gifts.map((gift) => {
      gift.categoeries.forEach((c) => {
        categoryModel[c.category].push(gift.gift_id);
      });
      return {
        id: gift.gift_id,
        name: gift.name,
        picture: gift.picture,
        point: gift.point,
        animateId: gift.animate_id,
        comboAnimateId: gift.special_animate_id,
        combo: gift.special_combo,
        toyEnable: gift.toy_enable === 1,
      };
    });

    dispatch({
      type: GiftAction.GET_GIFTS,
      payload: {
        categories: categories.map((name) => ({
          name,
          list: categoryModel[name],
        })),
        gifts,
      },
    });
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};

export const sendGift = (giftId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {
  try {
    const openState = getState().gift.open;
    const userId = openState?.userId ?? '';
    const targetId = openState?.targetId;
    const location = openState?.location ?? GiftLocation.Video;

    await GiftAPI.send(giftId, userId, location, targetId);
    dispatch({
      type: GiftAction.SEND_GIFT,
      payload: {
        giftId,
        targetId: targetId || userId,
        location,
      },
    });

    const onSuccess = getState().gift.open?.onSend;
    if (onSuccess) {
      onSuccess();
    }

    dispatch(updateWallet());
  } catch (err) {
    const error: ServerError = err;
    throw error.err_code || err;
  }
};
