import {Badge, BottomNavigation, BottomNavigationAction, Box} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {loadMailbox} from 'model/message/MessageAction';
import {hasMessageUnreadSelector} from 'model/message/MessageSelector';
import {authorized} from 'model/user/UserSelector';

import Icon from 'components/Icons';

import {ReactComponent as InactiveHeartIcon} from 'assets/icon/regular/heart.svg';
import {ReactComponent as ActiveHomeIcon} from 'assets/icon/regular/home-active.svg';
import {ReactComponent as InactiveHomeIcon} from 'assets/icon/regular/home-inactive.svg';
import {ReactComponent as ActiveMessageIcon} from 'assets/icon/regular/paper-plane-active.svg';
import {ReactComponent as InactiveMessageIcon} from 'assets/icon/regular/paper-plane-inactive.svg';
import {ReactComponent as ActiveSearchIcon} from 'assets/icon/regular/search-active.svg';
import {ReactComponent as InactiveSearchIcon} from 'assets/icon/regular/search-inactive.svg';
import {ReactComponent as ActiveUserIcon} from 'assets/icon/regular/user-active.svg';
import {ReactComponent as InactiveUserIcon} from 'assets/icon/regular/user-inactive.svg';
import {ReactComponent as ActiveHeartIcon} from 'assets/icon/solid/heart.svg';

import ActivityPage from './activity/ActivityPage';
import ExploreRoutes from './explore/ExploreRoutes';
import HomeRoutes from './home/HomeRoutes';
import MailboxPage from './mailbox/MailboxPage';
import ProfilePage from './profile/ProfilePage';

function BottomNavigationRoutes() {
  const location = useLocation();
  const history = useHistory();
  const styles = useThemeStyles();
  const auth = useSelector(authorized);

  const dispatch = useDispatch<AppDispatch>();
  const hasUnread = useSelector(hasMessageUnreadSelector);

  useEffect(() => {
    dispatch(loadMailbox(true));

    // eslint-disable-next-line
  }, []);

  const navIndex = useMemo(() => {
    switch (true) {
      case location.pathname.startsWith('/explore'):
        return 'explore';
      case location.pathname.startsWith('/livestream'):
        return 'livestream';
      case location.pathname.startsWith('/mailbox'):
        return 'mailbox';
      case location.pathname.startsWith('/profile'):
        return 'profile';
      case location.pathname.startsWith('/activity'):
        return 'activity';
      default:
        return '';
    }
  }, [location.pathname]);

  return (
    <Box component="main" className="full" display="flex" flexDirection="column">
      <Box display="flex" height="100%" flex="1" overflow="auto">
        <Switch>
          <Route path="/explore">
            <ExploreRoutes />
          </Route>

          {auth && (
            <Route path="/mailbox">
              <MailboxPage />
            </Route>
          )}

          {auth && (
            <Route path="/profile">
              <ProfilePage />
            </Route>
          )}

          {auth && (
            <Route path="/activity">
              <ActivityPage />
            </Route>
          )}

          {auth && (
            <Route path="/">
              <HomeRoutes />
            </Route>
          )}

          <Redirect to={auth ? '/' : '/explore'} />
        </Switch>
      </Box>

      <BottomNavigation
        className={`safeBottom ${styles.bottomNav}`}
        value={navIndex}
        showLabels
        onChange={(_, value) => {
          history.push(`/${value}`);
        }}>
        <BottomNavigationAction
          value=""
          icon={<Icon svg={navIndex === '' ? ActiveHomeIcon : InactiveHomeIcon} />}
        />
        <BottomNavigationAction
          value="explore"
          icon={<Icon svg={navIndex === 'explore' ? ActiveSearchIcon : InactiveSearchIcon} />}
        />

        <BottomNavigationAction
          value="mailbox"
          icon={
            <Badge
              color="primary"
              overlap="circle"
              badgeContent=" "
              variant="dot"
              invisible={!hasUnread}>
              <Icon svg={navIndex === 'mailbox' ? ActiveMessageIcon : InactiveMessageIcon} />
            </Badge>
          }
        />
        <BottomNavigationAction
          value="activity"
          icon={
            <Icon
              className={styles.activity}
              svg={navIndex === 'activity' ? ActiveHeartIcon : InactiveHeartIcon}
            />
          }
        />
        <BottomNavigationAction
          value="profile"
          icon={<Icon svg={navIndex === 'profile' ? ActiveUserIcon : InactiveUserIcon} />}
        />
      </BottomNavigation>
    </Box>
  );
}

export default BottomNavigationRoutes;
