import {combineReducers} from 'redux';

import {ExploreAction} from 'model/explore/ExploreTypes';
import {RootActions} from 'model/helper';
import {UserAction} from 'model/user/UserTypes';

import {PostModel, PostAction} from './PostTypes';

const initialState: PostModel.State = {
  banner: {
    interval: 3,
    items: [],
  },
  suggested: [],
  posts: {
    loading: true,
    cursor: null,
    articles: [],
  },
  unlockedPosts: {
    loading: true,
    cursor: null,
    articles: [],
  },
  savedPosts: {
    loading: true,
    cursor: null,
    articles: [],
  },
  comment: {},
  cache: {},
  video: {
    playing: null,
    muted: true,
  },
};

function suggested(
  state = initialState.suggested,
  action: RootActions,
): PostModel.State['suggested'] {
  switch (action.type) {
    case PostAction.GET_SUGGESTED_USERS_S:
      return action.payload.map((user) => user.userId);
    case UserAction.USER_LOGOUT:
      return initialState.suggested;
    default:
      return state;
  }
}

function banner(state = initialState.banner, action: RootActions): PostModel.State['banner'] {
  switch (action.type) {
    case PostAction.GET_BANNERS:
      return {
        items: action.payload.banners,
        interval: action.payload.interval,
      };
    default:
      return state;
  }
}

function posts(state = initialState.posts, action: RootActions): PostModel.State['posts'] {
  switch (action.type) {
    case PostAction.GET_POSTS:
      return {
        ...state,
        loading: true,
      };
    case PostAction.GET_POSTS_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        articles: action.payload.refresh
          ? action.payload.articles.map((article) => article.id)
          : state.articles.concat(action.payload.articles.map((article) => article.id)),
      };
    case UserAction.USER_LOGOUT:
      return initialState.posts;
    default:
      return state;
  }
}

function unlockedPosts(
  state = initialState.unlockedPosts,
  action: RootActions,
): PostModel.State['unlockedPosts'] {
  switch (action.type) {
    case PostAction.GET_UNLOCKED_POSTS:
      return {
        ...state,
        loading: true,
      };
    case PostAction.GET_UNLOCKED_POSTS_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        articles: action.payload.refresh
          ? action.payload.articles.map((article) => article.id)
          : state.articles.concat(action.payload.articles.map((article) => article.id)),
      };
    case UserAction.USER_LOGOUT:
      return initialState.unlockedPosts;
    default:
      return state;
  }
}

function savedPosts(
  state = initialState.savedPosts,
  action: RootActions,
): PostModel.State['savedPosts'] {
  switch (action.type) {
    case PostAction.GET_SAVED_POSTS:
      return {
        ...state,
        loading: true,
      };
    case PostAction.GET_SAVED_POSTS_S:
      return {
        loading: false,
        cursor: action.payload.cursor,
        articles: action.payload.refresh
          ? action.payload.articles.map((article) => article.id)
          : state.articles.concat(action.payload.articles.map((article) => article.id)),
      };
    case UserAction.USER_LOGOUT:
      return initialState.savedPosts;
    default:
      return state;
  }
}

function cache(state = initialState.cache, action: RootActions): PostModel.State['cache'] {
  switch (action.type) {
    case PostAction.GET_POSTS_S:
    case PostAction.GET_UNLOCKED_POSTS_S:
    case PostAction.GET_SAVED_POSTS_S:
      return {
        ...state,
        ...action.payload.articles.reduce<PostModel.State['cache']>((cache, article) => {
          cache[article.id] = article;
          return cache;
        }, {}),
      };
    case ExploreAction.GET_RECOMMENDED_POSTS_S:
    case ExploreAction.GET_KEYWORD_POSTS_S:
      return {
        ...state,
        ...action.payload.list.reduce<PostModel.State['cache']>((cache, article) => {
          cache[article.id] = article;
          return cache;
        }, {}),
      };
    case PostAction.UNLOCK_POST:
    case PostAction.GET_SINGLE_POST:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case PostAction.SAVE_POST:
    case PostAction.UNSAVE_POST:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          saved: action.type === PostAction.SAVE_POST,
        },
      };
    case PostAction.LIKE_POST:
    case PostAction.UNLIKE_POST:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          like: action.type === PostAction.LIKE_POST,
          numLikes:
            state[action.payload].numLikes + (action.type === PostAction.LIKE_POST ? 1 : -1),
        },
      };
    case UserAction.USER_LOGOUT:
      return initialState.cache;
    default:
      return state;
  }
}

function comment(state = initialState.comment, action: RootActions): PostModel.State['comment'] {
  switch (action.type) {
    case PostAction.GET_COMMENTS:
      return {
        ...state,
        [action.payload.postId]: {
          loading: true,
          cursor: state[action.payload.postId]?.cursor || null,
          comments: state[action.payload.postId]?.comments || [],
        },
      };
    case PostAction.GET_COMMENTS_S:
      return {
        ...state,
        [action.payload.postId]: {
          loading: false,
          cursor: action.payload.cursor,
          comments: action.payload.refresh
            ? action.payload.comments
            : state[action.payload.postId].comments.concat(action.payload.comments),
        },
      };
    case PostAction.SEND_COMMENT:
      return {
        ...state,
        [action.payload.postId]: {
          ...state[action.payload.postId],
          comments: [action.payload.comment, ...state[action.payload.postId].comments],
        },
      };
    case UserAction.USER_LOGOUT:
      return initialState.comment;
    default:
      return state;
  }
}

function video(state = initialState.video, action: RootActions): PostModel.State['video'] {
  switch (action.type) {
    case PostAction.PLAY_VIDEO:
      return {
        ...state,
        playing: action.payload,
      };
    case PostAction.STOP_VIDEO:
      return {
        ...state,
        playing: null,
      };
    case PostAction.MUTE_VIDEO:
    case PostAction.UNMUTE_VIDEO:
      return {
        ...state,
        muted: action.type === PostAction.MUTE_VIDEO,
      };
    case UserAction.USER_LOGOUT:
      return initialState.video;
    default:
      return state;
  }
}

export default combineReducers<PostModel.State>({
  suggested,
  banner,
  posts,
  unlockedPosts,
  savedPosts,
  cache,
  comment,
  video,
});
