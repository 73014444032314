import {Box, Button, Typography as Text} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useEffect, useState} from 'react';

import {leftPadding} from 'utils/number';

import {ReactComponent as ChevronRight} from 'assets/icon/regular/chevron-right.svg';
import {ReactComponent as ClockIcon} from 'assets/icon/regular/clock.svg';

import Icon from './Icons';
import Typography from './i18n/Typography';

interface WatchingCountdownProps {
  time: number;
  onClickDeposit?: () => void;
}

function WatchingCountdown({time, onClickDeposit}: WatchingCountdownProps) {
  const styles = useThemeStyles();
  const [now, setNow] = useState(Date.now());
  const diff = Math.max(time - now, 0);
  let second = Math.ceil(diff / 1000);
  const minute = Math.floor(second / 60);
  second -= minute * 60;

  useEffect(() => {
    const timer = window.setInterval(() => {
      const current = Date.now();
      setNow(current);
      if (current >= time) {
        clearInterval(timer);
      }

      return () => {
        clearInterval(timer);
      };
    }, 1000);
  }, [time]);

  return (
    <Box
      className={styles.countdown}
      position="relative"
      display={second <= 0 ? 'none' : 'flex'}
      py={1}
      px={2}>
      <Icon svg={ClockIcon} />

      <Box display="flex" flexDirection="column" ml={1} width={100}>
        <Typography color="textSecondary" className="nowrap">
          time.watch_remaining
        </Typography>
        <Text variant="h1" component="span">
          {leftPadding(minute, 2)}:{leftPadding(second, 2)}
        </Text>
      </Box>

      <Box position="absolute" right={0} bottom={-20}>
        <Button
          className={styles.gradient}
          size="small"
          endIcon={<Icon svg={ChevronRight} />}
          onClick={onClickDeposit}>
          <Typography>input.deposit_now</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default WatchingCountdown;
