import {AppBar, Box, Toolbar} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import StoryList from 'routes/home/StoryList';

import {loadRecommendedPosts} from 'model/explore/ExploreAction';
import {
  exploreArticlesSelector,
  hasExploreArticlesNextPage,
  isExploreArticlesLoading,
} from 'model/explore/ExploreSelector';
import {AppDispatch} from 'model/helper';

import BackButton from 'components/BackButton';
import PointLabel from 'components/PointLabel';
import Typography from 'components/i18n/Typography';

function ExploreRecommendedPage() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation<{start?: string}>();
  const articles = useSelector(exploreArticlesSelector);
  const isLoading = useSelector(isExploreArticlesLoading);
  const hasNextPage = useSelector(hasExploreArticlesNextPage);

  useEffect(() => {
    if (articles.length === 0) {
      dispatch(loadRecommendedPosts(true));
    }

    // eslint-disable-next-line
  }, []);

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadRecommendedPosts(false));
  }, [isLoading, hasNextPage, dispatch]);

  return (
    <Box className="full" display="flex" flexDirection="column">
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/explore" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.explore
            </Typography>
          </Box>
          <PointLabel flex={1} justifyContent="flex-end" />
        </Toolbar>
      </AppBar>
      <StoryList
        start={location.state?.start}
        items={articles}
        hasNextPage={hasNextPage}
        loadMore={loadMore}
      />
    </Box>
  );
}

export default ExploreRecommendedPage;
