import {Box, Button, ButtonGroup, Drawer} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {useCallback} from 'react';

import {PostModel} from 'model/post/PostTypes';

import Typography from 'components/i18n/Typography';

interface PostActionDrawerProps {
  article: PostModel.Article | undefined;
  onClose(): void;
}

function PostActionDrawer({article, onClose}: PostActionDrawerProps) {
  const styles = useThemeStyles();

  const share = useCallback(() => {
    if (window.navigator.share && article) {
      window.navigator
        .share({
          url: article.streamInfo
            ? `${window.location.origin}/livestream/${article.id}`
            : `${window.location.origin}/post/${article.id}`,
          title: article.content.join(' '),
          text: article.content.join(' '),
        })
        .then(onClose);
    }
  }, [article, onClose]);

  const copyLink = useCallback(() => {
    if (article) {
      navigator.clipboard
        .writeText(
          article.streamInfo
            ? `${window.location.origin}/livestream/${article.id}`
            : `${window.location.origin}/post/${article.id}`,
        )
        .then(onClose);
    }
  }, [article, onClose]);

  return (
    <Drawer
      anchor="bottom"
      open={article !== undefined}
      onClose={onClose}
      PaperProps={{style: {background: 'transparent'}}}>
      <Box p={2} display="flex" flexDirection="column" className="safeBottom">
        <Box mb={3}>
          <ButtonGroup
            orientation="vertical"
            variant="contained"
            className={styles.actionGroup}
            color="default">
            {navigator.share && (
              <Button className={styles.action} onClick={share}>
                <Typography>common.share</Typography>
              </Button>
            )}

            <Button className={styles.action} onClick={copyLink}>
              <Typography>common.copy_link</Typography>
            </Button>
          </ButtonGroup>
        </Box>

        <Button variant="contained" onClick={onClose} className={styles.action}>
          <Typography>common.cancel</Typography>
        </Button>
      </Box>
    </Drawer>
  );
}

export default PostActionDrawer;
