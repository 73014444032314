// import {Box, Typography} from '@material-ui/core';
import {Box, TypographyProps as MUITypographyProps} from '@material-ui/core';
import React, {CSSProperties} from 'react';

import Typography from './Typography';

interface CoronaTextProps extends MUITypographyProps {
  text: string;
  shadow: string;
  keys?: object;
  fallback?: string;
}

function CoronaText({text, shadow, ...props}: CoronaTextProps) {
  // const
  const className = props.className;
  const style: CSSProperties = {
    textShadow: `${shadow} 0px 0px 1px`,
    whiteSpace: 'nowrap',
    ...(props.style || {}),
    position: 'absolute',
    left: 0,
    right: 0,
  };

  return (
    <Box position="relative" component="span">
      <Typography {...props} className={className} style={props.style}>
        {text}
      </Typography>
      <Typography {...props} className={className} style={style}>
        {text}
      </Typography>
      <Typography {...props} className={className} style={style}>
        {text}
      </Typography>
      <Typography {...props} className={className} style={style}>
        {text}
      </Typography>
    </Box>
  );
}

export default CoronaText;
