import {Box, Button} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {CSSProperties, FC, ReactNode} from 'react';

import Typography from './i18n/Typography';

interface ConfirmationDialogProps {
  title: ReactNode;
  onConfirm?(): void;
  onCancel?(): void;
  style?: CSSProperties;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  style,
  children,
  onConfirm,
  onCancel,
}) => {
  const styles = useThemeStyles();
  // const charge = mode === WatchMode.Free ? 0 : mode === WatchMode.OM ? omCharge : ooCharge;
  // const needDeposit = balance < charge;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pb={2}
      className={`${styles.transparentBg} ${styles.livestreamDialog}`}
      borderRadius={5}
      style={style}>
      <Box className={styles.gradient} p={0.5} textAlign="center">
        {title}
      </Box>
      <Box my={2} alignSelf="center" textAlign="center">
        {children}
      </Box>

      <Box mx={3}>
        <Box
          position="relative"
          display="flex"
          width="100%"
          alignItems="center"
          className="gradientButtonWrapper"
          mb={2}>
          <Box
            borderRadius={16}
            className={[styles.gradient, 'gradientButton', 'overlay'].join(' ')}></Box>
          <Button variant="outlined" size="small" fullWidth onClick={onConfirm}>
            <Typography>common.yes</Typography>
          </Button>
        </Box>
        <Box
          position="relative"
          display="flex"
          width="100%"
          alignItems="center"
          className="gradientButtonWrapper"
          mb={2}>
          <Box
            borderRadius={16}
            className={[styles.gradient, 'gradientButton', 'overlay'].join(' ')}></Box>
          <Button variant="outlined" size="small" fullWidth onClick={onCancel}>
            <Typography>common.no</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmationDialog;
