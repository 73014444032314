import {Box, ButtonBase, IconButton, Typography as Text} from '@material-ui/core';
import React, {CSSProperties, useEffect, useRef, useState} from 'react';

import {leftPadding} from 'utils/number';

import {ReactComponent as PlayIcon} from 'assets/icon/solid/play-circle.svg';
import {ReactComponent as MuteIcon} from 'assets/icon/solid/volume-slash.svg';
import {ReactComponent as UnmuteIcon} from 'assets/icon/solid/volume.svg';

import Icon from './Icons';

interface CardMediaPlayerProps {
  className?: string;
  style?: CSSProperties;
  src: string;
  preview?: string;
  muted?: boolean;
  playIcon?: boolean;
  volumeIcon?: boolean;
  showLength?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  onClickVolume?: () => void;
}

function CardMediaPlayer({
  className,
  style,
  src,
  preview,
  muted = true,
  onClickVolume,
  volumeIcon = true,
  playIcon = true,
  showLength = true,
  autoPlay = false,
  loop = false,
}: CardMediaPlayerProps) {
  const player = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState('00:00');

  return (
    <Box position="relative" display="flex" className="full" alignItems="center">
      <video
        className={className}
        style={style}
        ref={player}
        preload="auto"
        playsInline
        muted={muted}
        autoPlay={autoPlay}
        loop={loop}
        onLoadedMetadata={() => {
          let seconds = player.current?.duration || 0;
          const minutes = Math.floor(seconds / 60);
          seconds = Math.round(seconds % 60);
          setDuration(`${leftPadding(minutes, 2)}:${leftPadding(seconds, 2)}`);
        }}
        onPlay={() => {
          setPlaying(true);
        }}>
        <source src={src} type="video/mp4" />
      </video>

      {duration === '00:00' && preview && (
        <Box className="overlay" display="flex" alignItems="center">
          <img alt="preview" src={preview} className={`fullMedia ${className}`} style={style} />
        </Box>
      )}

      {!playing && showLength && (
        <Box position="absolute" top={5} right={5}>
          <Text className="videoDuration">{duration}</Text>
        </Box>
      )}
      {!playing && playIcon && (
        <ButtonBase
          component="div"
          className="overlay"
          onClick={() => {
            player.current?.play();
          }}>
          <Icon svg={PlayIcon} className="videoPlay" />
        </ButtonBase>
      )}
      {playing && volumeIcon && (
        <Box position="absolute" right={5} bottom={5}>
          <IconButton onClick={onClickVolume}>
            <Icon className="videoDuration" svg={muted ? MuteIcon : UnmuteIcon} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default CardMediaPlayer;
