import {
  AppBar,
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import {useThemeStyles} from 'context/Theme';
import React, {ChangeEvent, useCallback, useMemo, useState} from 'react';
import Cropper from 'react-easy-crop';
import {Area} from 'react-easy-crop/types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {AppDispatch} from 'model/helper';
import {updateProfile} from 'model/user/UserActions';
import {getMyInfo} from 'model/user/UserSelector';

import BackButton from 'components/BackButton';
import Icon from 'components/Icons';
import TextField from 'components/i18n/TextField';
import Typography from 'components/i18n/Typography';

import {ReactComponent as PencilIcon} from 'assets/icon/solid/pencil.svg';

const PROFILE_SIZE = 500;

function ProfileEditPage() {
  const styles = useThemeStyles();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const user = useSelector(getMyInfo);
  const [bio, setBio] = useState(user.bio);
  const [name, setName] = useState(user.name);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState<{
    sx: number;
    sy: number;
    sw: number;
    sh: number;
  }>({
    sx: 0,
    sy: 0,
    sw: 0,
    sh: 0,
  });

  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [blob, setBlob] = useState<Blob | null>(null);
  const blobUrl = useMemo(() => {
    if (blob) {
      return URL.createObjectURL(blob);
    } else {
      return '';
    }
  }, [blob]);

  const handleSelectImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
      setFileUrl(URL.createObjectURL(file));
      e.target.value = '';
    },
    [fileUrl],
  );

  const onCropComplete = useCallback((_, croppedAreaPixels: Area) => {
    setCropArea({
      sx: croppedAreaPixels.x,
      sy: croppedAreaPixels.y,
      sw: croppedAreaPixels.width,
      sh: croppedAreaPixels.height,
    });
  }, []);

  const getCroppedImage = useCallback(() => {
    setLoading(true);
    const image = new Image();
    image.src = fileUrl;

    const canvas = document.createElement('canvas');
    canvas.width = PROFILE_SIZE;
    canvas.height = PROFILE_SIZE;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      cropArea.sx,
      cropArea.sy,
      cropArea.sw,
      cropArea.sh,
      0,
      0,
      PROFILE_SIZE,
      PROFILE_SIZE,
    );

    canvas.toBlob(
      (blob) => {
        setBlob(blob);
        setFileUrl('');
        setLoading(false);
      },
      'image/jpeg',
      0.8,
    );
  }, [cropArea, fileUrl]);

  const handleSave = useCallback(() => {
    setLoading(true);
    dispatch(updateProfile(user.userId, blob, bio, user.name !== '' ? null : name))
      .then(history.goBack)
      .finally(() => {
        setLoading(false);
      });
  }, [history, dispatch, user, bio, blob, name]);

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar>
          <Box flex={1}>
            <BackButton to="/profile" />
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Typography variant="h6" component="h2">
              title.profile_edit
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" pr={-1}>
            <Box mr={-1}>
              <Button onClick={handleSave}>
                <Typography color="primary">common.save</Typography>
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="form" display="flex" flexDirection="column" alignItems="center" p={2}>
        <IconButton component="label">
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              <Box className={styles.editBadge} borderRadius={12} p={0.5}>
                <Icon svg={PencilIcon} />
              </Box>
            }>
            <Avatar src={blobUrl || user.picture} className="avatar100" />
          </Badge>
          <input className="hidden" type="file" accept=".jpg" onChange={handleSelectImage} />
        </IconButton>

        <Box display="flex" width="100%" whiteSpace="nowrap" alignItems="center" my={2}>
          <Box mr={5}>
            <Typography>input.user_name</Typography>
          </Box>
          <TextField
            value={name}
            fullWidth
            disabled={user.name !== ''}
            className={styles.noBgInput}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>

        <Box width="100%" alignItems="center" my={2}>
          <Box mb={2}>
            <Typography>input.user_bio</Typography>
          </Box>
          <TextField
            fullWidth
            multiline
            variant="filled"
            className={styles.textarea}
            rows={6}
            inputProps={{
              maxLength: 150,
            }}
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
        </Box>

        {fileUrl && (
          <Box className={`overlay ${styles.bg}`} zIndex={1}>
            <Cropper
              image={fileUrl}
              crop={crop}
              zoom={zoom}
              cropShape="round"
              showGrid={false}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <Box
              p={1}
              className="overlay"
              bottom="unset"
              display="flex"
              justifyContent="space-between">
              <Button onClick={() => setFileUrl('')}>
                <Typography>common.cancel</Typography>
              </Button>
              <Button onClick={getCroppedImage}>
                <Typography color="primary">common.confirm</Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Backdrop open={loading} className="z2">
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default ProfileEditPage;
