import { PayloadAction, PureAction } from "model/helper";

export class GiftAction {
  public static readonly GET_GIFTS = 'GET_GIFTS';
  public static readonly OPEN_GIFT_DRAWER = 'OPEN_GIFT_DRAWER';
  public static readonly CLOSE_GIFT_DRAWER = 'CLOSE_GIFT_DRAWER';
  public static readonly SEND_GIFT = 'SEND_GIFT';
}

export enum GiftLocation {
  Nowhere = 0,
  Stream = 1,
  Video = 2,
  Chat = 3,
  Post = 4,
}

export declare namespace GiftModel {
  export interface State {
    categories: Array<{
      name: string;
      list: string[];
    }>;
    cache: {
      [giftId: string]: Gift;
    };
    open: {
      userId: string;
      targetId?: string;
      location: GiftLocation;
      onSend?: () => void;
    } | null;
  }

  export interface Gift {
    readonly id: string;
    readonly name: string;
    readonly picture: string;
    readonly point: number;
    readonly animateId: string;
    readonly comboAnimateId: string;
    readonly combo: number;
    readonly toyEnable: boolean;
  }

  type OpenGiftDrawerAction = PayloadAction<
    typeof GiftAction['OPEN_GIFT_DRAWER'],
    {
      userId: string;
      targetId?: string;
      location: GiftLocation;
      onSend?: () => void;
    }
  >

  type CloseGiftDrawerAction = PureAction<typeof GiftAction['CLOSE_GIFT_DRAWER']>;

  type GetGiftsAction = PayloadAction<
    typeof GiftAction['GET_GIFTS'],
    {
      categories: Array<{
        name: string;
        list: string[];
      }>;
      gifts: Gift[];
    }
  >

  type SendGiftAction = PayloadAction<
    typeof GiftAction['SEND_GIFT'],
    {
      giftId: string;
      targetId: string;
      location: GiftLocation;
    }
  >

  export type ActionTypes = OpenGiftDrawerAction | CloseGiftDrawerAction | GetGiftsAction | SendGiftAction;
}
