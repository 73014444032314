import {Avatar, Box, Button, Divider, Typography as Text} from '@material-ui/core';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLastLocation} from 'react-router-last-location';
import StoryList from 'routes/home/StoryList';

import {loadKeywordPosts} from 'model/explore/ExploreAction';
import {
  exploreArticlesSelector,
  hasExploreArticlesNextPage,
  isExploreArticlesLoading,
} from 'model/explore/ExploreSelector';
import {SearchType} from 'model/explore/ExploreTypes';
import {openGiftDrawer} from 'model/gift/GiftAction';
import {GiftLocation} from 'model/gift/GiftTypes';
import {AppDispatch} from 'model/helper';
import {followUser, loadUser, unfollowUser} from 'model/user/UserActions';
import {getUserById} from 'model/user/UserSelector';

import {useWindowSize} from 'utils/hook';

import AudioButton from 'components/AudioButton';
import Carousel from 'components/Carousel';
import Typography from 'components/i18n/Typography';

const COVER_RATIO = 16 / 9;

interface UserStoryListProps {
  userId: string;
}

function UserStoryList({userId}: UserStoryListProps) {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const width = useWindowSize();
  const height = width / COVER_RATIO;
  const imageStyle: CSSProperties = useMemo(() => {
    return {width, height};
  }, [width, height]);
  const lastLocation = useLastLocation();

  const ref = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const user = useSelector(getUserById)(userId);
  const articles = useSelector(exploreArticlesSelector);
  const isLoading = useSelector(isExploreArticlesLoading);
  const hasNextPage = useSelector(hasExploreArticlesNextPage);

  const startArticleId =
    !lastLocation || lastLocation.pathname === '/'
      ? undefined
      : lastLocation.pathname.startsWith('/post/')
      ? lastLocation.pathname.split('/')[2]
      : undefined;

  useEffect(() => {
    if (articles.length > 0 && lastLocation && lastLocation.pathname.startsWith('/post/')) {
      return;
    }
    dispatch(loadKeywordPosts(userId, SearchType.Streamer, true));

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    setHeaderHeight(ref.current?.clientHeight ?? 400);
  }, [user]);

  useEffect(() => {
    dispatch(loadUser(userId));
  }, [dispatch, userId]);

  const handleFollow = useCallback(() => {
    if (user?.followed === undefined) {
      return;
    }

    if (user.followed) {
      dispatch(unfollowUser(userId));
    } else {
      dispatch(followUser(userId));
    }
  }, [dispatch, userId, user?.followed]);

  const toMessagePage = useCallback(() => {
    history.push(`/message/${userId}`);
  }, [history, userId]);

  const loadMore = useCallback(() => {
    if (isLoading || !hasNextPage) {
      return null;
    }

    return dispatch(loadKeywordPosts(userId, SearchType.Streamer, false));
  }, [isLoading, hasNextPage, userId, dispatch]);

  const handleOpenGift = useCallback(() => {
    dispatch(
      openGiftDrawer({
        userId,
        location: GiftLocation.Chat,
      }),
    );
  }, [dispatch, userId]);

  const Header = useCallback(
    ({style}: {style?: CSSProperties}) => {
      return (
        <Box style={style}>
          {(user?.covers ?? []).length > 0 && (
            <Carousel interval={3000} style={imageStyle}>
              {(user?.covers ?? []).map((url, index) => (
                <img key={index} alt="cover" src={url} style={imageStyle} className="fullMedia" />
              ))}
            </Carousel>
          )}

          <Box display="flex" p={2}>
            <Avatar src={user?.picture} className="avatar85" />
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Text variant="h6" component="span">
                  {user?.numPosts ?? 0}
                </Text>
                <Typography>label.numPosts</Typography>
              </Box>
              <Button
                variant={user?.followed ? 'outlined' : 'contained'}
                color={user?.followed ? 'default' : 'primary'}
                size="small"
                onClick={handleFollow}>
                <Typography>{user?.followed ? 'input.unfollow' : 'input.follow'}</Typography>
              </Button>
            </Box>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Text variant="h6" component="span">
                  {user?.numFollowee ?? 0}
                </Text>
                <Typography>label.numFollowing</Typography>
              </Box>
              <Button variant="outlined" size="small" onClick={toMessagePage}>
                <Typography>input.send_message</Typography>
              </Button>
            </Box>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Text variant="h6" component="span">
                  {user?.numFollower ?? 0}
                </Text>
                <Typography>label.numFollower</Typography>
              </Box>
              <Button variant="outlined" size="small" onClick={handleOpenGift}>
                <Typography>input.send_gift</Typography>
              </Button>
            </Box>
          </Box>
          <Box display="flex" pb={1}>
            <Box flex={1} px={2}>
              <Text>{user?.bio ?? ''}</Text>
            </Box>

            {user?.voiceBio && (
              <Box pr={2} py={1}>
                <AudioButton src={user.voiceBio} />
              </Box>
            )}
          </Box>
          <Divider />
        </Box>
      );
    },
    [user, handleFollow, toMessagePage, handleOpenGift, imageStyle],
  );

  return (
    <>
      <div ref={ref} className="invisible">
        <Header />
      </div>

      <StoryList
        key={headerHeight}
        start={startArticleId}
        items={articles}
        hasNextPage={hasNextPage}
        loadMore={loadMore}
        HeaderComponent={Header}
        headerHeight={headerHeight}
      />
    </>
  );
}

export default UserStoryList;
